import { Bounce, toast, ToastOptions } from 'react-toastify';

export const BASIC_TOASTER_SETTINGS = (theme: string) => {
	return {
		position: 'top-center',
		autoClose: 3000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		theme: theme,
		transition: Bounce,
	};
};

export const useToast = (text: string) => {
	const theme = JSON.parse(localStorage.getItem(localStorage.getItem('userId') as string) as string)?.theme ? JSON.parse(localStorage.getItem(localStorage.getItem('userId') as string) as string)?.theme : 'light'

	//const theme = useAppSelector(selectTheme);
	const notify = () => {
		toast(text, BASIC_TOASTER_SETTINGS(theme) as ToastOptions);
	};

	return notify;
};

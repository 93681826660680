import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePinInput } from 'react-pin-input-hook';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { CustomButton } from '../../common/buttons/index';
import { PinInput } from '../../common/inputs/index';
import {
	useAskNewPhoneCodeMutation,
	useSubmitPhoneCodeMutation
} from '../../../data/mutations/registration';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectRegistrationCountry, selectRegistrationFullPhone, selectRegistrationState } from '../../../services/registration';
import {
	reduceCounter,
	selectCounter,
	selectIsResendCodeActive,
	selectIsTelegram,
	selectMaskedContact,
	setIsResendCodeActive,
	updateCounter
} from '../../../services/root';
import { APIError } from '../../common/error';
import { HeaderLayout } from '../../common/header';
import { mapClassnameForMobile } from '../../../utils';
import { selectCountriesDictionary } from '../../../services/dictionaries';

const RegistrationPhoneCodeLayout = () => {
	const isTelegram = useAppSelector(selectIsTelegram);
	const counter = useAppSelector(selectCounter);
	const isResendCodeActive = useAppSelector(selectIsResendCodeActive);
	const maskedContact = useAppSelector(selectMaskedContact);
	const identity = useAppSelector(selectRegistrationFullPhone);
	const state = useAppSelector(selectRegistrationState);
	const country = useAppSelector(selectRegistrationCountry);
	const countries = useAppSelector(selectCountriesDictionary);

	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const { askNewCode } = useAskNewPhoneCodeMutation();
	const { isError, submitPhone, mutationError, isPending } =
		useSubmitPhoneCodeMutation(navigate);

	const [fakeError, setFakeError] = useState<boolean>(false);

	const [values, setValues] = useState<string[]>(['', '', '', '', '', '']);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setFakeError(false);
			setValues(values);
		},
		onComplete: (value: string) => {
			if (value.length === 6) {
				submitPhone({
					code: value,
					identity: identity,
					identityType: 'phone',
					dispatch: dispatch,
					state: state
				});
			}
		},
		placeholder: ''
	});

	const handleResendCode = async () => {
		if(countries != undefined) {
			setFakeError(false);
			askNewCode({
				identity: identity,
				identityType: 'phone',
				phoneCountryCode: countries?.dictionary?.find((dictCountry) => dictCountry?.phoneCode === country?.code)?.code as string,
				dispatch: dispatch,
				state: state
			});
		}
	};

	const handleBackClick = () => {
		navigate(ROUTES.REGISTRATION.PHONE);
	};

	useEffect(() => {
		if (isError) {
			setFakeError(true);
			setValues(['', '', '', '', '', '']);
		}
	}, [isError]);

	useEffect(() => {
		if (counter == 0) {
			dispatch(setIsResendCodeActive(true));
			dispatch(updateCounter(60));
		}
		const timer =
			counter > 0 && setInterval(() => dispatch(reduceCounter()), 1000);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return () => clearInterval(timer as any);
	}, [counter]);

	const handlePasteClick = async () => {
		const clipboardContent = await navigator.clipboard.readText();
		if (
			clipboardContent &&
			clipboardContent.length === 6 &&
			!isNaN(Number(clipboardContent))
		) {
			setValues(clipboardContent.split(''));
			setFakeError(false);

			submitPhone({
				code: clipboardContent,
				identity: identity,
				identityType: 'phone',
				dispatch: dispatch,
				state: state
			});
		}
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout
					isFunctionalHeader
					handleBackClick={handleBackClick}
				/>
				<div className={mapClassnameForMobile('walletLayout', true)}>
					<div className='innerContainer'>
						<h1 className={'confirmEmailText'}>
							{t('twoFactorAuthMethods_phone')}
						</h1>
						<h1 className={'confirmCodeText'}>
							{t('twoFactorAuthCode_subtitle') + ' ' + maskedContact}
						</h1>
						<div className={'codeInputBlock'}>
							<PinInput
								isLoading={isPending}
								isError={fakeError}
								fields={fields}
							/>
						</div>
						{isResendCodeActive ? (
							<h1
								className={'resendCodeText'}
								onClick={handleResendCode}
							>
								{t('general_resendCode')}
							</h1>
						) : (
							<h1 className={'resendCodeText-inactive'}>
								{t('general_resendCodeIn')} {counter}
							</h1>
						)}
						<APIError isError={fakeError} error={mutationError} />
					</div>
				</div>
				{!isTelegram && (
					<div className='pasteButtonContainer'>
						<CustomButton
							isGrey
							text={t('general_pasteCode') as string}
							onClick={handlePasteClick}
							className='confirmFullWidthButton'
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default RegistrationPhoneCodeLayout;

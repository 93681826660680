import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../../types/routes';
import { HeaderLayout } from '../../../common/header';
import { formatNumber } from '../../../../utils';
import { CustomButton } from '../../../common/buttons';
import { useTranslation } from 'react-i18next';
import { selectMainAccountRests } from '../../../../services/wallet';
import { TAsset } from '../../../../types/types';
import { WhaleIcon } from '../../../common/icons';
import { RejectOperationModal, SaveAddressModal } from '../../../common/modals';
import { selectBankTemplates } from '../../../../services/withdraw/templates.withdraw.slice';
import {
	selectBankWithdrawAmount,
	selectBankWithdrawAsset,
	selectBankWithdrawFee,
	selectBankWithdrawIbanAccountNumber,
	selectBankWithdrawIsChecked,
	selectBankWithdrawOperationId,
	setBankWithdrawInit,
} from '../../../../services/withdraw/bank.withdraw.slice';

const BankWithdrawSummaryLayout = () => {
	const fee = useAppSelector(selectBankWithdrawFee);
	const amount = useAppSelector(selectBankWithdrawAmount);
	const selectedAsset = useAppSelector(selectBankWithdrawAsset);
	const mainCurrencies = useAppSelector(selectMainAccountRests);
	const savedBanks = useAppSelector(selectBankTemplates);
	const operationId = useAppSelector(selectBankWithdrawOperationId);
	const isChecked = useAppSelector(selectBankWithdrawIsChecked);

	const ibanAccountNumber = useAppSelector(selectBankWithdrawIbanAccountNumber);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();

	const [mainAsset, setMainAsset] = useState<TAsset>();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isSaveCardModalOpen, setIsSaveCardModalOpen] = useState<boolean>(false);

	const [isNeedToSaveBank, setIsNeedToSaveBank] = useState<boolean>(false);

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleSaveCardModalOpen = () => {
		setIsSaveCardModalOpen(!isSaveCardModalOpen);
	};

	const clearBankWithdrawState = () => {
		dispatch(setBankWithdrawInit());
		navigate(ROUTES.WALLET.INDEX);
	};

	useEffect(() => {
		if (amount === '') {
			dispatch(setBankWithdrawInit());
			navigate(ROUTES.WALLET.INDEX);
		}
		if (mainCurrencies) {
			setMainAsset(
				mainCurrencies?.assets?.find(
					(asset) => asset?.code === selectedAsset?.code
				)
			);
		}
		if (
			savedBanks?.filter(
				(tempTemplate) =>
					!!tempTemplate?.params.find(
						(param) =>
							param.paramId === 'beneficiaryAccountNumber' &&
							param.paramValue === ibanAccountNumber
					)
			)?.length === 0
		) {
			setIsNeedToSaveBank(true);
		}
	}, []);

	const handleBackClickButton = () => {
		dispatch(setBankWithdrawInit());
		navigate(ROUTES.WALLET.INDEX);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout isRejectHeader handleBackClick={handleModalOpen} />
				<SaveAddressModal
					type="bank"
					handleIsModalOpen={handleSaveCardModalOpen}
					isModalOpen={isSaveCardModalOpen}
					setIsNeedToSave={setIsNeedToSaveBank}
				/>
				<RejectOperationModal
					handleIsModalOpen={handleModalOpen}
					isModalOpen={isModalOpen}
					operId={operationId}
					additionalFunc={clearBankWithdrawState}
				/>
				<div
					style={{
						zIndex: '1',
						top: 'auto',
						marginBottom: '140px',
						gap: '0',
					}}
					className="walletLayout authMainContainer"
				>
					<WhaleIcon className="whaleConvertIcon" />
					<h1 className={'modalWalletConvertSummaryHeader'}>
						{t('operation_operationIsProcessed')}
					</h1>
					<h1 className={'modalWalletConvertSummaryBottom'}>
						{t('operation_conversionProcessedDetails')}
					</h1>
				</div>
			</div>
			<div
				style={{
					zIndex: '3',
				}}
				className="depositButtonsBottomContainer"
			>
				<div className="commonGreyContainer">
					<div className="commonItem">
						<h1 className="cryptoWithdrawCommonItemLeftText">
							{t('withdraw_totalWithdraw')}
						</h1>
						<h1 className="cryptoWithdrawCommonItemRightText">
							{formatNumber(
								isChecked
									? Number(Number(amount) + Number(fee)).toFixed(
											mainAsset?.precision
									  )
									: Number(amount).toFixed(mainAsset?.precision),
								mainAsset?.precision
							)}
							{` ${selectedAsset?.code}`}
						</h1>
					</div>
					<div className="commonItem">
						<h1 className="cryptoWithdrawCommonItemLeftText">
							{t('withdrawConfirmation_withdrawFee')}
						</h1>
						<h1 className="cryptoWithdrawCommonItemRightText">
							{formatNumber(
								Number(fee).toFixed(mainAsset?.precision),
								mainAsset?.precision
							)}
							{` ${selectedAsset?.code}`}
						</h1>
					</div>
					<div className="commonItem">
						<h1 className="cryptoWithdrawCommonItemLeftText">
							{t('withdrawBank_toAccount')}
						</h1>
						<h1
							style={{
								wordBreak: 'break-all',
							}}
							className="cryptoWithdrawCommonItemRightText"
						>
							{ibanAccountNumber}
						</h1>
					</div>
					{isNeedToSaveBank && (
						<div
							style={{
								width: '100%',
								padding: '0 24px',
								boxSizing: 'border-box',
							}}
						>
							<CustomButton
								isSaveAddress
								text={t('withdrawResult_saveAddress') as string}
								onClick={handleSaveCardModalOpen}
								className="confirmFullWidthButton"
							/>
						</div>
					)}
				</div>
				<CustomButton
					text={t('general_finishAndClose') as string}
					onClick={handleBackClickButton}
					className="confirmFullWidthButton"
				/>
			</div>
		</div>
	);
};

export default BankWithdrawSummaryLayout;

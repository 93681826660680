import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../../types/titles';
import DevicesLayout from '../../../../components/profile/security/devices';

const Devices = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.PROFILE.SECURITY.DEVICES}</title>
			</Helmet>
			<DevicesLayout />
		</>
	);
};

export default Devices;

import { createSlice } from '@reduxjs/toolkit';
import { TAllMarketAssets, TMarketAsset, TMarketTab } from '../../types/types';
import { TRootState } from '../../store';

export const selectMarketAssets = (state: TRootState) => state.market.marketAssets;

export const selectIsMarketPageInited = (state: TRootState) =>
	state.market.isMarketPageInited;

export const selectCurrentTab = (state: TRootState) => state.market.currentTab;

export const selectTimeFrame = (state: TRootState) => state.market.timeFrame;

export const selectTotalAmount = (state: TRootState) => state.market.totalAmount;

export const selectIsNonMainAccountsNotEmpty = (state: TRootState) =>
	state.market.isNonMainAccountsNotEmpty;

export const selectTotalBaseAmount = (state: TRootState) => state.market.totalBaseAmount;

interface IMarketState {
	marketAssets: TAllMarketAssets;
	currentTab: string;
	isMarketPageInited: boolean;
	timeFrame: string;
	totalAmount: number;
	totalBaseAmount: number;
	isNonMainAccountsNotEmpty: {
		spot: boolean;
		margin: boolean;
		commission: boolean;
	};
}

const initialState: IMarketState = {
	marketAssets: {},
	currentTab: '',
	isMarketPageInited: false,
	timeFrame: '',
	totalAmount: 0,
	totalBaseAmount: 0,
	isNonMainAccountsNotEmpty: {
		spot: false,
		margin: false,
		commission: false,
	},
};

export const marketSlice = createSlice({
	name: 'market',
	initialState,
	reducers: {
		setMarketAssets(state, action) {
			state.marketAssets[action.payload.filter] = action.payload.assets.map(
				(asset: TMarketAsset) => {
					const copiedAsset = JSON.parse(JSON.stringify(asset));
					if (copiedAsset?.percentChange1h === undefined) {
						Object.assign(copiedAsset, {
							percentChange1h: 0,
						});
					}
					if (copiedAsset?.percentChange24h === undefined) {
						Object.assign(copiedAsset, {
							percentChange24h: 0,
						});
					}
					if (copiedAsset?.percentChange30d === undefined) {
						Object.assign(copiedAsset, {
							percentChange30d: 0,
						});
					}
					if (copiedAsset?.percentChange7d === undefined) {
						Object.assign(copiedAsset, {
							percentChange7d: 0,
						});
					}
					return copiedAsset;
				}
			);
			if (action.payload.filter === 'all' && state.marketAssets['crypto']) {
				state.marketAssets['crypto'].forEach((asset) => {
					const copiedAsset = action.payload.assets.find(
						(payloadAsset: TMarketAsset) => payloadAsset.code === asset.code
					);
					asset.baseRate = copiedAsset?.baseRate;
					asset.baseRateNoMarkup = copiedAsset?.baseRateNoMarkup;
					asset.percentChange1h = copiedAsset?.percentChange1h;
					asset.percentChange24h = copiedAsset?.percentChange24h;
					asset.percentChange7d = copiedAsset?.percentChange7d;
					asset.percentChange30d = copiedAsset?.percentChange30d;
				});
			}
		},
		setIsMarketPageInited(state, action) {
			state.isMarketPageInited = action.payload;
		},
		setInitMarketAssets(state, action) {
			action.payload.forEach((tab: TMarketTab) => {
				state.marketAssets[tab.filter] = [];
			});
			if (localStorage.getItem(localStorage.getItem('userId') as string) != null) {
				const accSettings = localStorage.getItem(
					localStorage.getItem('userId') as string
				);
				if (accSettings) {
					const parsedAccSettings = JSON.parse(accSettings);
					const localFavouriteAssets = parsedAccSettings?.favouriteAssets;
					if (localFavouriteAssets?.length > 0) {
						state.marketAssets['crypto'] = localFavouriteAssets;
					}
				}
			}
		},
		setFavouriteAssets(state, action) {
			if (action.payload.isNeedToAdd) {
				state.marketAssets['crypto']?.push(action.payload.asset);
			} else {
				state.marketAssets['crypto'] = state.marketAssets['crypto']?.filter(
					(asset) => asset.code != action.payload.asset.code
				);
			}

			if (localStorage.getItem(localStorage.getItem('userId') as string) != null) {
				const accSettings = localStorage.getItem(
					localStorage.getItem('userId') as string
				);
				if (accSettings) {
					const parsedAccSettings = JSON.parse(accSettings);
					Object.assign(parsedAccSettings, {
						favouriteAssets: state.marketAssets['crypto'],
					});
					localStorage.setItem(
						localStorage.getItem('userId') as string,
						JSON.stringify(parsedAccSettings)
					);
				}
			}
		},
		setCurrentTab(state, action) {
			state.currentTab = action.payload;
		},
		setTimeFrame(state, action) {
			state.timeFrame = action.payload;
		},
		setTotalAmount(state, action) {
			state.totalAmount += action.payload;
		},
		setTotalBaseAmount(state, action) {
			state.totalBaseAmount += action.payload;
		},
		setIsNonMainAccountsNotEmpty(state, action) {
			state.isNonMainAccountsNotEmpty[
				action.payload.accType as keyof IMarketState['isNonMainAccountsNotEmpty']
			] = action.payload.value;
		},
		clearAmount(state) {
			state.totalAmount = 0;
			state.totalBaseAmount = 0;
			state.isNonMainAccountsNotEmpty = {
				spot: false,
				margin: false,
				commission: false,
			};
		},
	},
});

export const {
	setMarketAssets,
	setIsMarketPageInited,
	setInitMarketAssets,
	setCurrentTab,
	setFavouriteAssets,
	setTimeFrame,
	setTotalAmount,
	setTotalBaseAmount,
	clearAmount,
	setIsNonMainAccountsNotEmpty,
} = marketSlice.actions;

import { useTranslation } from 'react-i18next';
import { HeaderLayout } from '../../common/header';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { mapClassnameForMobile } from '../../../utils';
import { useGetUserSavedAddresses } from '../../../data/mutations/profile';
import { SavedAddressesList } from '../../common/lists';
import { useState } from 'react';
import { TSavedAddress, TSavedBank, TSavedCard } from '../../../types/types';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	selectIsCommonTemplatesEmpty,
	setBankWithdrawCurrentSavedBank,
	setCryptoWithdrawCurrentSavedAddress,
} from '../../../services/withdraw/templates.withdraw.slice';
import {
	setCardWithdrawCard,
	setCardWithdrawInit,
} from '../../../services/withdraw/card.withdraw.slice';
import {
	BankWithdrawEditSavedAddressModal,
	CardWithdrawEditSavedAddressModal,
	CryptoWithdrawEditSavedAddressModal,
	SaveAddressModal,
} from '../../common/modals';
import { CustomButton } from '../../common/buttons';
import { setCryptoWithdrawInit } from '../../../services/withdraw/crypto.withdraw.slice';
import { setBankWithdrawInit } from '../../../services/withdraw/bank.withdraw.slice';

const SavedAddressesLayout = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();

	const isCommonTemplatesEmpty = useAppSelector(selectIsCommonTemplatesEmpty);

	const { isLoading, getSavedAddresses } = useGetUserSavedAddresses();

	const [isCreateTemplateModalOpen, setIsCreateTemplateModalOpen] =
		useState<boolean>(false);

	const [isEditSavedAddressModalOpen, setIsEditSavedAddressModalOpen] =
		useState<boolean>(false);
	const [isEditSavedCardModalOpen, setIsEditSavedCardModalOpen] =
		useState<boolean>(false);
	const [isEditSavedBankModalOpen, setIsEditSavedBankModalOpen] =
		useState<boolean>(false);

	const initWithdraws = () => {
		dispatch(setCryptoWithdrawInit());
		dispatch(setBankWithdrawInit());
		dispatch(setCardWithdrawInit());
	};

	const handleBackClickButton = () => {
		initWithdraws();
		navigate(ROUTES.PROFILE.INDEX);
	};

	const handleIsCreateTemplateModalOpen = () => {
		if (!isCreateTemplateModalOpen === false) {
			initWithdraws();
		}
		setIsCreateTemplateModalOpen(!isCreateTemplateModalOpen);
		getSavedAddresses();
	};

	const handleEditSavedCardModalOpen = (i?: TSavedCard | undefined) => {
		if (i) {
			dispatch(setCardWithdrawCard(i));
		}
		setIsEditSavedCardModalOpen(!isEditSavedCardModalOpen);
	};

	const handleEditSavedAddressModalOpen = (i?: TSavedAddress | undefined) => {
		if (i) {
			dispatch(setCryptoWithdrawCurrentSavedAddress(i));
		}
		setIsEditSavedAddressModalOpen(!isEditSavedAddressModalOpen);
	};

	const handleEditSavedBankModalOpen = (i?: TSavedBank | undefined) => {
		if (i) {
			dispatch(setBankWithdrawCurrentSavedBank(i));
		}
		setIsEditSavedBankModalOpen(!isEditSavedBankModalOpen);
	};

	return (
		<div className={'component'}>
			<div
				style={{
					height: 'auto',
					minHeight: '-webkit-fill-available',
				}}
				className={'innerComponent aboutComponent'}
			>
				<HeaderLayout
					isFunctionalHeader
					isAboutHeader
					handleBackClick={handleBackClickButton}
				/>
				<SaveAddressModal
					type="common"
					handleIsModalOpen={handleIsCreateTemplateModalOpen}
					isModalOpen={isCreateTemplateModalOpen}
				/>
				<CardWithdrawEditSavedAddressModal
					isModalOpen={isEditSavedCardModalOpen}
					handleIsModalOpen={handleEditSavedCardModalOpen}
				/>
				<CryptoWithdrawEditSavedAddressModal
					isModalOpen={isEditSavedAddressModalOpen}
					handleIsModalOpen={handleEditSavedAddressModalOpen}
				/>
				<BankWithdrawEditSavedAddressModal
					isModalOpen={isEditSavedBankModalOpen}
					handleIsModalOpen={handleEditSavedBankModalOpen}
				/>
				<div
					style={{ marginBottom: '100px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className="innerContainer">
						<h1 className="innerContainerMainText">
							{t('savedAddresses_title')}
						</h1>
						<SavedAddressesList
							handleIsCreateTemplateModalOpen={
								handleIsCreateTemplateModalOpen
							}
							handleEditSavedAddressModalOpen={
								handleEditSavedAddressModalOpen
							}
							handleEditSavedBankModalOpen={handleEditSavedBankModalOpen}
							handleEditSavedCardModalOpen={handleEditSavedCardModalOpen}
							isLoading={isLoading}
						/>
					</div>
				</div>
			</div>
			{!isCommonTemplatesEmpty && (
				<div className="savedAddressesButtonsBottomContainer">
					<div className="withdrawCryptoButtonsBottomInnerContainer">
						<CustomButton
							text={t('savedAddresses_addNewAddress') as string}
							onClick={handleIsCreateTemplateModalOpen}
							className="withdrawCryptoBottomButtonContainer"
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default SavedAddressesLayout;

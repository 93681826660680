import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { selectMainAccountRests, selectMainEWallet } from '../../../../services/wallet';
import { ROUTES } from '../../../../types/routes';
import { HeaderLayout } from '../../../common/header';
import { formatNumber, mapClassnameForMobile } from '../../../../utils';
import { APIError } from '../../../common/error';
import { CustomButton } from '../../../common/buttons';
import { selectAccessToken } from '../../../../services/auth';
import { useEffect } from 'react';
import { useSendBankWithdraw } from '../../../../data/mutations/withdraw';
import {
	selectBankWithdrawAmount,
	selectBankWithdrawAsset,
	selectBankWithdrawBankCode,
	selectBankWithdrawBankCountry,
	selectBankWithdrawBankName,
	selectBankWithdrawCorrAccount,
	selectBankWithdrawFee,
	selectBankWithdrawIbanAccountNumber,
	selectBankWithdrawIsChecked,
	selectBankWithdrawParams,
	selectBankWithdrawPaymentSystem,
	selectBankWithdrawTaxIdentNumber,
	setBankWithdrawInit,
} from '../../../../services/withdraw/bank.withdraw.slice';
import { TBankWithdrawBodyParam, TBankWithdrawParamIds } from '../../../../types/types';

const BankWithdrawConfirmationLayout = () => {
	const mainCurrencies = useAppSelector(selectMainAccountRests);
	const amount = useAppSelector(selectBankWithdrawAmount);
	const selectedAsset = useAppSelector(selectBankWithdrawAsset);
	const fee = useAppSelector(selectBankWithdrawFee);
	const accessToken = useAppSelector(selectAccessToken);
	const isChecked = useAppSelector(selectBankWithdrawIsChecked);
	const mainEWallet = useAppSelector(selectMainEWallet);
	const bankParams = useAppSelector(selectBankWithdrawParams);

	const ibanAccountNumber = useAppSelector(selectBankWithdrawIbanAccountNumber);
	const bankName = useAppSelector(selectBankWithdrawBankName);
	const taxIdentNumber = useAppSelector(selectBankWithdrawTaxIdentNumber);
	const corrAccount = useAppSelector(selectBankWithdrawCorrAccount);
	const paymentSystem = useAppSelector(selectBankWithdrawPaymentSystem);
	const bankCode = useAppSelector(selectBankWithdrawBankCode);
	const bankCountry = useAppSelector(selectBankWithdrawBankCountry);

	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		if (selectedAsset === undefined) {
			navigate(ROUTES.WITHDRAW.CRYPTO.INDEX);
		}
	}, []);

	const { sendBankWithdraw, isWithdrawError, isWithdrawPending, withdrawError } =
		useSendBankWithdraw(navigate, dispatch, accessToken as string);

	const handleBackClickButton = (isCancel?: boolean) => {
		if (isCancel === true) {
			dispatch(setBankWithdrawInit());
		}
		navigate(ROUTES.WITHDRAW.BANK.INDEX);
	};

	const mapParamIdToValue = (paramId: TBankWithdrawParamIds) => {
		switch (paramId) {
			case 'currencyCode':
				return selectedAsset?.code;
			case 'beneficiaryAccountNumber':
				return ibanAccountNumber;
			case 'bankCode':
				return bankCode;
			case 'paymentSystem':
				return paymentSystem;
			case 'bankName':
				return bankName;
			case 'bankCountry':
				return bankCountry;
			case 'taxIdentNumber':
				return taxIdentNumber;
			case 'corrAccount':
				return corrAccount;
			default:
				return '';
		}
	};

	const handleSubmit = () => {
		sendBankWithdraw({
			accountNumber: mainEWallet,
			amount: Number(amount),
			params: bankParams?.params?.map((param) => {
				return {
					paramId: param.paramId,
					paramName: param.paramName,
					paramValue: mapParamIdToValue(param?.paramId) as string,
				};
			}) as TBankWithdrawBodyParam[],
			currencyCode: selectedAsset?.code as string,
			dispatch: dispatch,
			accessToken: accessToken as string,
		});
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent aboutComponent'}>
				<HeaderLayout
					isAboutHeader
					isFunctionalHeader
					handleBackClick={handleBackClickButton}
				/>
				<div
					style={{ marginBottom: '16px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className="innerContainer">
						<h1 className="innerContainerMainText">
							{t('confirmation_title')}
						</h1>
						<div className="commonContainer">
							<div className="commonItem">
								<h1 className="cryptoWithdrawCommonItemLeftText">
									{t('withdrawCrypto_withdrawAsset')}
								</h1>
								<h1 className="cryptoWithdrawCommonItemRightText">
									{selectedAsset?.code}{' '}
									{`(${
										mainCurrencies?.assets?.find(
											(asset) => asset?.code === selectedAsset?.code
										)?.name
											? mainCurrencies?.assets?.find(
													(asset) =>
														asset?.code ===
														selectedAsset?.code
											  )?.name
											: selectedAsset?.code
									})`}
								</h1>
							</div>
							<div className="commonItem">
								<h1 className="cryptoWithdrawCommonItemLeftText">
									{t('withdrawBank_accountNumberIban')}
								</h1>
								<h1 className="cryptoWithdrawCommonItemRightText">
									{ibanAccountNumber}
								</h1>
							</div>
							<div className="commonItem">
								<h1 className="cryptoWithdrawCommonItemLeftText">
									{t('withdrawBank_bankName')}
								</h1>
								<h1 className="cryptoWithdrawCommonItemRightText">
									{bankName}
								</h1>
							</div>
							<div className="commonItem">
								<h1 className="cryptoWithdrawCommonItemLeftText">
									{t('withdrawBank_bic')}
								</h1>
								<h1 className="cryptoWithdrawCommonItemRightText">
									{paymentSystem}
								</h1>
							</div>
						</div>

						<div className="commonContainer">
							<div
								style={{
									height: '40px',
								}}
								className="commonItem"
							>
								<h1 className="cryptoWithdrawCommonItemLeftText">
									{t('withdraw_totalWithdraw')}
								</h1>
								<h1 className="cryptoWithdrawCommonItemRightText">
									{isChecked
										? formatNumber(
												Number(Number(amount) + fee).toFixed(2),
												2
										  )
										: formatNumber(Number(amount).toFixed(2), 2)}{' '}
									{selectedAsset?.code}
								</h1>
							</div>
							<div
								style={{
									height: '40px',
								}}
								className="commonItem"
							>
								<h1 className="cryptoWithdrawCommonItemLeftText">
									{t('withdrawConfirmation_withdrawFee')}
								</h1>
								<h1 className="cryptoWithdrawCommonItemRightText">
									{formatNumber(Number(fee).toFixed(2), 2)}{' '}
									{selectedAsset?.code}
								</h1>
							</div>
							<div
								style={{
									height: '40px',
								}}
								className="commonItem"
							>
								<h1 className="cryptoWithdrawCommonItemLeftText">
									{t('withdrawConfirmation_toBeReceived')}
								</h1>
								<h1 className="cryptoWithdrawCommonItemRightText">
									{isChecked
										? formatNumber(Number(amount).toFixed(2), 2)
										: formatNumber(
												Number(
													Number(amount) - Number(fee)
												).toFixed(2),
												2
										  )}{' '}
									{selectedAsset?.code}
								</h1>
							</div>
						</div>
						<APIError isError={isWithdrawError} error={withdrawError} />
					</div>
					<div className="depositButtonsBottomContainer">
						<h1 className="cryptoWithdrawBottomWarningText">
							{t('withdrawCryptoConfirmation_withdrawWarn')}
						</h1>
						<CustomButton
							isDisabled={false}
							isLoading={isWithdrawPending}
							text={t('withdrawCryptoConfrimation_confirm') as string}
							onClick={handleSubmit}
							className="confirmFullWidthButton"
						/>
						<CustomButton
							isDisabled={false}
							isGrey
							isLoading={false}
							text={t('general_cancel') as string}
							onClick={() => handleBackClickButton(true)}
							className="confirmFullWidthButton"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BankWithdrawConfirmationLayout;

import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '../../store';
import { TOptionIdentity } from '../../types/types';

export const selectSecurityState = (state: TRootState) => state.security.state;

export const selectSecurityChangePassword = (state: TRootState) =>
	state.security.changePassword;

export const selectSecurityIdentityTypes = (state: TRootState) =>
	state.security.identityTypes;

export const selectSecuritySelectedIdentityType = (state: TRootState) =>
	state.security.selectedIdentityType;

export const selectTwoFaOptionsAvailableIdentityTypes = (state: TRootState) =>
	state.security.availableIdentityTypes;

export const selectTwoFaOptionsCurrentOption = (state: TRootState) =>
	state.security.currentTwoFactorOption;

export const selectTwoFaOptionsCurrenMethodType = (state: TRootState) =>
	state.security.methodType;

export const selectTwoFaSecretKey = (state: TRootState) => state.security.secretKey;

export const selectTwoFaQrCodePath = (state: TRootState) => state.security.qrCodePath;

interface IRootInitialState {
	state: string;
	identityTypes: { key: string; value: string }[];
	selectedIdentityType: string;
	changePassword: string;
	availableIdentityTypes: TOptionIdentity[];
	currentTwoFactorOption: TOptionIdentity | undefined;
	methodType: string;
	qrCodePath: string;
	secretKey: string;
}

const initialState: IRootInitialState = {
	state: '',
	identityTypes: [],
	selectedIdentityType: '',
	changePassword: '',
	availableIdentityTypes: [],
	currentTwoFactorOption: undefined,
	methodType: '',
	qrCodePath: '',
	secretKey: '',
};

export const securitySlice = createSlice({
	name: 'security',
	initialState,
	reducers: {
		updateTwoFactorOption(state, action) {
			state.availableIdentityTypes = state.availableIdentityTypes.map(
				(identity) => {
					if (identity?.identityTypeName === action.payload.type) {
						identity.isEnabled = action.payload.value;
					}
					return identity;
				}
			);
		},
		setCurrentTwoFactorOption(state, action) {
			state.currentTwoFactorOption = action.payload;
		},
		setTwoFaOptionsIdentityTypes(state, action) {
			state.availableIdentityTypes = action.payload;
		},
		setSecurityIdentityTypes(state, action) {
			state.identityTypes = action.payload;
		},
		setSecurityChangePassword(state, action) {
			state.changePassword = action.payload;
		},
		setSecuritySelectedIdentityType(state, action) {
			state.selectedIdentityType = action.payload;
		},
		setSecurityState(state, action) {
			state.state = action.payload;
		},
		setSecurityMethodType(state, action) {
			state.methodType = action.payload;
		},
		setTwoFaSecretKey(state, action) {
			state.secretKey = action.payload.secretKey;
			state.qrCodePath = action.payload.qrCodePath;
		},
		setSecurityInit(state) {
			state.state = '';
			state.changePassword = '';
			state.identityTypes = [];
			state.selectedIdentityType = '';
			state.availableIdentityTypes = [];
			state.currentTwoFactorOption = undefined;
			state.methodType = '';
			state.qrCodePath = '';
			state.secretKey = '';
		},
	},
});

export const {
	setSecurityIdentityTypes,
	setSecuritySelectedIdentityType,
	setSecurityState,
	setSecurityChangePassword,
	setSecurityInit,
	setTwoFaOptionsIdentityTypes,
	updateTwoFactorOption,
	setCurrentTwoFactorOption,
	setSecurityMethodType,
	setTwoFaSecretKey,
} = securitySlice.actions;

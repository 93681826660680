import { QueryClient } from '@tanstack/react-query';
import { fetchDictionary, fetchHealth } from '../../api/common';

const globalRouteLoader = () => {
	return new QueryClient().fetchQuery({
		queryKey: ['globalLoader'],
		queryFn: () => fetchHealth(),
		staleTime: Infinity,
	});
};

const registrationLoader = () => {
	return new QueryClient().fetchQuery({
		queryKey: ['registrationLoader'],
		queryFn: () => fetchDictionary('countries'),
		staleTime: 86400
	});
};

export { globalRouteLoader, registrationLoader };

import { useMutation } from '@tanstack/react-query';
import {
	fetchBankWithdrawCommission,
	fetchBankWithdrawDeleteTemplate,
	fetchBankWithdrawParams,
	fetchBankWithdrawSendTemplate,
	fetchBankWithdrawTemplate,
	fetchBankWithdrawUpdateTemplate,
	fetchCardWithdraw,
	fetchCardWithdrawCardValidation,
	fetchCardWithdrawCommission,
	fetchCardWithdrawCreateTemplate,
	fetchCardWithdrawCreateTemplateFromSettings,
	fetchCardWithdrawDeleteTemplate,
	fetchCardWithdrawTemplate,
	fetchCardWithdrawUpdateTemplate,
	fetchCryptoWithdrawAddressValidation,
	fetchCryptoWithdrawCommission,
	fetchCryptoWithdrawCreateTemplate,
	fetchCryptoWithdrawDeleteTemplate,
	fetchCryptoWithdrawTemplate,
	fetchCryptoWithdrawUpdateTemplate,
	fetchCryptoWithdrawWithoutTwoFa,
	fetchOperationsSendOtp,
	fetchSendBankWithdraw,
	fetchSendBankWithdrawParams
} from '../../../api/withdraw';
import {
	TBankWithdrawBody,
	TBankWithdrawCommissionBody,
	TBankWithdrawCreateTemplateBody,
	TBankWithdrawDeleteTemplateBody,
	TBankWithdrawParamsBody,
	TBankWithdrawUpdateTemplateBody,
	TCardWithdrawBody,
	TCardWithdrawCardValidationBody,
	TCardWithdrawCommissionBody,
	TCardWithdrawCreateTemplateBody,
	TCardWithdrawCreateTemplateFromSettingsBody,
	TCardWithdrawDeleteTemplateBody,
	TCardWithdrawUpdateTemplateBody,
	TCryptoWithdrawAddressValidationBody,
	TCryptoWithdrawBody,
	TCryptoWithdrawCommissionBody,
	TCryptoWithdrawCreateTemplateBody,
	TCryptoWithdrawDeleteTemplateBody,
	TCryptoWithdrawTemplateBody,
	TCryptoWithdrawUpdateTemplateBody,
	TCustomNavigate,
	TDefaultBody,
	TIdentity,
	TSecuritySendOtpBody
} from '../../../types/types';
import { AppDispatch } from '../../../store';
import {
	setCryptoWithdrawFee,
	setCryptoWithdrawIsAddressValid,
	setCryptoWithdrawOperationId
} from '../../../services/withdraw/crypto.withdraw.slice';
import { ROUTES } from '../../../types/routes';
import {
	setCardWithdrawCard,
	setCardWithdrawFee,
	setCardWithdrawInit,
	setCardWithdrawIsCardValid,
	setCardWithdrawOperationId
} from '../../../services/withdraw/card.withdraw.slice';
import {
	setBankWithdrawSavedBanksReduce,
	setBankWithdrawTemplates,
	setCardWithdrawSavedCards,
	setCardWithdrawSavedCardsReduce,
	setCryptoWithdrawSavedAddresses,
	setCryptoWithdrawSavedAddressesReduce,
	setUpdateBankWithdrawSavedBank,
	setUpdateCardWithdrawSavedCard,
	setUpdateCryptoWithdrawSavedAddresses
} from '../../../services/withdraw/templates.withdraw.slice';
import {
	setBankWithdrawFee,
	setBankWithdrawOperationId,
	setBankWithdrawParams
} from '../../../services/withdraw/bank.withdraw.slice';
import {
	setWithdrawIdentityTypes,
	setWithdrawSelectedIdentityType,
	setWithdrawState,
	setWithdrawType
} from '../../../services/withdraw/withdraw.slice';

//eslint-disable-next-line
function mapWithdrawTwoFaError(
	//eslint-disable-next-line
	jsonData: any,
	dispatch: AppDispatch,
	navigate: TCustomNavigate,
	accessToken: string
) {
	if (jsonData?.code === 'MULTI_FACTOR_REQUIRED') {
		dispatch(setWithdrawState(jsonData?.data?.state));
		dispatch(setWithdrawIdentityTypes(jsonData?.data?.identityTypes));

		if (
			jsonData?.data?.identityTypes.length === 1 &&
			jsonData?.data?.identityTypes.find(
				(identity: TIdentity) => identity.name === 'GoogleAuthenticator'
			)
		) {
			dispatch(setWithdrawSelectedIdentityType('googleauthenticator'));
			navigate(ROUTES.WITHDRAW.TWO_FA.TWO_FA);
		}

		if (
			jsonData?.data?.identityTypes.length === 1 &&
			jsonData?.data?.identityTypes.find(
				(identity: TIdentity) => identity.name === 'Email'
			)
		) {
			dispatch(setWithdrawSelectedIdentityType('email'));
			fetchOperationsSendOtp({
				identityType: 'email',
				state: jsonData?.data?.state,
				dispatch: dispatch,
				accessToken: accessToken
			});
			navigate(ROUTES.WITHDRAW.TWO_FA.OTP);
		}

		if (
			jsonData?.data?.identityTypes.length === 1 &&
			jsonData?.data?.identityTypes.find(
				(identity: TIdentity) => identity.name === 'phone'
			)
		) {
			dispatch(setWithdrawSelectedIdentityType('phone'));
			fetchOperationsSendOtp({
				identityType: 'phone',
				state: jsonData?.data?.state,
				dispatch: dispatch,
				accessToken: accessToken
			});
			navigate(ROUTES.WITHDRAW.TWO_FA.OTP);
		}

		if (jsonData?.data?.identityTypes.length > 1) {
			navigate(ROUTES.WITHDRAW.TWO_FA.SELECT_TWO_FA);
		}
	}
}

export function useGetCryptoWithdrawSavedAddresses(dispatch: AppDispatch) {
	const {
		mutateAsync: getCryptoWithdrawSavedAddresses,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['getCryptoWithdrawSavedAddresses'],
		mutationFn: (data: TCryptoWithdrawTemplateBody) =>
			fetchCryptoWithdrawTemplate(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.templates) {
				dispatch(setCryptoWithdrawSavedAddresses(data?.templates));
			}
		}
	});
	return {
		getCryptoWithdrawSavedAddresses,
		error,
		isError,
		isPending
	};
}

export function useDeleteCryptoWithdrawSavedAddresses(
	dispatch: AppDispatch,
	templateId: string,
	handleIsModalOpen: () => void,
	notify?: () => void
) {
	const {
		mutateAsync: deleteCryptoWithdrawSavedAddresses,
		isError: isDeleteError,
		error: deleteError,
		isPending: isDeletePending
	} = useMutation({
		mutationKey: ['getCryptoWithdrawSavedAddresses'],
		mutationFn: (data: TCryptoWithdrawDeleteTemplateBody) =>
			fetchCryptoWithdrawDeleteTemplate(data),
		onSuccess: async () => {
			if(notify) {
				notify()
			}
			dispatch(setCryptoWithdrawSavedAddressesReduce(templateId));
			handleIsModalOpen();
		}
	});
	return {
		deleteCryptoWithdrawSavedAddresses,
		deleteError,
		isDeleteError,
		isDeletePending
	};
}

export function useCreateCryptoWithdrawSavedAddresses(
	handleModalClose: (
		setIsNeedToSave?: React.Dispatch<React.SetStateAction<boolean>>
	) => void,
	notify?: () => void
) {
	const {
		mutateAsync: createCryptoWithdrawSavedAddresses,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['createCryptoWithdrawSavedAddresses'],
		mutationFn: (data: TCryptoWithdrawCreateTemplateBody) =>
			fetchCryptoWithdrawCreateTemplate(data),
		onSuccess: async () => {
			if(notify) {
				notify()
			}
			handleModalClose();
		}
	});
	return {
		createCryptoWithdrawSavedAddresses,
		error,
		isError,
		isPending
	};
}

export function useUpdateCryptoWithdrawSavedAddresses(
	dispatch: AppDispatch,
	templateName: string,
	templateId: string,
	handleIsModalOpen: () => void,
	notify?: () => void
) {
	const {
		mutateAsync: updateCryptoWithdrawSavedAddresses,
		isError: isUpdateError,
		error: updateError,
		isPending: isUpdatePending
	} = useMutation({
		mutationKey: ['getCryptoWithdrawSavedAddresses'],
		mutationFn: (data: TCryptoWithdrawUpdateTemplateBody) =>
			fetchCryptoWithdrawUpdateTemplate(data),
		onSuccess: async () => {
			if(notify) {
				notify()
			}
			dispatch(
				setUpdateCryptoWithdrawSavedAddresses({
					templateId: templateId,
					templateName: templateName
				})
			);
			handleIsModalOpen();
		}
	});
	return {
		updateCryptoWithdrawSavedAddresses,
		updateError,
		isUpdateError,
		isUpdatePending
	};
}

export function useGetCryptoWithdrawCommission(dispatch: AppDispatch) {
	const {
		mutateAsync: getCryptoWithdrawCommission,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['getCryptoWithdrawCommission'],
		mutationFn: (data: TCryptoWithdrawCommissionBody) =>
			fetchCryptoWithdrawCommission(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.feeAmount) {
				dispatch(setCryptoWithdrawFee(data?.feeAmount));
			}
		}
	});
	return {
		getCryptoWithdrawCommission,
		error,
		isError,
		isPending
	};
}

export function useCheckCryptoWithdrawAddressValidation(dispatch: AppDispatch) {
	const {
		mutateAsync: checkAddressValidation,
		isError,
		error: error,
		isPending: isCheckAddressValidationPending
	} = useMutation({
		mutationKey: ['checkAddressValidation'],
		mutationFn: (data: TCryptoWithdrawAddressValidationBody) =>
			fetchCryptoWithdrawAddressValidation(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.result) {
				dispatch(setCryptoWithdrawIsAddressValid(data?.result));
			}
		}
	});
	return {
		checkAddressValidation,
		error,
		isError,
		isCheckAddressValidationPending
	};
}

export function useSendCryptoWithdraw(
	dispatch: AppDispatch,
	navigate: TCustomNavigate,
	accessToken: string
) {
	const {
		mutateAsync: sendCryptoWithdraw,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['sendCryptoWithdraw'],
		mutationFn: (data: TCryptoWithdrawBody) =>
			fetchCryptoWithdrawWithoutTwoFa(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.idPayments) {
				dispatch(setCryptoWithdrawOperationId(data?.idPayments[0]));
				navigate(ROUTES.WITHDRAW.CRYPTO.SUMMARY);
			}
		},
		//eslint-disable-next-line
		onError: async (data: any) => {
			if (data?.data) {
				dispatch(setWithdrawType('crypto'));
				mapWithdrawTwoFaError(data, dispatch, navigate, accessToken);
			}
		}
	});
	return {
		sendCryptoWithdraw,
		error,
		isError,
		isPending
	};
}

export function useGetCardWithdrawSavedCard(dispatch: AppDispatch) {
	const {
		mutateAsync: getCardWithdrawSavedCards,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['getCardWithdrawSavedCards'],
		mutationFn: (data: TDefaultBody) => fetchCardWithdrawTemplate(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.templates) {
				dispatch(setCardWithdrawSavedCards(data?.templates));
			}
		}
	});
	return {
		getCardWithdrawSavedCards,
		error,
		isError,
		isPending
	};
}

export function useDeleteCardWithdrawSavedCard(
	dispatch: AppDispatch,
	templateId: string,
	handleIsModalOpen: () => void,
	notify?: () => void
) {
	const {
		mutateAsync: deleteCardWithdrawSavedCard,
		isError: isDeleteError,
		error: deleteError,
		isPending: isDeletePending
	} = useMutation({
		mutationKey: ['getCryptoWithdrawSavedAddresses'],
		mutationFn: (data: TCardWithdrawDeleteTemplateBody) =>
			fetchCardWithdrawDeleteTemplate(data),
		onSuccess: async () => {
			if(notify) {
				notify()
			}
			dispatch(setCardWithdrawSavedCardsReduce(templateId));
			handleIsModalOpen();
		}
	});
	return {
		deleteCardWithdrawSavedCard,
		deleteError,
		isDeleteError,
		isDeletePending
	};
}

export function useCreateCardWithdrawSavedCard(handleModalClose: () => void, notify?: () => void) {
	const {
		mutateAsync: createCardWithdrawSavedCard,
		isError: isCreateWithdrawCardError,
		error: createWithdrawCardError,
		isPending
	} = useMutation({
		mutationKey: ['createCardWithdrawSavedCard'],
		mutationFn: (data: TCardWithdrawCreateTemplateBody) =>
			fetchCardWithdrawCreateTemplate(data),
		onSuccess: async () => {
			if(notify) {
				notify()
			}
			handleModalClose();
		}
	});
	return {
		createCardWithdrawSavedCard,
		isCreateWithdrawCardError,
		createWithdrawCardError,
		isPending
	};
}

export function useCreateNewCardSettings(
	handleModalClose: () => void,
	dispatch: AppDispatch,
	notify?: () => void
) {
	const {
		mutateAsync: createNewCardSettings,
		isError: isCreateNewCardSettingsError,
		error: createNewCardSettingsError,
		isPending
	} = useMutation({
		mutationKey: ['createNewCardSettings'],
		mutationFn: (data: TCardWithdrawCreateTemplateFromSettingsBody) =>
			fetchCardWithdrawCreateTemplateFromSettings(data),
		onSuccess: async () => {
			if(notify) {
				notify()
			}
			dispatch(setCardWithdrawInit());
			handleModalClose();
		}
	});
	return {
		createNewCardSettings,
		isCreateNewCardSettingsError,
		createNewCardSettingsError,
		isPending
	};
}

export function useUpdateCardWithdrawSavedCard(
	dispatch: AppDispatch,
	templateName: string,
	templateId: string,
	handleIsModalOpen: () => void,
	notify?: () => void
) {
	const {
		mutateAsync: updateCardWithdrawSavedCard,
		isError: isUpdateError,
		error: updateError,
		isPending: isUpdatePending
	} = useMutation({
		mutationKey: ['updateCardWithdrawSavedCard'],
		mutationFn: (data: TCardWithdrawUpdateTemplateBody) =>
			fetchCardWithdrawUpdateTemplate(data),
		onSuccess: async () => {
			if(notify) {
				notify()
			}
			dispatch(
				setUpdateCardWithdrawSavedCard({
					templateId: templateId,
					templateName: templateName
				})
			);
			handleIsModalOpen();
		}
	});
	return {
		updateCardWithdrawSavedCard,
		updateError,
		isUpdateError,
		isUpdatePending
	};
}

export function useGetCardWithdrawCommission(dispatch: AppDispatch) {
	const {
		mutateAsync: getCardWithdrawCommission,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['getCardWithdrawCommission'],
		mutationFn: (data: TCardWithdrawCommissionBody) =>
			fetchCardWithdrawCommission(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.feeAmount) {
				dispatch(setCardWithdrawFee(data?.feeAmount));
			}
		}
	});
	return {
		getCardWithdrawCommission,
		error,
		isError,
		isPending
	};
}

export function useCheckCardWithdrawCardValidation(
	dispatch: AppDispatch,
	navigate: TCustomNavigate,
	cardNumber: string,
	cardholderName: string
) {
	const {
		mutateAsync: checkCardWithdrawCardValidation,
		isError,
		error: error,
		isPending: isCheckCardValidationPending
	} = useMutation({
		mutationKey: ['checkAddressValidation'],
		mutationFn: (data: TCardWithdrawCardValidationBody) =>
			fetchCardWithdrawCardValidation(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.userCardId) {
				dispatch(setCardWithdrawIsCardValid(true));
				dispatch(
					setCardWithdrawCard({
						cardNumber: cardNumber,
						userCardId: data?.userCardId,
						cardholderName: cardholderName
					})
				);
				navigate(ROUTES.WITHDRAW.CARD.CONFIRMATION);
			}
		}
	});
	return {
		checkCardWithdrawCardValidation,
		error,
		isError,
		isCheckCardValidationPending
	};
}

export function useSendCardWithdraw(
	dispatch: AppDispatch,
	navigate: TCustomNavigate,
	accessToken: string
) {
	const {
		mutateAsync: sendCardWithdraw,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['sendCardWithdraw'],
		mutationFn: (data: TCardWithdrawBody) => fetchCardWithdraw(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.idPayments) {
				dispatch(setCardWithdrawOperationId(data?.idPayments[0]));
				navigate(ROUTES.WITHDRAW.CARD.SUMMARY);
			}
		},
		//eslint-disable-next-line
		onError: async (data: any) => {
			if (data?.data) {
				dispatch(setWithdrawType('card'));
				mapWithdrawTwoFaError(data, dispatch, navigate, accessToken);
			}
		}
	});
	return {
		sendCardWithdraw,
		error,
		isError,
		isPending
	};
}

export function useGetBankWithdrawParams(dispatch: AppDispatch) {
	const {
		mutateAsync: getBankWithdrawParams,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['getBankWithdrawParams'],
		mutationFn: (data: TDefaultBody) => fetchBankWithdrawParams(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.params) {
				dispatch(setBankWithdrawParams(data));
			}
		}
	});
	return {
		getBankWithdrawParams,
		error,
		isError,
		isPending
	};
}

export function useSendBankWithdrawParams(dispatch: AppDispatch) {
	const {
		mutateAsync: sendBankWithdrawParams,
		isError: isSendParamsError,
		error: sendParamsError,
		isPending: isSendParamsPending
	} = useMutation({
		mutationKey: ['sendBankWithdrawParams'],
		mutationFn: (data: TBankWithdrawParamsBody) =>
			fetchSendBankWithdrawParams(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.params) {
				dispatch(setBankWithdrawParams(data));
			}
		}
	});
	return {
		sendBankWithdrawParams,
		isSendParamsError,
		sendParamsError,
		isSendParamsPending
	};
}

export function useSendBankWithdraw(
	navigate: TCustomNavigate,
	dispatch: AppDispatch,
	accessToken: string
) {
	const {
		mutateAsync: sendBankWithdraw,
		isError: isWithdrawError,
		error: withdrawError,
		isPending: isWithdrawPending
	} = useMutation({
		mutationKey: ['sendBankWithdraw'],
		mutationFn: (data: TBankWithdrawBody) => fetchSendBankWithdraw(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.idPayments) {
				dispatch(setBankWithdrawOperationId(data?.idPayments[0]));
				navigate(ROUTES.WITHDRAW.BANK.SUMMARY);
			}
		},
		//eslint-disable-next-line
		onError: async (data: any) => {
			if (data?.data) {
				dispatch(setWithdrawType('bank'));
				mapWithdrawTwoFaError(data, dispatch, navigate, accessToken);
			}
		}
	});
	return {
		sendBankWithdraw,
		withdrawError,
		isWithdrawError,
		isWithdrawPending
	};
}

export function useGetBankWithdrawCommission(dispatch: AppDispatch) {
	const {
		mutateAsync: getBankWithdrawCommission,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['getBankWithdrawCommission'],
		mutationFn: (data: TBankWithdrawCommissionBody) =>
			fetchBankWithdrawCommission(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.feeAmount) {
				dispatch(setBankWithdrawFee(data?.feeAmount));
			}
		}
	});
	return {
		getBankWithdrawCommission,
		error,
		isError,
		isPending
	};
}

export function useGetBankWithdrawSavedBank(dispatch: AppDispatch) {
	const {
		mutateAsync: getBankWithdrawSavedBank,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['getBankWithdrawSavedBank'],
		mutationFn: (data: TDefaultBody) => fetchBankWithdrawTemplate(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.templates) {
				dispatch(setBankWithdrawTemplates(data?.templates));
			}
		}
	});
	return {
		getBankWithdrawSavedBank,
		error,
		isError,
		isPending
	};
}

export function useDeleteBankWithdrawSavedBank(
	dispatch: AppDispatch,
	templateId: string,
	handleIsModalOpen: () => void,
	notify?: () => void
) {
	const {
		mutateAsync: deleteBankWithdrawSavedBank,
		isError: isDeleteError,
		error: deleteError,
		isPending: isDeletePending
	} = useMutation({
		mutationKey: ['deleteBankWithdrawSavedBank'],
		mutationFn: (data: TBankWithdrawDeleteTemplateBody) =>
			fetchBankWithdrawDeleteTemplate(data),
		onSuccess: async () => {
			if(notify) {
				notify()
			}
			dispatch(setBankWithdrawSavedBanksReduce(templateId));
			handleIsModalOpen();
		}
	});
	return {
		deleteBankWithdrawSavedBank,
		deleteError,
		isDeleteError,
		isDeletePending
	};
}

export function useCreateBankWithdrawSavedBank(handleModalClose: () => void, notify?: () => void) {
	const {
		mutateAsync: createBankWithdrawSavedBank,
		isError: isCreateWithdrawCardError,
		error: createWithdrawCardError,
		isPending
	} = useMutation({
		mutationKey: ['createBankWithdrawSavedBank'],
		mutationFn: (data: TBankWithdrawCreateTemplateBody) =>
			fetchBankWithdrawSendTemplate(data),
		onSuccess: async () => {
			if(notify) {
				notify()
			}
			handleModalClose();
		}
	});
	return {
		createBankWithdrawSavedBank,
		isCreateWithdrawCardError,
		createWithdrawCardError,
		isPending
	};
}

export function useUpdateBankWithdrawSavedBank(
	dispatch: AppDispatch,
	templateName: string,
	templateId: string,
	handleIsModalOpen: () => void,
	notify?: () => void
) {
	const {
		mutateAsync: updateBankWithdrawSavedBank,
		isError: isUpdateError,
		error: updateError,
		isPending: isUpdatePending
	} = useMutation({
		mutationKey: ['updateBankWithdrawSavedBank'],
		mutationFn: (data: TBankWithdrawUpdateTemplateBody) =>
			fetchBankWithdrawUpdateTemplate(data),
		onSuccess: async () => {
			if(notify) {
				notify()
			}
			dispatch(
				setUpdateBankWithdrawSavedBank({
					templateId: templateId,
					templateName: templateName
				})
			);
			handleIsModalOpen();
		}
	});
	return {
		updateBankWithdrawSavedBank,
		updateError,
		isUpdateError,
		isUpdatePending
	};
}

export function useSendWithdrawOTP(notify?: () => void) {
	const {
		mutateAsync: sendWithdrawOTP,
		isError,
		error,
		isPending
	} = useMutation({
		mutationKey: ['sendWithdrawOTP'],
		mutationFn: (data: TSecuritySendOtpBody) => fetchOperationsSendOtp(data),
		onSuccess: async () => {
			if(notify) {
				notify()
			}
		}
	});
	return {
		sendWithdrawOTP,
		isError,
		error,
		isPending
	};
}

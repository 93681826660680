import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../types/titles';
import SavedAddressesLayout from '../../components/profile/saved-addresses';

const SavedAddresses = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.PROFILE.SAVED_ADDRESSES}</title>
			</Helmet>
			<SavedAddressesLayout />
		</>
	);
};

export default SavedAddresses;

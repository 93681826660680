import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { HeaderLayout } from '../../common/header';
import { formatNumber, mapClassnameForMobile } from '../../../utils';
import { CustomButton } from '../../common/buttons';
import {
	useGetCardCommission,
	useSendCardDeposit,
} from '../../../data/mutations/deposit';
import { APIError } from '../../common/error';
import { useEffect, useState } from 'react';
import { TWalletModalItem } from '../../../types/types';
import { CardDepositAssetModal } from '../../common/modals';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectGlobalAsset, selectMainEWallet } from '../../../services/wallet';
import { selectAccessToken } from '../../../services/auth';
import { CustomInput } from '../../common/inputs';
import {
	selectAssetIcons,
	selectDepositWithdrawAssetsDictionary,
} from '../../../services/dictionaries';
import { useDebounce } from '../../../hooks/useDebounce';
import { selectAppSettings, selectIsTelegram } from '../../../services/root';
import { DepositIcons } from '../../common/icons';
import { setClearCardDepositLink } from '../../../services/deposit';
import { useTelegram } from '../../../hooks/useTelegram';

const CardLayout = () => {
	const appSettings = useAppSelector(selectAppSettings);
	const depositWithdrawAssetsDictionary = useAppSelector(
		selectDepositWithdrawAssetsDictionary
	);
	const globalAsset = useAppSelector(selectGlobalAsset);
	const accessToken = useAppSelector(selectAccessToken);
	const mainEWallet = useAppSelector(selectMainEWallet);
	const assetIcons = useAppSelector(selectAssetIcons);

	const isTelegram = useAppSelector(selectIsTelegram);
	const { openLink } = useTelegram();

	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();
	const { t } = useTranslation();

	const [selectedAsset, setSelectedAsset] = useState<TWalletModalItem>({
		code: 'USD',
		name: 'US Dollar',
	});

	const [minValue, setMinValue] = useState<number | undefined>(
		depositWithdrawAssetsDictionary?.dictionary?.deposit?.fiat[1]?.card?.find(
			(asset) => asset.code === selectedAsset.code
		)?.min
	);

	const [fee, setFee] = useState<number>(0);

	const [amount, setAmount] = useState<string>('');
	const [isAmountRequired, setIsAmountRequired] = useState<boolean>(false);

	const { sendCardDeposit, isError, error, isPending } = useSendCardDeposit(
		dispatch,
		isTelegram,
		openLink
	);

	const { getCardCommission } = useGetCardCommission(setFee);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isInputError, setIsInputError] = useState<boolean>(false);

	const debouncedValue = useDebounce(amount, 500);

	/*useEffect(() => {
		if (gatewayUrl != '') {
			window.open(gatewayUrl, '_blank');
		}
	}, [gatewayUrl]);*/

	useEffect(() => {
		if (Number(debouncedValue) > 0) {
			getCardCommission({
				amount: Number(debouncedValue),
				accessToken: accessToken as string,
				dispatch: dispatch,
				currencyCode: selectedAsset?.code,
			});
		}
	}, [debouncedValue]);

	useEffect(() => {
		if (selectedAsset) {
			setMinValue(
				depositWithdrawAssetsDictionary?.dictionary?.deposit?.fiat[1]?.card?.find(
					(asset) => asset.code === selectedAsset.code
				)?.min
			);
		}
	}, [selectedAsset, depositWithdrawAssetsDictionary?.dictionary]);

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleItemClick = (i: TWalletModalItem) => {
		setIsModalOpen(!isModalOpen);
		setSelectedAsset(i);
		setMinValue(
			depositWithdrawAssetsDictionary?.dictionary?.deposit?.fiat[1]?.card?.find(
				(asset) => asset.code === i.code
			)?.min
		);
	};

	const handleBackClickButton = () => {
		dispatch(setClearCardDepositLink());
		navigate(ROUTES.DEPOSIT.INDEX);
	};

	const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsInputError(false);
		setAmount(e.target.value);
		setIsAmountRequired(e.target.value.length > 0);
	};

	const handleClearClick = () => {
		setAmount('');
		setIsAmountRequired(false);
		setFee(0);
	};

	const handleSubmit = () => {
		setIsInputError(false);
		if (
			mainEWallet.length === 0 ||
			amount.length === 0 ||
			Number(amount) < Number(minValue)
		) {
			setIsInputError(true);
		} else {
			setIsInputError(false);
			sendCardDeposit({
				amount: Number(amount),
				accountNumber: mainEWallet,
				currencyCode: selectedAsset?.code as string,
				dispatch: dispatch,
				accessToken: accessToken as string,
			});
		}
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout
					isFunctionalHeader
					handleBackClick={handleBackClickButton}
				/>
				<CardDepositAssetModal
					isModalOpen={isModalOpen}
					handleIsModalOpen={handleModalOpen}
					handleItemClick={handleItemClick}
					currentAsset={globalAsset}
				/>
				<div
					style={{ marginBottom: '16px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className="innerContainer">
						<h1 className="innerContainerMainText">
							{t('depositCard_depositButtonText')}
						</h1>
						<CustomInput
							isRequired
							isSelectAsset
							type="text"
							id="cardDepositSelectAsset"
							onClick={handleModalOpen}
							value={selectedAsset?.code}
							imgUrl={
								assetIcons?.dictionary &&
								assetIcons?.dictionary[selectedAsset?.code?.toUpperCase()]
							}
							className="confirmFullWidthButton"
							label={t('general_currency') as string}
						/>
						<div className="depositAmountInputContainer">
							<CustomInput
								label={`${t('wallet_toBeReceived')} (${t(
									'withdraw_min'
								)} ${minValue} ${selectedAsset.code})`}
								className="confirmFullWidthButton"
								type="number"
								id="cardDepositAmount"
								onChange={handleAmountChange}
								onClearClick={handleClearClick}
								isRequired={isAmountRequired}
								value={amount}
								isError={isInputError}
							/>
							<h1 className="depositFeeText">
								{`${t('deposit_serviceFee')}: `}
								{formatNumber(fee, 2)}
								{` ${selectedAsset?.code}. ${t(
									'deposit_youWillReceive'
								)}: `}
								{fee === 0 || Number(amount) === 0
									? 0
									: formatNumber(Number(amount) - fee, 2)}
								{` ${selectedAsset?.code}`}
							</h1>
							<div className="cardWarningOuterContainer">
								<div className="cardWarningContainer">
									<DepositIcons.WarningCardIcon />
									<h1 className="cardWarningText">
										{
											appSettings?.depositWithdrawInformation
												?.bottomsheetText
										}
									</h1>
								</div>
							</div>
						</div>
						<APIError isError={isError} error={error} />
					</div>
					<div className="depositButtonsBottomContainer">
						<CustomButton
							isLoading={isPending}
							text={t('deposit_title') as string}
							onClick={handleSubmit}
							className="confirmFullWidthButton"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardLayout;

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect } from 'react';
import i18n from './locale/i18n';
import './styles/index.css';
import { Provider } from 'react-redux';
import { store } from './store';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import { ToastContainer } from 'react-toastify';

const locale = window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';

const queryClient = new QueryClient();

const App = () => {
	useEffect(() => {
		i18n.changeLanguage(locale);
	}, []);

	return (
		<div id={'wrapper'} className={'wrapper'}>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
				<QueryClientProvider client={queryClient}>
					<Provider store={store}>
						<ToastContainer limit={1} rtl={false} />
						<RouterProvider
							future={{
								v7_startTransition: true,
							}}
							router={router}
						/>
					</Provider>
				</QueryClientProvider>
			</LocalizationProvider>
		</div>
	);
};

export default App;

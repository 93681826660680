import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	useSubmitClearMutation,
	useSubmitFirstStepMutation,
} from '../../data/mutations/registration';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { CustomButton } from '../common/buttons/index';
import { CustomInput } from '../common/inputs';
import RegistrationCheckboxes from './personal';
import { TCheckbox, TMappedCheckboxes } from '../../types/types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
	selectRegistrationCheckoxes,
	selectRegistrationEmail,
	selectRegistrationInfo,
	selectRegistrationIsChecked,
	selectRegistrationState,
	setRegistrationEmail,
} from '../../services/registration';
import { APIError } from '../common/error';
import { HeaderLayout } from '../common/header';
import { mapClassnameForMobile } from '../../utils';
import { ROUTES } from '../../types/routes';

const RegistrationPersonalLayout = () => {
	const state = useAppSelector(selectRegistrationState);
	const checkBoxes = useAppSelector(selectRegistrationCheckoxes);
	const isChecked = useAppSelector(selectRegistrationIsChecked);
	const email = useAppSelector(selectRegistrationEmail);
	const stateInfo = useAppSelector(selectRegistrationInfo);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();
	const buttonRef = useRef(null);

	const { submitFirstStep, isPending, isError, error } = useSubmitFirstStepMutation(
		navigate,
		Number(stateInfo?.email?.length) > 0
	);

	const { clearState } = useSubmitClearMutation(navigate, dispatch);

	const [fakeError, setFakeError] = useState(false);

	useEffect(() => {
		if (isError) {
			setFakeError(isError);
		}
	}, [isError]);

	const handleBackClick = async () => {
		await clearState({
			state: state,
		});
	};

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setRegistrationEmail(e.target.value));
		setFakeError(false);
	};

	const handleIsChecked = () => {
		if (checkBoxes) {
			return checkBoxes.some(
				(checkbox, idx) =>
					checkbox.isRequired && checkbox.isRequired != isChecked[idx]
			);
		}
	};

	const handleEmailClear = () => {
		dispatch(setRegistrationEmail(''));
	};

	const handleSubmit = async () => {
		setFakeError(false);
		if (
			checkBoxes === undefined ||
			handleIsChecked() ||
			email.length === 0 ||
			!new RegExp(
				/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu
			).test(email)
		) {
			setFakeError(true);
		} else {
			setFakeError(false);
			if (stateInfo?.isEmailVerified) {
				navigate(ROUTES.REGISTRATION.PHONE);
			} else {
				await submitFirstStep({
					state: state,
					email: email,
					checkBoxesResult: checkBoxes
						?.filter((checkbox: TCheckbox) => checkbox.checkBoxId != 'cookie')
						.map(
							//eslint-disable-next-line
							(checkbox: any, idx: number) => {
								return {
									checkBoxId: checkbox.checkBoxId,
									isSelected: isChecked[idx],
								};
							}
						) as TMappedCheckboxes,
					dispatch: dispatch,
				});
			}
		}
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout isFunctionalHeader handleBackClick={handleBackClick} />
				<div className={mapClassnameForMobile('walletLayout')}>
					<div className="innerContainer">
						<h1 className={'innerContainerMainText'}>{t('start_signUp')}</h1>
						<CustomInput
							isNonCloseIcon={stateInfo?.isEmailVerified as boolean}
							isError={fakeError}
							isDisabled={stateInfo?.isEmailVerified as boolean}
							isRequired={email?.length > 0}
							type={'email'}
							id={'email'}
							label={t('passwordRecovery_provideYourEmail')}
							value={email}
							onChange={handleEmailChange}
							className={'input'}
							onClearClick={handleEmailClear}
							inputPropsRef={buttonRef}
						/>
						<APIError error={error} isError={isError} />
					</div>
				</div>
			</div>
			<div className="depositButtonsBottomContainer">
				<RegistrationCheckboxes
					setFakeError={setFakeError}
					isError={fakeError}
					checked={isChecked}
				/>
				<CustomButton
					buttonRef={buttonRef}
					text={t('registration_startRegistration') as string}
					type={'submit'}
					onClick={handleSubmit}
					className={'confirmFullWidthButton'}
					isLoading={isPending || checkBoxes === undefined}
				/>
			</div>
		</div>
	);
};

export default RegistrationPersonalLayout;

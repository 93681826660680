import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '../../store';
import {
	TRegistrationBackTextCheckboxes,
	TRegistrationCountry,
	TRegistrationGetEulaCheckbox,
	TRegistrationInfo,
	TRegistrationInfoCheckbox
} from '../../types/types';

export const selectRegistrationIsPageInited = (state: TRootState) =>
	state.registration.isRegistrationInited;

export const selectRegistrationState = (state: TRootState) =>
	state.registration.state;

export const selectRegistrationInfo = (state: TRootState) =>
	state.registration.stateInfo;

export const selectRegistrationIsChecked = (state: TRootState) =>
	state.registration.isChecked;

export const selectRegistrationEmail = (state: TRootState) =>
	state.registration.email;

export const selectRegistrationPhone = (state: TRootState) =>
	state.registration.phone;

export const selectRegistrationCheckoxes = (state: TRootState) =>
	state.registration.checkBoxes;

export const selectRegistrationTextCheckoxes = (state: TRootState) =>
	state.registration.textCheckboxes;

export const selectRegistrationBackCheckoxes = (state: TRootState) =>
	state.registration.backCheckBoxes;

export const selectRegistrationFullPhone = (state: TRootState) =>
	state.registration.fullPhone;

export const selectRegistrationCountry = (state: TRootState) =>
	state.registration.country;

interface IRegistrationState {
	state: string;
	stateInfo: TRegistrationInfo | undefined;
	checkBoxes: TRegistrationGetEulaCheckbox[] | undefined;
	backCheckBoxes: TRegistrationInfoCheckbox[] | undefined;
	textCheckboxes: TRegistrationBackTextCheckboxes | undefined;
	email: string;
	phone: string;
	fullPhone: string;
	country: TRegistrationCountry | undefined;
	isChecked: boolean[];
	isRegistrationInited: boolean;
}

const initialState: IRegistrationState = {
	state:
		localStorage.getItem('registrationState') != null
			? (localStorage.getItem('registrationState') as string)
			: '',
	email: '',
	phone: '',
	fullPhone: '',
	stateInfo: undefined,
	isChecked: [false, false, false],
	isRegistrationInited: false,
	checkBoxes: undefined,
	backCheckBoxes: undefined,
	textCheckboxes: undefined,
	country: undefined,
};

export const registrationSlice = createSlice({
	name: 'registration',
	initialState,
	reducers: {
		setIsRegistrationPageInited(state, action) {
			state.isRegistrationInited = action.payload;
		},
		setRegistrationCheckboxes(state, action) {
			state.checkBoxes = action.payload;
		},
		setRegistrationTextCheckboxes(state, action) {
			state.textCheckboxes = action.payload;
		},
		setRegistrationBackCheckboxes(state, action) {
			state.backCheckBoxes = action.payload;
		},
		setRegistrationState(state, action) {
			state.state = action.payload;
			localStorage.setItem('registrationState', action.payload);
		},
		setRegistrationInfo(state, action) {
			state.stateInfo = action.payload;
		},
		setRegistrationIsChecked(state, action) {
			state.isChecked = action.payload;
		},
		setRegistrationEmail(state, action) {
			state.email = action.payload;
		},
		setRegistrationFullPhone(state, action) {
			state.fullPhone = action.payload;
		},
		setRegistrationCountry(state, action) {
			state.country = action.payload;
		},
		setRegistrationPhone(state, action) {
			state.phone = action.payload;
		},
		setRegistrationInit(state) {
			state.email = '';
			state.isChecked = [false, false, false];
			state.stateInfo = undefined;
			state.state = '';
			state.checkBoxes = undefined;

			localStorage.setItem('registrationState', '');

			state.isRegistrationInited = false;
			state.backCheckBoxes = undefined;
			state.textCheckboxes = undefined;
			state.phone = '';
			state.country = undefined;
			state.fullPhone = ''
		}
	}
});

export const {
	setIsRegistrationPageInited,
	setRegistrationState,
	setRegistrationInfo,
	setRegistrationIsChecked,
	setRegistrationEmail,
	setRegistrationInit,
	setRegistrationPhone,
	setRegistrationCheckboxes,
	setRegistrationTextCheckboxes,
	setRegistrationBackCheckboxes,
	setRegistrationFullPhone,
	setRegistrationCountry
} = registrationSlice.actions;

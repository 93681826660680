import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../types/titles';
import BankWithdrawLayout from '../../../components/withdraw/bank';

const BankWithdraw = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.WITHDRAW.BANK.INDEX}</title>
			</Helmet>
			<BankWithdrawLayout />
		</>
	);
};

export default BankWithdraw;

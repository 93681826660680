import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '../../store';
import { TBankDepositInvoiceHistory, TBankDepositInvoiceInfo } from '../../types/types';

export const selectInvoiceInfo = (state: TRootState) => state.deposit.invoiceInfo;

export const selectInvoiceHistory = (state: TRootState) => state.deposit.invoiceHistory;

export const selectInvoiceHistoryIsEmpty = (state: TRootState) =>
	state.deposit.invoiceHistoryIsEmpty;

export const selectCurrentInvoiceId = (state: TRootState) =>
	state.deposit.currentInvoiceId;

export const selectInvoiceLink = (state: TRootState) => state.deposit.invoiceLink;

export const selectCardDepositLink = (state: TRootState) => state.deposit.cardDepositLink;

interface IRootInitialState {
	invoiceInfo: TBankDepositInvoiceInfo | undefined;
	invoiceHistory: TBankDepositInvoiceHistory[];
	invoiceHistoryIsEmpty: boolean;
	currentInvoiceId: string;
	invoiceLink: string;
	cardDepositLink: string;
}

const initialState: IRootInitialState = {
	invoiceInfo: undefined,
	invoiceHistory: [],
	invoiceHistoryIsEmpty: false,
	currentInvoiceId: '',
	invoiceLink: '',
	cardDepositLink: '',
};

export const depositSlice = createSlice({
	name: 'deposit',
	initialState,
	reducers: {
		setInvoiceInfo(state, action) {
			state.invoiceInfo = action.payload;
		},
		setInvoiceHistory(state, action) {
			if (action.payload?.length === 0) {
				state.invoiceHistoryIsEmpty = true;
				return;
			}
			state.invoiceHistory = action.payload;
		},
		setCurrentInvoiceId(state, action) {
			state.currentInvoiceId = action.payload;
		},
		removeInvoiceFromHistory(state, action) {
			state.invoiceHistory = state.invoiceHistory?.filter(
				(invoice) => invoice.invoiceId != action.payload
			);
		},
		setInvoiceLink(state, action) {
			state.invoiceLink = action?.payload?.link;
		},
		setCardDepositLink(state, action) {
			state.cardDepositLink = action?.payload;
		},
		setClearCardDepositLink(state) {
			state.cardDepositLink = '';
		},
	},
});

export const {
	setInvoiceInfo,
	setInvoiceHistory,
	setCurrentInvoiceId,
	removeInvoiceFromHistory,
	setInvoiceLink,
	setCardDepositLink,
	setClearCardDepositLink,
} = depositSlice.actions;

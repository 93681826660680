import { useTranslation } from 'react-i18next';
import { HeaderLayout } from '../../common/header';
import { CustomInput } from '../../common/inputs';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { CustomButton } from '../../common/buttons';
import { useSendPasswordRecoveryRequest } from '../../../data/mutations/login/login';
import { APIError } from '../../common/error';
import { mapClassnameForMobile } from '../../../utils';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectSuccessfulEmail, setPasswordRecoveryEmail } from '../../../services/auth';
import { selectUserEmail } from '../../../services/user';

const PasswordRecoveryLayout = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const successfulEmail = useAppSelector(selectSuccessfulEmail);
	const profileEmail = useAppSelector(selectUserEmail);

	const [email, setEmail] = useState<string>('');
	const [isEmailRequired, setIsEmailRequired] = useState<boolean>(false);

	const navigate = useCustomNavigate();

	const { sendPasswordRecoveryRequest, isError, error, isPending } =
		useSendPasswordRecoveryRequest(navigate);

	const buttonRef = useRef(null);

	useEffect(() => {
		if (profileEmail != '') {
			setEmail(profileEmail);
			setIsEmailRequired(profileEmail?.length > 0);
		}
		console.log('a')
		if (successfulEmail != '') {
			setEmail(successfulEmail);
			setIsEmailRequired(successfulEmail?.length > 0);
		}
	}, []);

	const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
		setEmail(e.target.value);
		e.target.value.length > 0 ? setIsEmailRequired(true) : setIsEmailRequired(false);
	};

	const handleEmailClearClick = () => {
		setEmail('');
		setIsEmailRequired(false);
	};

	const handleSubmit = async () => {
		dispatch(setPasswordRecoveryEmail(email));
		await sendPasswordRecoveryRequest({
			email: email,
		});
	};

	const handleBackClickButton = () => {
		dispatch(setPasswordRecoveryEmail(''));
		navigate(ROUTES.AUTH.LOGIN);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout
					isFunctionalHeader
					handleBackClick={handleBackClickButton}
				/>
				<div className={mapClassnameForMobile('walletLayout ', true)}>
					<div className="innerContainer">
						<h1 className="innerContainerMainText">
							{t('passwordRecovery_title')}
						</h1>
						<h1 className={'confirmCodeText'}>
							{t('passwordRecovery_provideYourEmail')}
						</h1>
						<CustomInput
							isTabDisable={true}
							isError={isError}
							isRequired={isEmailRequired}
							type={'email'}
							id={'emailPasswordRecoveryInput'}
							label={t('login_email')}
							value={email}
							onChange={handleEmailChange}
							className={'input'}
							onClearClick={handleEmailClearClick}
							inputPropsRef={buttonRef}
						/>
						<APIError isError={isError} error={error} />
					</div>
					<div className="depositButtonsBottomContainer">
						<CustomButton
							buttonRef={buttonRef}
							isLoading={isPending}
							text={t('passwordRecovery_continue') as string}
							onClick={handleSubmit}
							className="confirmFullWidthButton"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PasswordRecoveryLayout;

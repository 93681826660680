import { Navigate, Outlet, useLoaderData } from 'react-router-dom';
import { ROUTES } from '../../types/routes';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
	selectLocale,
	selectPreviousMainLocation,
	selectTheme,
	setBiometry,
	setIsTelegram,
} from '../../services/root';
import { useTelegram } from '../../hooks/useTelegram';
import { useEffect } from 'react';
import { selectCountriesDictionary, setDictionary } from '../../services/dictionaries';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { selectIsAuthenticated } from '../../services/auth';
import { TGlobalRouteLoaderResponse } from '../../types/types';
import { useGetUserRests } from '../../data/mutations/wallet';
import { selectIsVerified } from '../../services/user';
import { mapRegistrationStepId, setDefaultDictionaries } from '../../utils';
import { useGetMarketPrices } from '../../data/mutations/market';
import {
	selectRegistrationInfo,
	selectRegistrationIsPageInited,
	selectRegistrationState,
	setRegistrationBackCheckboxes,
	setRegistrationCountry,
	setRegistrationEmail,
	setRegistrationFullPhone,
	setRegistrationPhone,
} from '../../services/registration';
import {
	useGetStateInfoMutation,
	useRegistrationGetEula,
	useRegistrationStartMutation,
} from '../../data/mutations/registration';

const ProtectedRoute = () => {
	const locale = useAppSelector(selectLocale);
	const isAuthenticated = useAppSelector(selectIsAuthenticated);

	const dispatch = useAppDispatch();
	const data = useLoaderData();
	const navigate = useCustomNavigate();

	useEffect(() => {
		setDefaultDictionaries(data as TGlobalRouteLoaderResponse, dispatch, navigate);
	}, []);

	if (!isAuthenticated) {
		return <Navigate to={ROUTES.AUTH.INDEX.replace(':locale', locale)} />;
	}

	return <Outlet />;
};

const CommonRoute = () => {
	const isAuthenticated = useAppSelector(selectIsAuthenticated);
	const previousMainLocation = useAppSelector(selectPreviousMainLocation);
	const navigate = useCustomNavigate();

	useEffect(() => {
		if (isAuthenticated) {
			navigate(
				previousMainLocation != '' ? previousMainLocation : ROUTES.WALLET.INDEX
			);
		}
	}, []);

	return <Outlet />;
};

const GlobalRoute = () => {
	const theme = useAppSelector(selectTheme);
	const dispatch = useAppDispatch();

	const { isTelegram, biometricManager } = useTelegram();

	const handleIsInitBiometry = () => {
		biometricManager.init(() => {
			dispatch(
				setBiometry({
					isBiometricAvailable: biometricManager.isBiometricAvailable,
					isBiometryInited: true,
				})
			);
		});
	};

	useEffect(() => {
		if (theme === 'dark') {
			document.body.setAttribute('data-theme', theme?.toLowerCase());
		} else {
			document.body.removeAttribute('data-theme');
		}
	}, [theme]);

	useEffect(() => {
		if (isTelegram) {
			dispatch(setIsTelegram(isTelegram));
			handleIsInitBiometry();
		}
	}, []);

	return <Outlet />;
};

const ProfileRoute = () => {
	const dispatch = useAppDispatch();
	const data = useLoaderData();

	useEffect(() => {
		dispatch(
			setDictionary(
				//eslint-disable-next-line
				Object.assign(data as any, {
					dictionaryName: 'countries',
				})
			)
		);
	}, []);

	const isVerified = useAppSelector(selectIsVerified);
	//eslint-disable-next-line
	const {} = useGetUserRests(isVerified);
	//eslint-disable-next-line
	const {} = useGetMarketPrices();

	return <Outlet />;
};

const PaymentsRoute = () => {
	const isVerified = useAppSelector(selectIsVerified);
	//eslint-disable-next-line
	const {} = useGetUserRests(isVerified);
	//eslint-disable-next-line
	const {} = useGetMarketPrices();

	return <Outlet />;
};

const RegistrationRoute = () => {
	const state = useAppSelector(selectRegistrationState);
	const stateInfo = useAppSelector(selectRegistrationInfo);
	const isPageInited = useAppSelector(selectRegistrationIsPageInited);
	const countries = useAppSelector(selectCountriesDictionary);

	const navigate = useCustomNavigate();
	const dispatch = useAppDispatch();
	const data = useLoaderData();

	const { startRegistration } = useRegistrationStartMutation(
		navigate,
		isPageInited,
		dispatch
	);

	const { getRegistrationEula } = useRegistrationGetEula();

	const { getStateInfo } = useGetStateInfoMutation(dispatch);

	useEffect(() => {
		if (state === '') {
			startRegistration();
		}
		dispatch(
			setDictionary(
				//eslint-disable-next-line
				Object.assign(data as any, {
					dictionaryName: 'countries',
				})
			)
		);
	}, []);

	useEffect(() => {
		if (state?.length > 0) {
			if (!isPageInited) {
				getRegistrationEula({
					state: state,
					dispatch: dispatch,
				});
			}
			getStateInfo({
				state: state,
			});
		}
	}, [state]);

	useEffect(() => {
		if (stateInfo && countries) {
			if (stateInfo?.email != '') {
				dispatch(setRegistrationEmail(stateInfo?.email));
			}
			if (stateInfo?.phone != '') {
				dispatch(
					setRegistrationPhone(
						stateInfo?.phone.replace(
							countries?.dictionary?.find(
								(country) => country?.code === stateInfo?.phoneCountryCode
							)?.phoneCode as string,
							''
						)
					)
				);
				dispatch(
					setRegistrationCountry({
						code: countries?.dictionary?.find(
							(country) => country?.code === stateInfo?.phoneCountryCode
						)?.phoneCode,
						imgUrl: countries?.dictionary?.find(
							(country) => country?.code === stateInfo?.phoneCountryCode
						)?.link,
					})
				);
				dispatch(setRegistrationFullPhone(stateInfo?.phone));
			}

			dispatch(setRegistrationBackCheckboxes(stateInfo?.checkBoxesResult));
			if (!isPageInited) {
				mapRegistrationStepId(stateInfo?.currentStepId, navigate, dispatch);
			}
		}
	}, [stateInfo, countries]);

	return <Outlet />;
};

const ExchangeRoute = () => {
	const dispatch = useAppDispatch();
	const data = useLoaderData();

	useEffect(() => {
		dispatch(
			setDictionary(
				//eslint-disable-next-line
				Object.assign(data as any, {
					dictionaryName: 'assetSymbols',
				})
			)
		);
	}, []);

	const isVerified = useAppSelector(selectIsVerified);
	//eslint-disable-next-line
	const {} = useGetUserRests(isVerified);
	//eslint-disable-next-line
	const {} = useGetMarketPrices();

	return <Outlet />;
};

export {
	RegistrationRoute,
	ProtectedRoute,
	CommonRoute,
	GlobalRoute,
	ProfileRoute,
	PaymentsRoute,
	ExchangeRoute,
};

import { useAppSelector } from '../../../../../hooks';
import { useCustomNavigate } from '../../../../../hooks/useCustomNavigate';
import {
	selectTwoFaQrCodePath,
	selectTwoFaSecretKey,
} from '../../../../../services/security';
import { ROUTES } from '../../../../../types/routes';
import { SecretKeyFunctionalLayout } from '../../../../auth/secret-key';

const SecurityTwoFaSecretKeyLayout = () => {
	const qrCodePath = useAppSelector(selectTwoFaQrCodePath);
	const secretKey = useAppSelector(selectTwoFaSecretKey);

	const navigate = useCustomNavigate();

	const handleBackClickButton = () => {
		navigate(ROUTES.PROFILE.SECURITY.TWO_FA.SETUP_TWOFA);
	};

	const handleSubmit = () => {
		navigate(ROUTES.PROFILE.SECURITY.TWO_FA.TWO_FA);
	};

	return (
		<SecretKeyFunctionalLayout
			handleBackClickButton={handleBackClickButton}
			handleSubmit={handleSubmit}
			secretKey={secretKey}
			qrCodePath={qrCodePath}
		/>
	);
};

export default SecurityTwoFaSecretKeyLayout;

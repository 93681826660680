import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../types/titles';
import SecurityLayout from '../../../components/profile/security';

const ProfileSecurity = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.PROFILE.SECURITY.INDEX}</title>
			</Helmet>
			<SecurityLayout />
		</>
	);
};

export default ProfileSecurity;

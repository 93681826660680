import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../../types/titles';
import BankWithdrawSummaryLayout from '../../../../components/withdraw/bank/summary';

const BankWithdrawSummary = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.WITHDRAW.BANK.SUMMARY}</title>
			</Helmet>
			<BankWithdrawSummaryLayout />
		</>
	);
};

export default BankWithdrawSummary;

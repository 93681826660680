import { UAParser } from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';

const API_HEADERS = {
	'Content-Type': 'application/json',
	f2x_request_id: '',
	f2x_user_agent: '',
};

const BASE_URL = window.location.origin;

export async function customFetch(
	input: RequestInfo | URL,
	init?: RequestInit | undefined,
	noJson?: boolean
): Promise<Response> {
	const locale = window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
	const customHeaders = {
		'Accept-Language': locale.toLocaleLowerCase() + '-' + locale.toUpperCase(),
		f2x_user_agent:
			localStorage.getItem('f2xUserAgent') != null
				? localStorage.getItem('f2xUserAgent')
				: parseUserAgentString(window.navigator.userAgent),
		f2x_request_id: uuidv4(),
		...init?.headers,
	};
	if (init?.headers) {
		if (!noJson) {
			Object.assign(customHeaders, {
				'Content-Type': 'application/json',
			});
		}
		//eslint-disable-next-line
		init.headers = customHeaders as any;
	}

	return await fetch(input, init);
}

function setDefaultHeaders(headers?: Headers) {
	if (headers) {
		for (const [key, value] of Object.entries(API_HEADERS)) {
			if (key === 'f2x_user_agent') {
				headers.set(key, parseUserAgentString(window.navigator.userAgent));
				return;
			}
			headers.set(key, value != '' ? value : uuidv4());
		}
		return headers;
	}
	return headers;
}

function generateDeviceId() {
	const deviceId = uuidv4();
	localStorage.setItem('deviceId', deviceId);
	return deviceId;
}

export function parseUserAgentString(ua: string) {
	let DEFAULT_F2X_UA = 'app=F2X_PwA; app_v=0.0.1;';

	const { device, os } = UAParser(ua)

	if (ua.includes('Windows')) {
		DEFAULT_F2X_UA += ` os=${os?.name}; os_v=${os?.version}; model=PWA_Windows;`;
	} else {
		DEFAULT_F2X_UA += ` os=${os?.name}; os_v=${os?.version}; model=${device?.toString()};`;
	}

	DEFAULT_F2X_UA += ` deviceId=${localStorage.getItem('deviceId') != null
		? localStorage.getItem('deviceId')
		: generateDeviceId()
		}; lang=${window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en'}; theme=${JSON.parse(
			localStorage.getItem(localStorage.getItem('userId') as string) as string
		)?.theme != undefined
			? JSON.parse(
				localStorage.getItem(
					localStorage.getItem('userId') as string
				) as string
			)?.theme
			: 'light'
		}`;
	return DEFAULT_F2X_UA;
}

export { API_HEADERS, BASE_URL, setDefaultHeaders };

import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '../../store';
import { TInnerFiatWithdraw, TSavedCard } from '../../types/types';

export const selectCardWithdrawFee = (state: TRootState) => state.withdrawCard.fee;

export const selectCardWithdrawAsset = (state: TRootState) =>
	state.withdrawCard.selectedAsset;

export const selectCardWithdrawAmount = (state: TRootState) => state.withdrawCard.amount;

export const selectCardWithdrawIsChecked = (state: TRootState) =>
	state.withdrawCard.isChecked;

export const selectCardWithdrawIsCardValid = (state: TRootState) =>
	state.withdrawCard.isCardValid;

export const selectCardWithdrawCard = (state: TRootState) =>
	state.withdrawCard.selectedCard;

export const selectCardWithdrawOperationId = (state: TRootState) =>
	state.withdrawCard.lastWithdrawOperationId;

export const selectCardWithdrawIsCardOwner = (state: TRootState) =>
	state.withdrawCard.isCardOwner;

export const selectCardWithdrawMaskedCard = (state: TRootState) =>
	state.withdrawCard.maskedCard;

interface IRootInitialState {
	fee: string;
	selectedAsset: TInnerFiatWithdraw | undefined;
	amount: string;
	isChecked: boolean;
	isCardValid: boolean;
	selectedCard: TSavedCard | undefined;
	isCardOwner: boolean;
	lastWithdrawOperationId: string;
	maskedCard: string;
}

const initialState: IRootInitialState = {
	fee: '0',
	selectedAsset: undefined,
	amount: '',
	isChecked: false,
	isCardValid: true,
	selectedCard: undefined,
	lastWithdrawOperationId: '',
	isCardOwner: false,
	maskedCard: '',
};

export const withdrawCardSlice = createSlice({
	name: 'withdrawCard',
	initialState,
	reducers: {
		setCardWithdrawFee(state, action) {
			state.fee = action.payload;
		},
		setCardWithdrawAsset(state, action) {
			state.selectedAsset = action.payload;
		},
		setCardWithdrawAmount(state, action) {
			state.amount = action.payload;
		},
		setCardWithdrawIsChecked(state, action) {
			state.isChecked = action.payload;
		},
		setCardWithdrawIsCardValid(state, action) {
			state.isCardValid = action.payload;
		},
		setCardWithdrawCard(state, action) {
			const selectedCard = action.payload;
			state.selectedCard = selectedCard;
			state.maskedCard =
				'**** ' +
				selectedCard?.cardNumber?.substring(
					selectedCard?.cardNumber?.length - 4,
					selectedCard?.cardNumber?.length
				);
		},
		setCardWithdrawOperationId(state, action) {
			state.lastWithdrawOperationId = action.payload;
		},
		setCardWithdrawIsCardOwner(state, action) {
			state.isCardOwner = action.payload;
		},
		setCardWithdrawInit(state) {
			state.fee = '0';
			state.amount = '';
			state.selectedAsset = undefined;
			state.isChecked = false;
			state.isCardValid = true;
			state.selectedCard = undefined;
			state.lastWithdrawOperationId = '';
			state.isCardOwner = false;
			state.maskedCard = '';
		},
	},
});

export const {
	setCardWithdrawFee,
	setCardWithdrawAmount,
	setCardWithdrawAsset,
	setCardWithdrawInit,
	setCardWithdrawIsChecked,
	setCardWithdrawIsCardValid,
	setCardWithdrawCard,
	setCardWithdrawOperationId,
	setCardWithdrawIsCardOwner,
} = withdrawCardSlice.actions;

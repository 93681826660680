import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../types/titles';
import CardWithdrawLayout from '../../../components/withdraw/card';

const CardWithdraw = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.WITHDRAW.CARD.INDEX}</title>
			</Helmet>
			<CardWithdrawLayout />
		</>
	);
};

export default CardWithdraw;

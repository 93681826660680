import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../../../types/titles';
import SecurityTwoFaOTPLayout from '../../../../../components/profile/security/twofa/otp';

const TwoFaSettingsOTPProfile = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.PROFILE.SECURITY.TWO_FA_SETTINGS.OTP}</title>
			</Helmet>
			<SecurityTwoFaOTPLayout />
		</>
	);
};

export default TwoFaSettingsOTPProfile;

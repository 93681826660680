import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../types/titles';
import PasswordRecoveryLayout from '../../../components/auth/recovery';

const PasswordRecovery = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.AUTH.RECOVERY.PASSWORD_RECOVERY}</title>
			</Helmet>
			<PasswordRecoveryLayout />
		</>
	);
};

export default PasswordRecovery;

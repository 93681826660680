import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../types/titles';
import ExchangeLayout from '../../components/exchange';
import { QueryClient } from '@tanstack/react-query';
import { fetchDictionary } from '../../api/common';

export const exchangeLoader = () => {
	return new QueryClient().fetchQuery({
		queryKey: ['exchangeLoader'],
		queryFn: () => fetchDictionary('assetSymbols'),
		staleTime: 86400
	});
};


const Exchange = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.EXCHANGE.INDEX}</title>
			</Helmet>
			<ExchangeLayout />
		</>
	);
};

export default Exchange;

import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../../../types/titles';
import SecurityChangePasswordTwoFactorLayout from '../../../../../components/profile/security/change-password/two-factor';

const ProfileSecurityTwoFactor = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.PROFILE.SECURITY.CHANGE_PASSWORD.TWO_FA}</title>
			</Helmet>
			<SecurityChangePasswordTwoFactorLayout />
		</>
	);
};

export default ProfileSecurityTwoFactor;

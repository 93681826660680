import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../types/titles';
import RegistrationPhoneLayout from '../../../components/registration/phone';

const RegistrationPhone = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.REGISTRATION.PHONE}</title>
			</Helmet>
			<RegistrationPhoneLayout />
		</>
	);
};

export default RegistrationPhone;

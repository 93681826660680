import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { WhaleIcon } from '../../common/icons';
import { APIError } from '../../common/error';
import { CustomButton } from '../../common/buttons';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	selectTransferError,
	selectTransferIsError,
	setWalletTransferInfoInit
} from '../../../services/wallet';
import { useEffect } from 'react';

const WalletTransferSummaryLayout = () => {
	const { t } = useTranslation();
	const navigate = useCustomNavigate();
	const dispatch = useAppDispatch();

	const isError = useAppSelector(selectTransferIsError);
	const error = useAppSelector(selectTransferError);

	const handleBackClickButton = () => {
		dispatch(setWalletTransferInfoInit());
		navigate(ROUTES.WALLET.INDEX);
	};

	useEffect(() => {
		setTimeout(() => {
			navigate(ROUTES.WALLET.INDEX);
		}, 3000);
	}, []);

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<div
					style={{
						zIndex: '1',
						top: 'auto',
						marginBottom: '140px',
						gap: '0'
					}}
					className='walletLayout authMainContainer'
				>
					<WhaleIcon className='whaleConvertIcon' />
					<h1 className={'modalWalletConvertSummaryHeader'}>
						{t('wallet_transferProcessedTitle')}
					</h1>

					{isError ? (
						<APIError isError={isError} error={error} />
					) : (
						<h1 className={'modalWalletConvertSummaryBottom'}>
							{t('operation_conversionProcessedDetails')}
						</h1>
					)}
				</div>
			</div>
			<div
				style={{
					zIndex: '3'
				}}
				className='depositButtonsBottomContainer'
			>
				<CustomButton
					text={t('notifications_close') as string}
					onClick={handleBackClickButton}
					className='confirmFullWidthButton'
				/>
			</div>
		</div>
	);
};

export default WalletTransferSummaryLayout;

import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../../../types/titles';
import SecurityChangePasswordOTPLayout from '../../../../../components/profile/security/change-password/otp';

const ProfileSecurityOTP = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.PROFILE.SECURITY.CHANGE_PASSWORD.OTP}</title>
			</Helmet>
			<SecurityChangePasswordOTPLayout />
		</>
	);
};

export default ProfileSecurityOTP;

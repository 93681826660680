import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../types/titles';
import ExchangeConfirmationLayout from '../../../components/exchange/confirmation';

const ExchangeConfirmation = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.EXCHANGE.CONFIRMATION}</title>
			</Helmet>
			<ExchangeConfirmationLayout />
		</>
	);
};

export default ExchangeConfirmation;

import { customFetch } from "../../services";
import { API_ROUTES } from "../../types/api.routes";
import { TExchangeBody, TExchangeRateBody } from "../../types/types";
import { mapResponseError } from "../../utils";

export async function fetchExchangeRate({ accessToken, assetFrom, assetTo, dispatch }: TExchangeRateBody) {
    const exchangeRateDetailsData = await customFetch(
        API_ROUTES.ASSETS.PRICES.RATE.URL + `?${new URLSearchParams({
            assetFrom: assetFrom,
            assetTo: assetTo
        })}`,
        {
            method: API_ROUTES.ASSETS.PRICES.RATE.METHOD,
            headers: { Authorization: `Bearer ${accessToken}` },
        }
    );

    const jsonExchangeRateDetailsData = await exchangeRateDetailsData.json();
    if (exchangeRateDetailsData.status != 200) {
        mapResponseError(
            exchangeRateDetailsData,
            jsonExchangeRateDetailsData,
            undefined,
            dispatch
        );
    }

    return jsonExchangeRateDetailsData;
}

export async function fetchExchange({ accessToken, assetFrom, assetTo, dispatch, amount }: TExchangeBody) {
    const exchangeDetailsData = await customFetch(
        API_ROUTES.OPERATIONS.EXCHANGE.STACK.URL,
        {
            method: API_ROUTES.OPERATIONS.EXCHANGE.STACK.METHOD,
            headers: { Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({
                assetFrom: assetFrom,
                assetTo: assetTo,
                amount: amount
            })
        }
    );

    const jsonExchangeDetailsData = await exchangeDetailsData.json();
    if (exchangeDetailsData.status != 200) {
        mapResponseError(
            exchangeDetailsData,
            jsonExchangeDetailsData,
            undefined,
            dispatch
        );
    }

    return jsonExchangeDetailsData;
}
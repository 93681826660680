import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../../../types/titles';
import SecurityTwoFaSetupTwoFaLayout from '../../../../../components/profile/security/twofa/setup-two-fa';

const TwoFaSettingsSetupProfile = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.PROFILE.SECURITY.TWO_FA_SETTINGS.SETUP}</title>
			</Helmet>
			<SecurityTwoFaSetupTwoFaLayout />
		</>
	);
};

export default TwoFaSettingsSetupProfile;

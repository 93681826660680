import { useEffect, useRef, useState } from 'react';
import { HeaderLayout } from '../common/header';
import { ExchangeInput } from '../common/inputs';
import { CustomButton } from '../common/buttons';
import { useTranslation } from 'react-i18next';
import { SwapIcon } from '../common/icons';
import { BottomNavigationContainer } from '../common/navigation';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
	selectExchangeFromAmount,
	selectExchangeFromAsset,
	selectExchangeRate,
	selectExchangeRateNum,
	selectExchangeRateText,
	selectExchangeToAmount,
	selectExchangeToAsset,
	setExchangeFromAmount,
	setExchangeSelectedFromAsset,
	setExchangeSelectedToAsset,
	setExchangeToAmount,
} from '../../services/exchange/exchange.slice';
import { useDebounce } from '../../hooks/useDebounce';
import { selectAccessToken } from '../../services/auth';
import { useGetExchangeRate } from '../../data/mutations/exchange';
import { ROUTES } from '../../types/routes';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';

const ExchangeLayout = () => {
	const selectedFromAsset = useAppSelector(selectExchangeFromAsset);
	const selectedToAsset = useAppSelector(selectExchangeToAsset);
	const selectedFromAmount = useAppSelector(selectExchangeFromAmount);
	const selectedToAmount = useAppSelector(selectExchangeToAmount);
	const exchangeRateText = useAppSelector(selectExchangeRateText);
	const exchangeRate = useAppSelector(selectExchangeRate);
	const exchangeRateNum = useAppSelector(selectExchangeRateNum);

	const accessToken = useAppSelector(selectAccessToken);

	const navigate = useCustomNavigate();
	const dispatch = useAppDispatch();
	const buttonRef = useRef(null);
	const { t } = useTranslation();

	const [isInputError, setIsInputError] = useState<boolean>(false);

	const { getExchangeRate } = useGetExchangeRate(dispatch);

	const debouncedValue = useDebounce(selectedToAsset?.code as string, 500);
	const debouncedFromValue = useDebounce(selectedFromAsset?.code as string, 500);

	useEffect(() => {
		if (
			debouncedFromValue != undefined &&
			selectedFromAsset != undefined &&
			selectedToAsset
		) {
			getExchangeRate({
				accessToken: accessToken as string,
				assetFrom: selectedFromAsset?.code as string,
				assetTo: selectedToAsset?.code as string,
				dispatch: dispatch,
			});
		}
	}, [debouncedFromValue]);

	useEffect(() => {
		if (
			debouncedValue != undefined &&
			selectedFromAsset != undefined &&
			selectedToAsset
		) {
			getExchangeRate({
				accessToken: accessToken as string,
				assetFrom: selectedFromAsset?.code as string,
				assetTo: selectedToAsset?.code as string,
				dispatch: dispatch,
			});
		}
	}, [debouncedValue]);

	const handleFromChange = async (amount: string) => {
		dispatch(setExchangeFromAmount(amount));
		if (exchangeRate != undefined) {
			if (
				selectedFromAsset?.code.concat(selectedToAsset?.code as string) ===
				exchangeRate.symbol
			) {
				dispatch(
					setExchangeToAmount(
						Number(Number(exchangeRateNum) * Number(amount)).toFixed(
							selectedToAsset?.precision
						)
					)
				);
			} else {
				dispatch(
					setExchangeToAmount(
						Number(Number(amount) / Number(exchangeRateNum)).toFixed(
							selectedToAsset?.precision
						)
					)
				);
			}
		}
	};

	const handleToChange = async (amount: string) => {
		dispatch(setExchangeToAmount(amount));
		if (exchangeRate != undefined) {
			if (
				selectedFromAsset?.code.concat(selectedToAsset?.code as string) ===
				exchangeRate.symbol
			) {
				dispatch(
					setExchangeFromAmount(
						Number(Number(amount) / Number(exchangeRateNum)).toFixed(
							selectedFromAsset?.precision
						)
					)
				);
			} else {
				dispatch(
					setExchangeFromAmount(
						Number(Number(exchangeRateNum) * Number(amount)).toFixed(
							selectedFromAsset?.precision
						)
					)
				);
			}
		}
	};

	const handleSwapClick = () => {
		if (selectedFromAsset && selectedToAsset) {
			const tempSelectedFromAsset = { ...selectedFromAsset };
			const tempSelectedToAsset = { ...selectedToAsset };
			dispatch(setExchangeSelectedFromAsset(tempSelectedToAsset));
			dispatch(setExchangeSelectedToAsset(tempSelectedFromAsset));
			dispatch(setExchangeFromAmount(''));
			dispatch(setExchangeToAmount(''));
		}
	};

	const handleSubmit = () => {
		if (
			!(
				selectedFromAsset === undefined ||
				selectedToAsset === undefined ||
				Number(selectedFromAmount) === 0 ||
				Number(selectedToAmount) === 0 ||
				Number(selectedFromAmount) > selectedFromAsset?.available ||
				exchangeRateText === ''
			)
		) {
			navigate(ROUTES.EXCHANGE.CONFIRMATION);
		}
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout isAuthorizedHeader />
				<div className="walletLayout">
					<ExchangeInput
						asset={selectedFromAsset}
						isFrom={true}
						isInputError={isInputError}
						setIsInputError={setIsInputError}
						available={selectedFromAsset?.available ?? 0}
						handleAvailableClick={(amount) => handleFromChange(amount)}
						amount={selectedFromAmount}
						setAmount={handleFromChange}
						fontSize={selectedFromAmount.length > 8 ? '18px' : '24px'}
					/>
					<CustomButton
						onClick={handleSwapClick}
						isDisabled={
							selectedFromAsset === undefined ||
							selectedToAsset === undefined
						}
						isSwapButton={true}
						className={'exchangeSwapContainer'}
					>
						<SwapIcon className={'exchangeSwapIcon'} />
					</CustomButton>
					<ExchangeInput
						asset={selectedToAsset}
						isFrom={false}
						isInputError={isInputError}
						setIsInputError={setIsInputError}
						available={selectedToAsset?.available ?? 0}
						amount={selectedToAmount}
						setAmount={handleToChange}
						fontSize={selectedToAmount.length > 8 ? '18px' : '24px'}
						rate={exchangeRateText}
					/>
					<div
						style={{
							marginBottom: '64px',
						}}
						className="depositButtonsBottomContainer"
					>
						<CustomButton
							buttonRef={buttonRef}
							isLoading={false}
							text={t('wallet_exchange') as string}
							onClick={handleSubmit}
							className="confirmFullWidthButton"
						/>
					</div>
				</div>

				<BottomNavigationContainer />
			</div>
		</div>
	);
};

export default ExchangeLayout;

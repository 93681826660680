import { useEffect, useState } from 'react';
import { PinInput } from '../../../../common/inputs';
import { CustomButton } from '../../../../common/buttons';
import { ROUTES } from '../../../../../types/routes';
import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../../../hooks/useCustomNavigate';
import { usePinInput } from 'react-pin-input-hook';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { APIError } from '../../../../common/error';
import { mapClassnameForMobile } from '../../../../../utils';
import {
	reduceCounter,
	selectCounter,
	selectIsResendCodeActive,
	selectIsTelegram,
	selectMaskedContact,
	setIsResendCodeActive,
	updateCounter,
} from '../../../../../services/root';
import { HeaderLayout } from '../../../../common/header';
import {
	selectSecurityChangePassword,
	selectSecurityIdentityTypes,
	selectSecuritySelectedIdentityType,
	selectSecurityState,
} from '../../../../../services/security';
import {
	useSendSecurityOTP,
	useSendSecurityPasswordChange,
} from '../../../../../data/mutations/profile';
import { selectAccessToken } from '../../../../../services/auth';
import { useToast } from '../../../../../hooks/useToast';

const SecurityChangePasswordOTPLayout = () => {
	const accessToken = useAppSelector(selectAccessToken);
	const state = useAppSelector(selectSecurityState);
	const password = useAppSelector(selectSecurityChangePassword);
	const isTelegram = useAppSelector(selectIsTelegram);
	const selectedIdentityType = useAppSelector(selectSecuritySelectedIdentityType);
	const counter = useAppSelector(selectCounter);
	const identityTypes = useAppSelector(selectSecurityIdentityTypes);
	const isResendCodeActive = useAppSelector(selectIsResendCodeActive);
	const maskedContact = useAppSelector(selectMaskedContact);

	const [fakeError, setFakeError] = useState<boolean>(false);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const [values, setValues] = useState<string[]>(['', '', '', '', '', '']);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
			setFakeError(false);
		},
		onComplete: (values: string) => {
			if (values.length == 6) {
				sendSecurityPasswordChange({
					newPassword: password,
					twoFA: {
						code: values,
						state: state,
						identityType: selectedIdentityType,
					},
					dispatch: dispatch,
					navigate: navigate,
					accessToken: accessToken as string,
				});
			}
		},
		type: 'numeric',
		placeholder: '',
	});

	const navigate = useCustomNavigate();

	useEffect(() => {
		if (identityTypes.length === 0) {
			navigate(ROUTES.PROFILE.SECURITY.INDEX);
		}
	}, []);

	useEffect(() => {
		if (counter == 0) {
			dispatch(setIsResendCodeActive(true));
			dispatch(updateCounter(60));
		}
		const timer = counter > 0 && setInterval(() => dispatch(reduceCounter()), 1000);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return () => clearInterval(timer as any);
	}, [counter]);

	const { isError, error, sendSecurityPasswordChange, isPending } =
		useSendSecurityPasswordChange(
			navigate,
			useToast(t('passwordRecovery_success') as string)
		);

	const { sendSecurityOTP } = useSendSecurityOTP(
		navigate,
		true,
		undefined,
		useToast(t('registration_codeSentAgainEmail') as string)
	);

	useEffect(() => {
		if (isError) {
			setFakeError(isError);
			setValues(['', '', '', '', '', '']);
		}
	}, [isError]);

	const handleBackClickButton = () => {
		navigate(ROUTES.PROFILE.SECURITY.CHANGE_PASSWORD.INDEX);
	};

	const handleResendCode = () => {
		sendSecurityOTP({
			identityType: selectedIdentityType,
			state: state,
			dispatch: dispatch,
			accessToken: accessToken as string,
		});
	};

	const handlePasteClick = async () => {
		const clipboardContent = await navigator.clipboard.readText();
		if (
			clipboardContent &&
			clipboardContent.length === 6 &&
			!isNaN(Number(clipboardContent))
		) {
			setValues(clipboardContent.split(''));
			setFakeError(false);

			sendSecurityPasswordChange({
				newPassword: password,
				twoFA: {
					code: clipboardContent,
					state: state,
					identityType: selectedIdentityType,
				},
				dispatch: dispatch,
				navigate: navigate,
				accessToken: accessToken as string,
			});
		}
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout
					isFunctionalHeader
					handleBackClick={handleBackClickButton}
				/>
				<div className={mapClassnameForMobile('walletLayout', true)}>
					<div className="innerContainer">
						<h1 className={'confirmEmailText'}>
							{t(
								selectedIdentityType.toLocaleLowerCase() === 'email'
									? 'twoFactorAuthMethods_email'
									: 'twoFactorAuthMethods_phone'
							)}
						</h1>
						<h1 className={'confirmCodeText'}>
							{t('twoFactorAuthCode_subtitle') + ' ' + maskedContact}
						</h1>
						<div className={'codeInputBlock'}>
							<PinInput
								isLoading={isPending}
								isError={fakeError}
								fields={fields}
							/>
						</div>
						{isResendCodeActive ? (
							<h1 className={'resendCodeText'} onClick={handleResendCode}>
								{t('general_resendCode')}
							</h1>
						) : (
							<h1 className={'resendCodeText-inactive'}>
								{t('general_resendCodeIn')} {counter}
							</h1>
						)}
						<APIError isError={fakeError} error={error} />
					</div>
				</div>
				{!isTelegram && (
					<div className="pasteButtonContainer">
						<CustomButton
							isGrey
							text={t('general_pasteCode') as string}
							onClick={handlePasteClick}
							className="confirmFullWidthButton"
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default SecurityChangePasswordOTPLayout;

import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../types/titles';
import RegistrationPhoneCodeLayout from '../../../components/registration/phone-code';

const RegistrationPhoneCode = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.REGISTRATION.PHONE_CODE}</title>
			</Helmet>
			<RegistrationPhoneCodeLayout />
		</>
	);
};

export default RegistrationPhoneCode;

import { Helmet } from 'react-helmet-async';
import WithdrawTwoFactorLayout from '../../../../components/withdraw/twofa/twofa';
import { TITLES } from '../../../../types/titles';

const WithdrawTwoFa = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.WITHDRAW.TWO_FA.TWO_FA}</title>
			</Helmet>
			<WithdrawTwoFactorLayout />
		</>
	);
};

export default WithdrawTwoFa;

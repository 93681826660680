import { useTranslation } from 'react-i18next';
import { mapClassnameForMobile } from '../../../../utils';
import { HeaderLayout } from '../../../common/header';
import { ROUTES } from '../../../../types/routes';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { UserActivitiesList } from '../../../common/lists';
import { useGetVisitHistory } from '../../../../data/mutations/profile';
import { APIError } from '../../../common/error';
import { useEffect } from 'react';
import { selectAccessToken } from '../../../../services/auth';
import { selectUserActivityNextFrameId, setUserActivitiesInit } from '../../../../services/user';

const VisitHistoryLayout = () => {
	const accessToken = useAppSelector(selectAccessToken);
	const nextFrameId = useAppSelector(selectUserActivityNextFrameId);

	const { t } = useTranslation();
	const navigate = useCustomNavigate();
	const dispatch = useAppDispatch();

	const { getVisitHistory, isError, isPending, error } = useGetVisitHistory(
		navigate,
		dispatch
	);

	useEffect(() => {
		getVisitHistory({
			accessToken: accessToken as string,
			dispatch: dispatch,
			nextFrameId: nextFrameId,
		});
	}, []);

	const handleBackClickButton = () => {
		dispatch(setUserActivitiesInit())
		navigate(ROUTES.PROFILE.SECURITY.INDEX);
	};

	return (
		<div className={'component'}>
			<div
				style={{
					minHeight: '100%',
					height: 'auto',
				}}
				className={'innerComponent aboutComponent'}
			>
				<HeaderLayout
					isFunctionalHeader
					isAboutHeader
					handleBackClick={handleBackClickButton}
				/>
				<div
					style={{ marginBottom: '16px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className="innerContainer">
						<h1 className="innerContainerMainText">
							{t('visitHistory_title')}
						</h1>

						<APIError isError={isError} error={error} />

						<UserActivitiesList isLoading={isPending} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default VisitHistoryLayout;

import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../../types/titles';
import SecurityTwoFaSettingsLayout from '../../../../components/profile/security/twofa';

const TwoFaSettingsProfile = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.PROFILE.SECURITY.TWO_FA_SETTINGS.INDEX}</title>
			</Helmet>
			<SecurityTwoFaSettingsLayout />
		</>
	);
};

export default TwoFaSettingsProfile;

import { useEffect, useState } from 'react';
import { usePinInput } from 'react-pin-input-hook';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { selectAccessToken } from '../../../../services/auth';
import {
	useSendBankWithdraw,
	useSendCardWithdraw,
	useSendCryptoWithdraw,
} from '../../../../data/mutations/withdraw';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../../types/routes';
import { mapClassnameForMobile } from '../../../../utils';
import { CustomButton } from '../../../common/buttons';
import { PinInput } from '../../../common/inputs';
import { APIError } from '../../../common/error';
import { selectMainEWallet } from '../../../../services/wallet';
import { selectIsTelegram } from '../../../../services/root';
import {
	selectBankWithdrawAmount,
	selectBankWithdrawAsset,
	selectBankWithdrawBankCode,
	selectBankWithdrawBankName,
	selectBankWithdrawCorrAccount,
	selectBankWithdrawIbanAccountNumber,
	selectBankWithdrawIsBankCountryAvailable,
	selectBankWithdrawParams,
	selectBankWithdrawPaymentSystem,
	selectBankWithdrawTaxIdentNumber,
} from '../../../../services/withdraw/bank.withdraw.slice';
import { TBankWithdrawBodyParam, TBankWithdrawParamIds } from '../../../../types/types';
import {
	selectWithdrawIdentityTypes,
	selectWithdrawSelectedIdentityTypes,
	selectWithdrawState,
	selectWithdrawType,
	setWithdrawInit,
} from '../../../../services/withdraw/withdraw.slice';
import {
	selectCryptoWithdrawAddress,
	selectCryptoWithdrawAmount,
	selectCryptoWithdrawAsset,
	selectCryptoWithdrawNetwork,
} from '../../../../services/withdraw/crypto.withdraw.slice';
import {
	selectCardWithdrawAmount,
	selectCardWithdrawAsset,
	selectCardWithdrawCard,
} from '../../../../services/withdraw/card.withdraw.slice';

const WithdrawTwoFactorLayout = () => {
	const withdrawType = useAppSelector(selectWithdrawType);
	const state = useAppSelector(selectWithdrawState);
	const identityTypes = useAppSelector(selectWithdrawIdentityTypes);
	const selectedIdentity = useAppSelector(selectWithdrawSelectedIdentityTypes);

	const amount = useAppSelector(selectBankWithdrawAmount);
	const selectedAsset = useAppSelector(selectBankWithdrawAsset);
	const accessToken = useAppSelector(selectAccessToken);
	const isTelegram = useAppSelector(selectIsTelegram);
	const mainEWallet = useAppSelector(selectMainEWallet);
	const bankParams = useAppSelector(selectBankWithdrawParams);

	const ibanAccountNumber = useAppSelector(selectBankWithdrawIbanAccountNumber);
	const bankName = useAppSelector(selectBankWithdrawBankName);
	const taxIdentNumber = useAppSelector(selectBankWithdrawTaxIdentNumber);
	const corrAccount = useAppSelector(selectBankWithdrawCorrAccount);
	const paymentSystem = useAppSelector(selectBankWithdrawPaymentSystem);
	const bankCode = useAppSelector(selectBankWithdrawBankCode);

	const address = useAppSelector(selectCryptoWithdrawAddress);
	const cryptoAmount = useAppSelector(selectCryptoWithdrawAmount);
	const cryptoSelectedAsset = useAppSelector(selectCryptoWithdrawAsset);
	const selectedNetwork = useAppSelector(selectCryptoWithdrawNetwork);
	const accountNumber = useAppSelector(selectMainEWallet);

	const cardAmount = useAppSelector(selectCardWithdrawAmount);
	const cardSelectedAsset = useAppSelector(selectCardWithdrawAsset);
	const savedCard = useAppSelector(selectCardWithdrawCard);

	const selectBankCountry = useAppSelector(selectBankWithdrawIsBankCountryAvailable);

	const dispatch = useAppDispatch();
	const [fakeError, setFakeError] = useState<boolean>(false);

	const mapParamIdToValue = (paramId: TBankWithdrawParamIds) => {
		switch (paramId) {
			case 'currencyCode':
				return selectedAsset?.code;
			case 'beneficiaryAccountNumber':
				return ibanAccountNumber;
			case 'bankCode':
				return bankCode;
			case 'paymentSystem':
				return paymentSystem;
			case 'bankName':
				return bankName;
			case 'bankCountry':
				return selectBankCountry?.valueList[0];
			case 'taxIdentNumber':
				return taxIdentNumber;
			case 'corrAccount':
				return corrAccount;
			default:
				return '';
		}
	};

	const mapWithdrawTypeToBackClick = (isIndex: boolean) => {
		dispatch(setWithdrawInit());
		if (withdrawType === 'bank') {
			navigate(
				isIndex ? ROUTES.WITHDRAW.BANK.INDEX : ROUTES.WITHDRAW.BANK.CONFIRMATION
			);
			return;
		}
		if (withdrawType === 'crypto') {
			navigate(
				isIndex
					? ROUTES.WITHDRAW.CRYPTO.INDEX
					: ROUTES.WITHDRAW.CRYPTO.CONFIRMATION
			);
			return;
		}
		if (withdrawType === 'card') {
			navigate(
				isIndex ? ROUTES.WITHDRAW.CARD.INDEX : ROUTES.WITHDRAW.CARD.CONFIRMATION
			);
			return;
		}
		navigate(ROUTES.WITHDRAW.INDEX);
	};

	useEffect(() => {
		if (identityTypes.length === 0) {
			mapWithdrawTypeToBackClick(true);
		}
	}, []);

	const mapWithdrawTypeToMethod = (values: string) => {
		if (withdrawType === 'bank') {
			sendBankWithdraw({
				accountNumber: mainEWallet,
				amount: Number(amount),
				params: bankParams?.params?.map((param) => {
					return {
						paramId: param.paramId,
						paramName: param.paramName,
						paramValue: mapParamIdToValue(param?.paramId) as string,
					};
				}) as TBankWithdrawBodyParam[],
				twoFA: {
					state: state,
					code: values,
					identityType: selectedIdentity,
				},
				currencyCode: selectedAsset?.code as string,
				dispatch: dispatch,
				accessToken: accessToken as string,
			});
		}
		if (withdrawType === 'crypto') {
			sendCryptoWithdraw({
				accountNumber: accountNumber,
				accessToken: accessToken as string,
				amount: cryptoAmount,
				cryptoAddress: address,
				assetCode: cryptoSelectedAsset?.code as string,
				networkCode: selectedNetwork?.networkCode as string,
				networkName: selectedNetwork?.networkName as string,
				twoFa: {
					state: state,
					code: values,
					identityType: selectedIdentity,
				},
				dispatch: dispatch,
			});
		}
		if (withdrawType === 'card') {
			sendCardWithdraw({
				accountNumber: accountNumber,
				accessToken: accessToken as string,
				amount: cardAmount,
				currencyCode: cardSelectedAsset?.code as string,
				userCardId: savedCard?.userCardId as string,
				twoFa: {
					state: state,
					code: values,
					identityType: selectedIdentity,
				},
				dispatch: dispatch,
			});
		}
	};

	const [values, setValues] = useState<string[]>(['', '', '', '', '', '']);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
			setFakeError(false);
		},
		onComplete: (values: string) => {
			if (values.length == 6) {
				mapWithdrawTypeToMethod(values);
			}
		},
		type: 'numeric',
		placeholder: '',
	});

	const navigate = useCustomNavigate();

	const { sendBankWithdraw, isWithdrawError, isWithdrawPending, withdrawError } =
		useSendBankWithdraw(navigate, dispatch, accessToken as string);

	const {
		isError: isCardError,
		error: cardError,
		sendCardWithdraw,
		isPending: cardPending,
	} = useSendCardWithdraw(dispatch, navigate, accessToken as string);

	const {
		isError: isCryptoError,
		error: cryptoError,
		sendCryptoWithdraw,
		isPending: cryptoPending,
	} = useSendCryptoWithdraw(dispatch, navigate, accessToken as string);

	const { t } = useTranslation();

	useEffect(() => {
		if (isWithdrawError || isCardError || isCryptoError) {
			setFakeError(isWithdrawError || isCardError || isCryptoError);
			setValues(['', '', '', '', '', '']);
		}
	}, [isWithdrawError, isCardError, isCryptoError]);

	const handleBackClickButton = () => {
		mapWithdrawTypeToBackClick(false);
	};

	const handlePasteClick = async () => {
		const tempClipboardContent = await navigator.clipboard.readText();

		if (
			tempClipboardContent &&
			tempClipboardContent != '' &&
			tempClipboardContent.length === 6 &&
			!isNaN(Number(tempClipboardContent))
		) {
			setValues(tempClipboardContent.split(''));
			setFakeError(false);
			mapWithdrawTypeToMethod(tempClipboardContent);
		}
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<div className={mapClassnameForMobile('walletLayout authMainContainer')}>
					<div className="innerContainer">
						<CustomButton
							isBack={true}
							onClick={handleBackClickButton}
							className={'backButton'}
						/>
						<h1 className={'confirmEmailText'}>
							{t('twoFactorAuthCode_title')}
						</h1>
						<h1 className={'confirmCodeText'}>
							{t('twoFactorAuthCode_pleaseEnter6DigitCode')}
						</h1>
						<div className={'codeInputBlock'}>
							<PinInput
								isLoading={
									isWithdrawPending || cardPending || cryptoPending
								}
								isError={fakeError}
								fields={fields}
							/>
						</div>
						<APIError
							isError={fakeError}
							error={withdrawError || cardError || cryptoError}
						/>
					</div>
				</div>
				{!isTelegram && (
					<div className="pasteButtonContainer">
						<CustomButton
							isGrey
							text={t('general_pasteCode') as string}
							onClick={handlePasteClick}
							className="confirmFullWidthButton"
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default WithdrawTwoFactorLayout;

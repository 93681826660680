import { useTranslation } from 'react-i18next';
import { mapClassnameForMobile } from '../../../../utils';
import { APIError } from '../../../common/error';
import { HeaderLayout } from '../../../common/header';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { ROUTES } from '../../../../types/routes';
import { useGetAllDevices } from '../../../../data/mutations/profile';
import { useEffect, useState } from 'react';
import { selectAccessToken } from '../../../../services/auth';
import { UserDevicesList } from '../../../common/lists';
import { TUserDevice } from '../../../../types/types';
import { setSelectedUserDevice, setUserDevicesInit } from '../../../../services/user';
import {
	DeviceModal,
	QuickAccessModal,
	TerminateSessionModal,
} from '../../../common/modals';

const DevicesLayout = () => {
	const accessToken = useAppSelector(selectAccessToken);

	const { t } = useTranslation();
	const navigate = useCustomNavigate();
	const dispatch = useAppDispatch();

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isTerminateSessionModalOpen, setIsTerminateSessionModalOpen] =
		useState<boolean>(false);
	const [isQuickAccessModalOpen, setIsQuickAccessModalOpen] = useState<boolean>(false);

	const { getAllDevices, error, isError } = useGetAllDevices(dispatch);

	useEffect(() => {
		getAllDevices({
			accessToken: accessToken as string,
			dispatch: dispatch,
		});
	}, []);

	const handleModalOpen = (item?: TUserDevice) => {
		if (item) {
			dispatch(setSelectedUserDevice(item));
		}
		setIsModalOpen(!isModalOpen);
	};

	const handleQuickAccessModalOpen = () => {
		handleModalOpen();
		setIsQuickAccessModalOpen(!isQuickAccessModalOpen);
	};

	const handleTerminateSessionModalOpen = () => {
		handleModalOpen();
		setIsTerminateSessionModalOpen(!isTerminateSessionModalOpen);
	};

	const handleBackClickButton = () => {
		dispatch(setUserDevicesInit());
		navigate(ROUTES.PROFILE.SECURITY.INDEX);
	};

	return (
		<div className={'component'}>
			<div
				style={{
					minHeight: '100%',
					height: 'auto',
				}}
				className={'innerComponent aboutComponent'}
			>
				<HeaderLayout
					isFunctionalHeader
					isAboutHeader
					handleBackClick={handleBackClickButton}
				/>
				<DeviceModal
					handleQuickAccessModalOpen={handleQuickAccessModalOpen}
					handleTerminateSessionModalOpen={handleTerminateSessionModalOpen}
					isModalOpen={isModalOpen}
					handleIsModalOpen={handleModalOpen}
				/>
				<TerminateSessionModal
					isModalOpen={isTerminateSessionModalOpen}
					handleIsModalOpen={handleTerminateSessionModalOpen}
					handleCloseModal={() => {
						getAllDevices({
							accessToken: accessToken as string,
							dispatch: dispatch,
						});
						setIsTerminateSessionModalOpen(false);
					}}
				/>
				<QuickAccessModal
					isModalOpen={isQuickAccessModalOpen}
					handleIsModalOpen={handleQuickAccessModalOpen}
					handleCloseModal={() => {
						getAllDevices({
							accessToken: accessToken as string,
							dispatch: dispatch,
						});
						setIsQuickAccessModalOpen(false);
					}}
				/>
				<div
					style={{ marginBottom: '16px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className="innerContainer">
						<h1 className="innerContainerMainText">{t('devices_title')}</h1>

						<APIError isError={isError} error={error} />

						<UserDevicesList handleModalOpen={handleModalOpen} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default DevicesLayout;

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSubmitLastStepMutation } from '../../../data/mutations/registration';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { CustomButton } from '../../common/buttons';
import { CustomInput } from '../../common/inputs';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	selectRegistrationState,
	selectRegistrationTextCheckoxes,
} from '../../../services/registration';
import { HeaderLayout } from '../../common/header';
import { mapClassnameForMobile } from '../../../utils';
import { APIError } from '../../common/error';

const RegistrationSummaryLayout = () => {
	const registrationTextCheckoxes = useAppSelector(selectRegistrationTextCheckoxes);
	const state = useAppSelector(selectRegistrationState);

	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const navigate = useCustomNavigate();
	const buttonRef = useRef();

	const [name, setName] = useState<string>('');

	const [password, setPassword] = useState<string>('');
	const [passwordError, setPasswordError] = useState<boolean>(false);

	const { submitLastStep, mutationError, isError, isPending } =
		useSubmitLastStepMutation(navigate, dispatch);

	const [emailError, setEmailError] = useState<boolean>(false);

	useEffect(() => {
		if (isError && mutationError) {
			if (
				(mutationError as any)?.data?.target === 'name' //eslint-disable-line @typescript-eslint/no-explicit-any
			) {
				setEmailError(isError);
			}
			if (
				(mutationError as any)?.data?.target === 'password' //eslint-disable-line @typescript-eslint/no-explicit-any
			) {
				setPasswordError(isError);
			}
		}
	}, [isError]);

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setName(e.target.value);
		setEmailError(false);
	};

	const handleNameClear = () => {
		setName('');
		setEmailError(false);
	};

	const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);
		setPasswordError(false);
	};

	const handlePasswordPaste = (e: React.ClipboardEvent) => {
		const pastePassword = e.clipboardData.getData('text')[0];
		setPassword(pastePassword);
		setPasswordError(false);
	};

	const handlePasswordClear = () => {
		setPassword('');
		setPasswordError(false);
	};

	const handleBackClick = () => {
		navigate(ROUTES.REGISTRATION.PHONE);
	};

	const handleSubmit = async () => {
		setEmailError(false);
		setPasswordError(false);
		if (name?.length === 0 && password?.length === 0) {
			setEmailError(true);
			setPasswordError(true);
			return;
		}
		if (name?.length === 0) {
			setEmailError(true);
			return;
		}
		if (password?.length === 0) {
			setPasswordError(true);
			return;
		}

		submitLastStep({
			state: state,
			name: name,
			password: password,
			referral: '',
			dispatch: dispatch,
		});
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout isFunctionalHeader handleBackClick={handleBackClick} />
				<div className={mapClassnameForMobile('walletLayout')}>
					<div className="innerContainer">
						<h1 className={'confirmEmailText'}>
							{t('registration_nameAndPassword')}
						</h1>
						<h1 className={'confirmCodeText'}>
							{t('registration_provideNameAndPassword')}
						</h1>
						<CustomInput
							autoComplete={''}
							isRequired={name?.length > 0}
							type={'text'}
							id={'nameInput'}
							isError={emailError}
							label={t('registration_yourName')}
							value={name}
							onChange={handleNameChange}
							className={'input'}
							onClearClick={handleNameClear}
							inputPropsRef={buttonRef}
						/>
						<APIError isError={emailError} error={mutationError} />
						<CustomInput
							autoComplete={''}
							isError={passwordError}
							isRequired={password?.length > 0}
							type={'password'}
							id={'passwordInput'}
							label={t('registration_createPassword')}
							value={password}
							onChange={handlePasswordChange}
							onPaste={handlePasswordPaste}
							className={'input'}
							onClearClick={handlePasswordClear}
							inputPropsRef={buttonRef}
							isPassword={true}
						/>
						<h1
							className={
								passwordError
									? 'registrationPasswordRequirementsError'
									: 'registrationPasswordRequirements'
							}
						>
							{registrationTextCheckoxes?.passwordRequirements}
						</h1>
					</div>
				</div>
			</div>
			<div className="depositButtonsBottomContainer">
				<CustomButton
					buttonRef={buttonRef}
					isLoading={isPending}
					text={t('login_continue') as string}
					type={'submit'}
					onClick={handleSubmit}
					className={'confirmFullWidthButton'}
				/>
			</div>
		</div>
	);
};

export default RegistrationSummaryLayout;

import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '../../store';
import { TCommonTemplates, TSavedAddress, TSavedBank } from '../../types/types';

export const selectBankTemplates = (state: TRootState) =>
	state.withdrawTemplates.commonTemplates.bankTemplates;

export const selectCardTemplates = (state: TRootState) =>
	state.withdrawTemplates.commonTemplates.cardTemplates;

export const selectCryptoTemplates = (state: TRootState) =>
	state.withdrawTemplates.commonTemplates.cryptoTemplates;

export const selectCommonTemplates = (state: TRootState) =>
	state.withdrawTemplates.commonTemplates;

export const selectCryptoWithdrawCurrentSavedAddress = (state: TRootState) =>
	state.withdrawTemplates.currentCryptoTemplate;

export const selectBankWithdrawCurrentSavedBank = (state: TRootState) =>
	state.withdrawTemplates.currentBankTemplate;

export const selectIsCommonTemplatesEmpty = (state: TRootState) =>
	state.withdrawTemplates.commonTemplates?.bankTemplates?.length === 0 &&
	state.withdrawTemplates.commonTemplates?.cryptoTemplates?.length === 0 &&
	state.withdrawTemplates.commonTemplates?.cardTemplates?.length === 0;

interface IRootInitialState {
	commonTemplates: TCommonTemplates;
	currentCryptoTemplate: TSavedAddress | undefined;
	currentBankTemplate: TSavedBank | undefined;
}

const initialState: IRootInitialState = {
	commonTemplates: {
		bankTemplates: [],
		cryptoTemplates: [],
		cardTemplates: [],
	},
	currentCryptoTemplate: undefined,
	currentBankTemplate: undefined,
};

export const withdrawTemplatesSlice = createSlice({
	name: 'withdrawTemplates',
	initialState,
	reducers: {
		setCardWithdrawSavedCards(state, action) {
			state.commonTemplates.cardTemplates = action.payload;
		},
		setCardWithdrawSavedCardsReduce(state, action) {
			state.commonTemplates.cardTemplates =
				state.commonTemplates.cardTemplates?.filter(
					(card) => card?.templateId != action.payload
				);
		},
		setUpdateCardWithdrawSavedCard(state, action) {
			state.commonTemplates.cardTemplates = state.commonTemplates.cardTemplates.map(
				(card) => {
					if (card?.templateId === action.payload?.templateId) {
						card.templateName = action?.payload?.templateName;
						card.cardholderName = action.payload?.cardholderName;
					}
					return card;
				}
			);
		},
		setCryptoWithdrawSavedAddresses(state, action) {
			state.commonTemplates.cryptoTemplates = action.payload;
		},
		setCryptoWithdrawSavedAddressesReduce(state, action) {
			state.commonTemplates.cryptoTemplates =
				state.commonTemplates.cryptoTemplates?.filter(
					(address) => address.templateId != action.payload
				);
		},
		setUpdateCryptoWithdrawSavedAddresses(state, action) {
			state.commonTemplates.cryptoTemplates =
				state.commonTemplates.cryptoTemplates.map((address) => {
					if (address?.templateId === action.payload?.templateId) {
						address.templateName = action.payload?.templateName;
					}
					return address;
				});
		},
		setCryptoWithdrawCurrentSavedAddress(state, action) {
			state.currentCryptoTemplate = action.payload;
		},
		setBankWithdrawCurrentSavedBank(state, action) {
			state.currentBankTemplate = action.payload;
		},
		setBankWithdrawTemplates(state, action) {
			state.commonTemplates.bankTemplates = action.payload;
		},
		setBankWithdrawSavedBanksReduce(state, action) {
			state.commonTemplates.bankTemplates =
				state.commonTemplates.bankTemplates?.filter(
					(bank) => bank?.templateId != action.payload
				);
		},
		setUpdateBankWithdrawSavedBank(state, action) {
			state.commonTemplates.bankTemplates = state.commonTemplates.bankTemplates.map(
				(bank) => {
					if (bank?.templateId === action.payload?.templateId) {
						bank.templateName = action?.payload?.templateName;
					}
					return bank;
				}
			);
		},
	},
});

export const {
	setCardWithdrawSavedCards,
	setCardWithdrawSavedCardsReduce,
	setUpdateCardWithdrawSavedCard,
	setCryptoWithdrawSavedAddresses,
	setCryptoWithdrawSavedAddressesReduce,
	setUpdateCryptoWithdrawSavedAddresses,
	setCryptoWithdrawCurrentSavedAddress,
	setBankWithdrawTemplates,
	setBankWithdrawSavedBanksReduce,
	setUpdateBankWithdrawSavedBank,
	setBankWithdrawCurrentSavedBank,
} = withdrawTemplatesSlice.actions;

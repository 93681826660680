import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { HeaderLayout } from '../../common/header';
import { ROUTES } from '../../../types/routes';
import { useEffect, useState } from 'react';
import { WithdrawHistoryModalContainer } from '../../common/modals/containers';
import { useTranslation } from 'react-i18next';
import { formatNumber, mapClassnameForMobile } from '../../../utils';
import { CustomInput } from '../../common/inputs';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	selectCardWithdrawAmount,
	selectCardWithdrawAsset,
	selectCardWithdrawCard,
	selectCardWithdrawFee,
	selectCardWithdrawIsCardOwner,
	selectCardWithdrawIsCardValid,
	selectCardWithdrawIsChecked,
	setCardWithdrawAmount,
	setCardWithdrawAsset,
	setCardWithdrawCard,
	setCardWithdrawInit,
	setCardWithdrawIsCardOwner,
	setCardWithdrawIsChecked,
} from '../../../services/withdraw/card.withdraw.slice';
import { CustomButton } from '../../common/buttons';
import {
	selectAssetIcons,
	selectDepositWithdrawAssetsDictionary,
} from '../../../services/dictionaries';
import { TAsset, TInnerFiatWithdraw, TSavedCard } from '../../../types/types';
import { selectMainAccountRests } from '../../../services/wallet';
import {
	CardWithdrawAssetModal,
	CardWithdrawEditSavedAddressModal,
	CardWithdrawSavedCardsModal,
} from '../../common/modals';
import { CustomCheckbox } from '../../common/checkboxes';
import { selectFullName } from '../../../services/user';
import {
	useCheckCardWithdrawCardValidation,
	useGetCardWithdrawCommission,
} from '../../../data/mutations/withdraw';
import { useDebounce } from '../../../hooks/useDebounce';
import { selectAccessToken } from '../../../services/auth';
import { APIError } from '../../common/error';

const CardWithdrawFunctionalLayout = () => {
	const savedCard = useAppSelector(selectCardWithdrawCard);
	const fullName = useAppSelector(selectFullName);
	const amount = useAppSelector(selectCardWithdrawAmount);
	const selectedAsset = useAppSelector(selectCardWithdrawAsset);
	const fee = useAppSelector(selectCardWithdrawFee);
	const mainCurrencies = useAppSelector(selectMainAccountRests);
	const assetIcons = useAppSelector(selectAssetIcons);
	const isChecked = useAppSelector(selectCardWithdrawIsChecked);
	const isCardValid = useAppSelector(selectCardWithdrawIsCardValid);
	const accessToken = useAppSelector(selectAccessToken);
	const isCardOwner = useAppSelector(selectCardWithdrawIsCardOwner);
	const depositWithdrawAssetsDictionary = useAppSelector(
		selectDepositWithdrawAssetsDictionary
	)?.dictionary?.withdraw?.paymentSystem[0]?.cards;

	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();
	const { t } = useTranslation();

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isHistoryModalOpen, setIsHistoryModalOpen] = useState<boolean>(false);
	const [isSavedCardsModalOpen, setIsSavedCardsModalOpen] = useState<boolean>(false);
	const [isEditSavedCardModalOpen, setIsEditSavedCardModalOpen] =
		useState<boolean>(false);

	const [mainAsset, setMainAsset] = useState<TAsset | undefined>();
	const [minValue, setMinValue] = useState<number | undefined>(0);
	const [isAmountRequired, setIsAmountRequired] = useState<boolean>(false);

	const [savedCardNumber, setSavedCardNumber] = useState<string>('');
	const [isSavedCardRequired, setIsSavedCardRequired] = useState<boolean>(false);

	const [cardHolderName, setCardHolderName] = useState<string>('');
	const [isCardHolderNameRequired, setIsCardHolderNameRequired] =
		useState<boolean>(false);

	const {
		isCheckCardValidationPending,
		isError,
		error,
		checkCardWithdrawCardValidation,
	} = useCheckCardWithdrawCardValidation(
		dispatch,
		navigate,
		savedCardNumber,
		cardHolderName
	);

	const { getCardWithdrawCommission } = useGetCardWithdrawCommission(dispatch);

	const debouncedValue = useDebounce(amount, 500);

	useEffect(() => {
		if (Number(debouncedValue) > 0 && selectedAsset != undefined) {
			getCardWithdrawCommission({
				accessToken: accessToken as string,
				asset: selectedAsset?.code as string,
				amount: debouncedValue,
				dispatch: dispatch,
			});
		}
	}, [debouncedValue]);

	useEffect(() => {
		if (fullName) {
			setCardHolderName(fullName?.toUpperCase());
			setIsCardHolderNameRequired(fullName?.length > 0);
		}
	}, [fullName]);

	useEffect(() => {
		if (savedCard) {
			setSavedCardNumber(savedCard?.cardNumber);
			setIsSavedCardRequired(savedCard?.cardNumber?.length > 0);
		}
		if (selectedAsset) {
			setMainAsset(
				mainCurrencies?.assets?.find(
					(asset) => asset?.code === selectedAsset?.code
				)
			);
			setMinValue(selectedAsset?.min);
		}
		if (amount) {
			setIsAmountRequired(amount?.length > 0);
		}
	}, []);

	const handleBackClickButton = () => {
		dispatch(setCardWithdrawInit());
		navigate(ROUTES.WITHDRAW.INDEX);
	};

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleHistoryModalOpen = () => {
		setIsHistoryModalOpen(!isHistoryModalOpen);
	};

	const handleSavedCardsModalOpen = () => {
		setIsSavedCardsModalOpen(!isSavedCardsModalOpen);
	};

	const handleSavedCardClick = (i: TSavedCard) => {
		handleSavedCardsModalOpen();
		dispatch(setCardWithdrawCard(i));
		setCardHolderName(i?.cardholderName);
		setSavedCardNumber(i?.cardNumber);
		setIsSavedCardRequired(i?.cardNumber?.length > 0);
		dispatch(setCardWithdrawIsCardOwner(true));
	};

	const handleEditSavedCardsModalOpen = (i?: TSavedCard | undefined) => {
		const tempIsEditSavedAddressModalOpen = !isEditSavedCardModalOpen;
		if (i) {
			dispatch(setCardWithdrawCard(i));
		}
		handleSavedCardsModalOpen();
		setIsEditSavedCardModalOpen(tempIsEditSavedAddressModalOpen);
	};

	const handleSavedCardChange = (e: React.ChangeEvent<HTMLInputElement> | string) => {
		if(typeof e === 'string' && !e.includes("*")) {
			dispatch(
				setCardWithdrawCard({
					cardNumber: e.replaceAll(' ',''),
				})
			);
			setSavedCardNumber(e.replaceAll(' ',''));
			setIsSavedCardRequired(e?.length > 0);
		}
	};

	const handleSavedCardClearClick = () => {
		setSavedCardNumber('');
		dispatch(setCardWithdrawCard(undefined));
		setIsSavedCardRequired(false);
	};

	const handleNavigateClick = () => {
		handleHistoryModalOpen();
	};

	const handleItemClick = (i: TInnerFiatWithdraw) => {
		setIsModalOpen(!isModalOpen);
		dispatch(setCardWithdrawAsset(i));
		setMinValue(i?.min);
		setMainAsset(mainCurrencies?.assets?.find((asset) => asset?.code === i?.code));
	};

	const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value?.length <= 12 + (mainAsset?.precision ?? 2)) {
			dispatch(setCardWithdrawAmount(e.target.value));
			setIsAmountRequired(e.target.value.length > 0);
		}
	};

	const handleMaxClick = () => {
		if (
			selectedAsset != undefined &&
			mainAsset != undefined &&
			mainAsset?.available != undefined
		) {
			dispatch(
				setCardWithdrawAmount(mainAsset?.available ? mainAsset?.available : 0)
			);
			setIsAmountRequired(String(mainAsset?.available)?.length > 0);
		}
	};

	const handleClearClick = () => {
		dispatch(setCardWithdrawAmount(''));
		setIsAmountRequired(false);
	};

	const handleCardHolderNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCardHolderName(e.target.value);
		setIsCardHolderNameRequired(e.target.value?.length > 0);
	};

	const handleCardHolderNameClearClick = () => {
		setCardHolderName('');
		setIsCardHolderNameRequired(false);
	};

	const handleIsCheckedFeeChange = (eventValue: boolean) => {
		dispatch(setCardWithdrawIsChecked(eventValue));
	};

	const handleIsCheckedIsCardOwnerChange = (eventValue: boolean) => {
		dispatch(setCardWithdrawIsCardOwner(eventValue));
	};

	const handleSubmit = () => {
		if (savedCard?.userCardId) {
			navigate(ROUTES.WITHDRAW.CARD.CONFIRMATION);
		} else {
			checkCardWithdrawCardValidation({
				cardNumber: savedCard?.cardNumber as string,
				currencyCode: selectedAsset?.code as string,
				cardholderName: cardHolderName,
				isCardholderCard: isCardOwner,
				dispatch: dispatch,
				accessToken: accessToken as string,
			});
		}
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout
					isInvoiceHistory
					handleBackClick={handleBackClickButton}
					handleNavigateClick={handleNavigateClick}
				/>
				<CardWithdrawAssetModal
					isModalOpen={isModalOpen}
					handleIsModalOpen={handleModalOpen}
					handleItemClick={handleItemClick}
				/>
				<WithdrawHistoryModalContainer
					isHistoryModalOpen={isHistoryModalOpen}
					setIsHistoryModalOpen={setIsHistoryModalOpen}
				/>
				<CardWithdrawSavedCardsModal
					handleEditSavedCardModalOpen={handleEditSavedCardsModalOpen}
					isModalOpen={isSavedCardsModalOpen}
					handleIsModalOpen={handleSavedCardsModalOpen}
					handleItemClick={handleSavedCardClick}
				/>
				<CardWithdrawEditSavedAddressModal
					isModalOpen={isEditSavedCardModalOpen}
					handleIsModalOpen={handleEditSavedCardsModalOpen}
				/>
				<div
					style={{ marginBottom: '16px', overflow: 'unset !important' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div
						style={{
							height: '100vh',
							justifyContent: 'flex-start',
							marginBottom: '16px',
						}}
						className="innerContainer"
					>
						<h1 className="innerContainerMainText">
							{t('withdrawCard_title')}
						</h1>
						<div className="depositAmountInputContainer">
							<CustomInput
								isRequired={selectedAsset ? true : false}
								isSelectAsset
								type="text"
								id="cardWithdrawSelectAsset"
								onClick={handleModalOpen}
								value={selectedAsset?.code as string}
								imgUrl={
									selectedAsset
										? assetIcons?.dictionary &&
										  assetIcons?.dictionary[
												selectedAsset?.code?.toUpperCase() as string
										  ]
										: ''
								}
								className="confirmFullWidthButton"
								label={t('depositCrypto_chooseDepositAsset') as string}
							/>
						</div>
						<div className="depositAmountInputContainer">
							<CustomInput
								isError={!isCardValid}
								isCardNumber
								isStartIcon
								onClick={handleSavedCardsModalOpen}
								label={t('general_cardNumber') as string}
								className="confirmFullWidthButton"
								type="text"
								id="cryptoWithdrawAddress"
								imgUrl={
									depositWithdrawAssetsDictionary?.find((system) =>
										savedCardNumber?.startsWith(
											String(system?.startNum[0])
										)
									)?.icon
								}
								onChange={handleSavedCardChange}
								onClearClick={handleSavedCardClearClick}
								isRequired={isSavedCardRequired}
								value={savedCardNumber as string}
							/>
							{isError && (
								<h1 className="cryptoWithdrawAddressErrorText">
									{t('withdrawCard_checkBankCard')}
								</h1>
							)}
							<div className="includeFeeContainer">
								<CustomCheckbox
									isError={
										!isCardOwner && savedCardNumber?.length === 16
									}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										handleIsCheckedIsCardOwnerChange(e.target.checked)
									}
									key={selectedAsset?.code}
									checked={isCardOwner}
								/>
								<h1
									className={
										'includeFeeText' +
										(!isCardOwner && savedCardNumber?.length === 16
											? ' includeFeeTextError'
											: '')
									}
								>
									{t('withdrawCard_bankCardConfirm')}
								</h1>
							</div>
						</div>
						<div className="depositAmountInputContainer">
							<CustomInput
								isRequired={isCardHolderNameRequired}
								type="text"
								id="cardWithdrawSelectAsset"
								onChange={handleCardHolderNameChange}
								onClearClick={handleCardHolderNameClearClick}
								value={cardHolderName}
								className="confirmFullWidthButton"
								label={t('withdrawCard_cardholderName') as string}
							/>
						</div>
						<div className="depositAmountInputContainer">
							<CustomInput
								isAmountMax
								label={
									selectedAsset != undefined
										? `${t('wallet_toBeReceived')} (${t(
												'withdraw_min'
										  )} ${minValue} ${selectedAsset?.code})`
										: t('wallet_toBeReceived')
								}
								className="confirmFullWidthButton"
								type="number"
								id="cryptoWithdrawAmount"
								onChange={handleAmountChange}
								onClick={handleMaxClick}
								onClearClick={handleClearClick}
								isRequired={isAmountRequired}
								value={amount}
							/>
							{selectedAsset != undefined && (
								<h1 className="depositFeeText">{`${t(
									'general_availableAmount'
								)}: ${
									mainAsset?.available ? mainAsset?.available : 0.0
								} ${selectedAsset?.code}`}</h1>
							)}
							<div className="includeFeeContainer">
								<CustomCheckbox
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										handleIsCheckedFeeChange(e.target.checked)
									}
									key={selectedAsset?.code}
									checked={isChecked}
								/>
								<h1 className="includeFeeText">
									{t('withdraw_includeFee')}
								</h1>
							</div>
						</div>
						<APIError isError={isError} error={error} />
					</div>
				</div>
			</div>
			<div className="withdrawCryptoButtonsBottomContainer">
				<div className="withdrawButtonsBottomSeparator" />
				<div className="withdrawCryptoButtonsBottomInnerContainer">
					<div className="withdrawCryptoBottomAmountContainer">
						<h1 className="withdrawCryptoBottomAmountContainerHeader">
							{t('withdraw_totalWithdraw')}
						</h1>
						<h1
							className={
								'withdrawCryptoBottomAmountContainerMain' +
								(amount?.length >= 10
									? ' withdrawCryptoBottomAmountContainerMainSmallerText'
									: ' ')
							}
						>
							{formatNumber(
								isChecked ? Number(amount) + Number(fee) : Number(amount),
								mainAsset?.precision
							)}
							{selectedAsset === undefined ? '' : ` ${selectedAsset?.code}`}
						</h1>
						<h1 className="withdrawCryptoBottomAmountContainerBottom">
							{`${t('withdraw_includingFee')}: `}
							{formatNumber(Number(fee), mainAsset?.precision)}
							{selectedAsset === undefined ? '' : ` ${selectedAsset?.code}`}
						</h1>
					</div>
					<CustomButton
						isDisabled={
							(isChecked ? Number(amount) + Number(fee) : Number(amount)) >
								Number(mainAsset?.available) ||
							amount.length === 0 ||
							Number(amount) < Number(minValue) ||
							mainAsset === undefined ||
							selectedAsset === undefined ||
							!isCardOwner
						}
						isLoading={isCheckCardValidationPending}
						text={t('wallet_withdraw') as string}
						onClick={handleSubmit}
						className="withdrawCryptoBottomButtonContainer"
					/>
				</div>
			</div>
		</div>
	);
};

const CardWithdrawLayout = () => {
	return <CardWithdrawFunctionalLayout />;
};

export default CardWithdrawLayout;

import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../../types/titles';
import BankWithdrawConfirmationLayout from '../../../../components/withdraw/bank/confirmation';

const BankWithdrawConfirmation = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.WITHDRAW.BANK.CONFIRMATION}</title>
			</Helmet>
			<BankWithdrawConfirmationLayout />
		</>
	);
};

export default BankWithdrawConfirmation;

interface CustomSwitchProps {
	onClick: () => void;
	isActive: boolean;
	setIsActive?: React.Dispatch<React.SetStateAction<boolean>>;
	isDisabled?: boolean;
}

const CustomSwitch = ({
	onClick,
	setIsActive,
	isActive,
	isDisabled,
}: CustomSwitchProps) => {
	const handleClick = () => {
		if (isDisabled === undefined && !isDisabled) {
			if (setIsActive) setIsActive(!isActive);
		}
		onClick();
	};

	return (
		<div
			className={'switchContainer' + (isActive ? ' switchContainerActive' : '')}
			onClick={handleClick}
		>
			<div
				className={
					'innerContainerEllipse' +
					(isActive ? ' innerContainerEllipseActive' : '')
				}
			/>
		</div>
	);
};

export { CustomSwitch };

import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../../types/titles';
import PasswordRecoveryChangePasswordLayout from '../../../../components/auth/recovery/change-password';

const PasswordRecoveryChangePassword = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.AUTH.RECOVERY.NEW_PASSWORD}</title>
			</Helmet>
			<PasswordRecoveryChangePasswordLayout />
		</>
	);
};

export default PasswordRecoveryChangePassword;

import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../types/titles';
import WalletTransferSummaryLayout from '../../../components/wallet/transfer-summary';

const WalletTransferSummary = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.WALLET.TRANSFER_SUMMARY}</title>
			</Helmet>
			<WalletTransferSummaryLayout />
		</>
	);
};

export default WalletTransferSummary;

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authenticationSlice } from './services/auth';
import { rootSlice } from './services/root';
import { dictionariesSlice } from './services/dictionaries';
import { walletSlice } from './services/wallet';
import { userSlice } from './services/user';
import { marketSlice } from './services/market';
import { historySlice } from './services/history';
import { depositSlice } from './services/deposit';
import { withdrawCryptoSlice } from './services/withdraw/crypto.withdraw.slice';
import { withdrawCardSlice } from './services/withdraw/card.withdraw.slice';
import { withdrawTemplatesSlice } from './services/withdraw/templates.withdraw.slice';
import { withdrawBankSlice } from './services/withdraw/bank.withdraw.slice';
import { exchangeSlice } from './services/exchange/exchange.slice';
import { securitySlice } from './services/security';
import { withdrawSlice } from './services/withdraw/withdraw.slice';
import { registrationSlice } from './services/registration';

export const allReducers = combineReducers({
	[authenticationSlice.reducerPath]: authenticationSlice.reducer,
	[rootSlice.reducerPath]: rootSlice.reducer,
	[dictionariesSlice.reducerPath]: dictionariesSlice.reducer,
	[walletSlice.reducerPath]: walletSlice.reducer,
	[userSlice.reducerPath]: userSlice.reducer,
	[marketSlice.reducerPath]: marketSlice.reducer,
	[historySlice.reducerPath]: historySlice.reducer,
	[depositSlice.reducerPath]: depositSlice.reducer,
	[withdrawCryptoSlice.reducerPath]: withdrawCryptoSlice.reducer,
	[withdrawCardSlice.reducerPath]: withdrawCardSlice.reducer,
	[withdrawTemplatesSlice.reducerPath]: withdrawTemplatesSlice.reducer,
	[withdrawBankSlice.reducerPath]: withdrawBankSlice.reducer,
	[exchangeSlice.reducerPath]: exchangeSlice.reducer,
	[securitySlice.reducerPath]: securitySlice.reducer,
	[withdrawSlice.reducerPath]: withdrawSlice.reducer,
	[registrationSlice.reducerPath]: registrationSlice.reducer
});

export const store = configureStore({
	reducer: allReducers,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware()
	//!!! - using for prevent security issues in production using : redux-toolkit-extension in browser
	//devTools: process.env.NODE_ENV !== 'production'
});

export type TRootState = ReturnType<typeof allReducers>;
export type AppDispatch = typeof store.dispatch;

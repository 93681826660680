import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../../../types/titles';
import SecurityTwoFaSecretKeyLayout from '../../../../../components/profile/security/twofa/secret-key';
import { useLoaderData } from 'react-router-dom';
import { useAppDispatch } from '../../../../../hooks';
import { setTwoFaSecretKey } from '../../../../../services/security';
import { useEffect } from 'react';

const TwoFaSettingsSecretKeyProfile = () => {
    const data = useLoaderData();
        const dispatch = useAppDispatch();
    
        useEffect(() => {
            dispatch(
                setTwoFaSecretKey({
                    //eslint-disable-next-line
                    secretKey: (data as any)?.key,
                    //eslint-disable-next-line
                    qrCodePath: (data as any)?.uri
                })
            );
        }, []);


	return (
		<>
			<Helmet>
				<title>{TITLES.PROFILE.SECURITY.TWO_FA_SETTINGS.SECRET_KEY}</title>
			</Helmet>
			<SecurityTwoFaSecretKeyLayout />
		</>
	);
};

export default TwoFaSettingsSecretKeyProfile;

import { useMutation } from '@tanstack/react-query';
import {
	TBiometry,
	TCustomNavigate,
	TLoginBody,
	TPasswordRecoveryBody,
	TPasswordRecoveryChangeBody,
	TRefreshToken,
} from '../../../types/types';
import { ROUTES } from '../../../types/routes';
import {
	fetchLoginOTP,
	fetchLoginWithoutTwoFA,
	fetchLoginWithTwoFA,
	fetchRefreshToken,
} from '../../../api/login';
import { AppDispatch } from '../../../store';
import {
	fetchPasswordRecoveryChange,
	fetchPasswordRecoveryRequest,
} from '../../../api/security';

export function useLoginWithoutTwoFAMutation(navigate: TCustomNavigate) {
	const {
		mutateAsync: login,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['loginWithoutTwoFA'],
		mutationFn: (data: TLoginBody) => fetchLoginWithoutTwoFA(data, navigate),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.additional.twoFAStatus != 'enabled') {
				navigate(ROUTES.AUTH.SETUP_TWOFA);
				return;
			}
			navigate(ROUTES.AUTH.INDEX);
		},
	});
	return {
		login,
		error,
		isError,
		isPending,
	};
}

export function useRefreshToken(navigate: TCustomNavigate) {
	const {
		mutateAsync: refresh,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['refreshToken'],
		mutationFn: (data: TRefreshToken) => fetchRefreshToken(data),
		onSuccess: () => {
			navigate(ROUTES.WALLET.INDEX);
		},
		//eslint-disable-next-line
		onError: async (data: any) => {
			if (data.message === 'Token is not active') {
				navigate(ROUTES.AUTH.LOGIN);
			}
		},
	});
	return {
		refresh,
		error,
		isError,
		isPending,
	};
}

export function useLoginWithTwoFAMutation(
	navigate: TCustomNavigate,
	biometry: TBiometry
) {
	const {
		mutateAsync: login,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['loginWithTwoFA'],
		mutationFn: (data: TLoginBody) => fetchLoginWithTwoFA(data, navigate),
		onSuccess: () => {
			if (biometry.isBiometricAvailable && !biometry.isBiometrySetup) {
				navigate(ROUTES.AUTH.SETUP_BIOMETRY);
				return;
			}
			navigate(ROUTES.WALLET.INDEX);
		},
	});
	return {
		login,
		error,
		isError,
		isPending,
	};
}

export function useSendOtpCodeMutation(navigate?: TCustomNavigate, notify?: () => void) {
	const {
		mutateAsync: sendOtpCode,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['sendOtpCode'],
		mutationFn: ({
			identityType,
			state,
			navigate,
			dispatch,
		}: {
			identityType: string;
			state: string;
			navigate: TCustomNavigate;
			dispatch?: AppDispatch;
		}) => fetchLoginOTP(identityType, state, navigate, dispatch),
		onSuccess: async () => {
			if(navigate) {
				navigate(ROUTES.AUTH.OTP);
			}
			if(notify) {
				notify()
			}
		},
	});
	return {
		sendOtpCode,
		error,
		isError,
		isPending,
	};
}

export function useSendPasswordRecoveryRequest(
	navigate: TCustomNavigate,
	isResend?: boolean,
	notify?: () => void
) {
	const {
		mutateAsync: sendPasswordRecoveryRequest,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['sendPasswordRecoveryRequest'],
		mutationFn: (data: TPasswordRecoveryBody) => fetchPasswordRecoveryRequest(data),
		onSuccess: async () => {
			if (!isResend) {
				navigate(ROUTES.AUTH.RECOVERY.CONFIRM_EMAIL);
			}
			if(isResend && notify) {
				notify()
			}
		},
	});
	return {
		sendPasswordRecoveryRequest,
		error,
		isError,
		isPending,
	};
}

export function useSendPasswordRecoveryChange(navigate: TCustomNavigate) {
	const {
		mutateAsync: sendPasswordRecoveryChange,
		isError,
		error: error,
		isPending,
		isSuccess
	} = useMutation({
		mutationKey: ['sendPasswordRecoveryChange'],
		mutationFn: (data: TPasswordRecoveryChangeBody) =>
			fetchPasswordRecoveryChange(data),
		//eslint-disable-next-line
		onError: async (data: any) => {
			if (data?.code === 'PASSWORD_REQUIRED') {
				navigate(ROUTES.AUTH.RECOVERY.NEW_PASSWORD);
			}
		},
	});
	return {
		sendPasswordRecoveryChange,
		error,
		isError,
		isPending,
		isSuccess
	};
}

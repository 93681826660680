export const API_ROUTES = {
	ASSETS: {
		PRICES: {
			RATE: {
				METHOD: 'GET',
				URL: '/api/assets/prices',
			},
		},
	},
	LOGIN: {
		METHOD: 'POST',
		URL: '/api/token/login',
	},
	REFRESH_TOKEN: {
		METHOD: 'GET',
		URL: '/api/token/refresh',
	},
	SEND_OTP: {
		METHOD: 'POST',
		URL: '/api/token/login/twofa/otp/send',
	},
	REVOKE: {
		METHOD: 'DELETE',
		URL: '/api/token/revoke',
	},
	DICTIONARY: {
		METHOD: 'GET',
		URL: '/api/dictionaries',
	},
	HEALTH: {
		METHOD: 'GET',
		URL: '/api/health',
	},
	SECURITY: {
		DEVICES: {
			ALL: {
				METHOD: 'GET',
				URL: '/api/security/devices'
			},
			RESET: {
				METHOD: 'PUT',
				URL: '/api/security/devices/reset'
			}
		},
		NEWKEY: {
			METHOD: 'GET',
			URL: '/api/security/twofa/googleauthenticator/newkey',
		},
		ENABLE_GA: {
			METHOD: 'POST',
			URL: '/api/security/twofa/googleauthenticator/enable',
		},
		TWOFA: {
			OPTIONS: {
				METHOD: 'GET',
				URL: '/api/security/twofa',
			},
			IDENTITY_TYPE: {
				ENABLE: {
					METHOD: 'POST',
					URL: '/api/security/twofa/{identityType}/enable',
				},
				DISABLE: {
					METHOD: 'POST',
					URL: '/api/security/twofa/{identityType}/disable',
				},
			},
		},
		PASSWORD: {
			OTP: {
				SEND: {
					METHOD: 'POST',
					URL: '/api/security/otp/send',
				},
			},
			CHANGE: {
				METHOD: 'POST',
				URL: '/api/security/password/change',
			},
			RECOVERY: {
				REQUEST: {
					METHOD: 'POST',
					URL: '/api/security/password/recovery/request',
				},
				CHANGE: {
					METHOD: 'POST',
					URL: '/api/security/password/recovery/change',
				},
			},
		},
	},
	MARKET: {
		PRICES: {
			METHOD: 'POST',
			URL: '/api/assets/prices',
		},
	},
	OPERATIONS: {
		CANCEL: {
			METHOD: 'GET',
			URL: '/api/operations/cancel/{operId}',
		},
		TWOFA: {
			OTP: {
				SEND: {
					METHOD: 'POST',
					URL: '/api/operations/twofa/otp/send',
				},
			},
		},
		DETAILS: {
			METHOD: 'GET',
			URL: '/api/operations/details/{operId}',
		},
		LIST: {
			METHOD: 'GET',
			URL: '/api/operations/list/{filter}',
		},
		STATEMENT: {
			METHOD: 'GET',
			URL: '/api/operations/list/{filter}/statement',
		},
		EXCHANGE: {
			STACK: {
				METHOD: 'POST',
				URL: '/api/operations/exchange/stack',
			},
		},
		DEPOSIT: {
			ERIP_DEPOSIT: {
				METHOD: 'GET',
				URL: '/api/operations/erip/deposit',
			},
			OPLATI_DEPOSIT: {
				METHOD: 'GET',
				URL: '/api/operations/oplati/deposit',
			},
			CRYPTO_DEPOSIT_ADDRESS: {
				METHOD: 'GET',
				URL: '/api/operations/crypto/{networkName}/deposit',
			},
			CARD: {
				CARD_DEPOSIT: {
					METHOD: 'POST',
					URL: '/api/operations/card/deposit',
				},
				CARD_COMMISSION: {
					METHOD: 'POST',
					URL: '/api/operations/card/deposit/commission',
				},
			},
			BANK: {
				CREATE_BANK_INVOICE: {
					METHOD: 'POST',
					URL: '/api/operations/bank/deposit',
				},
				GET_BANK_INVOICE: {
					METHOD: 'GET',
					URL: '/api/operations/bank/deposit/invoices/{invoiceId}/pdf',
				},
				GET_BANK_INVOICE_DETAILS: {
					METHOD: 'GET',
					URL: '/api/operations/bank/deposit/invoices/{invoiceId}',
				},
				DELETE_BANK_INVOICE: {
					METHOD: 'DELETE',
					URL: '/api/operations/bank/deposit/invoices/{invoiceId}',
				},
				INVOICE_HISTORY: {
					METHOD: 'GET',
					URL: '/api/operations/bank/deposit/invoices',
				},
				BANK_COMMISSION: {
					METHOD: 'GET',
					URL: '/api/operations/bank/deposit/commission',
				},
			},
		},
		WITHDRAW: {
			CRYPTO: {
				WITHDRAW: {
					METHOD: 'POST',
					URL: '/api/operations/crypto/{networkName}/withdraw',
				},
				CHECK_ADDRESS: {
					METHOD: 'GET',
					URL: '/api/operations/crypto/{networkName}/check/{cryptoAddress}',
				},
				COMMISSION: {
					METHOD: 'GET',
					URL: '/api/operations/crypto/{networkName}/withdraw/commission',
				},
				CREATE_TEMPLATE: {
					METHOD: 'POST',
					URL: '/api/operations/crypto/{networkName}/template',
				},
				GET_TEMPLATE: {
					METHOD: 'GET',
					URL: '/api/operations/crypto/{networkName}/template',
				},
				UPDATE_TEMPLATE: {
					METHOD: 'PATCH',
					URL: '/api/operations/crypto/{networkName}/template/{templateId}',
				},
				DELETE_TEMPLATE: {
					METHOD: 'DELETE',
					URL: '/api/operations/crypto/{networkName}/template/{templateId}',
				},
			},
			CARD: {
				WITHDRAW: {
					METHOD: 'POST',
					URL: '/api/operations/card/withdraw',
				},
				CHECK_CARD: {
					METHOD: 'POST',
					URL: '/api/operations/card',
				},
				COMMISSION: {
					METHOD: 'GET',
					URL: '/api/operations/card/withdraw/commission',
				},
				CREATE_TEMPLATE: {
					METHOD: 'POST',
					URL: '/api/operations/card/template',
				},
				GET_TEMPLATE: {
					METHOD: 'GET',
					URL: '/api/operations/card/template',
				},
				UPDATE_TEMPLATE: {
					METHOD: 'PATCH',
					URL: '/api/operations/card/template/{templateId}',
				},
				DELETE_TEMPLATE: {
					METHOD: 'DELETE',
					URL: '/api/operations/card/template/{templateId}',
				},
			},
			BANK: {
				GET_TEMPLATE: {
					METHOD: 'GET',
					URL: '/api/operations/bank/template',
				},
				CREATE_TEMPLATE: {
					METHOD: 'POST',
					URL: '/api/operations/bank/template',
				},
				UPDATE_TEMPLATE: {
					METHOD: 'PATCH',
					URL: '/api/operations/bank/template/{templateId}',
				},
				DELETE_TEMPLATE: {
					METHOD: 'DELETE',
					URL: '/api/operations/bank/template/{templateId}',
				},
				GET_PARAMS: {
					METHOD: 'GET',
					URL: '/api/operations/bank/withdraw/params',
				},
				SEND_PARAMS: {
					METHOD: 'POST',
					URL: '/api/operations/bank/withdraw/params',
				},
				WITHDRAW: {
					METHOD: 'POST',
					URL: '/api/operations/bank/withdraw',
				},
				COMMISSION: {
					METHOD: 'GET',
					URL: '/api/operations/bank/withdraw/commission',
				},
			},
		},
	},
	USER: {
		PROFILE: {
			ACTIVITY: {
				METHOD: 'GET',
				URL: '/api/users/profile/activity',
			},
		},
		REGISTRATION: {
			REGISTRATION_START: {
				METHOD: "POST",
				URL: "/api/users/registration/start",
			},
			REGISTRATION_INFO: {
				METHOD: "GET",
				URL: "/api/users/registration/info",
			},
			GET_EULA: {
				METHOD: "GET",
				URL: "/api/users/registration/eula",
			},
			SEND_EULA: {
				METHOD: "POST",
				URL: "/api/users/registration/eula",
			},
			SEND_CODE: {
				METHOD: "POST",
				URL: "/api/users/registration/send",
			},
			VERIFY_CODE: {
				METHOD: "POST",
				URL: "/api/users/registration/verify",
			},
			CREATE_ACCOUNT: {
				METHOD: "POST",
				URL: "/api/users/registration/create",
			},
			CLEAR: {
				METHOD: "POST",
				URL: "/api/users/registration/clear"
			}
		},
		VERIFICATION: {
			INFO: {
				METHOD: 'GET',
				URL: '/api/users/verification/info',
			},
		},
		INFO: {
			METHOD: 'GET',
			URL: '/api/users/profile/info',
		},
		GENERATE_ACCOUNT_CONFIRMATION_LETTER: {
			METHOD: 'POST',
			URL: '/api/users/profile/accountLetter',
		},
	},
	WALLET: {
		TRANSFER_RESTS_TO_MAIN_WALLET: {
			METHOD: 'POST',
			URL: '/api/operations/transfer/batch/toacc',
		},
		ACCOUNTS_INFO: {
			METHOD: 'GET',
			URL: '/api/accounts/info',
		},
		ACCOUNT_REST: {
			METHOD: 'GET',
			URL: '/api/accounts/rests',
		},
		ACCOUNTS_MARGIN_RESTS: {
			METHOD: 'GET',
			URL: '/api/accounts/rests/margin',
		},
		CONVERT_LOW_BALANCES: {
			METHOD: 'POST',
			URL: '/api/operations/exchange/convertlow',
		},
	},
};

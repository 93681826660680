import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { selectMainAccountRests, selectMainEWallet } from '../../../../services/wallet';
import { ROUTES } from '../../../../types/routes';
import { HeaderLayout } from '../../../common/header';
import { formatNumber, mapClassnameForMobile } from '../../../../utils';
import { APIError } from '../../../common/error';
import { CustomButton } from '../../../common/buttons';
import { selectAccessToken } from '../../../../services/auth';
import { useEffect } from 'react';
import { useSendCardWithdraw } from '../../../../data/mutations/withdraw';
import {
	selectCardWithdrawAmount,
	selectCardWithdrawAsset,
	selectCardWithdrawCard,
	selectCardWithdrawFee,
	selectCardWithdrawIsChecked,
	selectCardWithdrawMaskedCard,
} from '../../../../services/withdraw/card.withdraw.slice';

const CardWithdrawConfirmationLayout = () => {
	const mainCurrencies = useAppSelector(selectMainAccountRests);
	const amount = useAppSelector(selectCardWithdrawAmount);
	const selectedAsset = useAppSelector(selectCardWithdrawAsset);
	const fee = useAppSelector(selectCardWithdrawFee);
	const accessToken = useAppSelector(selectAccessToken);
	const isChecked = useAppSelector(selectCardWithdrawIsChecked);
	const accountNumber = useAppSelector(selectMainEWallet);
	const selectedCard = useAppSelector(selectCardWithdrawCard);
	const maskedCard = useAppSelector(selectCardWithdrawMaskedCard);

	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		if (selectedAsset === undefined) {
			navigate(ROUTES.WITHDRAW.CRYPTO.INDEX);
		}
	}, []);

	const { sendCardWithdraw, isError, error, isPending } = useSendCardWithdraw(
		dispatch,
		navigate,
		accessToken as string
	);

	const handleBackClickButton = () => {
		navigate(ROUTES.WITHDRAW.CARD.INDEX);
	};

	const handleSubmit = () => {
		sendCardWithdraw({
			accountNumber: accountNumber,
			accessToken: accessToken as string,
			amount: amount,
			currencyCode: selectedAsset?.code as string,
			userCardId: selectedCard?.userCardId as string,
			dispatch: dispatch,
		});
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent aboutComponent'}>
				<HeaderLayout
					isAboutHeader
					isFunctionalHeader
					handleBackClick={handleBackClickButton}
				/>
				<div
					style={{ marginBottom: '16px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className="innerContainer">
						<h1 className="innerContainerMainText">
							{t('confirmation_title')}
						</h1>
						<div className="commonContainer">
							<div className="commonItem">
								<h1 className="cryptoWithdrawCommonItemLeftText">
									{t('withdrawCrypto_withdrawAsset')}
								</h1>
								<h1 className="cryptoWithdrawCommonItemRightText">
									{selectedAsset?.code}{' '}
									{`(${
										mainCurrencies?.assets?.find(
											(asset) => asset?.code === selectedAsset?.code
										)?.name
											? mainCurrencies?.assets?.find(
													(asset) =>
														asset?.code ===
														selectedAsset?.code
											  )?.name
											: selectedAsset?.code
									})`}
								</h1>
							</div>
							<div className="commonItem">
								<h1 className="cryptoWithdrawCommonItemLeftText">
									{t('withdrawCardConfirmation_toCard')}
								</h1>
								<h1 className="cryptoWithdrawCommonItemRightText">
									{maskedCard}
								</h1>
							</div>
						</div>

						<div className="commonContainer">
							<div className="commonItem">
								<h1 className="cryptoWithdrawCommonItemLeftText">
									{t('withdraw_totalWithdraw')}
								</h1>
								<h1 className="cryptoWithdrawCommonItemRightText">
									{isChecked
										? formatNumber(
												Number(Number(amount) + fee).toFixed(2),
												2
										  )
										: formatNumber(Number(amount).toFixed(2), 2)}{' '}
									{selectedAsset?.code}
								</h1>
							</div>
							<div className="commonItem">
								<h1 className="cryptoWithdrawCommonItemLeftText">
									{t('withdrawConfirmation_withdrawFee')}
								</h1>
								<h1 className="cryptoWithdrawCommonItemRightText">
									{formatNumber(Number(fee).toFixed(2), 2)}{' '}
									{selectedAsset?.code}
								</h1>
							</div>
							<div className="commonItem">
								<h1 className="cryptoWithdrawCommonItemLeftText">
									{t('withdrawConfirmation_toBeReceived')}
								</h1>
								<h1 className="cryptoWithdrawCommonItemRightText">
									{isChecked
										? formatNumber(Number(amount).toFixed(2), 2)
										: formatNumber(
												Number(
													Number(amount) - Number(fee)
												).toFixed(2),
												2
										  )}{' '}
									{selectedAsset?.code}
								</h1>
							</div>
						</div>
						<APIError isError={isError} error={error} />
					</div>
					<div className="depositButtonsBottomContainer">
						<h1 className="cryptoWithdrawBottomWarningText">
							{t('withdrawCryptoConfirmation_withdrawWarn')}
						</h1>
						<CustomButton
							isDisabled={false}
							isLoading={isPending}
							text={t('withdrawCryptoConfrimation_confirm') as string}
							onClick={handleSubmit}
							className="confirmFullWidthButton"
						/>
						<CustomButton
							isDisabled={false}
							isGrey
							isLoading={false}
							text={t('general_cancel') as string}
							onClick={handleBackClickButton}
							className="confirmFullWidthButton"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardWithdrawConfirmationLayout;

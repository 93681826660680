import { useMutation } from '@tanstack/react-query';
import {
	fetchBankCommission,
	fetchBankDepositInvoiceHistory,
	fetchCardCommission,
	fetchCardDeposit,
	fetchCreateBankInvoice,
	fetchCryptoDepositAddress,
	fetchDeleteBankInvoice,
	fetchGetBankInvoice,
} from '../../../api/deposit';
import {
	setCardDepositLink,
	setInvoiceHistory,
	setInvoiceInfo,
	setInvoiceLink,
} from '../../../services/deposit';
import { AppDispatch } from '../../../store';
import { ROUTES } from '../../../types/routes';
import {
	TBankCommissionBody,
	TCardCommissionBody,
	TCardDepositBody,
	TCreateBankInvoiceBody,
	TCryptoDepositAddressBody,
	TCryptoDepositInfo,
	TCustomNavigate,
	TDefaultBody,
	TGetBankInvoiceBody,
} from '../../../types/types';

export function useSendCardDeposit(dispatch: AppDispatch, isTelegram?: boolean, openLink?: (url: string) => void) {
	const {
		mutateAsync: sendCardDeposit,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['sendCardDeposit'],
		mutationFn: (data: TCardDepositBody) => fetchCardDeposit(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.gatewayUrl) {
				if(isTelegram && openLink) {
					openLink(data?.gatewayUrl)
				}
				dispatch(setCardDepositLink(data?.gatewayUrl));
			}
		},
	});
	return {
		sendCardDeposit,
		error,
		isError,
		isPending,
	};
}

export function useGetBankDepositInvoiceHistory(dispatch: AppDispatch) {
	const {
		mutateAsync: getBankDepositInvoiceHistory,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['getBankDepositInvoiceHistory'],
		mutationFn: (data: TDefaultBody) => fetchBankDepositInvoiceHistory(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data) {
				dispatch(setInvoiceHistory(data));
			}
		},
	});
	return {
		getBankDepositInvoiceHistory,
		error,
		isError,
		isPending,
	};
}

export function useGetBankDepositInvoice(dispatch: AppDispatch) {
	const {
		mutateAsync: getBankDepositInvoice,
		isError,
		error: error,
		isPending: isBankDepositInvoicePending,
	} = useMutation({
		mutationKey: ['getBankDepositInvoice'],
		mutationFn: (data: TGetBankInvoiceBody) => fetchGetBankInvoice(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data) {
				dispatch(setInvoiceInfo(data?.invoiceData));
				dispatch(setInvoiceLink(data?.pdfData));
			}
		},
	});
	return {
		getBankDepositInvoice,
		error,
		isError,
		isBankDepositInvoicePending,
	};
}

export function useDeleteBankDepositInvoice(
	dispatch: AppDispatch,
	handleModalClose: () => void
) {
	const {
		mutateAsync: deleteBankDepositInvoice,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['deleteBankDepositInvoice'],
		mutationFn: (data: TGetBankInvoiceBody) => fetchDeleteBankInvoice(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data) {
				handleModalClose();
			}
		},
	});
	return {
		deleteBankDepositInvoice,
		error,
		isError,
		isPending,
	};
}

export function useGetCardCommission(
	setFee: React.Dispatch<React.SetStateAction<number>>
) {
	const {
		mutateAsync: getCardCommission,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['getCardCommission'],
		mutationFn: (data: TCardCommissionBody) => fetchCardCommission(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.feeAmount) {
				setFee(data?.feeAmount);
			}
		},
	});
	return {
		getCardCommission,
		error,
		isError,
		isPending,
	};
}

export function useGetBankCommission(
	setFee: React.Dispatch<React.SetStateAction<number>>
) {
	const {
		mutateAsync: getBankCommission,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['getBankCommission'],
		mutationFn: (data: TBankCommissionBody) => fetchBankCommission(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.feeAmount) {
				setFee(data?.feeAmount);
			}
		},
	});
	return {
		getBankCommission,
		error,
		isError,
		isPending,
	};
}

export function useGetCryptoDepositAddress(
	setDepositInfo: React.Dispatch<React.SetStateAction<TCryptoDepositInfo | undefined>>
) {
	const {
		mutateAsync: getCryptoDepositAddress,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['getCryptoDepositAddress'],
		mutationFn: (data: TCryptoDepositAddressBody) => fetchCryptoDepositAddress(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.network && data?.cryptoAddress) {
				setDepositInfo(data);
			}
		},
	});
	return {
		getCryptoDepositAddress,
		error,
		isError,
		isPending,
	};
}

export function useCreateBankInvoice(navigate: TCustomNavigate, dispatch: AppDispatch) {
	const {
		mutateAsync: createBankInvoice,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['createBankInvoice'],
		mutationFn: (data: TCreateBankInvoiceBody) => fetchCreateBankInvoice(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.invoiceId) {
				dispatch(setInvoiceInfo(data?.invoice));
				navigate(
					ROUTES.DEPOSIT.BANK_TRANSFER_INVOICE.replace(
						':invoiceId',
						data?.invoiceId
					)
				);
			}
		},
	});
	return {
		createBankInvoice,
		error,
		isError,
		isPending,
	};
}

import { API_ROUTES } from '../../types/api.routes';
import {
	TRegistrationEulaBody,
	TRegistrationGetCodeBody,
	TRegistrationInfoBody,
	TRegistrationLastStepBody,
	TRegistrationSendCodeBody,
	TRegistrationSendEulaBody
} from '../../types/types';
import { mapResponseError } from '../../utils';
import { fetchDictionary } from '../common';
import { customFetch } from '../../services';
import {
	setRegistrationCheckboxes,
	setRegistrationTextCheckboxes
} from '../../services/registration';
import {
	setIsResendCodeActive,
	setMaskedContact,
	updateCounter
} from '../../services/root';
import { setInputLength, setSelectedIdentityType } from '../../services/auth';

export async function fetchRegistrationInfo({ state }: TRegistrationInfoBody) {
	const infoData = await customFetch(
		API_ROUTES.USER.REGISTRATION.REGISTRATION_INFO.URL +
			`?${new URLSearchParams({
				state: state
			})}`,
		{
			method: API_ROUTES.USER.REGISTRATION.REGISTRATION_INFO.METHOD,
			headers: {
				'Content-Type': 'application/json'
			}
		}
	);
	const jsonInfoData = await infoData.json();
	if (infoData.status != 200) {
		mapResponseError(infoData, jsonInfoData, undefined, undefined);
		throw jsonInfoData;
	}

	return jsonInfoData;
}

export async function fetchRegistrationStart() {
	const startData = await customFetch(
		API_ROUTES.USER.REGISTRATION.REGISTRATION_START.URL,
		{
			method: API_ROUTES.USER.REGISTRATION.REGISTRATION_START.METHOD,
			headers: {
				'Content-Type': 'application/json'
			}
		}
	);

	const jsonStartData = await startData.json();
	if (startData.status != 200) {
		mapResponseError(startData, jsonStartData);
		throw jsonStartData;
	}

	return jsonStartData;
}

export async function fetchRegistrationEula({
	state,
	dispatch
}: TRegistrationEulaBody) {
	const jsonEulaTextData = await fetchDictionary('registration');

	if (jsonEulaTextData) {
		dispatch(
			setRegistrationTextCheckboxes(jsonEulaTextData?.value)
		);
	}

	const eulaData = await customFetch(
		API_ROUTES.USER.REGISTRATION.GET_EULA.URL +
			`?${new URLSearchParams({
				state: state
			})}`,
		{
			method: API_ROUTES.USER.REGISTRATION.GET_EULA.METHOD,
			headers: {
				'Content-Type': 'application/json'
			}
		}
	);
	const jsonEulaData = await eulaData.json();
	if (eulaData.status != 200) {
		mapResponseError(eulaData, jsonEulaData);
		throw jsonEulaData;
	}

	dispatch(setRegistrationCheckboxes(jsonEulaData?.checkBoxes));

	return jsonEulaData;
}

export async function fetchSubmitClear({ state }: TRegistrationInfoBody) {
	const clearData = await customFetch(
		API_ROUTES.USER.REGISTRATION.CLEAR.URL,
		{
			method: API_ROUTES.USER.REGISTRATION.CLEAR.METHOD,
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				state: state
			})
		}
	);

	if (clearData.status != 200) {
		const clearDataJson = await clearData.json();
		mapResponseError(clearData, clearDataJson);
		throw clearDataJson;
	}

	return clearData;
}

export async function fetchSubmitFirstStep({
	state,
	checkBoxesResult,
	email,
	dispatch
}: TRegistrationSendEulaBody) {
	const sendEulaData = await customFetch(
		API_ROUTES.USER.REGISTRATION.SEND_EULA.URL,
		{
			method: API_ROUTES.USER.REGISTRATION.SEND_EULA.METHOD,
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				state: state,
				checkBoxesResult: checkBoxesResult
			})
		}
	);

	if (sendEulaData.status != 200) {
		const sendEulaDataJson = await sendEulaData.json();
		mapResponseError(sendEulaData, sendEulaDataJson);
		throw sendEulaDataJson;
	}

	const codeData = await customFetch(
		API_ROUTES.USER.REGISTRATION.SEND_CODE.URL,
		{
			method: API_ROUTES.USER.REGISTRATION.SEND_CODE.METHOD,
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				state: state,
				identityType: 'email',
				identity: email
			})
		}
	);

	const codeDataJson = await codeData.json();
	if (codeData.status != 200) {
		mapResponseError(codeData, codeDataJson);
		throw codeDataJson;
	}

	dispatch && dispatch(setInputLength(codeDataJson?.length));
	dispatch && dispatch(setIsResendCodeActive(false));
	dispatch &&
		dispatch(
			setMaskedContact(
				codeDataJson?.maskedContact
					? codeDataJson?.maskedContact
					: email
			)
		);
	dispatch &&
		dispatch(updateCounter(Math.floor(codeDataJson?.resendIn / 1000)));
	dispatch && dispatch(setSelectedIdentityType('email'));

	return sendEulaData;
}

export async function fetchAskNewCode({
	state,
	identity,
	identityType,
	dispatch,
	phoneCountryCode
}: TRegistrationGetCodeBody) {
	const codeData = await customFetch(
		API_ROUTES.USER.REGISTRATION.SEND_CODE.URL,
		{
			method: API_ROUTES.USER.REGISTRATION.SEND_CODE.METHOD,
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				state: state,
				identityType: identityType,
				identity: identity,
				phoneCountryCode: phoneCountryCode
			})
		}
	);

	const codeDataJson = await codeData.json();
	if (codeData.status != 200) {
		mapResponseError(codeData, codeDataJson);
		throw codeDataJson;
	}

	dispatch && dispatch(setInputLength(codeDataJson?.length));
	dispatch && dispatch(setIsResendCodeActive(false));
	dispatch &&
		dispatch(
			setMaskedContact(
				codeDataJson?.maskedContact
					? codeDataJson?.maskedContact
					: identity
			)
		);
	dispatch &&
		dispatch(updateCounter(Math.floor(codeDataJson?.resendIn / 1000)));
	dispatch && dispatch(setSelectedIdentityType(identityType));

	return codeData;
}

export async function fetchSubmitCode({
	state,
	identity,
	identityType,
	code
}: TRegistrationSendCodeBody) {
	const codeData = await customFetch(
		API_ROUTES.USER.REGISTRATION.VERIFY_CODE.URL,
		{
			method: API_ROUTES.USER.REGISTRATION.VERIFY_CODE.METHOD,
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				state: state,
				identityType: identityType,
				identity: identity,
				code: code
			})
		}
	);

	if (codeData.status != 200) {
		const codeDataJson = await codeData.json();
		mapResponseError(codeData, codeDataJson);
		throw codeDataJson;
	}

	return codeData;
}

export async function fetchSubmitLastStep({
	state,
	password,
	name,
	referral
}: TRegistrationLastStepBody) {
	const lastStepData = await customFetch(
		API_ROUTES.USER.REGISTRATION.CREATE_ACCOUNT.URL,
		{
			method: API_ROUTES.USER.REGISTRATION.CREATE_ACCOUNT.METHOD,
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				state: state,
				password: password,
				name: name,
				referal: referral
			})
		}
	);

	if (lastStepData.status != 200) {
		const lastStepDataJson = await lastStepData.json();
		mapResponseError(lastStepData, lastStepDataJson);
		throw lastStepDataJson;
	}

	return lastStepData;
}

import { useMutation } from '@tanstack/react-query';
import {
	TCustomNavigate,
	TDefaultBody,
	TDisableTwoFaBody,
	TEnableTwoFaBody,
	TLogoutBody,
	TResetDeviceBody,
	TSecurityPasswordChangeBody,
	TSecuritySendOtpBody,
	TSendGenerateAccountConfirmationLetterBody,
	TSendStatementBody,
	TVisitHistoryBody,
} from '../../../types/types';
import { ROUTES } from '../../../types/routes';
import { fetchRevokeToken } from '../../../api/login';
import { fetchGetVisitHistory, fetchSendGenerateAccountLetter } from '../../../api/user';
import { fetchSendStatement } from '../../../api/history';
import { fetchHealth } from '../../../api/common';
import { AppDispatch } from '../../../store';
import { getCryptoTemplates, setDefaultDictionaries } from '../../../utils';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectAccessToken } from '../../../services/auth';
import { selectDepositWithdrawAssetsDictionary } from '../../../services/dictionaries';
import { useEffect, useState } from 'react';
import {
	setBankWithdrawTemplates,
	setCardWithdrawSavedCards,
	setCryptoWithdrawSavedAddresses,
} from '../../../services/withdraw/templates.withdraw.slice';
import {
	fetchBankWithdrawTemplate,
	fetchCardWithdrawTemplate,
} from '../../../api/withdraw';
import {
	fetchDisableTwoFa,
	fetchEnableTwoFa,
	fetchGetAllDevices,
	fetchGetTwoFaOptions,
	fetchResetDevice,
	fetchSecurityPasswordChange,
	fetchSecuritySendOtp,
} from '../../../api/security';
import { setUserActivities, setUserDevices } from '../../../services/user';

export function useRevokeTokenMutation() {
	const {
		mutateAsync: logout,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['logout'],
		mutationFn: (data: TLogoutBody) => fetchRevokeToken(data),
		onSuccess: async () => {
			const locale = window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(ROUTES.AUTH.INDEX.replace(':locale', locale));
		},
		onError: async () => {
			const locale = window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(ROUTES.AUTH.INDEX.replace(':locale', locale));
		},
	});
	return {
		logout,
		error,
		isError,
		isPending,
	};
}

export function useSendGeneralAccountLetterMutation(
	handleCloseModal: () => void,
	isTelegram?: boolean,
	openLink?: (url: string) => void
) {
	const {
		mutateAsync: send,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['sendGeneralAccountConfirmationLetter'],
		mutationFn: (data: TSendGenerateAccountConfirmationLetterBody) =>
			fetchSendGenerateAccountLetter(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (isTelegram && openLink) {
				openLink(data?.link);
			} else {
				window.open(data?.link, '_blank');
			}
			handleCloseModal();
		},
	});
	return {
		send,
		error,
		isError,
		isPending,
	};
}

export function useHandleLanguageChange(
	dispatch: AppDispatch,
	navigate: TCustomNavigate
) {
	const {
		mutateAsync: handleLanguageChange,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['handleLanguageChange'],
		mutationFn: () => fetchHealth(),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			setDefaultDictionaries(data, dispatch, navigate);
		},
	});
	return {
		handleLanguageChange,
		error,
		isError,
		isPending,
	};
}

export function useSendStatementMutation(
	handleCloseModal: () => void,
	isTelegram?: boolean,
	openLink?: (url: string) => void
) {
	const {
		mutateAsync: send,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['sendStatement'],
		mutationFn: (data: TSendStatementBody) => fetchSendStatement(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (isTelegram && openLink) {
				openLink(data?.link);
			} else {
				window.open(data?.link, '_blank');
			}
			handleCloseModal();
		},
	});
	return {
		send,
		error,
		isError,
		isPending,
	};
}

export const useGetUserSavedAddresses = () => {
	const depositWithdrawAssets = useAppSelector(selectDepositWithdrawAssetsDictionary)
		?.dictionary?.withdraw;
	const accessToken = useAppSelector(selectAccessToken);

	const dispatch = useAppDispatch();

	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		if (depositWithdrawAssets) {
			initSavedAddressesPage();
		}
	}, [depositWithdrawAssets]);

	const getSavedAddresses = async () => {
		if (depositWithdrawAssets?.crypto) {
			const cryptoTemplates = await Promise.all(
				depositWithdrawAssets?.crypto?.map(getCryptoTemplates, {
					dispatch: dispatch,
					accessToken: accessToken as string,
				})
			);
			dispatch(
				setCryptoWithdrawSavedAddresses(
					cryptoTemplates
						?.map((template) => {
							return template?.templates?.flat();
						})
						?.flat()
				)
			);
		}
		const cardTemplates = await fetchCardWithdrawTemplate({
			dispatch: dispatch,
			accessToken: accessToken as string,
		});
		dispatch(
			setCardWithdrawSavedCards(
				cardTemplates?.templates ? cardTemplates?.templates : []
			)
		);

		const bankTemplates = await fetchBankWithdrawTemplate({
			paymentSystem: undefined,
			dispatch: dispatch,
			accessToken: accessToken as string,
		});

		dispatch(
			setBankWithdrawTemplates(
				bankTemplates?.templates ? bankTemplates?.templates : []
			)
		);

		setIsLoading(false);
	};

	const initSavedAddressesPage = async () => {
		setIsLoading(true);
		getSavedAddresses();
	};

	return {
		isLoading,
		getSavedAddresses,
	};
};

export function useSendSecurityPasswordChange(
	navigate: TCustomNavigate,
	notify?: () => void
) {
	const {
		mutateAsync: sendSecurityPasswordChange,
		isError,
		error: error,
		isPending,
		isSuccess,
	} = useMutation({
		mutationKey: ['sendSecurityPasswordChange'],
		mutationFn: (data: TSecurityPasswordChangeBody) =>
			fetchSecurityPasswordChange(data),
		onSuccess: async () => {
			if (notify) {
				notify();
			}
			setTimeout(() => {
				navigate(ROUTES.PROFILE.SECURITY.INDEX);
			}, 1500);
		},
	});
	return {
		sendSecurityPasswordChange,
		error,
		isError,
		isPending,
		isSuccess,
	};
}

export function useSendSecurityOTP(
	navigate: TCustomNavigate,
	isResend: boolean,
	isSetupTwoFa?: boolean,
	notify?: () => void
) {
	const {
		mutateAsync: sendSecurityOTP,
		isError,
		error: error,
		isPending,
		isSuccess,
	} = useMutation({
		mutationKey: ['sendSecurityOTP'],
		mutationFn: (data: TSecuritySendOtpBody) => fetchSecuritySendOtp(data),
		onSuccess: async () => {
			if (notify) {
				notify();
			}
			if (!isResend) {
				navigate(ROUTES.PROFILE.SECURITY.CHANGE_PASSWORD.OTP);
			}
			if (isSetupTwoFa) {
				navigate(ROUTES.PROFILE.SECURITY.TWO_FA.OTP);
			}
		},
	});
	return {
		sendSecurityOTP,
		error,
		isError,
		isPending,
		isSuccess,
	};
}

export function useGetTwoFaOptionsMutation() {
	const {
		mutateAsync: getTwoFaOptionsMutation,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['getTwoFaOptionsMutation'],
		mutationFn: (data: TDefaultBody) => fetchGetTwoFaOptions(data),
	});
	return {
		getTwoFaOptionsMutation,
		error,
		isError,
		isPending,
	};
}

export function useEnableTwoFaMutation(navigate: TCustomNavigate) {
	const {
		mutateAsync: enableTwoFaMutation,
		isError: isEnableError,
		error: enableError,
		isPending: isEnablePending,
	} = useMutation({
		mutationKey: ['enableTwoFaMutation'],
		mutationFn: (data: TEnableTwoFaBody) => fetchEnableTwoFa(data),
		onSuccess: () => {
			navigate(ROUTES.PROFILE.SECURITY.TWO_FA.INDEX);
		},
	});
	return {
		enableTwoFaMutation,
		isEnableError,
		enableError,
		isEnablePending,
	};
}

export function useDisableTwoFaMutation(navigate: TCustomNavigate) {
	const {
		mutateAsync: disableTwoFaMutation,
		isError: isDisableError,
		error: disableError,
		isPending: isDisablePending,
	} = useMutation({
		mutationKey: ['disableTwoFaMutation'],
		mutationFn: (data: TDisableTwoFaBody) => fetchDisableTwoFa(data),
		onSuccess: () => {
			navigate(ROUTES.PROFILE.SECURITY.TWO_FA.INDEX);
		},
	});
	return {
		disableTwoFaMutation,
		isDisableError,
		disableError,
		isDisablePending,
	};
}

export function useGetVisitHistory(navigate: TCustomNavigate, dispatch: AppDispatch) {
	const {
		mutateAsync: getVisitHistory,
		isError,
		error,
		isPending,
	} = useMutation({
		mutationKey: ['getVisitHistory'],
		mutationFn: (data: TVisitHistoryBody) => fetchGetVisitHistory(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.activities) {
				dispatch(setUserActivities(data));
			}
		},
	});
	return {
		getVisitHistory,
		isError,
		error,
		isPending,
	};
}

export function useGetAllDevices(dispatch: AppDispatch) {
	const {
		mutateAsync: getAllDevices,
		isError,
		error,
		isPending,
	} = useMutation({
		mutationKey: ['getAllDevices'],
		mutationFn: (data: TDefaultBody) => fetchGetAllDevices(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data?.devices) {
				dispatch(setUserDevices(data));
			}
		},
	});
	return {
		getAllDevices,
		isError,
		error,
		isPending,
	};
}

export function useResetDevice(handleIsModalOpen: () => void) {
	const {
		mutateAsync: resetDevice,
		isError,
		error,
		isPending,
	} = useMutation({
		mutationKey: ['resetDevice'],
		mutationFn: (data: TResetDeviceBody) => fetchResetDevice(data),
		onSuccess: async () => {
			handleIsModalOpen();
		},
	});
	return {
		resetDevice,
		isError,
		error,
		isPending,
	};
}

import { customFetch } from '../../services';
import { API_ROUTES } from '../../types/api.routes';
import { TAppSettings } from '../../types/types';
import { mapResponseError } from '../../utils';

export async function fetchDictionary(dictionary: string, version?: number) {
	const accessToken = JSON.parse(
		localStorage.getItem(localStorage.getItem('userId') as string) as string
	)?.accessToken != undefined
		? JSON.parse(
			localStorage.getItem(
				localStorage.getItem('userId') as string
			) as string
		)?.accessToken
		: ''

	const dictionaryData = await customFetch(
		API_ROUTES.DICTIONARY.URL +
			'?' +
			new URLSearchParams({
				dictionary: dictionary,
				version: version != undefined ? String(version) : '',
			}),
		{
			method: API_ROUTES.DICTIONARY.METHOD,
			headers: accessToken != '' ? {
				'Authorization': `Bearer ${accessToken}`
			} : {},
		}
	);

	const jsonDictionaryData = await dictionaryData.json();
	if (dictionaryData.status != 200) {
		mapResponseError(dictionaryData, jsonDictionaryData);
	}

	return jsonDictionaryData;
}

export async function fetchHealth() {
	const healthData = await customFetch(API_ROUTES.HEALTH.URL, {
		method: API_ROUTES.HEALTH.METHOD,
		headers: {},
	});

	const jsonHealthData = await healthData.json();
	if (healthData.status != 200) {
		mapResponseError(healthData, jsonHealthData);
	}

	const dictionaryData = await fetchDictionary('appsettings');
	const appIconsData = await fetchDictionary('asseticons');
	const marketTabs = await fetchDictionary('assetfilters');
	const depositWithdrawAssets = await fetchDictionary('depositwithdrawassets');
	const registrationDictionary = await fetchDictionary('registration');

	return {
		appSettingsData: dictionaryData?.value as TAppSettings,
		healthData: {
			value: jsonHealthData,
		},
		registrationData: registrationDictionary,
		depositWithdrawAssetsData: depositWithdrawAssets,
		marketTabsData: marketTabs,
		assetIconsData: appIconsData,
	};
}

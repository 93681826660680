import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '../../store';
import { TInnerCryptoWithdraw, TInnerCryptoWithdrawAsset } from '../../types/types';

export const selectCryptoWithdrawAddress = (state: TRootState) =>
	state.withdrawCrypto.address;

export const selectCryptoWithdrawFee = (state: TRootState) => state.withdrawCrypto.fee;

export const selectCryptoWithdrawAsset = (state: TRootState) =>
	state.withdrawCrypto.selectedAsset;

export const selectCryptoWithdrawNetwork = (state: TRootState) =>
	state.withdrawCrypto.selectedNetwork;

export const selectCryptoWithdrawSuitableNetworks = (state: TRootState) =>
	state.withdrawCrypto.suitableNetworks;

export const selectCryptoWithdrawAmount = (state: TRootState) =>
	state.withdrawCrypto.amount;

export const selectCryptoWithdrawIsAddressValid = (state: TRootState) =>
	state.withdrawCrypto.isAddressValid;

export const selectCryptoWithdrawIsChecked = (state: TRootState) =>
	state.withdrawCrypto.isChecked;

export const selectCryptoWithdrawOperationId = (state: TRootState) =>
	state.withdrawCrypto.lastWithdrawOperationId;

interface IRootInitialState {
	address: string;
	fee: string;
	selectedAsset: TInnerCryptoWithdrawAsset | undefined;
	selectedNetwork: TInnerCryptoWithdraw | undefined;
	suitableNetworks: TInnerCryptoWithdraw[] | undefined;
	amount: string;
	isAddressValid: boolean;
	isChecked: boolean;
	lastWithdrawOperationId: string;
}

const initialState: IRootInitialState = {
	address: '',
	fee: '0',
	selectedAsset: undefined,
	selectedNetwork: undefined,
	suitableNetworks: undefined,
	amount: '',
	isAddressValid: true,
	isChecked: false,
	lastWithdrawOperationId: '',
};

export const withdrawCryptoSlice = createSlice({
	name: 'withdrawCrypto',
	initialState,
	reducers: {
		setSavedAddressCryptoWithdraw(state, action) {
			state.address = action.payload;
		},
		setCryptoWithdrawFee(state, action) {
			state.fee = action.payload;
		},
		setCryptoWithdrawAsset(state, action) {
			state.selectedAsset = action.payload;
		},
		setCryptoWithdrawNetwork(state, action) {
			state.selectedNetwork = action.payload;
		},
		setCryptoWithdrawSuitableNetworks(state, action) {
			state.suitableNetworks = action.payload;
		},
		setCryptoWithdrawAmount(state, action) {
			state.amount = action.payload;
		},
		setCryptoWithdrawIsAddressValid(state, action) {
			state.isAddressValid =
				action.payload === 'valid' || action.payload === 'unknown' ? true : false;
		},
		setCryptoWithdrawIsChecked(state, action) {
			state.isChecked = action.payload;
		},

		setCryptoWithdrawOperationId(state, action) {
			state.lastWithdrawOperationId = action.payload;
		},
		setCryptoWithdrawInit(state) {
			state.address = '';
			state.fee = '0';
			state.amount = '';
			state.isAddressValid = true;
			state.selectedAsset = undefined;
			state.selectedNetwork = undefined;
			state.suitableNetworks = undefined;
			state.isChecked = false;
			state.lastWithdrawOperationId = '';
		},
	},
});

export const {
	setSavedAddressCryptoWithdraw,
	setCryptoWithdrawFee,
	setCryptoWithdrawAmount,
	setCryptoWithdrawAsset,
	setCryptoWithdrawNetwork,
	setCryptoWithdrawSuitableNetworks,
	setCryptoWithdrawIsAddressValid,
	setCryptoWithdrawInit,
	setCryptoWithdrawIsChecked,
	setCryptoWithdrawOperationId,
} = withdrawCryptoSlice.actions;

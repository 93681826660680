import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { mapClassnameForMobile } from '../../../utils';
import { HeaderLayout } from '../../common/header';
import { ROUTES } from '../../../types/routes';
import { ProfileIcons, RightChev } from '../../common/icons';
import { CustomSwitch } from '../../common/switchs';
import { useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { selectBiometry, selectIsTelegram } from '../../../services/root';

const SecurityLayout = () => {
	const biometry = useAppSelector(selectBiometry);
	const isTelegram = useAppSelector(selectIsTelegram);

	const [isActive, setIsActive] = useState<boolean>(false);

	const navigate = useCustomNavigate();
	const { t } = useTranslation();

	const handleBackClickButton = () => {
		navigate(ROUTES.PROFILE.INDEX);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent aboutComponent'}>
				<HeaderLayout
					isFunctionalHeader
					isAboutHeader
					handleBackClick={handleBackClickButton}
				/>
				<div
					style={{ marginBottom: '16px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className="innerContainer">
						<h1 className="innerContainerMainText">{t('privacy_title')}</h1>

						<div className="commonContainer">
							<div
								onClick={() =>
									navigate(
										ROUTES.PROFILE.SECURITY.CHANGE_PASSWORD.INDEX
									)
								}
								className="commonItem"
							>
								<ProfileIcons.ChangePasswordIcon />
								<h1 className="commonText">
									{t('changePassword_title')}
								</h1>
								<RightChev />
							</div>

							<div
								onClick={() =>
									navigate(ROUTES.PROFILE.SECURITY.TWO_FA.INDEX)
								}
								className="commonItem"
							>
								<ProfileIcons.ChangeTwoFactorIcon />
								<h1 className="commonText">
									{t('twoFactorAuthMethods_title')}
								</h1>
								<RightChev />
							</div>

							{isTelegram &&
								biometry?.isBiometricAvailable &&
								biometry?.isBiometryInited && (
									<div className="commonItem">
										<ProfileIcons.ChangePasswordIcon />
										<h1 className="commonText">
											{t('devices_disableQuickLogin')}
										</h1>
										<RightChev />
									</div>
								)}

							<div className="commonItem">
								<ProfileIcons.AllowScreenshotsIcon />
								<h1 className="commonText">
									{t('settings_allowScreenRecording')}
								</h1>
								<div className="">
									<CustomSwitch
										setIsActive={setIsActive}
										isActive={isActive}
										onClick={() => console.log('WIP')}
									/>
								</div>
							</div>
						</div>

						<div className="commonContainer">
							<div
								onClick={() =>
									navigate(ROUTES.PROFILE.SECURITY.VISIT_HISTORY)
								}
								className="commonItem"
							>
								<ProfileIcons.VisitHistoryIcon />
								<h1 className="commonText">{t('visitHistory_title')}</h1>
								<RightChev />
							</div>

							<div
								onClick={() => navigate(ROUTES.PROFILE.SECURITY.DEVICES)}
								className="commonItem"
							>
								<ProfileIcons.DevicesIcon />
								<h1 className="commonText">{t('devices_title')}</h1>
								<RightChev />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SecurityLayout;

import { useTranslation } from 'react-i18next';
import { formatNumber, mapClassnameForMobile } from '../../../utils';
import { HeaderLayout } from '../../common/header';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { CustomInput } from '../../common/inputs';
import { useEffect, useState } from 'react';
import {
	TAccountRest,
	TAsset,
	TAssetIconsDictionary,
	TInnerCryptoDeposit,
	TInnerCryptoWithdraw,
	TInnerCryptoWithdrawAsset,
	TSavedAddress,
} from '../../../types/types';
import {
	selectAssetIcons,
	selectDepositWithdrawAssetsDictionary,
} from '../../../services/dictionaries';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	CryptoDepositNetworkModal,
	CryptoWithdrawAssetModal,
	CryptoWithdrawEditSavedAddressModal,
	CryptoWithdrawSavedAddressModal,
} from '../../common/modals';
import { selectAccessToken } from '../../../services/auth';
import { useGetMarketPrices } from '../../../data/mutations/market';
import { selectMainAccountRests } from '../../../services/wallet';
import {
	selectCryptoWithdrawAddress,
	selectCryptoWithdrawAmount,
	selectCryptoWithdrawAsset,
	selectCryptoWithdrawFee,
	selectCryptoWithdrawIsAddressValid,
	selectCryptoWithdrawIsChecked,
	selectCryptoWithdrawNetwork,
	selectCryptoWithdrawSuitableNetworks,
	setCryptoWithdrawAmount,
	setCryptoWithdrawAsset,
	setCryptoWithdrawInit,
	setCryptoWithdrawIsAddressValid,
	setCryptoWithdrawIsChecked,
	setCryptoWithdrawNetwork,
	setCryptoWithdrawSuitableNetworks,
	setSavedAddressCryptoWithdraw,
} from '../../../services/withdraw/crypto.withdraw.slice';
import { CustomCheckbox } from '../../common/checkboxes';
import { CustomButton } from '../../common/buttons';
import {
	useCheckCryptoWithdrawAddressValidation,
	useGetCryptoWithdrawCommission,
} from '../../../data/mutations/withdraw';
import { useDebounce } from '../../../hooks/useDebounce';
import { WithdrawHistoryModalContainer } from '../../common/modals/containers';
import { setCryptoWithdrawCurrentSavedAddress } from '../../../services/withdraw/templates.withdraw.slice';

interface Props {
	address: string;
	fee: string;
	selectedAsset: TInnerCryptoWithdrawAsset | undefined;
	selectedNetwork: TInnerCryptoWithdraw | undefined;
	suitableNetworks: TInnerCryptoWithdraw[] | undefined;
	amount: string;
	accessToken: string;
	mainCurrencies: TAccountRest | undefined;
	depositWithdrawAssetsDictionary: TInnerCryptoWithdraw[] | undefined;
	assetIcons: {
		dictionaryName: string;
		dictionary: TAssetIconsDictionary | undefined;
		version: number;
		timeStamp: number;
	};
	isAddressValid: boolean;
	isChecked: boolean;
}

const CryptoWithdrawFunctionalLayout = ({
	accessToken,
	selectedAsset,
	selectedNetwork,
	suitableNetworks,
	address,
	amount,
	assetIcons,
	depositWithdrawAssetsDictionary,
	fee,
	mainCurrencies,
	isAddressValid,
	isChecked,
}: Props) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isNetworkModalOpen, setIsNetworkModalOpen] = useState<boolean>(false);
	const [isSavedAddressModalOpen, setIsSavedAddressModalOpen] =
		useState<boolean>(false);
	const [isHistoryModalOpen, setIsHistoryModalOpen] = useState<boolean>(false);
	const [isEditSavedAddressModalOpen, setIsEditSavedAddressModalOpen] =
		useState<boolean>(false);

	const [minValue, setMinValue] = useState<number | undefined>(0);
	const [isAmountRequired, setIsAmountRequired] = useState<boolean>(false);
	const [isSavedAddressRequired, setIsSavedAddressRequired] = useState<boolean>(false);
	const [mainAsset, setMainAsset] = useState<TAsset | undefined>();

	const { getCryptoWithdrawCommission } = useGetCryptoWithdrawCommission(dispatch);
	const { checkAddressValidation, isCheckAddressValidationPending } =
		useCheckCryptoWithdrawAddressValidation(dispatch);
	//eslint-disable-next-line
	const {} = useGetMarketPrices();

	const debouncedValue = useDebounce(amount, 500);
	const debouncedAddressValue = useDebounce(address, 500);

	useEffect(() => {
		if (
			Number(debouncedValue) > 0 &&
			selectedAsset != undefined &&
			selectedNetwork != undefined
		) {
			getCryptoWithdrawCommission({
				accessToken: accessToken as string,
				asset: selectedAsset?.code as string,
				amount: debouncedValue,
				networkName: selectedNetwork?.networkName as string,
				dispatch: dispatch,
			});
		}
	}, [debouncedValue]);

	useEffect(() => {
		if (
			debouncedAddressValue?.length > 10 &&
			selectedAsset != undefined &&
			selectedNetwork != undefined
		) {
			checkAddressValidation({
				accessToken: accessToken as string,
				networkName: selectedNetwork?.networkName as string,
				cryptoAddress: debouncedAddressValue,
				dispatch: dispatch,
			});
		}
	}, [debouncedAddressValue]);

	useEffect(() => {
		if (selectedAsset) {
			setMinValue(selectedAsset?.minWithdraw);
			setMainAsset(
				mainCurrencies?.assets?.find(
					(asset) => asset?.code === selectedAsset?.code
				)
			);
		}
		if (amount) {
			setIsAmountRequired(String(amount)?.length > 0);
		}
	}, []);

	const handleBackClickButton = () => {
		navigate(ROUTES.WITHDRAW.INDEX);
		dispatch(setCryptoWithdrawInit());
	};

	const handleNavigateClick = () => {
		handleHistoryModalOpen();
	};

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleNetworkModalOpen = () => {
		setIsNetworkModalOpen(!isNetworkModalOpen);
	};

	const handleSavedAddressModalOpen = () => {
		setIsSavedAddressModalOpen(!isSavedAddressModalOpen);
	};

	const handleHistoryModalOpen = () => {
		setIsHistoryModalOpen(!isHistoryModalOpen);
	};

	const handleEditSavedAddressModalOpen = (i?: TSavedAddress | undefined) => {
		const tempIsEditSavedAddressModalOpen = !isEditSavedAddressModalOpen;
		if (i) {
			dispatch(setCryptoWithdrawCurrentSavedAddress(i));
		}
		handleSavedAddressModalOpen();
		setIsEditSavedAddressModalOpen(tempIsEditSavedAddressModalOpen);
	};

	const handleIsCheckedChange = (eventValue: boolean) => {
		dispatch(setCryptoWithdrawIsChecked(eventValue));
	};

	const handleSavedAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setSavedAddressCryptoWithdraw(e.target.value));
		dispatch(setCryptoWithdrawIsAddressValid('valid'));
		setIsSavedAddressRequired(e.target.value?.length > 0);
	};

	const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value?.length <= 12 + (mainAsset?.precision ?? 2)) {
			dispatch(setCryptoWithdrawAmount(e.target.value));
			setIsAmountRequired(e.target.value.length > 0);
		}
	};

	const handleMaxClick = () => {
		if (
			selectedAsset != undefined &&
			mainAsset != undefined &&
			mainAsset?.available != undefined
		) {
			dispatch(
				setCryptoWithdrawAmount(mainAsset?.available ? mainAsset?.available : 0)
			);
			setIsAmountRequired(String(mainAsset?.available)?.length > 0);
		}
	};

	const handleClearClick = () => {
		dispatch(setCryptoWithdrawAmount(''));
		setIsAmountRequired(false);
	};

	const handleSavedAddressClearClick = () => {
		dispatch(setSavedAddressCryptoWithdraw(''));
		dispatch(setCryptoWithdrawIsAddressValid('valid'));
		setIsSavedAddressRequired(false);
	};

	const handleNetworkItemClick = (i: TInnerCryptoWithdraw | TInnerCryptoDeposit) => {
		setIsNetworkModalOpen(!isNetworkModalOpen);
		dispatch(setCryptoWithdrawNetwork(i as TInnerCryptoWithdraw));
	};

	const handleItemClick = (i: TInnerCryptoWithdrawAsset, networkCode?: string) => {
		setIsModalOpen(!isModalOpen);
		dispatch(setCryptoWithdrawAsset(i));
		setMinValue(i?.minWithdraw);
		const tempSelectedNetwork = depositWithdrawAssetsDictionary?.find(
			(network) => network.networkCode === networkCode
		);
		const tempSuitableNetworks = depositWithdrawAssetsDictionary?.filter((network) =>
			network.assets.find((asset) => asset.code === i?.code)
		);
		setMainAsset(mainCurrencies?.assets?.find((asset) => asset?.code === i?.code));
		dispatch(setCryptoWithdrawNetwork(tempSelectedNetwork));
		dispatch(setCryptoWithdrawSuitableNetworks(tempSuitableNetworks));
	};

	const handleSubmit = () => {
		navigate(ROUTES.WITHDRAW.CRYPTO.CONFIRMATION);
	};

	const handleSavedAddressClick = (i: string) => {
		handleSavedAddressModalOpen();
		dispatch(setSavedAddressCryptoWithdraw(i));
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout
					isInvoiceHistory
					handleBackClick={handleBackClickButton}
					handleNavigateClick={handleNavigateClick}
				/>

				<CryptoWithdrawAssetModal
					isModalOpen={isModalOpen}
					handleIsModalOpen={handleModalOpen}
					handleItemClick={handleItemClick}
				/>
				<WithdrawHistoryModalContainer
					isHistoryModalOpen={isHistoryModalOpen}
					setIsHistoryModalOpen={setIsHistoryModalOpen}
				/>
				<CryptoDepositNetworkModal
					suitableNetworks={suitableNetworks}
					isModalOpen={isNetworkModalOpen}
					handleIsModalOpen={handleNetworkModalOpen}
					handleItemClick={handleNetworkItemClick}
					currentItem={selectedNetwork}
				/>

				<CryptoWithdrawSavedAddressModal
					handleEditSavedAddressModalOpen={handleEditSavedAddressModalOpen}
					isModalOpen={isSavedAddressModalOpen}
					handleIsModalOpen={handleSavedAddressModalOpen}
					handleItemClick={handleSavedAddressClick}
				/>
				<CryptoWithdrawEditSavedAddressModal
					isModalOpen={isEditSavedAddressModalOpen}
					handleIsModalOpen={handleEditSavedAddressModalOpen}
				/>
				<div
					style={{ marginBottom: '16px', overflow: 'unset !important' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div
						style={{
							height: '100vh',
							justifyContent: 'flex-start',
							marginBottom: '16px',
						}}
						className="innerContainer"
					>
						<h1 className="innerContainerMainText">
							{t('withdrawCrypto_title')}
						</h1>
						<div className="depositAmountInputContainer">
							<CustomInput
								isRequired={selectedAsset ? true : false}
								isSelectAsset
								type="text"
								id="cryptoWithdrawSelectAsset"
								onClick={handleModalOpen}
								value={selectedAsset?.code as string}
								imgUrl={
									selectedAsset
										? assetIcons?.dictionary &&
										  assetIcons?.dictionary[
												selectedAsset?.code?.toUpperCase() as string
										  ]
										: ''
								}
								className="confirmFullWidthButton"
								label={t('depositCrypto_chooseDepositAsset') as string}
							/>
						</div>
						<div className="depositAmountInputContainer">
							<CustomInput
								isRequired={selectedAsset != undefined}
								isSelectAsset
								isDisabled={
									suitableNetworks?.length === 1 ||
									selectedAsset === undefined
								}
								type="text"
								id="cryptoWithdrawSelectNetwork"
								onClick={handleNetworkModalOpen}
								value={selectedNetwork?.networkCode as string}
								imgUrl={''}
								className="confirmFullWidthButton"
								label={t('general_network') as string}
							/>
						</div>
						<div className="depositAmountInputContainer">
							<CustomInput
								isError={!isAddressValid}
								isSavedAddress
								onClick={handleSavedAddressModalOpen}
								label={t('withdrawCrypto_cryptoAddress') as string}
								className="confirmFullWidthButton"
								type="text"
								id="cryptoWithdrawAddress"
								onChange={handleSavedAddressChange}
								onClearClick={handleSavedAddressClearClick}
								isRequired={isSavedAddressRequired || address?.length > 0}
								value={address}
							/>
							{address != '' && selectedAsset != undefined && (
								<span
									style={{
										padding: '0 16px !important',
									}}
									className="depositCryptoWarningText"
								>
									{`${t('depositCrypto_depositWarn')}`}
									<span className="depositCryptoWarningTextNetwork">{` (${selectedNetwork?.network}) ${selectedNetwork?.networkCode}`}</span>
								</span>
							)}

							{!isAddressValid && (
								<h1 className="cryptoWithdrawAddressErrorText">
									{t('withdrawCrypto_checkAddress')}
								</h1>
							)}
						</div>
						<div className="depositAmountInputContainer">
							<CustomInput
								isAmountMax
								label={
									selectedAsset != undefined
										? `${t('wallet_toBeReceived')} (${t(
												'withdraw_min'
										  )} ${minValue} ${selectedAsset?.code})`
										: t('wallet_toBeReceived')
								}
								className="confirmFullWidthButton"
								type="number"
								id="cryptoWithdrawAmount"
								onChange={handleAmountChange}
								onClick={handleMaxClick}
								onClearClick={handleClearClick}
								isRequired={isAmountRequired}
								value={amount}
							/>
							{selectedAsset != undefined && (
								<h1 className="depositFeeText">{`${t(
									'general_availableAmount'
								)}: ${
									mainAsset?.available ? mainAsset?.available : 0.0
								} ${selectedAsset?.code}`}</h1>
							)}
							<div className="includeFeeContainer">
								<CustomCheckbox
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										handleIsCheckedChange(e.target.checked)
									}
									key={selectedAsset?.code}
									checked={isChecked}
								/>
								<h1 className="includeFeeText">
									{t('withdraw_includeFee')}
								</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="withdrawCryptoButtonsBottomContainer">
				<div className="withdrawButtonsBottomSeparator" />
				<div className="withdrawCryptoButtonsBottomInnerContainer">
					<div className="withdrawCryptoBottomAmountContainer">
						<h1 className="withdrawCryptoBottomAmountContainerHeader">
							{t('withdraw_totalWithdraw')}
						</h1>
						<h1
							className={
								'withdrawCryptoBottomAmountContainerMain' +
								(amount?.length >= 10
									? ' withdrawCryptoBottomAmountContainerMainSmallerText'
									: ' ')
							}
						>
							{isChecked
								? formatNumber(
										Number(amount) + Number(fee),
										mainAsset?.precision
								  )
								: formatNumber(Number(amount), mainAsset?.precision)}
							{selectedAsset === undefined ? '' : ` ${selectedAsset?.code}`}
						</h1>
						<h1 className="withdrawCryptoBottomAmountContainerBottom">
							{`${t('withdraw_includingFee')}: `}
							{formatNumber(Number(fee), mainAsset?.precision)}
							{selectedAsset === undefined ? '' : ` ${selectedAsset?.code}`}
						</h1>
					</div>
					<CustomButton
						isDisabled={
							(isChecked ? Number(Number(amount) + fee) : Number(amount)) >
								Number(mainAsset?.available) ||
							amount.length === 0 ||
							Number(amount) < Number(minValue) ||
							mainAsset === undefined ||
							selectedAsset === undefined ||
							address.length < 10 ||
							!isAddressValid ||
							isCheckAddressValidationPending
						}
						isLoading={false}
						text={t('wallet_withdraw') as string}
						onClick={handleSubmit}
						className="withdrawCryptoBottomButtonContainer"
					/>
				</div>
			</div>
		</div>
	);
};

const CryptoWithdrawLayout = () => {
	const address = useAppSelector(selectCryptoWithdrawAddress);
	const amount = useAppSelector(selectCryptoWithdrawAmount);
	const selectedAsset = useAppSelector(selectCryptoWithdrawAsset);
	const selectedNetwork = useAppSelector(selectCryptoWithdrawNetwork);
	const suitableNetworks = useAppSelector(selectCryptoWithdrawSuitableNetworks);
	const fee = useAppSelector(selectCryptoWithdrawFee);
	const isAddressValid = useAppSelector(selectCryptoWithdrawIsAddressValid);
	const accessToken = useAppSelector(selectAccessToken);
	const mainCurrencies = useAppSelector(selectMainAccountRests);
	const depositWithdrawAssetsDictionary = useAppSelector(
		selectDepositWithdrawAssetsDictionary
	)?.dictionary?.withdraw?.crypto;
	const assetIcons = useAppSelector(selectAssetIcons);
	const isChecked = useAppSelector(selectCryptoWithdrawIsChecked);

	return (
		<CryptoWithdrawFunctionalLayout
			assetIcons={assetIcons}
			mainCurrencies={mainCurrencies}
			depositWithdrawAssetsDictionary={depositWithdrawAssetsDictionary}
			accessToken={accessToken as string}
			address={address}
			amount={amount}
			selectedAsset={selectedAsset}
			selectedNetwork={selectedNetwork}
			suitableNetworks={suitableNetworks}
			fee={fee}
			isAddressValid={isAddressValid}
			isChecked={isChecked}
		/>
	);
};

export default CryptoWithdrawLayout;

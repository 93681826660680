import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../../types/routes';
import { HeaderLayout } from '../../../common/header';
import { formatNumber } from '../../../../utils';
import { CustomButton } from '../../../common/buttons';
import { useTranslation } from 'react-i18next';
import { selectMainAccountRests } from '../../../../services/wallet';
import { TAsset } from '../../../../types/types';
import { WhaleIcon } from '../../../common/icons';
import { RejectOperationModal, SaveAddressModal } from '../../../common/modals';
import {
	selectCardWithdrawAmount,
	selectCardWithdrawAsset,
	selectCardWithdrawCard,
	selectCardWithdrawFee,
	selectCardWithdrawIsChecked,
	selectCardWithdrawMaskedCard,
	selectCardWithdrawOperationId,
	setCardWithdrawInit,
} from '../../../../services/withdraw/card.withdraw.slice';
import { selectCardTemplates } from '../../../../services/withdraw/templates.withdraw.slice';

const CardWithdrawSummaryLayout = () => {
	const fee = useAppSelector(selectCardWithdrawFee);
	const amount = useAppSelector(selectCardWithdrawAmount);
	const selectedAsset = useAppSelector(selectCardWithdrawAsset);
	const mainCurrencies = useAppSelector(selectMainAccountRests);
	const savedCards = useAppSelector(selectCardTemplates);
	const operationId = useAppSelector(selectCardWithdrawOperationId);
	const savedCard = useAppSelector(selectCardWithdrawCard);
	const isChecked = useAppSelector(selectCardWithdrawIsChecked);
	const maskedCard = useAppSelector(selectCardWithdrawMaskedCard);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();

	const [mainAsset, setMainAsset] = useState<TAsset>();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isSaveCardModalOpen, setIsSaveCardModalOpen] = useState<boolean>(false);

	const [isNeedToSaveCard, setIsNeedToSaveCard] = useState<boolean>(false);

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleSaveCardModalOpen = () => {
		setIsSaveCardModalOpen(!isSaveCardModalOpen);
	};

	const clearCardWithdrawState = () => {
		dispatch(setCardWithdrawInit());
		navigate(ROUTES.WALLET.INDEX);
	};

	useEffect(() => {
		if (amount === '') {
			dispatch(setCardWithdrawInit());
			navigate(ROUTES.WALLET.INDEX);
		}
		if (mainCurrencies) {
			setMainAsset(
				mainCurrencies?.assets?.find(
					(asset) => asset?.code === selectedAsset?.code
				)
			);
		}
		if (
			savedCards?.filter(
				(tempAddress) => tempAddress?.userCardId === savedCard?.userCardId
			)?.length === 0
		) {
			setIsNeedToSaveCard(true);
		}
	}, []);

	const handleBackClickButton = () => {
		dispatch(setCardWithdrawInit());
		navigate(ROUTES.WALLET.INDEX);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout isRejectHeader handleBackClick={handleModalOpen} />
				<SaveAddressModal
					type="card"
					handleIsModalOpen={handleSaveCardModalOpen}
					isModalOpen={isSaveCardModalOpen}
					setIsNeedToSave={setIsNeedToSaveCard}
				/>
				<RejectOperationModal
					handleIsModalOpen={handleModalOpen}
					isModalOpen={isModalOpen}
					operId={operationId}
					additionalFunc={clearCardWithdrawState}
				/>
				<div
					style={{
						zIndex: '1',
						top: 'auto',
						marginBottom: '140px',
						gap: '0',
					}}
					className="walletLayout authMainContainer"
				>
					<WhaleIcon className="whaleConvertIcon" />
					<h1 className={'modalWalletConvertSummaryHeader'}>
						{t('operation_operationIsProcessed')}
					</h1>
					<h1 className={'modalWalletConvertSummaryBottom'}>
						{t('operation_conversionProcessedDetails')}
					</h1>
				</div>
			</div>
			<div
				style={{
					zIndex: '3',
				}}
				className="depositButtonsBottomContainer"
			>
				<div className="commonGreyContainer">
					<div className="commonItem">
						<h1 className="cryptoWithdrawCommonItemLeftText">
							{t('withdraw_totalWithdraw')}
						</h1>
						<h1 className="cryptoWithdrawCommonItemRightText">
							{formatNumber(
								isChecked
									? Number(Number(amount) + Number(fee)).toFixed(
											mainAsset?.precision
									  )
									: Number(amount).toFixed(mainAsset?.precision),
								mainAsset?.precision
							)}
							{` ${selectedAsset?.code}`}
						</h1>
					</div>
					<div className="commonItem">
						<h1 className="cryptoWithdrawCommonItemLeftText">
							{t('withdrawConfirmation_withdrawFee')}
						</h1>
						<h1 className="cryptoWithdrawCommonItemRightText">
							{formatNumber(
								Number(fee).toFixed(mainAsset?.precision),
								mainAsset?.precision
							)}
							{` ${selectedAsset?.code}`}
						</h1>
					</div>
					<div className="commonItem">
						<h1 className="cryptoWithdrawCommonItemLeftText">
							{t('withdrawCardConfirmation_toCard')}
						</h1>
						<h1 className="cryptoWithdrawCommonItemRightText">
							{maskedCard}
						</h1>
					</div>
					{isNeedToSaveCard && (
						<div
							style={{
								width: '100%',
								padding: '0 24px',
								boxSizing: 'border-box',
							}}
						>
							<CustomButton
								isSaveAddress
								text={t('savedAddresses_saveCard') as string}
								onClick={handleSaveCardModalOpen}
								className="confirmFullWidthButton"
							/>
						</div>
					)}
				</div>
				<CustomButton
					text={t('general_finishAndClose') as string}
					onClick={handleBackClickButton}
					className="confirmFullWidthButton"
				/>
			</div>
		</div>
	);
};

export default CardWithdrawSummaryLayout;

import { useMutation } from "@tanstack/react-query";
import { AppDispatch } from "../../../store";
import { TCustomNavigate, TExchangeBody, TExchangeRateBody } from "../../../types/types";
import { fetchExchange, fetchExchangeRate } from "../../../api/exchange";
import { setExchangeRate, setExchangeStatus } from "../../../services/exchange/exchange.slice";
import { ROUTES } from "../../../types/routes";

export function useGetExchangeRate(
    dispatch: AppDispatch,
) {
    const {
        mutateAsync: getExchangeRate,
        isError,
        error,
        isPending
    } = useMutation({
        mutationKey: ['getExchangeRate'],
        mutationFn: (data: TExchangeRateBody) =>
            fetchExchangeRate(data),
        //eslint-disable-next-line
        onSuccess: async (data: any) => {
            dispatch(
                setExchangeRate(data)
            );
        },
        //eslint-disable-next-line
        onError: async (data: any) => {
            dispatch(
                setExchangeRate({
                    code: data?.code,
                    msg: data?.message
                })
            )
        }
    });
    return {
        getExchangeRate,
        isError,
        error,
        isPending
    };
}

export function useSendExchange(
    dispatch: AppDispatch,
    navigate: TCustomNavigate
) {
    const {
        mutateAsync: sendExchange,
        isError,
        error,
        isPending
    } = useMutation({
        mutationKey: ['sendExchange'],
        mutationFn: (data: TExchangeBody) =>
            fetchExchange(data),
        //eslint-disable-next-line
        onSuccess: async (data: any) => {
            if (data?.operations) {

                dispatch(
                    setExchangeStatus(true)
                );
                navigate(ROUTES.EXCHANGE.SUMMARY)
            }
        },
        //eslint-disable-next-line
        onError: async (data: any) => {
            dispatch(
                setExchangeStatus(false)
            );
        },
    });
    return {
        sendExchange,
        isError,
        error,
        isPending
    };
}
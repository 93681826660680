import { useTranslation } from 'react-i18next';
import { mapClassnameForMobile } from '../../../utils';
import { CustomButton } from '../../common/buttons';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import QRCode from 'qrcode';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	selectQrCodePath,
	selectSecretKey,
	setIsEnablingTwoFa
} from '../../../services/auth';
import { CopyIcon } from '../../common/icons';
import { useEffect, useState } from 'react';
import { HeaderLayout } from '../../common/header';
import { useToast } from '../../../hooks/useToast';

const SecretKeyLayout = () => {
	const secretKey = useAppSelector(selectSecretKey);
	const qrCodePath = useAppSelector(selectQrCodePath);

	const navigate = useCustomNavigate();
	const dispatch = useAppDispatch();

	const handleBackClickButton = () => {
		navigate(ROUTES.AUTH.SETUP_TWOFA);
	};

	const handleSubmit = () => {
		dispatch(setIsEnablingTwoFa(true));
		navigate(ROUTES.AUTH.TWO_FA);
	};

	return (
		<SecretKeyFunctionalLayout
			handleBackClickButton={handleBackClickButton}
			handleSubmit={handleSubmit}
			secretKey={secretKey}
			qrCodePath={qrCodePath}
		/>
	);
};

interface Props {
	handleBackClickButton: () => void;
	handleSubmit: () => void;
	qrCodePath: string;
	secretKey: string;
}

export const SecretKeyFunctionalLayout = ({
	handleBackClickButton,
	handleSubmit,
	qrCodePath,
	secretKey
}: Props) => {
	const [qrCodeImage, setQrCodeImage] = useState<string>('');
	const [isDeviceMobile, setIsDeviceMobile] = useState<boolean>(false);

	useEffect(() => {
		if (
			navigator.userAgent.match(/Android/i) ||
			navigator.userAgent.match(/iPhone/i)
		) {
			setIsDeviceMobile(true);
		}
	}, []);

	useEffect(() => {
		QRCode.toDataURL(
			qrCodePath,
			{
				width: 250
			},
			(err, url) => {
				setQrCodeImage(url);
			}
		);
	}, [secretKey, qrCodePath]);

	const { t } = useTranslation();

	const handleCopyClick = async () => {
		await navigator.clipboard.writeText(secretKey);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout
					isFunctionalHeader
					handleBackClick={handleBackClickButton}
				/>
				<div className={mapClassnameForMobile('walletLayout')}>
					<div className='innerContainer'>
						<h1 className='confirmEmailText'>
							{t('twoFactorAuthSettings_set2FA')}
						</h1>
						<h1 className='confirmCodeText'>
							{t('twoFactorAuthSettings_copySecretCode')}
						</h1>

						{isDeviceMobile ? (
							<div className='secretKeyContainer'>
								<h1 className='secretKeyText'>
									{t('twoFactorAuthSettings_secretCode')}
								</h1>
								<div className='secretKeyInnerContainer'>
									<h1 className='secretKeyInnerText'>
										{secretKey}
									</h1>
									<CopyIcon
										className=''
										onClick={() => {		
											useToast(t("general_copied") as string) 
											handleCopyClick()
										}}
									/>
								</div>
							</div>
						) : (
							<div className='secretKeyQrContainer'>
								<img src={qrCodeImage} alt={qrCodeImage} />
							</div>
						)}
					</div>
				</div>
				<div className='depositButtonsBottomContainer'>
					<CustomButton
						text={t('login_continue') as string}
						type={'submit'}
						onClick={handleSubmit}
						className={'confirmFullWidthButton'}
					/>
				</div>
			</div>
		</div>
	);
};

export default SecretKeyLayout;

import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import {
	useLoginWithoutTwoFAMutation,
	useSendPasswordRecoveryChange,
} from '../../../../data/mutations/login/login';
import { CustomInput } from '../../../common/inputs';
import { APIError } from '../../../common/error';
import { CustomButton } from '../../../common/buttons';
import { selectPasswordRecoveryEmail } from '../../../../services/auth';
import { ROUTES } from '../../../../types/routes';
import { HeaderLayout } from '../../../common/header';
import { mapClassnameForMobile } from '../../../../utils';
import { selectIsTelegram } from '../../../../services/root';
import { selectRegistrationDictionary } from '../../../../services/dictionaries';

const PasswordRecoveryChangePasswordLayout = () => {
	const isTelegram = useAppSelector(selectIsTelegram);
	const passwordRecoveryEmail = useAppSelector(selectPasswordRecoveryEmail);
	const registrationDictionary = useAppSelector(selectRegistrationDictionary);

	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const [password, setPassword] = useState<string>('');
	const [isPasswordRequired, setIsPasswordRequired] = useState<boolean>(false);
	const [isPasswordError, setIsPasswordError] = useState<boolean>(false);

	const navigate = useCustomNavigate();

	const { sendPasswordRecoveryChange, isError, error, isPending, isSuccess } =
		useSendPasswordRecoveryChange(navigate);

	const { login } = useLoginWithoutTwoFAMutation(navigate);

	useEffect(() => {
		if (passwordRecoveryEmail.length === 0) {
			navigate(ROUTES.AUTH.RECOVERY.PASSWORD_RECOVERY);
		}
	}, []);

	useEffect(() => {
		if (isSuccess) {
			login({
				login: passwordRecoveryEmail,
				password: password,
				isTelegram: isTelegram,
				dispatch: dispatch,
			});
		}
	}, [isSuccess]);

	const buttonRef = useRef(null);

	const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>): void => {
		setPassword(e.target.value);
		setIsPasswordError(false);
		e.target.value.length > 0
			? setIsPasswordRequired(true)
			: setIsPasswordRequired(false);
	};

	const handlePasswordClearClick = () => {
		setPassword('');
		setIsPasswordRequired(false);
	};

	const handleSubmit = async () => {
		setIsPasswordError(password.length === 0);
		if (password.length > 0) {
			await sendPasswordRecoveryChange({
				email: passwordRecoveryEmail,
				newPassword: password,
				code: null,
			});
		}
	};

	const handleBackClickButton = () => {
		navigate(ROUTES.AUTH.RECOVERY.PASSWORD_RECOVERY);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout
					isFunctionalHeader
					handleBackClick={handleBackClickButton}
				/>
				<div className={mapClassnameForMobile('walletLayout ', true)}>
					<div className="innerContainer">
						<h1 className="innerContainerMainText">
							{t('passwordRecovery_setNewPassword')}
						</h1>
						<h1 className={'confirmCodeText'}>
							{t('registration_createPassword')}
						</h1>
						<CustomInput
							isTabDisable={true}
							isError={isError || isPasswordError}
							isRequired={isPasswordRequired}
							type={'password'}
							id={'password'}
							label={t('login_password')}
							value={password}
							onChange={handlePasswordChange}
							className={'input passwordInput'}
							onClearClick={handlePasswordClearClick}
							inputPropsRef={buttonRef}
							isPassword={true}
						/>
						{isPasswordError && (
							<h1 className={'loginErrorText'}>
								{t('general_mandatoryField')}
							</h1>
						)}
						<h1 className={'confirmCodeText'}>
							{registrationDictionary?.dictionary?.passwordRequirements}
						</h1>
						<APIError isError={isError} error={error} />
					</div>
					<div className="depositButtonsBottomContainer">
						<CustomButton
							isLoading={isPending || isSuccess}
							text={t('passwordRecovery_change') as string}
							onClick={handleSubmit}
							className="confirmFullWidthButton"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PasswordRecoveryChangePasswordLayout;

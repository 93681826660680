import { Helmet } from "react-helmet-async";
import { TITLES } from "../../../types/titles";
import ExchangeSummaryLayout from "../../../components/exchange/summary";

const ExchangeSummary = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.EXCHANGE.SUMMARY}</title>
			</Helmet>
			<ExchangeSummaryLayout />
		</>
	);
};

export default ExchangeSummary;

import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../../types/titles';
import VisitHistoryLayout from '../../../../components/profile/security/visit-history';

const VisitHistory = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.PROFILE.SECURITY.VISIT_HISTORY}</title>
			</Helmet>
			<VisitHistoryLayout />
		</>
	);
};

export default VisitHistory;

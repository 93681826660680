import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
	selectWithdrawIdentityTypes,
	selectWithdrawState,
	selectWithdrawType,
	setWithdrawInit,
	setWithdrawSelectedIdentityType,
} from '../../../../services/withdraw/withdraw.slice';
import { selectAccessToken } from '../../../../services/auth';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../../types/routes';
import { HeaderLayout } from '../../../common/header';
import { mapClassnameForMobile } from '../../../../utils';
import { TwoFAButton } from '../../../common/buttons';
import { APIError } from '../../../common/error';
import { useSendWithdrawOTP } from '../../../../data/mutations/withdraw';

const WithdrawSelectTwoFaLayout = () => {
	const withdrawType = useAppSelector(selectWithdrawType);

	const accessToken = useAppSelector(selectAccessToken);
	const state = useAppSelector(selectWithdrawState);
	const identityTypes = useAppSelector(selectWithdrawIdentityTypes);

	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const { sendWithdrawOTP, isError, error } = useSendWithdrawOTP();

	const mapWithdrawTypeToBackClick = (isIndex: boolean) => {
		dispatch(setWithdrawInit());
		if (withdrawType === 'bank') {
			navigate(
				isIndex ? ROUTES.WITHDRAW.BANK.INDEX : ROUTES.WITHDRAW.BANK.CONFIRMATION
			);
		}
		if (withdrawType === 'crypto') {
			navigate(
				isIndex
					? ROUTES.WITHDRAW.CRYPTO.INDEX
					: ROUTES.WITHDRAW.CRYPTO.CONFIRMATION
			);
		}
		if (withdrawType === 'card') {
			navigate(
				isIndex ? ROUTES.WITHDRAW.CARD.INDEX : ROUTES.WITHDRAW.CARD.CONFIRMATION
			);
		}
	};

	const handleBackClick = () => {
		mapWithdrawTypeToBackClick(false);
	};

	const handleOTPSelect = (otpType: string) => {
		dispatch(setWithdrawSelectedIdentityType(otpType.toLowerCase()));
		if (otpType === 'GoogleAuthenticator') {
			navigate(ROUTES.WITHDRAW.TWO_FA.TWO_FA);
		} else {
			sendWithdrawOTP({
				identityType: otpType,
				state: state as string,
				dispatch: dispatch,
				accessToken: accessToken as string,
			});
            navigate(ROUTES.WITHDRAW.TWO_FA.OTP);
		}
	};

	const mapTextByIdentityValue = (identity: string) => {
		if (identity.toLowerCase() === 'email') {
			return t('twoFactorAuthMethods_email') as string;
		}
		if (identity.toLowerCase() === 'phone') {
			return t('twoFactorAuthMethods_phone') as string;
		}
		if (identity.toLowerCase() === 'googleauthenticator') {
			return t('twoFactorAuthMethods_app') as string;
		}
		return '';
	};

	useEffect(() => {
		if (identityTypes.length === 0) {
			mapWithdrawTypeToBackClick(true);
		}
	}, []);

	return (
		<>
			<div>
				<div className={'component'}>
					<div
						style={{
							backgroundColor: '#F6F6F8',
						}}
						className={'innerComponent'}
					>
						<HeaderLayout
							isFunctionalHeader
							isAboutHeader
							handleBackClick={handleBackClick}
						/>
						<div className={mapClassnameForMobile('walletLayout', true)}>
							<div className="innerContainer">
								<h1 className={'welcomeText'}>
									{t('twoFactorAuthMethods_title')}
								</h1>
								<h1 className={'confirmCodeText'}>
									{t('twoFactorAuthMethods_subtitle')}
								</h1>
								{identityTypes.map((identity) => (
									<TwoFAButton
										onClick={() => handleOTPSelect(identity.name)}
										text={mapTextByIdentityValue(identity.name)}
										iconType={identity.name}
									/>
								))}
								<APIError isError={isError} error={error} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default WithdrawSelectTwoFaLayout;

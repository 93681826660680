import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import {
	selectCryptoWithdrawAddress,
	selectCryptoWithdrawAmount,
	selectCryptoWithdrawAsset,
	selectCryptoWithdrawFee,
	selectCryptoWithdrawOperationId,
	setCryptoWithdrawInit,
} from '../../../../services/withdraw/crypto.withdraw.slice';
import { ROUTES } from '../../../../types/routes';
import { HeaderLayout } from '../../../common/header';
import { formatNumber } from '../../../../utils';
import { CustomButton } from '../../../common/buttons';
import { useTranslation } from 'react-i18next';
import { selectMainAccountRests } from '../../../../services/wallet';
import { TAsset } from '../../../../types/types';
import { WhaleIcon } from '../../../common/icons';
import { RejectOperationModal, SaveAddressModal } from '../../../common/modals';
import { selectCryptoTemplates } from '../../../../services/withdraw/templates.withdraw.slice';

const CryptoWithdrawSummaryLayout = () => {
	const address = useAppSelector(selectCryptoWithdrawAddress);
	const fee = useAppSelector(selectCryptoWithdrawFee);
	const amount = useAppSelector(selectCryptoWithdrawAmount);
	const selectedAsset = useAppSelector(selectCryptoWithdrawAsset);
	const operationId = useAppSelector(selectCryptoWithdrawOperationId);
	const mainCurrencies = useAppSelector(selectMainAccountRests);
	const savedAddresses = useAppSelector(selectCryptoTemplates);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();

	const [mainAsset, setMainAsset] = useState<TAsset>();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isSaveAddressModalOpen, setIsSaveAddressModalOpen] = useState<boolean>(false);

	const [isNeedToSaveAddress, setIsNeedToSaveAddress] = useState<boolean>(false);

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleSaveAddressModalOpen = () => {
		setIsSaveAddressModalOpen(!isSaveAddressModalOpen);
	};

	const clearCryptoWithdrawState = () => {
		dispatch(setCryptoWithdrawInit());
		navigate(ROUTES.WALLET.INDEX);
	};

	useEffect(() => {
		if (address === '' || amount === '') {
			dispatch(setCryptoWithdrawInit());
			navigate(ROUTES.WALLET.INDEX);
		}
		if (mainCurrencies) {
			setMainAsset(
				mainCurrencies?.assets?.find(
					(asset) => asset?.code === selectedAsset?.code
				)
			);
		}
		setIsNeedToSaveAddress(
			savedAddresses?.filter(
				(tempAddress) => tempAddress?.cryptoAddress === address
			)?.length === 0
		);
	}, []);

	const handleBackClickButton = () => {
		dispatch(setCryptoWithdrawInit());
		navigate(ROUTES.WALLET.INDEX);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout isRejectHeader handleBackClick={handleModalOpen} />
				<SaveAddressModal
					type="crypto"
					handleIsModalOpen={handleSaveAddressModalOpen}
					isModalOpen={isSaveAddressModalOpen}
					setIsNeedToSave={setIsNeedToSaveAddress}
				/>
				<RejectOperationModal
					handleIsModalOpen={handleModalOpen}
					isModalOpen={isModalOpen}
					operId={operationId}
					additionalFunc={clearCryptoWithdrawState}
				/>
				<div
					style={{
						zIndex: '1',
						top: 'auto',
						marginBottom: '140px',
						gap: '0',
					}}
					className="walletLayout authMainContainer"
				>
					<WhaleIcon className="whaleConvertIcon" />
					<h1 className={'modalWalletConvertSummaryHeader'}>
						{t('operation_operationIsProcessed')}
					</h1>
					<h1 className={'modalWalletConvertSummaryBottom'}>
						{t('operation_conversionProcessedDetails')}
					</h1>
				</div>
			</div>
			<div
				style={{
					zIndex: '3',
				}}
				className="depositButtonsBottomContainer"
			>
				<div className="commonGreyContainer">
					<div className="commonItem">
						<h1 className="cryptoWithdrawCommonItemLeftText">
							{t('withdraw_totalWithdraw')}
						</h1>
						<h1 className="cryptoWithdrawCommonItemRightText">
							{formatNumber(amount, mainAsset?.precision)}
							{` ${selectedAsset?.code}`}
						</h1>
					</div>
					<div className="commonItem">
						<h1 className="cryptoWithdrawCommonItemLeftText">
							{t('withdrawConfirmation_withdrawFee')}
						</h1>
						<h1 className="cryptoWithdrawCommonItemRightText">
							{formatNumber(fee, mainAsset?.precision)}
							{` ${selectedAsset?.code}`}
						</h1>
					</div>
					<div className="commonItem">
						<h1
							style={{
								width: '60px !important',
							}}
							className="cryptoWithdrawCommonItemLeftText"
						>
							{t('withdrawCryptoResult_toAddress')}
						</h1>
						<h1
							style={{
								wordBreak: 'break-word',
								width: '200px !important',
							}}
							className="cryptoWithdrawCommonItemRightText"
						>
							{address}
						</h1>
					</div>
					{isNeedToSaveAddress && (
						<div
							style={{
								width: '100%',
								padding: '0 24px',
								boxSizing: 'border-box',
							}}
						>
							<CustomButton
								isSaveAddress
								text={t('withdrawResult_saveAddress') as string}
								onClick={handleSaveAddressModalOpen}
								className="confirmFullWidthButton"
							/>
						</div>
					)}
				</div>
				<CustomButton
					text={t('general_finishAndClose') as string}
					onClick={handleBackClickButton}
					className="confirmFullWidthButton"
				/>
			</div>
		</div>
	);
};

export default CryptoWithdrawSummaryLayout;

import { useEffect, useState } from 'react';
import {
	TAsset,
	TBankDepositInvoiceHistory,
	TExchangeItem,
	TInnerCryptoDepositAsset,
	TMarketAsset,
	TOperation,
	TSavedAddress,
	TSavedBank,
	TSavedCard,
	TUserActivity,
	TUserDevice,
	TWalletModalItem,
} from '../../../../types/types';
import {
	AssetIcon,
	DepositIcons,
	DocumentIcon,
	FavouriteIcon,
	OperationIcon,
	OperationStatusIcon,
	PercentChangeIcon,
	UserActivityIcon,
	UserDeviceIcon,
} from '../../icons';
import { CustomCheckbox } from '../../checkboxes';
import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../../types/routes';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setPreviousLocation } from '../../../../services/root';
import { formatNumber } from '../../../../utils';
import { selectBanksDictionary } from '../../../../services/dictionaries';

interface WalletItemProps<T> {
	asset?: TAsset;
	idx: number;
	isLoading?: boolean;
	baseAsset?: string;
	isBalanceHidden?: boolean;
	basePrecision?: number;
	handleSelectedAmountChange?: (i: TAsset, isSelected: boolean, idx: number) => void;
	isChecked?: boolean;
	withdrawAsset?: T | undefined;
	handleItemClick?: (i: T, networkCode?: string) => void;
	networkCode?: string;
	isError?: boolean;
}

const WalletItem = <T,>({
	asset,
	idx,
	isLoading,
	baseAsset,
	isBalanceHidden,
	basePrecision,
	handleSelectedAmountChange,
	isChecked,
	withdrawAsset,
	handleItemClick,
	networkCode,
	isError
}: WalletItemProps<T>) => {
	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();

	const mapOnClickEvent = () => {
		if (handleItemClick) {
			handleItemClick(withdrawAsset as T, networkCode as string);
			return;
		}
		if (handleSelectedAmountChange === undefined) {
			handleItemNavigateClick();
			return;
		}
		return () => undefined;
	};

	const handleItemNavigateClick = () => {
		if (!isLoading) {
			dispatch(setPreviousLocation(window.location.pathname));
			navigate(ROUTES.MARKET.ASSET_DETAILS.replace(':code', asset?.code as string));
		}
	};

	return (
		<div key={idx} className="assetItem" onClick={() => mapOnClickEvent()}>
			{handleSelectedAmountChange && (
				<CustomCheckbox
					isError={isError}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleSelectedAmountChange(asset as TAsset, e.target.checked, idx)
					}
					key={asset?.code}
					checked={isChecked}
				/>
			)}
			<AssetIcon
				isLoading={isLoading as boolean}
				className="assetIcon"
				group={asset?.group as string}
				assetName={asset?.code as string}
			/>
			<div
				className={
					handleSelectedAmountChange
						? 'innerAssetItemHeader walletConvertModalItem'
						: 'innerAssetItemHeader'
				}
			>
				{isLoading ? (
					<div className="innerAssetHeaderTextLoading"></div>
				) : (
					<h1 className="innerAssetHeaderText">{asset?.code}</h1>
				)}
				{isLoading ? (
					<div className="innerAssetBottomTextLoading"></div>
				) : (
					<h1 className="innerAssetBottomText">{asset?.name}</h1>
				)}
			</div>
			<div className="innerAssetItemAmount">
				{asset && (
					<>
						<h1 className="innerAssetItemAmountText">
							{isBalanceHidden
								? '******'
								: asset?.code === baseAsset
								? formatNumber(asset?.baseAvailable, asset?.precision)
								: formatNumber(asset?.available, asset?.precision)}
						</h1>
						{asset?.code != baseAsset && (
							<h1 className="innerAssetItemAmountUSDText">
								{isBalanceHidden
									? '******'
									: formatNumber(
											asset?.baseAvailable,
											basePrecision
									  )}{' '}
								{baseAsset}
							</h1>
						)}
					</>
				)}
			</div>
		</div>
	);
};

interface ExchangeItemProps {
	asset: TExchangeItem;
	idx: number;
	baseAsset: string;
	basePrecision: number;
	handleItemClick: (i: TExchangeItem) => void;
}

const ExchangeFromItem = ({
	asset,
	idx,
	baseAsset,
	basePrecision,
	handleItemClick,
}: ExchangeItemProps) => {
	return (
		<div key={idx} className="assetItem" onClick={() => handleItemClick(asset)}>
			<AssetIcon
				className="assetIcon"
				group={asset?.group as string}
				assetName={asset?.code as string}
			/>
			<div className={'innerAssetItemHeader'}>
				<h1 className="innerAssetHeaderText">{asset?.code}</h1>
				<h1 className="innerAssetBottomText">{asset?.name}</h1>
			</div>
			<div className="innerAssetItemAmount">
				{asset && (
					<>
						<h1 className="innerAssetItemAmountText">
							{asset?.code === baseAsset
								? formatNumber(asset?.baseAvailable, asset?.precision)
								: formatNumber(asset?.available, asset?.precision)}
						</h1>
						{asset?.code != baseAsset && (
							<h1 className="innerAssetItemAmountUSDText">
								{formatNumber(asset?.baseAvailable, basePrecision)}{' '}
								{baseAsset}
							</h1>
						)}
					</>
				)}
			</div>
		</div>
	);
};

interface SearchAssetItemProps {
	asset?: TMarketAsset | TAsset;
	idx: number;
	globalAssetCode?: string;
	isLoading?: boolean;
	basePrecision?: number;
}

const SearchAssetItem = ({
	asset,
	idx,
	globalAssetCode,
	isLoading,
	basePrecision,
}: SearchAssetItemProps) => {
	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();

	const handleItemNavigateClick = () => {
		if (!isLoading) {
			dispatch(setPreviousLocation(window.location.pathname));
			navigate(ROUTES.MARKET.ASSET_DETAILS.replace(':code', asset?.code as string));
		}
	};

	if (isLoading) {
		return (
			<div key={idx} className="searchAssetsItem">
				<AssetIcon
					isLoading={isLoading}
					className="assetIcon"
					group=""
					assetName=""
				/>
				<div className="searchAssetTextContainer">
					<div className="searchAssetTextHeaderLoading" />
					<div className="searchAssetTextBottomLoading" />
				</div>
			</div>
		);
	}

	return (
		<div onClick={handleItemNavigateClick} key={idx} className="searchAssetsItem">
			<AssetIcon
				isLoading={false}
				className="assetIcon"
				group={asset?.group as string}
				assetName={asset?.code as string}
			/>
			<div className="searchAssetTextContainer">
				<h1 className="upperMarketItemText">{asset?.code}</h1>
				<h1 className="bottomMarketItemText">{asset?.name}</h1>
			</div>
			<div className="innerAssetItemAmount">
				{asset && (asset as TAsset)?.available && (
					<>
						<h1 className="innerAssetItemAmountText">
							{asset?.code === globalAssetCode
								? formatNumber(
										(asset as TAsset)?.baseAvailable,
										asset.precision
								  )
								: formatNumber(
										(asset as TAsset)?.available,
										asset.precision
								  )}
						</h1>
						{asset?.code != globalAssetCode && (
							<h1 className="innerAssetItemAmountUSDText">
								{formatNumber(
									(asset as TAsset)?.baseAvailable,
									basePrecision
								)}{' '}
								{globalAssetCode}
							</h1>
						)}
					</>
				)}
			</div>
		</div>
	);
};

interface MarketAssetItemProps<T> {
	asset?: TMarketAsset;
	idx: number;
	globalAssetCode?: string;
	isLoading?: boolean;
	handleFavouriteClick?: (asset: TMarketAsset, isNeedToAdd: boolean) => void;
	isFavourite?: boolean;
	timeFrame?: string;
	isDeposit?: boolean;
	onClick?: (i: T, networkCode: string) => void;
	depositAsset?: TInnerCryptoDepositAsset;
	networkCode?: string;
}

const MarketAssetItem = <T,>({
	asset,
	idx,
	globalAssetCode,
	isLoading,
	handleFavouriteClick,
	isFavourite,
	timeFrame,
	isDeposit,
	onClick,
	depositAsset,
	networkCode,
}: MarketAssetItemProps<T>) => {
	const { t } = useTranslation();
	const navigate = useCustomNavigate();
	const dispatch = useAppDispatch();

	const [isPositive, setIsPositive] = useState<boolean>(false);
	const [amount, setAmount] = useState<number>(0);

	const handleLocalFavouriteClick = () => {
		handleFavouriteClick && handleFavouriteClick(asset as TMarketAsset, !isFavourite);
	};

	useEffect(() => {
		if (timeFrame && asset) {
			if (timeFrame === `1${t('filters_h')}`) {
				setIsPositive(asset?.percentChange1h >= 0);
				setAmount(asset?.percentChange1h);
			}
			if (timeFrame === `24${t('filters_h')}`) {
				setIsPositive(asset?.percentChange24h >= 0);
				setAmount(asset?.percentChange24h);
			}
			if (timeFrame === `7${t('filters_d')}`) {
				setIsPositive(asset?.percentChange7d >= 0);
				setAmount(asset?.percentChange7d);
			}
			if (timeFrame === `30${t('filters_d')}`) {
				setIsPositive(asset?.percentChange30d >= 0);
				setAmount(asset?.percentChange30d);
			}
		}
	}, [timeFrame, asset]);

	const handleItemNavigateClick = () => {
		if (!isLoading && !isDeposit) {
			dispatch(setPreviousLocation(window.location.pathname));
			navigate(ROUTES.MARKET.ASSET_DETAILS.replace(':code', asset?.code as string));
		}
	};

	return (
		<div
			onClick={() =>
				isDeposit && onClick
					? onClick(
							depositAsset === undefined
								? asset
								: ({
										code:
											asset != undefined
												? (asset?.code as string)
												: (depositAsset?.code as string),
										minDeposit: depositAsset?.minDeposit as number,
										depositFee: depositAsset?.depositFee as number,
										//eslint-disable-next-line
								  } as any),
							networkCode as string
					  )
					: undefined
			}
			key={idx}
			className="marketAssetItem"
		>
			{!isDeposit && (
				<FavouriteIcon
					isLoading={isLoading as boolean}
					className=""
					isActive={Boolean(isFavourite)}
					onClick={handleLocalFavouriteClick}
				/>
			)}
			<div className="marketAssetItem" onClick={handleItemNavigateClick}>
				<AssetIcon
					isLoading={isLoading as boolean}
					className="assetIcon"
					group={
						asset != undefined
							? (asset?.group?.toLowerCase() as string)
							: 'crypto'
					}
					assetName={
						asset != undefined
							? (asset?.code as string)
							: (depositAsset?.code as string)
					}
				/>
				{isLoading ? (
					<div className="marketAssetItemNameContainer">
						<h1 className="upperMarketItemTextLoading" />
						<h1 className="bottomMarketItemTextLoading" />
					</div>
				) : (
					<div className="marketAssetItemNameContainer">
						{asset != undefined ? (
							<>
								<h1 className="upperMarketItemText">{asset?.code}</h1>
								<h1 className="bottomMarketItemText">{asset?.name}</h1>
							</>
						) : (
							<>
								<h1 className="upperMarketItemText">
									{depositAsset?.code}
								</h1>

								<h1 className="bottomMarketItemText">
									{
										//eslint-disable-next-line
										(depositAsset as any)?.name
									}
								</h1>
							</>
						)}
					</div>
				)}
				{!isLoading && (asset || depositAsset) && (
					<div className="marketAssetItemRateContainer">
						<h1 className="upperMarketItemText">
							{formatNumber(
								asset != undefined ? asset?.baseRate : 0,
								asset?.precision
							)}{' '}
							{globalAssetCode}
						</h1>
						<div className="rateChevContainer">
							<PercentChangeIcon isPositive={isPositive} className="" />
							<h1
								className={
									isPositive ? 'positivePercent' : 'negativePercent'
								}
							>
								{isNaN(amount) ? 0 : formatNumber(Math.abs(amount))}%
							</h1>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

interface WalletModalItemProps {
	asset: TWalletModalItem;
	idx: number;
	onClick: (i: TWalletModalItem) => void;
}

const WalletModalItem = ({ asset, idx, onClick }: WalletModalItemProps) => {
	return (
		<div onClick={() => onClick(asset)} key={idx} className="assetItem">
			<AssetIcon
				className="assetIcon"
				group={asset.code === 'USDT' ? 'Crypto' : 'Fiat'}
				assetName={asset.code}
			/>
			<div className="innerAssetItemHeader">
				<h1 className="innerAssetHeaderText">{asset?.code}</h1>
				<h1 className="innerAssetBottomText">{asset?.name}</h1>
			</div>
		</div>
	);
};

interface HistoryItemProps {
	item: TOperation;
	idx: number;
	isLoading?: boolean;
	handleItemClick: (i: TOperation) => void;
}

const HistoryItem = ({ item, idx, isLoading, handleItemClick }: HistoryItemProps) => {
	return (
		<div onClick={() => handleItemClick(item)} key={idx} className="marketAssetItem">
			<OperationIcon
				className=""
				status={item.status}
				operationType={item.operationType}
			/>
			<div className="marketAssetItem">
				{isLoading ? (
					<div className="marketAssetItemNameContainer">
						<h1 className="upperMarketItemTextLoading" />
						<h1 className="bottomMarketItemTextLoading" />
					</div>
				) : (
					<div className="historyItemNameContainer">
						<h1 className="upperMarketItemText">
							{item?.operationSubTypeName}
						</h1>
						<h1 className="bottomMarketItemText">
							{new Date(item?.date)
								.toLocaleString('ru-RU')
								.replaceAll(',', '')}
						</h1>
					</div>
				)}
				{!isLoading && item && (
					<div className="marketAssetItemRateContainer">
						<h1 className="upperMarketItemText">
							{item?.direction === 'credit' ? '+' : '-' + ' '}
							{formatNumber(item?.amount, item?.asset?.precision)}{' '}
							{item.asset.code}
						</h1>
						{item?.additionalAsset && (
							<h1 className="bottomMarketItemText">
								{item?.additionalDirection === 'credit' ? '+' : '-' + ' '}
								{formatNumber(
									item?.additionalAmount,
									item?.additionalAsset?.precision
								)}{' '}
								{item.additionalAsset.code}
							</h1>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

interface InvoiceBankDepositItemProps {
	item: TBankDepositInvoiceHistory;
	idx: number;
	handleItemClick: (i: string) => void;
}

const InvoiceBankDepositItem = ({
	item,
	idx,
	handleItemClick,
}: InvoiceBankDepositItemProps) => {
	const banksDictionary = useAppSelector(selectBanksDictionary);

	return (
		<div
			onClick={() => handleItemClick(item?.invoiceId)}
			key={idx}
			className="commonItem"
		>
			<DocumentIcon />
			<div
				style={{
					marginLeft: '60px',
				}}
				className="historyItemNameContainer"
			>
				<h1 className="upperMarketItemText">
					{banksDictionary?.dictionary?.deposit?.countries
						?.map((country) =>
							country.banks?.find((bank) => bank.bankKey === item?.bankKey)
						)
						?.find((obj) => obj != undefined)
						?.bankName.substring(0, 14) + '...'}
				</h1>
				<h1 className="bottomMarketItemText">
					{new Date(item?.date).toLocaleString('ru-RU').replaceAll(',', '')}
				</h1>
			</div>
			<div className="marketAssetItemRateContainer">
				<h1 className="upperMarketItemText">
					{formatNumber(Number(item?.amount), 2)} {item?.currencyCode}
				</h1>
			</div>
		</div>
	);
};

interface SavedAddressItemProps {
	idx: number;
	handleIsEditTemplateModalOpen: (template: TSavedAddress) => void;
	iconUrl: string;
	template: TSavedAddress;
}

const SavedAddressItem = ({
	iconUrl,
	idx,
	handleIsEditTemplateModalOpen,
	template,
}: SavedAddressItemProps) => {
	return (
		<div key={`cryptoTemplates_${idx}`} className="marketAssetItem">
			<img className="assetIcon" src={iconUrl} />
			<div className="bankSavedAddressItemNameContainer">
				<h1 className="upperMarketItemText">{template?.templateName}</h1>
				<h1 className="bottomMarketItemText">{template?.networkName}</h1>
			</div>
			<div className="bankSavedAddressItemEditContainer">
				<DepositIcons.SavedAddressEditIcon
					className=""
					onClick={() => handleIsEditTemplateModalOpen(template)}
				/>
			</div>
		</div>
	);
};

interface SavedCardItemProps {
	idx: number;
	handleIsEditTemplateModalOpen: (card: TSavedCard) => void;
	iconUrl: string;
	card: TSavedCard;
}

const SavedCardItem = ({
	iconUrl,
	idx,
	handleIsEditTemplateModalOpen,
	card,
}: SavedCardItemProps) => {
	return (
		<div key={`cryptoTemplates_${idx}`} className="marketAssetItem">
			<img className="assetIcon" src={iconUrl} />
			<div className="bankSavedAddressItemNameContainer">
				<h1 className="upperMarketItemText">{card?.templateName}</h1>
				<h1 className="bottomMarketItemText">{card?.cardNumber}</h1>
			</div>
			<div className="bankSavedAddressItemEditContainer">
				<DepositIcons.SavedAddressEditIcon
					className=""
					onClick={() => handleIsEditTemplateModalOpen(card)}
				/>
			</div>
		</div>
	);
};

interface SavedBankItemProps {
	idx: number;
	handleIsEditTemplateModalOpen: (bank: TSavedBank) => void;
	bank: TSavedBank;
}

const SavedBankItem = ({
	idx,
	handleIsEditTemplateModalOpen,
	bank,
}: SavedBankItemProps) => {
	return (
		<div key={`cryptoTemplates_${idx}`} className="bankSavedAddressItem">
			<div className="bankSavedAddressItemNameContainer">
				<h1 className="upperMarketItemText">{bank?.templateName}</h1>
				<h1 className="bottomMarketItemText">
					{bank?.params?.find((param) => param?.paramId === 'bankName')
						?.paramValue ?? ''}
				</h1>
			</div>
			<div className="bankSavedAddressItemEditContainer">
				<DepositIcons.SavedAddressEditIcon
					className=""
					onClick={() => handleIsEditTemplateModalOpen(bank)}
				/>
			</div>
		</div>
	);
};

interface UserActivityItemProps {
	item: TUserActivity;
	idx: number;
}

const UserActivityItem = ({ item, idx }: UserActivityItemProps) => {
	const { t } = useTranslation();

	const importantKeys = ['loginDateTime', 'ip', 'location', 'identityType'];

	const mapKeyToHeader = (key: string) => {
		if (key === 'loginDateTime') {
			return 'visitHistory_date';
		}
		if (key === 'identityType') {
			return 'visitHistory_authorisation';
		}
		if (key === 'location') {
			return 'visitHistory_location';
		}
		if (key === 'ip') {
			return 'visitHistory_ipAddress';
		}
		return '';
	};

	const mapKeyToValue = (key: string, item: TUserActivity) => {
		if (key === 'loginDateTime') {
			return new Date(item?.loginDateTime)?.toLocaleString();
		}
		return item[key as keyof TUserActivity];
	};

	return (
		<div className="commonContainer">
			<div key={idx} className="commonUserActivityItem">
				<div className="userActivityItemHeader">
					<UserActivityIcon type={item?.userDevice as string} />
					<h1 className="userActivityHeaderText">{item?.application}</h1>
				</div>

				<div key={idx} className="userActivityItem">
					<h1 className="userActivityItemKeyText">
						{t('visitHistory_loginResult')}
					</h1>
					<div className="visitHistoryValueContainer">
						<OperationStatusIcon
							status={item?.failed ? 'rejected' : 'done'}
							className="userActivityStatusIcon"
						/>
						<h1 className="userActivityItemValueText">
							{t(
								item?.failed
									? 'visitHistory_loginResult_failed'
									: 'visitHistory_loginResult_success'
							)}
						</h1>
					</div>
				</div>

				{Object.keys(item)?.map((key, idx) => (
					<>
						{importantKeys?.includes(key) && (
							<div key={idx} className="userActivityItem">
								<h1 className="userActivityItemKeyText">
									{t(mapKeyToHeader(key))}
								</h1>
								<div className="visitHistoryValueContainer">
									{key === 'failed' && (
										<OperationStatusIcon
											status={item?.failed ? 'rejected' : 'done'}
											className="operationIconInnerStatus"
										/>
									)}
									<h1 className="userActivityItemValueText">
										{mapKeyToValue(key, item)}
									</h1>
								</div>
							</div>
						)}
					</>
				))}
			</div>
		</div>
	);
};

interface UserDeviceItemProps {
	item: TUserDevice;
	idx: number;
	onClick: (item?: TUserDevice) => void;
}

const UserDeviceItem = ({ item, onClick, idx }: UserDeviceItemProps) => {
	const currentDeviceId = localStorage.getItem('deviceId');
	const { t } = useTranslation();

	return (
		<div
			onClick={() => onClick(item)}
			style={{
				gap: '8px',
			}}
			key={idx}
			className="commonItem"
		>
			<div
				style={{
					width: 'auto',
				}}
				className="userActivityItemHeader"
			>
				<UserDeviceIcon
					model={item?.deviceModel as string}
					type={item?.deviceOS as string}
				/>
			</div>

			<div className="bankSavedAddressItemNameContainer">
				<h1 className="upperMarketItemText">{item?.deviceModel}</h1>
				<h1 style={{
					fontSize: '12px'
				}} className="bottomMarketItemText">
					{item?.deviceId === currentDeviceId
						? t('devices_online')
						: `${t('devices_lastSession')} ${new Date(
								new Date(item?.lastLoginDate).getTime() >
								new Date(item?.lastQuickAccessDate).getTime()
									? item?.lastLoginDate
									: item?.lastQuickAccessDate
						  ).toLocaleString()}`}
				</h1>
			</div>
		</div>
	);
};

export {
	UserActivityItem,
	WalletItem,
	MarketAssetItem,
	WalletModalItem,
	SearchAssetItem,
	HistoryItem,
	InvoiceBankDepositItem,
	SavedAddressItem,
	SavedCardItem,
	SavedBankItem,
	ExchangeFromItem,
	UserDeviceItem,
};

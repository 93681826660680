import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../types/titles';
import WalletConvertSmallBalancesSummaryLayout from '../../../components/wallet/convert-small-summary';

const ConvertLowBalancesSummary = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.WALLET.CONVERT_LOW_BALANCES_SUMMARY}</title>
			</Helmet>
			<WalletConvertSmallBalancesSummaryLayout />
		</>
	);
};

export default ConvertLowBalancesSummary;

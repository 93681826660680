import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../../types/titles';
import PasswordRecoveryOTPLayout from '../../../../components/auth/recovery/otp';

const PasswordRecoveryOTP = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.AUTH.RECOVERY.CONFIRM_EMAIL}</title>
			</Helmet>
			<PasswordRecoveryOTPLayout />
		</>
	);
};

export default PasswordRecoveryOTP;
import { useEffect, useState } from 'react';
import {
	CardWithdrawAssetModal,
	CryptoDepositNetworkModal,
	CryptoWithdrawAssetModal,
	CryptoWithdrawHistoryModal,
	HistoryOperationDetailsModal,
	PaymentSystemModal,
	RejectOperationModal,
} from '..';
import { useGetDefaultHistory } from '../../../../data/mutations/history';
import {
	TBankWithdrawBodyParam,
	TBankWithdrawInputKeys,
	TBankWithdrawParamIds,
	TbankWithdrawParamsBodyInner,
	TInnerCryptoDeposit,
	TInnerCryptoWithdraw,
	TInnerCryptoWithdrawAsset,
	TInnerFiatWithdraw,
	TOperation,
	TSaveAddressTabs,
} from '../../../../types/types';
import { setCurrentOperation } from '../../../../services/history';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { CustomInput } from '../../inputs';
import { APIError } from '../../error';
import { CustomButton } from '../../buttons';
import { useTranslation } from 'react-i18next';
import { selectAccessToken } from '../../../../services/auth';
import {
	selectAssetIcons,
	selectDepositWithdrawAssetsDictionary,
} from '../../../../services/dictionaries';
import {
	selectCardWithdrawAsset,
	selectCardWithdrawCard,
	selectCardWithdrawIsCardOwner,
	setCardWithdrawAsset,
	setCardWithdrawCard,
	setCardWithdrawInit,
	setCardWithdrawIsCardOwner,
} from '../../../../services/withdraw/card.withdraw.slice';
import {
	useCheckCryptoWithdrawAddressValidation,
	useCreateBankWithdrawSavedBank,
	useCreateCardWithdrawSavedCard,
	useCreateCryptoWithdrawSavedAddresses,
	useCreateNewCardSettings,
	useGetBankWithdrawParams,
	useSendBankWithdrawParams,
} from '../../../../data/mutations/withdraw';
import { CustomCheckbox } from '../../checkboxes';
import { selectFullName } from '../../../../services/user';
import {
	selectCryptoWithdrawAddress,
	selectCryptoWithdrawAsset,
	selectCryptoWithdrawIsAddressValid,
	selectCryptoWithdrawNetwork,
	selectCryptoWithdrawSuitableNetworks,
	setCryptoWithdrawAsset,
	setCryptoWithdrawIsAddressValid,
	setCryptoWithdrawNetwork,
	setCryptoWithdrawSuitableNetworks,
	setSavedAddressCryptoWithdraw,
} from '../../../../services/withdraw/crypto.withdraw.slice';
import { useDebounce } from '../../../../hooks/useDebounce';
import {
	selectBankWithdrawAsset,
	selectBankWithdrawBankCode,
	selectBankWithdrawBankCountry,
	selectBankWithdrawBankName,
	selectBankWithdrawCorrAccount,
	selectBankWithdrawCorrBankCode,
	selectBankWithdrawCorrBankName,
	selectBankWithdrawIbanAccountNumber,
	selectBankWithdrawParams,
	selectBankWithdrawPaymentSystem,
	selectBankWithdrawTaxIdentNumber,
	setBankWithdrawAsset,
	setBankWithdrawInit,
	setBankWithdrawPaymentSystem,
	setIsInputRequired,
} from '../../../../services/withdraw/bank.withdraw.slice';
import { BankWithdrawInputContainers } from '../../containers';
import { useToast } from '../../../../hooks/useToast';

interface Props {
	isHistoryModalOpen: boolean;
	setIsHistoryModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const WithdrawHistoryModalContainer = ({
	isHistoryModalOpen,
	setIsHistoryModalOpen,
}: Props) => {
	const dispatch = useAppDispatch();

	const [isHistoryRejectModalOpen, setIsHistoryRejectModalOpen] =
		useState<boolean>(false);
	const [isHistoryOperationDetailsModalOpen, setIsHistoryOperationDetailsModalOpen] =
		useState<boolean>(false);

	const [operId, setOperId] = useState<string>('');

	const { getOperationDetails } = useGetDefaultHistory();

	const handleHistoryModalOpen = () => {
		setIsHistoryModalOpen(!isHistoryModalOpen);
	};

	const handleHistoryOperationDetailsModalOpen = () => {
		const tempIsHistoryOperationDetailsModalOpen =
			!isHistoryOperationDetailsModalOpen;
		setIsHistoryOperationDetailsModalOpen(tempIsHistoryOperationDetailsModalOpen);
		if (!tempIsHistoryOperationDetailsModalOpen) {
			setIsHistoryModalOpen(!isHistoryModalOpen);
		}
	};

	const handleHistoryRejectModalOpen = (operId?: string) => {
		if (operId) {
			setOperId(operId);
		}
		handleHistoryOperationDetailsModalOpen();
		setIsHistoryRejectModalOpen(!isHistoryRejectModalOpen);
	};

	const handleOperationItemClick = (i: TOperation) => {
		getOperationDetails(i.operaionid);
		setIsHistoryModalOpen(!isHistoryModalOpen);
		dispatch(setCurrentOperation(i));
		setIsHistoryOperationDetailsModalOpen(!isHistoryOperationDetailsModalOpen);
	};

	return (
		<>
			<CryptoWithdrawHistoryModal
				isModalOpen={isHistoryModalOpen}
				handleIsModalOpen={handleHistoryModalOpen}
				handleItemClick={handleOperationItemClick}
			/>
			<HistoryOperationDetailsModal
				isModalOpen={isHistoryOperationDetailsModalOpen}
				handleIsModalOpen={handleHistoryOperationDetailsModalOpen}
				handleRejectOperation={handleHistoryRejectModalOpen}
			/>
			<RejectOperationModal
				handleIsModalOpen={handleHistoryRejectModalOpen}
				isModalOpen={isHistoryRejectModalOpen}
				operId={operId}
			/>
		</>
	);
};

interface SaveCardTabModalContainerProps {
	isCommon: boolean | undefined;
	handleModalClose: (
		setIsNeedToSave?: React.Dispatch<React.SetStateAction<boolean>>
	) => void;
}

const SaveCardTabModalContainer = ({
	isCommon,
	handleModalClose,
}: SaveCardTabModalContainerProps) => {
	const selectedAsset = useAppSelector(selectCardWithdrawAsset);
	const isCardOwner = useAppSelector(selectCardWithdrawIsCardOwner);
	const assetIcons = useAppSelector(selectAssetIcons);
	const depositWithdrawAssetsDictionary = useAppSelector(
		selectDepositWithdrawAssetsDictionary
	)?.dictionary?.withdraw?.paymentSystem[0]?.cards;
	const savedCard = useAppSelector(selectCardWithdrawCard);
	const fullName = useAppSelector(selectFullName);
	const accessToken = useAppSelector(selectAccessToken);

	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const [templateName, setTemplateName] = useState<string>('');
	const [isTemplateNameRequired, setIsTemplateNameRequired] = useState<boolean>(false);

	const [cardHolderName, setCardHolderName] = useState<string>('');
	const [isCardHolderNameRequired, setIsCardHolderNameRequired] =
		useState<boolean>(false);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const [cardNumber, setCardNumber] = useState<string>('');
	const [isSavedCardRequired, setIsSavedCardRequired] = useState<boolean>(!isCommon);

	const handleTemplateNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTemplateName(e.target.value);
		setIsTemplateNameRequired(e.target.value?.length > 0);
	};

	const handleTemplateNameClearClick = () => {
		setTemplateName('');
		setIsTemplateNameRequired(false);
	};

	const [isError, setIsError] = useState<boolean>(false);
	const [isPending, setIsPending] = useState<boolean>(false);

	const {
		createCardWithdrawSavedCard,
		createWithdrawCardError,
		isCreateWithdrawCardError,
		isPending: isNotFromSettingsPending,
	} = useCreateCardWithdrawSavedCard(
		handleModalClose,
		useToast(t('savedAddresses_addressAdded'))
	);

	const {
		createNewCardSettings,
		isCreateNewCardSettingsError,
		createNewCardSettingsError,
		isPending: isFromSettingsLoading,
	} = useCreateNewCardSettings(
		handleModalClose,
		dispatch,
		useToast(t('savedAddresses_addressAdded'))
	);

	useEffect(() => {
		setIsError(isCreateNewCardSettingsError || isCreateWithdrawCardError);
	}, [isCreateNewCardSettingsError, isCreateWithdrawCardError]);

	useEffect(() => {
		setIsPending(isFromSettingsLoading || isNotFromSettingsPending);
	}, [isFromSettingsLoading, isNotFromSettingsPending]);

	useEffect(() => {
		if (isCommon) {
			dispatch(setCardWithdrawInit());
		}
	}, []);

	useEffect(() => {
		if (fullName) {
			setCardHolderName(fullName?.toUpperCase());
			setIsCardHolderNameRequired(fullName?.length > 0);
		}
	}, [fullName]);

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleItemClick = (i: TInnerFiatWithdraw) => {
		setIsModalOpen(!isModalOpen);
		dispatch(setCardWithdrawAsset(i));
	};

	const handleSavedCardChange = (e: React.ChangeEvent<HTMLInputElement> | string) => {
		if (typeof e === 'string' && !e.includes('*')) {
			dispatch(
				setCardWithdrawCard({
					cardNumber: e.replaceAll(' ', ''),
				})
			);
			setCardNumber(e.replaceAll(' ', ''));
			setIsSavedCardRequired(e?.length > 0);
		}
	};

	const handleCardHolderNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCardHolderName(e.target.value);
		setIsCardHolderNameRequired(e.target.value?.length > 0);
	};

	const handleCardHolderNameClearClick = () => {
		setCardHolderName('');
		setIsCardHolderNameRequired(false);
	};

	const handleIsCheckedIsCardOwnerChange = (eventValue: boolean) => {
		dispatch(setCardWithdrawIsCardOwner(eventValue));
	};

	const handleSubmit = () => {
		if (isCommon) {
			createNewCardSettings({
				accessToken: accessToken as string,
				dispatch: dispatch,
				templateName: templateName,
				cardholderName: isCommon ? '' : (savedCard?.cardholderName as string),
				cardNumber: isCommon ? cardNumber : (savedCard?.cardNumber as string),
				currencyCode: selectedAsset?.code as string,
				isCardholderCard: isCardOwner,
			});
		} else {
			createCardWithdrawSavedCard({
				accessToken: accessToken as string,
				dispatch: dispatch,
				templateName: templateName,
				cardholderName: isCommon ? '' : (savedCard?.cardholderName as string),
				cardNumber: isCommon ? cardNumber : (savedCard?.cardNumber as string),
				userCardId: savedCard?.userCardId as string,
			});
		}
	};

	return (
		<div className={'modalGenerateAccountStatementContent'}>
			<CardWithdrawAssetModal
				isModalOpen={isModalOpen}
				handleIsModalOpen={handleModalOpen}
				handleItemClick={handleItemClick}
			/>
			{isCommon && (
				<div className="depositAmountInputContainer">
					<CustomInput
						isRequired={selectedAsset ? true : false}
						isSelectAsset
						type="text"
						id="cardWithdrawSelectAsset"
						onClick={handleModalOpen}
						value={selectedAsset?.code as string}
						imgUrl={
							selectedAsset
								? assetIcons?.dictionary &&
								  assetIcons?.dictionary[
										selectedAsset?.code?.toUpperCase() as string
								  ]
								: ''
						}
						className="confirmFullWidthButton"
						label={t('depositCrypto_chooseDepositAsset') as string}
					/>
				</div>
			)}
			<div className="depositAmountInputContainer">
				<CustomInput
					isCardNumber={isCommon}
					isStartIcon
					isNonCloseIcon={isCommon}
					isDisabled={!isCommon}
					isRequired={isSavedCardRequired}
					onChange={handleSavedCardChange}
					type="text"
					id="cardWithdrawSaveCardNumber"
					value={isCommon ? cardNumber : (savedCard?.cardNumber as string)}
					imgUrl={
						isCommon
							? depositWithdrawAssetsDictionary?.find((system) =>
									cardNumber?.startsWith(String(system?.startNum[0]))
							  )?.icon
							: depositWithdrawAssetsDictionary?.find((system) =>
									savedCard?.cardNumber?.startsWith(
										String(system?.startNum[0])
									)
							  )?.icon
					}
					className="confirmFullWidthButton"
					label={t('general_cardNumber') as string}
				/>

				{isError && //eslint-disable-next-line
					(createNewCardSettingsError as any)?.code != 'WITHDRAW_DENYED' && (
						<h1 className="cryptoWithdrawAddressErrorText">
							{t('withdrawCard_checkBankCard')}
						</h1>
					)}
				{isCommon && (
					<div className="includeFeeContainer">
						<CustomCheckbox
							isError={!isCardOwner && cardNumber?.length === 16}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								handleIsCheckedIsCardOwnerChange(e.target.checked)
							}
							key={selectedAsset?.code}
							checked={isCardOwner}
						/>
						<h1
							className={
								'includeFeeText' +
								(!isCardOwner && cardNumber?.length === 16
									? ' includeFeeTextError'
									: '')
							}
						>
							{t('withdrawCard_bankCardConfirm')}
						</h1>
					</div>
				)}
			</div>
			{isCommon && (
				<div className="depositAmountInputContainer">
					<CustomInput
						isRequired={isCardHolderNameRequired}
						type="text"
						id="cardWithdrawSelectAsset"
						onChange={handleCardHolderNameChange}
						onClearClick={handleCardHolderNameClearClick}
						value={cardHolderName}
						className="confirmFullWidthButton"
						label={t('withdrawCard_cardholderName') as string}
					/>
				</div>
			)}
			<CustomInput
				isRequired={isTemplateNameRequired}
				type="text"
				id="cardWithdrawSaveTemplateName"
				value={templateName}
				onChange={handleTemplateNameChange}
				onClearClick={handleTemplateNameClearClick}
				className="confirmFullWidthButton"
				label={t('savedAddresses_cardName') as string}
			/>

			{isError && //eslint-disable-next-line
				(createNewCardSettingsError as any)?.code != 'WITHDRAW_DENYED' && (
					<APIError
						isError={isError}
						error={createNewCardSettingsError || createWithdrawCardError}
					/>
				)}
			<CustomButton
				text={t('savedAddresses_save') as string}
				type={'submit'}
				isLoading={isPending}
				isGrey={!isCommon}
				onClick={handleSubmit}
				className={'confirmFullWidthButton'}
				isDisabled={templateName.length < 1}
			/>
			{!isCommon && (
				<CustomButton
					text={t('general_cancel') as string}
					type={'submit'}
					onClick={handleModalClose}
					className={'confirmFullWidthButton'}
					isDisabled={false}
				/>
			)}
		</div>
	);
};

const SaveBankTabModalContainer = ({
	isCommon,
	handleModalClose,
}: SaveCardTabModalContainerProps) => {
	const selectedAsset = useAppSelector(selectBankWithdrawAsset);
	const ibanAccountNumber = useAppSelector(selectBankWithdrawIbanAccountNumber);
	const bankName = useAppSelector(selectBankWithdrawBankName);
	const taxIdentNumber = useAppSelector(selectBankWithdrawTaxIdentNumber);
	const corrAccount = useAppSelector(selectBankWithdrawCorrAccount);
	const paymentSystem = useAppSelector(selectBankWithdrawPaymentSystem);
	const bankCode = useAppSelector(selectBankWithdrawBankCode);
	const bankCountry = useAppSelector(selectBankWithdrawBankCountry);
	const corrBankCode = useAppSelector(selectBankWithdrawCorrBankCode);
	const corrBankName = useAppSelector(selectBankWithdrawCorrBankName);
	const bankParams = useAppSelector(selectBankWithdrawParams);

	const accessToken = useAppSelector(selectAccessToken);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isPaymentSystemModalOpen, setIsPaymentSystemModalOpen] =
		useState<boolean>(false);

	const [templateName, setTemplateName] = useState<string>('');
	const [isTemplateNameRequired, setIsTemplateNameRequired] = useState<boolean>(false);

	const {
		createBankWithdrawSavedBank,
		isCreateWithdrawCardError,
		isPending,
		createWithdrawCardError,
	} = useCreateBankWithdrawSavedBank(
		handleModalClose,
		useToast(t('savedAddresses_addressAdded'))
	);

	const { getBankWithdrawParams } = useGetBankWithdrawParams(dispatch);

	useEffect(() => {
		if (isCommon) {
			dispatch(setBankWithdrawInit());
			getBankWithdrawParams({
				accessToken: accessToken as string,
				dispatch: dispatch,
			});
		}
	}, []);

	const { sendBankWithdrawParams } = useSendBankWithdrawParams(dispatch);

	const handleTemplateNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTemplateName(e.target.value);
		setIsTemplateNameRequired(e.target.value?.length > 0);
	};

	const handleTemplateNameClearClick = () => {
		setTemplateName('');
		setIsTemplateNameRequired(false);
	};

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handlePaymentSystemModalOpen = () => {
		setIsPaymentSystemModalOpen(!isPaymentSystemModalOpen);
	};

	const handlePaymentSystemItemClick = (i: string) => {
		setIsPaymentSystemModalOpen(!isPaymentSystemModalOpen);
		dispatch(setBankWithdrawPaymentSystem(i));
	};

	const handleItemClick = (i: TInnerFiatWithdraw) => {
		setIsModalOpen(!isModalOpen);
		dispatch(setBankWithdrawAsset(i));
	};

	const mapParamIdToValue = (paramId: TBankWithdrawParamIds) => {
		switch (paramId) {
			case 'currencyCode':
				return selectedAsset?.code;
			case 'beneficiaryAccountNumber':
				return ibanAccountNumber;
			case 'bankCode':
				return bankCode;
			case 'paymentSystem':
				return paymentSystem;
			case 'bankName':
				return bankName;
			case 'bankCountry':
				return bankCountry;
			case 'taxIdentNumber':
				return taxIdentNumber;
			case 'corrAccount':
				return corrAccount;
			case 'corrBankCode':
				return corrBankCode;
			case 'corrBankName':
				return corrBankName;
			default:
				return '';
		}
	};

	const handleIsInputRequiredChange = (
		value: boolean,
		key: keyof TBankWithdrawInputKeys
	) => {
		dispatch(
			setIsInputRequired({
				key: key,
				value: value,
			})
		);
		return value;
	};

	const checkInputsRequired = () => {
		if (bankParams?.params) {
			const isChanged: boolean[] = bankParams?.params?.map((param) => {
				switch (param?.paramId) {
					case 'beneficiaryAccountNumber':
						return handleIsInputRequiredChange(
							ibanAccountNumber?.length === 0,
							'iban'
						);
					case 'bankCode':
						return handleIsInputRequiredChange(
							bankCode?.length === 0,
							'bankCode'
						);
					case 'bankName':
						return handleIsInputRequiredChange(
							bankName?.length === 0,
							'bankName'
						);
					case 'bankCountry':
						return handleIsInputRequiredChange(
							bankCountry?.length === 0,
							'bankCountry'
						);
					case 'taxIdentNumber':
						return handleIsInputRequiredChange(
							taxIdentNumber?.length === 0,
							'taxIdentNumber'
						);
					case 'corrAccount':
						return handleIsInputRequiredChange(
							corrAccount?.length === 0,
							'corrAccount'
						);
					case 'corrBankCode':
						return handleIsInputRequiredChange(
							corrBankCode?.length === 0,
							'corrBankCode'
						);
					case 'corrBankName':
						return handleIsInputRequiredChange(
							corrBankName?.length === 0,
							'corrBankName'
						);
					default:
						return false;
				}
			});
			return !isChanged?.some((value) => value);
		}
	};

	const handleSubmit = () => {
		if (checkInputsRequired()) {
			if (isCommon) {
				if (bankParams?.isDisplayAmount) {
					createBankWithdrawSavedBank({
						accessToken: accessToken as string,
						dispatch: dispatch,
						templateName: templateName,
						params: bankParams?.params?.map((param) => {
							return {
								paramId: param.paramId,
								paramName: param.paramName,
								paramValue: mapParamIdToValue(param?.paramId) as string,
							};
						}) as TBankWithdrawBodyParam[],
					});
				} else {
					sendBankWithdrawParams({
						stepId:
							bankParams?.params?.length === 2
								? 'findBankByCode'
								: undefined,
						params: bankParams?.params?.map((param) => {
							return {
								paramId: param.paramId,
								paramValue: mapParamIdToValue(param?.paramId) as string,
								isNew: true,
							};
						}) as TbankWithdrawParamsBodyInner[],
						dispatch: dispatch,
						accessToken: accessToken as string,
					});
				}
			} else {
				createBankWithdrawSavedBank({
					accessToken: accessToken as string,
					dispatch: dispatch,
					templateName: templateName,
					params: bankParams?.params?.map((param) => {
						return {
							paramId: param.paramId,
							paramName: param.paramName,
							paramValue: mapParamIdToValue(param?.paramId) as string,
						};
					}) as TBankWithdrawBodyParam[],
				});
			}
		}
	};

	return (
		<div
			style={{
				overflow: 'scroll',
			}}
			className={'modalGenerateAccountStatementContent'}
		>
			<CardWithdrawAssetModal
				isBankDeposit
				isModalOpen={isModalOpen}
				handleIsModalOpen={handleModalOpen}
				handleItemClick={handleItemClick}
			/>
			<PaymentSystemModal
				isModalOpen={isPaymentSystemModalOpen}
				handleIsModalOpen={handlePaymentSystemModalOpen}
				handleItemClick={handlePaymentSystemItemClick}
			/>
			<BankWithdrawInputContainers
				isCommon
				handleModalOpen={handleModalOpen}
				handlePaymentSystemModalOpen={handlePaymentSystemModalOpen}
			/>
			<CustomInput
				isRequired={isTemplateNameRequired}
				type="text"
				id="cardWithdrawSaveTemplateName"
				value={templateName}
				onChange={handleTemplateNameChange}
				onClearClick={handleTemplateNameClearClick}
				className="confirmFullWidthButton"
				label={t('savedAddresses_addressName') as string}
			/>
			<APIError
				isError={isCreateWithdrawCardError}
				error={createWithdrawCardError}
			/>

			<CustomButton
				isLoading={isPending}
				text={
					isCommon
						? (t(
								bankParams?.isDisplayAmount
									? 'savedAddresses_save'
									: 'login_continue'
						  ) as string)
						: (t('savedAddresses_save') as string)
				}
				type={'submit'}
				onClick={handleSubmit}
				isGrey={!isCommon}
				className="confirmFullWidthButton"
				isDisabled={templateName.length < 1}
			/>
			{!isCommon && (
				<CustomButton
					text={t('general_cancel') as string}
					type={'submit'}
					onClick={handleModalClose}
					className={'confirmFullWidthButton'}
					isDisabled={false}
				/>
			)}
		</div>
	);
};

const SaveAddressTabModalContainer = ({
	isCommon,
	handleModalClose,
}: SaveCardTabModalContainerProps) => {
	const selectedAsset = useAppSelector(selectCryptoWithdrawAsset);
	const suitableNetworks = useAppSelector(selectCryptoWithdrawSuitableNetworks);
	const isAddressValid = useAppSelector(selectCryptoWithdrawIsAddressValid);
	const accessToken = useAppSelector(selectAccessToken);
	const depositWithdrawAssetsDictionary = useAppSelector(
		selectDepositWithdrawAssetsDictionary
	)?.dictionary?.withdraw?.crypto;
	const selectedNetwork = useAppSelector(selectCryptoWithdrawNetwork);
	const address = useAppSelector(selectCryptoWithdrawAddress);
	const assetIcons = useAppSelector(selectAssetIcons);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const [templateName, setTemplateName] = useState<string>('');
	const [isTemplateNameRequired, setIsTemplateNameRequired] = useState<boolean>(false);

	const [isSavedAddressRequired, setIsSavedAddressRequired] = useState<boolean>(false);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isNetworkModalOpen, setIsNetworkModalOpen] = useState<boolean>(false);

	const { createCryptoWithdrawSavedAddresses, isError, error, isPending } =
		useCreateCryptoWithdrawSavedAddresses(
			handleModalClose,
			useToast(t('savedAddresses_addressAdded'))
		);

	const { checkAddressValidation, isCheckAddressValidationPending } =
		useCheckCryptoWithdrawAddressValidation(dispatch);

	const debouncedAddressValue = useDebounce(address, 500);

	useEffect(() => {
		if (
			debouncedAddressValue?.length > 1 &&
			selectedAsset != undefined &&
			selectedNetwork != undefined
		) {
			checkAddressValidation({
				accessToken: accessToken as string,
				networkName: selectedNetwork?.networkName as string,
				cryptoAddress: debouncedAddressValue,
				dispatch: dispatch,
			});
		}
	}, [debouncedAddressValue]);

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleNetworkModalOpen = () => {
		setIsNetworkModalOpen(!isNetworkModalOpen);
	};

	const handleTemplateNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTemplateName(e.target.value);
		setIsTemplateNameRequired(e.target.value?.length > 0);
	};

	const handleTemplateNameClearClick = () => {
		setTemplateName('');
		setIsTemplateNameRequired(false);
	};

	const handleItemClick = (i: TInnerCryptoWithdrawAsset, networkCode?: string) => {
		setIsModalOpen(!isModalOpen);
		dispatch(setCryptoWithdrawAsset(i));
		const tempSelectedNetwork = depositWithdrawAssetsDictionary?.find(
			(network) => network.networkCode === networkCode
		);
		const tempSuitableNetworks = depositWithdrawAssetsDictionary?.filter((network) =>
			network.assets.find((asset) => asset.code === i?.code)
		);
		dispatch(setCryptoWithdrawNetwork(tempSelectedNetwork));
		dispatch(setCryptoWithdrawSuitableNetworks(tempSuitableNetworks));
	};

	const handleNetworkItemClick = (i: TInnerCryptoWithdraw | TInnerCryptoDeposit) => {
		setIsNetworkModalOpen(!isNetworkModalOpen);
		dispatch(setCryptoWithdrawNetwork(i as TInnerCryptoWithdraw));
	};

	const handleSavedAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setSavedAddressCryptoWithdraw(e.target.value));
		dispatch(setCryptoWithdrawIsAddressValid('valid'));
		setIsSavedAddressRequired(e.target.value?.length > 0);
	};
	const handleSavedAddressClearClick = () => {
		dispatch(setSavedAddressCryptoWithdraw(''));
		dispatch(setCryptoWithdrawIsAddressValid('valid'));
		setIsSavedAddressRequired(false);
	};

	const handleSubmit = () => {
		createCryptoWithdrawSavedAddresses({
			accessToken: accessToken as string,
			dispatch: dispatch,
			templateName: templateName,
			cryptoAddress: address,
			networkName: selectedNetwork?.networkName as string,
		});
	};

	return (
		<div className={'modalGenerateAccountStatementContent'}>
			<CryptoWithdrawAssetModal
				isModalOpen={isModalOpen}
				handleIsModalOpen={handleModalOpen}
				handleItemClick={handleItemClick}
			/>
			<CryptoDepositNetworkModal
				suitableNetworks={suitableNetworks}
				isModalOpen={isNetworkModalOpen}
				handleIsModalOpen={handleNetworkModalOpen}
				handleItemClick={handleNetworkItemClick}
				currentItem={selectedNetwork}
			/>
			{isCommon && (
				<div className="depositAmountInputContainer">
					<CustomInput
						isRequired={selectedAsset ? true : false}
						isSelectAsset
						type="text"
						id="cryptoWithdrawSelectAsset"
						onClick={handleModalOpen}
						value={selectedAsset?.code as string}
						imgUrl={
							selectedAsset
								? assetIcons?.dictionary &&
								  assetIcons?.dictionary[
										selectedAsset?.code?.toUpperCase() as string
								  ]
								: ''
						}
						className="confirmFullWidthButton"
						label={t('depositCrypto_chooseDepositAsset') as string}
					/>
				</div>
			)}
			<div className="depositAmountInputContainer">
				<CustomInput
					isRequired={selectedAsset != undefined}
					isSelectAsset
					isDisabled={
						suitableNetworks?.length === 1 ||
						selectedAsset === undefined ||
						!isCommon
					}
					type="text"
					id="cryptoWithdrawSelectNetwork"
					onClick={handleNetworkModalOpen}
					value={selectedNetwork?.networkCode as string}
					imgUrl={''}
					className="confirmFullWidthButton"
					label={t('general_network') as string}
				/>
			</div>
			<div className="depositAmountInputContainer">
				<CustomInput
					isError={!isAddressValid}
					isDisabled={!isCommon}
					label={t('withdrawCrypto_cryptoAddress') as string}
					className="confirmFullWidthButton"
					type="text"
					id="cryptoWithdrawAddress"
					onChange={handleSavedAddressChange}
					onClearClick={handleSavedAddressClearClick}
					isRequired={isSavedAddressRequired || address?.length > 0}
					value={address}
				/>
				{isCommon && !isAddressValid && (
					<h1 className="cryptoWithdrawAddressErrorText">
						{t('withdrawCrypto_checkAddress')}
					</h1>
				)}
			</div>
			<CustomInput
				isRequired={isTemplateNameRequired}
				type="text"
				id="cardWithdrawSaveTemplateName"
				value={templateName}
				onChange={handleTemplateNameChange}
				onClearClick={handleTemplateNameClearClick}
				className="confirmFullWidthButton"
				label={t('savedAddresses_addressName') as string}
			/>
			<APIError isError={isError} error={error} />
			<CustomButton
				text={t('savedAddresses_save') as string}
				type={'submit'}
				isLoading={isPending}
				isGrey={!isCommon}
				onClick={handleSubmit}
				className={'confirmFullWidthButton'}
				isDisabled={
					templateName.length < 1 ||
					isCheckAddressValidationPending ||
					!isAddressValid
				}
			/>
			{!isCommon && (
				<CustomButton
					text={t('general_cancel') as string}
					type={'submit'}
					onClick={handleModalClose}
					className={'confirmFullWidthButton'}
					isDisabled={false}
				/>
			)}
		</div>
	);
};

interface SavedAddressesTabModalContainerProps {
	tab: TSaveAddressTabs;
	isCommon?: boolean;
	handleModalClose: (
		setIsNeedToSave?: React.Dispatch<React.SetStateAction<boolean>>
	) => void;
}

const SavedAddressesTabModalContainer = ({
	tab,
	isCommon,
	handleModalClose,
}: SavedAddressesTabModalContainerProps) => {
	if (tab === 'card') {
		return (
			<SaveCardTabModalContainer
				isCommon={isCommon}
				handleModalClose={handleModalClose}
			/>
		);
	}

	if (tab === 'crypto') {
		return (
			<SaveAddressTabModalContainer
				isCommon={isCommon}
				handleModalClose={handleModalClose}
			/>
		);
	}

	if (tab === 'bank') {
		return (
			<SaveBankTabModalContainer
				isCommon={isCommon}
				handleModalClose={handleModalClose}
			/>
		);
	}

	return <></>;
};

export { WithdrawHistoryModalContainer, SavedAddressesTabModalContainer };

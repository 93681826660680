import { useEffect, useState } from 'react';
import {
	useGetBankWithdrawCommission,
	useGetBankWithdrawParams,
	useSendBankWithdrawParams,
} from '../../../data/mutations/withdraw';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectAccessToken } from '../../../services/auth';
import { HeaderLayout } from '../../common/header';
import { formatNumber, mapClassnameForMobile } from '../../../utils';
import { useTranslation } from 'react-i18next';
import {
	selectBankWithdrawAmount,
	selectBankWithdrawAsset,
	selectBankWithdrawBankCode,
	selectBankWithdrawBankCountry,
	selectBankWithdrawBankName,
	selectBankWithdrawCorrAccount,
	selectBankWithdrawCorrBankCode,
	selectBankWithdrawCorrBankName,
	selectBankWithdrawFee,
	selectBankWithdrawIbanAccountNumber,
	selectBankWithdrawIsBankCountryAvailable,
	selectBankWithdrawIsChecked,
	selectBankWithdrawParams,
	selectBankWithdrawPaymentSystem,
	selectBankWithdrawTaxIdentNumber,
	selectIsBankWithdrawInited,
	setBankWithdrawAsset,
	setBankWithdrawIban,
	setBankWithdrawInit,
	setBankWithdrawIsInited,
	setBankWithdrawParams,
	setBankWithdrawPaymentSystem,
	setIsInputRequired,
} from '../../../services/withdraw/bank.withdraw.slice';
import { ROUTES } from '../../../types/routes';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { selectDepositWithdrawAssetsDictionary } from '../../../services/dictionaries';
import { CustomButton } from '../../common/buttons';
import { APIError } from '../../common/error';
import {
	TAsset,
	TBankWithdrawInputKeys,
	TBankWithdrawParamIds,
	TbankWithdrawParamsBodyInner,
	TInnerFiatWithdraw,
	TSavedBank,
} from '../../../types/types';
import {
	BankWithdrawEditSavedAddressModal,
	BankWithdrawSavedBankModal,
	CardWithdrawAssetModal,
	PaymentSystemModal,
} from '../../common/modals';
import { selectMainAccountRests } from '../../../services/wallet';
import { WithdrawHistoryModalContainer } from '../../common/modals/containers';
import { useDebounce } from '../../../hooks/useDebounce';
import { setBankWithdrawCurrentSavedBank } from '../../../services/withdraw/templates.withdraw.slice';
import { BankWithdrawInputContainers } from '../../common/containers';

const BankWithdrawLayout = () => {
	const isBankWithdrawInited = useAppSelector(selectIsBankWithdrawInited);
	const selectedAsset = useAppSelector(selectBankWithdrawAsset);
	const ibanAccountNumber = useAppSelector(selectBankWithdrawIbanAccountNumber);
	const bankName = useAppSelector(selectBankWithdrawBankName);
	const taxIdentNumber = useAppSelector(selectBankWithdrawTaxIdentNumber);
	const corrAccount = useAppSelector(selectBankWithdrawCorrAccount);
	const corrBankCode = useAppSelector(selectBankWithdrawCorrBankCode);
	const corrBankName = useAppSelector(selectBankWithdrawCorrBankName);
	const paymentSystem = useAppSelector(selectBankWithdrawPaymentSystem);
	const bankCode = useAppSelector(selectBankWithdrawBankCode);
	const bankCountry = useAppSelector(selectBankWithdrawBankCountry);
	const bankParams = useAppSelector(selectBankWithdrawParams);
	const mainCurrencies = useAppSelector(selectMainAccountRests);
	const amount = useAppSelector(selectBankWithdrawAmount);
	const isChecked = useAppSelector(selectBankWithdrawIsChecked);
	const fee = useAppSelector(selectBankWithdrawFee);

	const depositWithdrawAssetsDictionary = useAppSelector(
		selectDepositWithdrawAssetsDictionary
	)?.dictionary?.withdraw?.fiat[0]?.bank;
	const selectBankCountry = useAppSelector(selectBankWithdrawIsBankCountryAvailable);
	const accessToken = useAppSelector(selectAccessToken);

	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isHistoryModalOpen, setIsHistoryModalOpen] = useState<boolean>(false);
	const [isSavedAddressModalOpen, setIsSavedAddressModalOpen] =
		useState<boolean>(false);
	const [isEditSavedBankModalOpen, setIsEditSavedBankModalOpen] =
		useState<boolean>(false);
	const [isPaymentSystemModalOpen, setIsPaymentSystemModalOpen] =
		useState<boolean>(false);

	const [mainAsset, setMainAsset] = useState<TAsset | undefined>();
	const [minValue, setMinValue] = useState<number | undefined>(0);

	const { getBankWithdrawParams } = useGetBankWithdrawParams(dispatch);
	const {
		sendBankWithdrawParams,
		isSendParamsError,
		isSendParamsPending,
		sendParamsError,
	} = useSendBankWithdrawParams(dispatch);
	const { getBankWithdrawCommission } = useGetBankWithdrawCommission(dispatch);

	const [isError, setIsError] = useState<boolean>(false);
	const [isPending, setIsPending] = useState<boolean>(false);

	const debouncedValue = useDebounce(amount, 500);

	useEffect(() => {
		if (Number(debouncedValue) > 0 && selectedAsset != undefined) {
			getBankWithdrawCommission({
				accessToken: accessToken as string,
				asset: selectedAsset?.code as string,
				amount: debouncedValue,
				bankCountryCode: selectBankCountry?.valueList
					? (selectBankCountry?.valueList[0] as string)
					: bankCountry,
				dispatch: dispatch,
			});
		}
	}, [debouncedValue]);

	useEffect(() => {
		setIsPending(isSendParamsPending);
	}, [isSendParamsPending]);

	useEffect(() => {
		setIsError(isSendParamsError);
	}, [isSendParamsError]);

	useEffect(() => {
		if (!isBankWithdrawInited && bankParams === undefined) {
			getBankWithdrawParams({
				dispatch: dispatch,
				accessToken: accessToken as string,
			});
		}
		if (selectedAsset) {
			setMainAsset(
				mainCurrencies?.assets?.find(
					(asset) => asset?.code === selectedAsset?.code
				)
			);
			setMinValue(selectedAsset?.min);
		}
	}, []);

	const handleBackClickButton = () => {
		dispatch(setBankWithdrawInit());
		getBankWithdrawParams({
			dispatch: dispatch,
			accessToken: accessToken as string,
		});
		if (bankParams?.params && bankParams?.params?.length === 2) {
			navigate(ROUTES.WITHDRAW.INDEX);
		}
	};

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleSavedAddressModalOpen = () => {
		setIsSavedAddressModalOpen(!isSavedAddressModalOpen);
	};

	const handleHistoryModalOpen = () => {
		setIsHistoryModalOpen(!isHistoryModalOpen);
	};

	const handlePaymentSystemModalOpen = () => {
		setIsPaymentSystemModalOpen(!isPaymentSystemModalOpen);
	};

	const handlePaymentSystemItemClick = (i: string) => {
		setIsPaymentSystemModalOpen(!isPaymentSystemModalOpen);
		dispatch(setBankWithdrawPaymentSystem(i));
	};

	const handleEditSavedBankModalOpen = (i?: TSavedBank | undefined) => {
		if (i) {
			dispatch(setBankWithdrawCurrentSavedBank(i));
		}
		setIsEditSavedBankModalOpen(!isEditSavedBankModalOpen);
	};

	const handleItemClick = (i: TInnerFiatWithdraw) => {
		setIsModalOpen(!isModalOpen);
		dispatch(setBankWithdrawAsset(i));
		setMinValue(i?.min);
		setMainAsset(mainCurrencies?.assets?.find((asset) => asset?.code === i?.code));
	};

	const handleNavigateClick = () => {
		handleHistoryModalOpen();
	};

	const handleSavedBanksClick = (i: TSavedBank) => {
		handleSavedAddressModalOpen();
		dispatch(
			setBankWithdrawParams({
				params: i?.params?.filter(
					(param) =>
						param?.paramId === 'currencyCode' ||
						param?.paramId === 'beneficiaryAccountNumber'
				),
			})
		);

		const tempIban = i?.params?.find(
			(param) => param?.paramId === 'beneficiaryAccountNumber'
		)?.paramValue;

		if (tempIban) {
			dispatch(setBankWithdrawIban(tempIban));
		}

		const tempSavedBank = i?.params?.filter(
			(param) =>
				param?.paramId === 'currencyCode' ||
				param?.paramId === 'beneficiaryAccountNumber'
		);

		const tempAsset = depositWithdrawAssetsDictionary?.find(
			(asset) =>
				asset?.code ===
				tempSavedBank?.find((param) => param?.paramId === 'currencyCode')
					?.paramValue
		);

		dispatch(setBankWithdrawAsset(tempAsset));
		setMinValue(tempAsset?.min);
		setMainAsset(
			mainCurrencies?.assets?.find((asset) => asset?.code === tempAsset?.code)
		);
	};

	const mapParamIdToValue = (paramId: TBankWithdrawParamIds) => {
		switch (paramId) {
			case 'currencyCode':
				return selectedAsset?.code;
			case 'beneficiaryAccountNumber':
				return ibanAccountNumber;
			case 'bankCode':
				return bankCode;
			case 'paymentSystem':
				return paymentSystem;
			case 'bankName':
				return bankName;
			case 'bankCountry':
				return bankCountry;
			case 'taxIdentNumber':
				return taxIdentNumber;
			case 'corrAccount':
				return corrAccount;
			case 'corrBankCode':
				return corrBankCode;
			case 'corrBankName':
				return corrBankName;
			default:
				return '';
		}
	};

	const handleIsInputRequiredChange = (
		value: boolean,
		key: keyof TBankWithdrawInputKeys
	) => {
		dispatch(
			setIsInputRequired({
				key: key,
				value: value,
			})
		);
		return value;
	};

	const checkInputsRequired = () => {
		if (bankParams?.params) {
			const isChanged: boolean[] = bankParams?.params?.map((param) => {
				switch (param?.paramId) {
					case 'beneficiaryAccountNumber':
						return handleIsInputRequiredChange(
							ibanAccountNumber?.length === 0,
							'iban'
						);
					case 'bankCode':
						return handleIsInputRequiredChange(
							bankCode?.length === 0,
							'bankCode'
						);
					case 'bankName':
						return handleIsInputRequiredChange(
							bankName?.length === 0,
							'bankName'
						);
					case 'bankCountry':
						return handleIsInputRequiredChange(
							bankCountry?.length === 0,
							'bankCountry'
						);
					case 'taxIdentNumber':
						return handleIsInputRequiredChange(
							taxIdentNumber?.length === 0,
							'taxIdentNumber'
						);
					case 'corrAccount':
						return handleIsInputRequiredChange(
							corrAccount?.length === 0,
							'corrAccount'
						);
					case 'corrBankCode':
						return handleIsInputRequiredChange(
							corrBankCode?.length === 0,
							'corrBankCode'
						);
					case 'corrBankName':
						return handleIsInputRequiredChange(
							corrBankName?.length === 0,
							'corrBankName'
						);
					default:
						return false;
				}
			});
			return !isChanged?.some((value) => value);
		}
	};

	const handleSubmit = () => {
		if (checkInputsRequired()) {
			if (bankParams?.isDisplayAmount) {
				dispatch(setBankWithdrawIsInited(true))
				navigate(ROUTES.WITHDRAW.BANK.CONFIRMATION);
			} else {
				sendBankWithdrawParams({
					stepId:
						bankParams?.params?.length === 2 ? 'findBankByCode' : undefined,
					params: bankParams?.params?.map((param) => {
						return {
							paramId: param.paramId,
							paramValue: mapParamIdToValue(param?.paramId) as string,
							isNew: true,
						};
					}) as TbankWithdrawParamsBodyInner[],
					dispatch: dispatch,
					accessToken: accessToken as string,
				});
			}
		}
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout
					isInvoiceHistory
					handleBackClick={handleBackClickButton}
					handleNavigateClick={handleNavigateClick}
				/>
				<CardWithdrawAssetModal
					isBankDeposit
					isModalOpen={isModalOpen}
					handleIsModalOpen={handleModalOpen}
					handleItemClick={handleItemClick}
				/>
				<PaymentSystemModal
					isModalOpen={isPaymentSystemModalOpen}
					handleIsModalOpen={handlePaymentSystemModalOpen}
					handleItemClick={handlePaymentSystemItemClick}
				/>
				<WithdrawHistoryModalContainer
					isHistoryModalOpen={isHistoryModalOpen}
					setIsHistoryModalOpen={setIsHistoryModalOpen}
				/>

				<BankWithdrawSavedBankModal
					handleEditSavedBankModalOpen={handleEditSavedBankModalOpen}
					isModalOpen={isSavedAddressModalOpen}
					handleIsModalOpen={handleSavedAddressModalOpen}
					handleItemClick={handleSavedBanksClick}
				/>
				<BankWithdrawEditSavedAddressModal
					isModalOpen={isEditSavedBankModalOpen}
					handleIsModalOpen={handleEditSavedBankModalOpen}
				/>
				<div
					style={{
						marginBottom: '128px',
						overflow: 'unset !important',
					}}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div
						style={{
							justifyContent: 'flex-start',
							marginBottom: '128px',
						}}
						className="innerContainer"
					>
						<h1 className="innerContainerMainText">
							{t('withdrawBank_title')}
						</h1>
						<BankWithdrawInputContainers
							isWithdraw
							mainAsset={mainAsset}
							minValue={minValue}
							handleModalOpen={handleModalOpen}
							handleSavedAddressModalOpen={handleSavedAddressModalOpen}
							handlePaymentSystemModalOpen={handlePaymentSystemModalOpen}
						/>
						<APIError isError={isError} error={sendParamsError} />
					</div>
					{bankParams?.isDisplayAmount ? (
						<div className="withdrawCryptoButtonsBottomContainer">
							<div className="withdrawButtonsBottomSeparator" />
							<div className="withdrawCryptoButtonsBottomInnerContainer">
								<div className="withdrawCryptoBottomAmountContainer">
									<h1 className="withdrawCryptoBottomAmountContainerHeader">
										{t('withdraw_totalWithdraw')}
									</h1>
									<h1
										className={
											'withdrawCryptoBottomAmountContainerMain' +
											(amount?.length >= 10
												? ' withdrawCryptoBottomAmountContainerMainSmallerText'
												: ' ')
										}
									>
										{formatNumber(
											isChecked
												? Number(amount) + Number(fee)
												: Number(amount),
											mainAsset?.precision
										)}
										{selectedAsset === undefined
											? ''
											: ` ${selectedAsset?.code}`}
									</h1>
									<h1 className="withdrawCryptoBottomAmountContainerBottom">
										{`${t('withdraw_includingFee')}: `}
										{formatNumber(Number(fee), mainAsset?.precision)}
										{selectedAsset === undefined
											? ''
											: ` ${selectedAsset?.code}`}
									</h1>
								</div>
								<CustomButton
									isDisabled={
										(isChecked
											? Number(amount) + Number(fee)
											: Number(amount)) >
											Number(mainAsset?.available) ||
										amount.length === 0 ||
										Number(amount) < Number(minValue) ||
										mainAsset === undefined ||
										selectedAsset === undefined
									}
									isLoading={isPending}
									text={t('wallet_withdraw') as string}
									onClick={handleSubmit}
									className="withdrawCryptoBottomButtonContainer"
								/>
							</div>
						</div>
					) : (
						<div className="depositButtonsBottomContainer">
							<CustomButton
								isDisabled={selectedAsset === undefined}
								isLoading={isPending}
								text={
									t(
										bankParams?.isDisplayAmount
											? 'wallet_withdraw'
											: 'login_continue'
									) as string
								}
								onClick={handleSubmit}
								className="confirmFullWidthButton"
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default BankWithdrawLayout;

import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { selectIsTelegram } from '../../../../../services/root';
import { selectAccessToken } from '../../../../../services/auth';
import {
	selectSecuritySelectedIdentityType,
	selectSecurityState,
	selectTwoFaOptionsCurrenMethodType,
	selectTwoFaSecretKey,
} from '../../../../../services/security';
import { useCustomNavigate } from '../../../../../hooks/useCustomNavigate';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../../../types/routes';
import { mapClassnameForMobile } from '../../../../../utils';
import { CustomButton } from '../../../../common/buttons';
import { PinInput } from '../../../../common/inputs';
import { APIError } from '../../../../common/error';
import { usePinInput } from 'react-pin-input-hook';
import {
	useDisableTwoFaMutation,
	useEnableTwoFaMutation,
} from '../../../../../data/mutations/profile';

const SecurityTwoFaLayout = () => {
	const state = useAppSelector(selectSecurityState);
	const selectedIdentity = useAppSelector(selectSecuritySelectedIdentityType);
	const accessToken = useAppSelector(selectAccessToken);
	const isTelegram = useAppSelector(selectIsTelegram);
	const currentMethodType = useAppSelector(selectTwoFaOptionsCurrenMethodType);
	const secretKey = useAppSelector(selectTwoFaSecretKey);

	const dispatch = useAppDispatch();
	const [fakeError, setFakeError] = useState<boolean>(false);

	const mapTwoFaTypeToMethod = (values: string) => {
		if (currentMethodType === 'enable') {
			enableTwoFaMutation({
				accessToken: accessToken as string,
				dispatch: dispatch,
				identity: '',
				key: secretKey,
				identityType: selectedIdentity,
				code: values,
				navigate: navigate,
			});
		} else if (currentMethodType === 'disable') {
			disableTwoFaMutation({
				accessToken: accessToken as string,
				dispatch: dispatch,
				navigate: navigate,
				twoFA: {
					state: state,
					code: values,
					identityType: selectedIdentity as string,
				},
				identityType: selectedIdentity as string,
			});
		}
	};

	const [values, setValues] = useState<string[]>(['', '', '', '', '', '']);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
			setFakeError(false);
		},
		onComplete: (values: string) => {
			if (values.length == 6) {
				mapTwoFaTypeToMethod(values);
			}
		},
		type: 'numeric',
		placeholder: '',
	});

	const navigate = useCustomNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		if (selectedIdentity.length === 0) {
			navigate(ROUTES.PROFILE.SECURITY.TWO_FA.INDEX);
		}
	}, []);

	const { disableTwoFaMutation, disableError, isDisableError, isDisablePending } =
		useDisableTwoFaMutation(navigate);
	const { enableTwoFaMutation, enableError, isEnableError, isEnablePending } =
		useEnableTwoFaMutation(navigate);

	useEffect(() => {
		if (isDisableError || isEnableError) {
			setFakeError(isDisableError || isEnableError);
			setValues(['', '', '', '', '', '']);
		}
	}, [isDisableError, isEnableError]);
	const handleBackClickButton = () => {
		navigate(ROUTES.PROFILE.SECURITY.TWO_FA.INDEX);
	};

	const handlePasteClick = async () => {
		const tempClipboardContent = await navigator.clipboard.readText();

		if (
			tempClipboardContent &&
			tempClipboardContent != '' &&
			tempClipboardContent.length === 6 &&
			!isNaN(Number(tempClipboardContent))
		) {
			setValues(tempClipboardContent.split(''));
			setFakeError(false);
			mapTwoFaTypeToMethod(tempClipboardContent);
		}
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<div className={mapClassnameForMobile('walletLayout authMainContainer')}>
					<div className="innerContainer">
						<CustomButton
							isBack={true}
							onClick={handleBackClickButton}
							className={'backButton'}
						/>
						<h1 className={'confirmEmailText'}>
							{t('twoFactorAuthCode_title')}
						</h1>
						<h1 className={'confirmCodeText'}>
							{t('twoFactorAuthCode_pleaseEnter6DigitCode')}
						</h1>
						<div className={'codeInputBlock'}>
							<PinInput
								isLoading={isDisablePending || isEnablePending}
								isError={fakeError}
								fields={fields}
							/>
						</div>
						<APIError
							isError={fakeError}
							error={disableError || enableError}
						/>
					</div>
				</div>
				{!isTelegram && (
					<div className="pasteButtonContainer">
						<CustomButton
							isGrey
							text={t('general_pasteCode') as string}
							onClick={handlePasteClick}
							className="confirmFullWidthButton"
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default SecurityTwoFaLayout;

import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '../../store';

export const selectLoginData = (state: TRootState) => state.authentication.loginData;

export const selectPasswordRecoveryEmail = (state: TRootState) => state.authentication.passwordRecoveryEmail;

export const selectIdentityTypes = (state: TRootState) =>
	state.authentication.identityTypes;

export const selectSelectedIdentityType = (state: TRootState) =>
	state.authentication.selectedIdentityType;

export const selectState = (state: TRootState) => state.authentication.loginData?.state;

export const selectInputLength = (state: TRootState) => state.authentication.inputLength;

export const selectIsAuthenticated = (state: TRootState) =>
	state.authentication.isAuthenticated;

export const selectAccessToken = (state: TRootState) => state.authentication.accessToken;

export const selectSecretKey = (state: TRootState) => state.authentication.secretKey;

export const selectQrCodePath = (state: TRootState) => state.authentication.qrCodePath;

export const selectIsEnablingTwoFa = (state: TRootState) =>
	state.authentication.isEnablingTwoFa;

export const selectRefreshToken = (state: TRootState) =>
	state.authentication.refreshToken;

export const selectSuccessfulEmail = () => JSON.parse(
	localStorage.getItem(localStorage.getItem('userId') as string) as string
)?.email != undefined
	? JSON.parse(
		localStorage.getItem(
			localStorage.getItem('userId') as string
		) as string
	)?.email
	: ''

interface IAuthenticationState {
	isAuthenticated: boolean | null;
	accessToken: string | null;
	loginData:
	| {
		login: string;
		password: string;
		state: string;
	}
	| undefined;
	inputLength: number;
	identityTypes: { key: string; value: string }[];
	selectedIdentityType: string;
	[key: string]: unknown;
	secretKey: string;
	qrCodePath: string;
	isEnablingTwoFa: boolean;
	refreshToken: string;
	passwordRecoveryEmail: string;
}

const initialState: IAuthenticationState = {
	secretKey: '',
	isAuthenticated:
		JSON.parse(
			localStorage.getItem(localStorage.getItem('userId') as string) as string
		)?.isAuthenticated != undefined
			? Boolean(
				JSON.parse(
					localStorage.getItem(
						localStorage.getItem('userId') as string
					) as string
				)?.isAuthenticated
			)
			: null,
	accessToken:
		JSON.parse(
			localStorage.getItem(localStorage.getItem('userId') as string) as string
		)?.accessToken != undefined
			? JSON.parse(
				localStorage.getItem(
					localStorage.getItem('userId') as string
				) as string
			)?.accessToken
			: '',
	loginData: undefined,
	refreshToken: '',
	inputLength: 6,
	identityTypes: [],
	selectedIdentityType: '',
	qrCodePath: '',
	isEnablingTwoFa: false,
	passwordRecoveryEmail: '',
};

export const authenticationSlice = createSlice({
	name: 'authentication',
	initialState,
	reducers: {
		setLoginData(state, action) {
			state.loginData = action.payload;
		},
		setPasswordRecoveryEmail(state, action) {
			state.passwordRecoveryEmail = action.payload;
		},
		clearLoginData(state) {
			state.login = '';
			state.password = '';
			state.state = '';
			state.loginData = undefined;
			sessionStorage.removeItem('temporaryAccessToken');
			state.isEnablingTwoFa = false;
			state.secretKey = '';
			state.qrCodePath = '';
		},
		setIdentityTypes(state, action) {
			state.identityTypes = action.payload;
		},
		setSelectedIdentityType(state, action) {
			state.selectedIdentityType = action.payload;
		},
		setInputLength(state, action) {
			state.inputLength = action.payload;
		},
		authenticate(state, action) {
			state.accessToken = action.payload;
			state.isAuthenticated = true;

			const accSettings = localStorage.getItem(
				localStorage.getItem('userId') as string
			);
			if (accSettings) {
				const parsedAccSettings = JSON.parse(accSettings);
				Object.assign(parsedAccSettings, {
					isAuthenticated: true,
					accessToken: action.payload,
				});
				localStorage.setItem(
					localStorage.getItem('userId') as string,
					JSON.stringify(parsedAccSettings)
				);
			}
		},
		logout(state) {
			state.refreshToken = '';
			state.accessToken = '';
			state.isAuthenticated = false;

			const accSettings = localStorage.getItem(
				localStorage.getItem('userId') as string
			);
			if (accSettings) {
				const parsedAccSettings = JSON.parse(accSettings);
				Object.assign(parsedAccSettings, {
					isAuthenticated: false,
					accessToken: '',
				});
				localStorage.setItem(
					localStorage.getItem('userId') as string,
					JSON.stringify(parsedAccSettings)
				);
			}
		},
		setRefreshToken(state, action) {
			state.refreshToken = action.payload;
		},
		setSuccessfulEmail(state, action) {
			const accSettings = localStorage.getItem(
				localStorage.getItem('userId') as string
			);
			if (accSettings) {
				const parsedAccSettings = JSON.parse(accSettings);
				Object.assign(parsedAccSettings, {
					email: action.payload
				});
				localStorage.setItem(
					localStorage.getItem('userId') as string,
					JSON.stringify(parsedAccSettings)
				);
			}
		},
		setSecretKey(state, action) {
			state.secretKey = action.payload.secretKey;
			state.qrCodePath = action.payload.qrCodePath;
		},
		setIsEnablingTwoFa(state, action) {
			state.isEnablingTwoFa = action.payload;
		},
	},
});

export const {
	authenticate,
	logout,
	setIdentityTypes,
	setInputLength,
	setSelectedIdentityType,
	setLoginData,
	clearLoginData,
	setSecretKey,
	setIsEnablingTwoFa,
	setRefreshToken,
	setPasswordRecoveryEmail,
	setSuccessfulEmail
} = authenticationSlice.actions;

import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../../../types/titles';
import SecurityTwoFaLayout from '../../../../../components/profile/security/twofa/twofa';

const TwoFaSettingsTwoFaProfile = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.PROFILE.SECURITY.TWO_FA_SETTINGS.TWO_FA}</title>
			</Helmet>
			<SecurityTwoFaLayout />
		</>
	);
};

export default TwoFaSettingsTwoFaProfile;

import React, { useEffect, useMemo, useState } from 'react';
import {
	HistoryItem,
	InvoiceBankDepositItem,
	MarketAssetItem,
	SavedAddressItem,
	SavedBankItem,
	SavedCardItem,
	SearchAssetItem,
	UserActivityItem,
	UserDeviceItem,
	WalletItem,
} from './items';
import {
	TAsset,
	TBankDepositInvoiceHistory,
	TMarketAsset,
	TMarketTab,
	TOperation,
	TSavedAddress,
	TSavedBank,
	TSavedCard,
	TUserActivity,
	TUserDevice,
	TWalletModalItem,
} from '../../../types/types';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	selectBasePrecision,
	selectCommissionAccountRests,
	selectGlobalAsset,
	selectIsEmpty,
	selectMainAccountRests,
	selectMarginAccountsRests,
	selectSpotAccountsRests,
	selectWalletConvertAssets,
	selectWalletTransferToMainRestsAssets,
	setIsEmpty,
} from '../../../services/wallet';
import { useTranslation } from 'react-i18next';
import {
	DepositIcons,
	FavouriteMarketAssetsEmptyIcon,
	LeverageIcon,
	TransferSpotIcon,
	WhaleIcon,
} from '../icons';
import WalletEmptyList from '../../wallet/empty-wallet';
import { selectMarketAssets, setFavouriteAssets } from '../../../services/market';
import {
	selectHistoryOperationsList,
	selectHistoryServerMessage,
	selectIsHistoryOperationsListEmpty,
} from '../../../services/history';
import {
	selectInvoiceHistory,
	selectInvoiceHistoryIsEmpty,
} from '../../../services/deposit';
import {
	selectBanksDictionary,
	selectDepositWithdrawAssetsDictionary,
} from '../../../services/dictionaries';
import {
	selectBankTemplates,
	selectCardTemplates,
	selectCommonTemplates,
	selectCryptoTemplates,
	selectIsCommonTemplatesEmpty,
} from '../../../services/withdraw/templates.withdraw.slice';
import { CustomButton } from '../buttons';
import {
	selectCurrentUserDevice,
	selectUserActivity,
	selectUserActivityIsListEnd,
	selectUserActivityNextFrameId,
	selectUserDevices,
} from '../../../services/user';
import { selectAccessToken } from '../../../services/auth';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { useGetVisitHistory } from '../../../data/mutations/profile';

interface SavedAddressItemListProps {
	isLoading?: boolean;
	handleItemClick: (i: string) => void;
	handleModalOpen: (address: TSavedAddress | undefined) => void;
}

const SavedAddressItemList = ({
	isLoading,
	handleItemClick,
	handleModalOpen,
}: SavedAddressItemListProps) => {
	const depositWithdrawAssetsDictionary = useAppSelector(
		selectDepositWithdrawAssetsDictionary
	)?.dictionary?.withdraw?.crypto;
	const savedAddresses = useAppSelector(selectCryptoTemplates);

	if (!isLoading && savedAddresses?.length === 0) {
		return <SavedAddressEmptyList />;
	}

	const loadingCards = ['1', '2', '3', '4'];

	return (
		<div className="historyOperationsContainer">
			{isLoading
				? loadingCards.map((card) => (
						<MarketAssetItem isLoading key={card} idx={Number(card)} />
				  ))
				: savedAddresses?.map((address: TSavedAddress, idx: number) => (
						<>
							<div key={idx} className="marketAssetItem">
								<img
									onClick={() =>
										handleItemClick(address?.cryptoAddress)
									}
									className="assetIcon"
									src={
										depositWithdrawAssetsDictionary?.find(
											(network) =>
												network?.networkName ===
												address?.networkName
										)?.networkIcon
									}
									alt={''}
								/>
								<div className="marketAssetItem">
									{isLoading ? (
										<div className="marketAssetItemNameContainer">
											<h1 className="upperMarketItemTextLoading" />
											<h1 className="bottomMarketItemTextLoading" />
										</div>
									) : (
										<div
											onClick={() =>
												handleItemClick(address?.cryptoAddress)
											}
											className="historyItemNameContainer"
										>
											<h1 className="upperMarketItemText">
												{address?.templateName}
											</h1>
											<h1 className="bottomMarketItemText">
												{address?.networkName}
											</h1>
										</div>
									)}
									<div className="marketAssetItemRateContainer">
										<DepositIcons.SavedAddressEditIcon
											className=""
											onClick={() => handleModalOpen(address)}
										/>
									</div>
								</div>
							</div>
						</>
				  ))}
		</div>
	);
};

interface SavedAddresesListProps {
	isLoading: boolean;
	handleIsCreateTemplateModalOpen: () => void;
	handleEditSavedCardModalOpen: (i?: TSavedCard | undefined) => void;
	handleEditSavedAddressModalOpen: (i?: TSavedAddress | undefined) => void;
	handleEditSavedBankModalOpen: (i?: TSavedBank | undefined) => void;
}

const SavedAddressesList = ({
	isLoading,
	handleIsCreateTemplateModalOpen,
	handleEditSavedAddressModalOpen,
	handleEditSavedCardModalOpen,
	handleEditSavedBankModalOpen,
}: SavedAddresesListProps) => {
	const depositWithdrawAssetsDictionary = useAppSelector(
		selectDepositWithdrawAssetsDictionary
	)?.dictionary?.withdraw;
	const commonTemplates = useAppSelector(selectCommonTemplates);
	const isCommonTemplatesEmpty = useAppSelector(selectIsCommonTemplatesEmpty);

	const { t } = useTranslation();

	const loadingCards = ['1', '2'];

	if (!isLoading && isCommonTemplatesEmpty) {
		return (
			<SavedAddressesEmptyList handleModalOpen={handleIsCreateTemplateModalOpen} />
		);
	}

	if (isLoading) {
		return (
			<div className="savedAddressesContainer">
				{loadingCards?.map((card) => (
					<>
						<div key={card} className="savedAddressContainer">
							<div className="savedAddressContainerHeader">
								<div className="savedAddressContainerHeaderLoading" />
							</div>
							<div className="smallerCommonContainerLoading" />
						</div>
						<div key={card} className="savedAddressContainer">
							<div className="savedAddressContainerHeader">
								<div className="savedAddressContainerHeaderLoading" />
							</div>
							<div className="commonContainerLoading" />
						</div>
					</>
				))}
			</div>
		);
	}

	return (
		<div className="savedAddressesContainer">
			{commonTemplates?.cryptoTemplates?.length > 0 && (
				<div className="savedAddressContainer">
					<div className="savedAddressContainerHeader">
						<h1 className="savedAddressContainerHeaderText">
							{t('savedAddresses_cryptoWallets')}
						</h1>
					</div>

					<div className="commonSavedAddressesContainer">
						{commonTemplates?.cryptoTemplates?.map((template, idx) => (
							<SavedAddressItem
								idx={idx}
								template={template}
								handleIsEditTemplateModalOpen={
									handleEditSavedAddressModalOpen
								}
								iconUrl={
									depositWithdrawAssetsDictionary?.crypto?.find(
										(network) =>
											template?.networkName === network?.networkName
									)?.networkIcon as string
								}
							/>
						))}
					</div>
				</div>
			)}

			{commonTemplates?.cardTemplates?.length > 0 && (
				<div className="savedAddressContainer">
					<div className="savedAddressContainerHeader">
						<h1 className="savedAddressContainerHeaderText">
							{t('savedAddresses_creditCards')}
						</h1>
					</div>

					<div className="commonSavedAddressesContainer">
						{commonTemplates?.cardTemplates?.map((card, idx) => (
							<SavedCardItem
								idx={idx}
								card={card}
								handleIsEditTemplateModalOpen={
									handleEditSavedCardModalOpen
								}
								iconUrl={
									depositWithdrawAssetsDictionary?.paymentSystem[0]?.cards?.find(
										(system) =>
											card?.cardNumber?.startsWith(
												String(system?.startNum[0])
											)
									)?.icon as string
								}
							/>
						))}
					</div>
				</div>
			)}

			{commonTemplates?.bankTemplates?.length > 0 && (
				<div className="savedAddressContainer">
					<div className="savedAddressContainerHeader">
						<h1 className="savedAddressContainerHeaderText">
							{t('savedAddresses_bankAccounts')}
						</h1>
					</div>

					<div className="commonSavedAddressesContainer">
						{commonTemplates?.bankTemplates?.map((template, idx) => (
							<SavedBankItem
								idx={idx}
								bank={template}
								handleIsEditTemplateModalOpen={
									handleEditSavedBankModalOpen
								}
							/>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

interface SavedCardsItemListProps {
	isLoading?: boolean;
	handleItemClick: (i: TSavedCard) => void;
	handleModalOpen: (address: TSavedCard | undefined) => void;
}

const SavedCardsItemList = ({
	isLoading,
	handleItemClick,
	handleModalOpen,
}: SavedCardsItemListProps) => {
	const depositWithdrawAssetsDictionary = useAppSelector(
		selectDepositWithdrawAssetsDictionary
	)?.dictionary?.withdraw?.paymentSystem[0]?.cards;
	const savedCards = useAppSelector(selectCardTemplates);

	if (!isLoading && savedCards?.length === 0) {
		return <SavedAddressEmptyList />;
	}

	const loadingCards = ['1', '2', '3', '4'];

	return (
		<div className="historyOperationsContainer">
			{isLoading
				? loadingCards.map((card) => (
						<MarketAssetItem isLoading key={card} idx={Number(card)} />
				  ))
				: savedCards?.map((card: TSavedCard, idx: number) => (
						<>
							<div key={idx} className="marketAssetItem">
								<img
									onClick={() => handleItemClick(card)}
									className="assetIcon"
									src={
										depositWithdrawAssetsDictionary?.find((system) =>
											card?.cardNumber?.startsWith(
												String(system?.startNum[0])
											)
										)?.icon
									}
									alt={''}
								/>
								<div className="marketAssetItem">
									{isLoading ? (
										<div className="marketAssetItemNameContainer">
											<h1 className="upperMarketItemTextLoading" />
											<h1 className="bottomMarketItemTextLoading" />
										</div>
									) : (
										<div
											onClick={() => handleItemClick(card)}
											className="historyItemNameContainer"
										>
											<h1 className="upperMarketItemText">
												{card?.templateName}
											</h1>
											<h1 className="bottomMarketItemText">
												{card?.cardNumber}
											</h1>
										</div>
									)}
									<div className="marketAssetItemRateContainer">
										<DepositIcons.SavedAddressEditIcon
											className=""
											onClick={() => handleModalOpen(card)}
										/>
									</div>
								</div>
							</div>
						</>
				  ))}
		</div>
	);
};

interface SavedBanksItemListProps {
	isLoading?: boolean;
	handleItemClick: (i: TSavedBank) => void;
	handleModalOpen: (bank: TSavedBank | undefined) => void;
}

const SavedBanksItemList = ({
	isLoading,
	handleItemClick,
	handleModalOpen,
}: SavedBanksItemListProps) => {
	const savedBanks = useAppSelector(selectBankTemplates);

	if (!isLoading && savedBanks?.length === 0) {
		return <SavedAddressEmptyList />;
	}

	const loadingCards = ['1', '2', '3', '4'];

	return (
		<div className="historyOperationsContainer">
			{isLoading
				? loadingCards.map((card) => (
						<MarketAssetItem isLoading key={card} idx={Number(card)} />
				  ))
				: savedBanks?.map((bank: TSavedBank, idx: number) => (
						<>
							<div key={idx} className="bankSavedAddressItem">
								{isLoading ? (
									<div className="marketAssetItemNameContainer">
										<h1 className="upperMarketItemTextLoading" />
										<h1 className="bottomMarketItemTextLoading" />
									</div>
								) : (
									<div
										onClick={() => handleItemClick(bank)}
										className="bankSavedAddressItemNameContainer"
									>
										<h1 className="upperMarketItemText">
											{bank?.templateName}
										</h1>
										<h1 className="bottomMarketItemText">
											{bank?.params?.find(
												(param) => param?.paramId === 'bankName'
											)?.paramValue ?? ''}
										</h1>
									</div>
								)}
								<div className="bankSavedAddressItemEditContainer">
									<DepositIcons.SavedAddressEditIcon
										className=""
										onClick={() => handleModalOpen(bank)}
									/>
								</div>
							</div>
						</>
				  ))}
		</div>
	);
};

interface HistoryItemListProps {
	isLoading?: boolean;
	handleItemClick: (i: TOperation) => void;
}

const HistoryItemList = ({ isLoading, handleItemClick }: HistoryItemListProps) => {
	const historyOperationsList = useAppSelector(selectHistoryOperationsList);

	const isHistoryOperationsListEmpty = useAppSelector(
		selectIsHistoryOperationsListEmpty
	);

	if (!isLoading && isHistoryOperationsListEmpty) {
		return <HistoryEmptyList />;
	}

	const loadingCards = ['1', '2', '3', '4', '5', '6', '7'];

	return (
		<div className="historyOperationsContainer">
			{isLoading
				? loadingCards.map((card) => (
						<MarketAssetItem isLoading key={card} idx={Number(card)} />
				  ))
				: historyOperationsList?.map((operation: TOperation, idx: number) => (
						<HistoryItem
							handleItemClick={handleItemClick}
							item={operation}
							idx={idx}
						/>
				  ))}
		</div>
	);
};

const HistoryEmptyList = () => {
	const historyServerMessage = useAppSelector(selectHistoryServerMessage);
	const { t } = useTranslation();

	return (
		<div className="walletConfirmIdentityContainer">
			<WhaleIcon className="walletConfirmIdentityWhale" />
			<h1 className="walletConfirmIdentityHeader">
				{historyServerMessage != ''
					? historyServerMessage
					: t('history_noTransactionsYet')}
			</h1>
		</div>
	);
};

interface SavedAddressesEmptyListProps {
	handleModalOpen: () => void;
}

const SavedAddressesEmptyList = ({ handleModalOpen }: SavedAddressesEmptyListProps) => {
	const { t } = useTranslation();

	return (
		<div className="walletConfirmIdentityContainer">
			<WhaleIcon className="walletConfirmIdentityWhale" />
			<h1 className="walletConfirmIdentityHeader">
				{t('withdraw_noSavedAddressesYet')}
			</h1>
			<h1 className="walletConfirmIdentityBottom">
				{t('withdraw_savedAddressesInfo')}
			</h1>
			<CustomButton
				onClick={handleModalOpen}
				className="walletConfirmIdentityButton"
				text={t('savedAddresses_addNewAddress') as string}
			/>
		</div>
	);
};

const SavedAddressEmptyList = () => {
	const { t } = useTranslation();

	return (
		<div className="walletConfirmIdentityContainer">
			<WhaleIcon className="walletConfirmIdentityWhale" />
			<h1 className="walletConfirmIdentityHeader">{t('general_noResultsFound')}</h1>
		</div>
	);
};

interface WalletItemListProps {
	isLowBalanceHidden: boolean;
	isLoading?: boolean;
	isBalanceHidden: boolean;
	globalAsset: TWalletModalItem;
	searchValue?: string;
	isWalletConvertModal?: boolean;
	isWalletTransferModal?: boolean;
	handleSelectedAmountChange?: (i: TAsset, isSelected: boolean, idx: number) => void;
	selected?: boolean[];
	handleWalletTransferRestsToMainModalOpen?: (isLeverageTransfer?: boolean) => void;
	isError?: boolean;
}

const WalletItemList = ({
	isLowBalanceHidden,
	isLoading,
	globalAsset,
	isBalanceHidden,
	searchValue,
	isWalletConvertModal,
	isWalletTransferModal,
	handleSelectedAmountChange,
	selected,
	handleWalletTransferRestsToMainModalOpen,
	isError
}: WalletItemListProps) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const mainCurrencies = useAppSelector(selectMainAccountRests);
	const commissionCurrencies = useAppSelector(selectCommissionAccountRests);
	const spotCurrencies = useAppSelector(selectSpotAccountsRests);
	const marginCurrencies = useAppSelector(selectMarginAccountsRests);
	const isEmpty = useAppSelector(selectIsEmpty);

	const walletConvertAssets = useAppSelector(selectWalletConvertAssets);
	const walletTransferAssets = useAppSelector(selectWalletTransferToMainRestsAssets);

	const minHideValue = mainCurrencies?.baseLowBalance as number;

	function filterCurrenciesByMinHideValue(
		currencies: TAsset[] | undefined,
		isLowBalanceHidden: boolean,
		searchValue: string | undefined
	) {
		if (currencies) {
			if (searchValue) {
				return currencies.filter(
					(currency) =>
						currency.code.toLowerCase().includes(searchValue) ||
						currency.name.toLowerCase().includes(searchValue)
				);
			}
			if (isLowBalanceHidden) {
				return currencies.filter((currency) =>
					currency?.baseAvailable
						? currency.baseAvailable > minHideValue
						: currency.available > minHideValue
				);
			} else {
				return currencies;
			}
		}
	}

	const visibleMainCurrencies = useMemo(
		() =>
			filterCurrenciesByMinHideValue(
				mainCurrencies?.assets,
				isLowBalanceHidden,
				searchValue
			),
		[mainCurrencies, isLowBalanceHidden, searchValue]
	);

	const visibleSpotCurrencies = useMemo(
		() =>
			filterCurrenciesByMinHideValue(
				spotCurrencies?.assets,
				isLowBalanceHidden,
				searchValue
			),
		[spotCurrencies, isLowBalanceHidden, searchValue]
	);
	const visibleMarginCurrencies = useMemo(
		() =>
			filterCurrenciesByMinHideValue(
				marginCurrencies?.assets,
				isLowBalanceHidden,
				searchValue
			),
		[marginCurrencies, isLowBalanceHidden, searchValue]
	);

	const visibleCommissionCurrencies = useMemo(
		() =>
			filterCurrenciesByMinHideValue(
				commissionCurrencies?.assets,
				isLowBalanceHidden,
				searchValue
			),
		[commissionCurrencies, isLowBalanceHidden, searchValue]
	);

	useEffect(() => {
		if (!isLowBalanceHidden) {
			if (mainCurrencies) {
				const localIsEmpty =
					(visibleCommissionCurrencies?.length ?? 0) +
						(visibleMainCurrencies?.length ?? 0) +
						(visibleMarginCurrencies?.length ?? 0) +
						(visibleSpotCurrencies?.length ?? 0) ===
					0;
				dispatch(setIsEmpty(localIsEmpty));
			}
		}
	}, [
		visibleCommissionCurrencies,
		visibleMainCurrencies,
		visibleMarginCurrencies,
		visibleSpotCurrencies,
		walletConvertAssets,
	]);

	const loadingCards = ['1', '2', '3', '4'];

	if (isEmpty) {
		return <WalletEmptyList />;
	}

	if (isWalletTransferModal) {
		return (
			<div className="assetsWalletSearchContainer">
				{!walletTransferAssets
					? loadingCards.map((card) => (
							<WalletItem isLoading key={card} idx={Number(card)} />
					  ))
					: walletTransferAssets?.assets.map((currency, idx: number) => (
							<WalletItem
								isError={isError}
								isChecked={(selected as boolean[])[idx]}
								handleSelectedAmountChange={handleSelectedAmountChange}
								basePrecision={mainCurrencies?.basePrecision}
								isBalanceHidden={isBalanceHidden}
								baseAsset={globalAsset.code}
								key={idx}
								idx={idx}
								asset={currency}
							/>
					  ))}
			</div>
		);
	}

	if (isWalletConvertModal) {
		return (
			<div className="assetsWalletSearchContainer">
				{!walletConvertAssets
					? loadingCards.map((card) => (
							<WalletItem isLoading key={card} idx={Number(card)} />
					  ))
					: walletConvertAssets?.assets.map((currency, idx: number) => (
							<WalletItem
								isError={isError}
								isChecked={(selected as boolean[])[idx]}
								handleSelectedAmountChange={handleSelectedAmountChange}
								basePrecision={mainCurrencies?.basePrecision}
								isBalanceHidden={isBalanceHidden}
								baseAsset={globalAsset.code}
								key={idx}
								idx={idx}
								asset={currency}
							/>
					  ))}
			</div>
		);
	}

	if (!isLoading && !isLowBalanceHidden && isEmpty) {
		return <WalletEmptyList />;
	}

	return (
		<div
			style={{
				marginBottom: '64px',
			}}
			className={'assetsContainer'}
		>
			{!visibleMainCurrencies
				? loadingCards.map((card) => (
						<WalletItem isLoading key={card} idx={Number(card)} />
				  ))
				: visibleMainCurrencies?.map((currency, idx: number) => (
						<WalletItem
							basePrecision={mainCurrencies?.basePrecision}
							isBalanceHidden={isBalanceHidden}
							baseAsset={globalAsset.code}
							key={idx}
							idx={idx}
							asset={currency}
						/>
				  ))}

			{visibleSpotCurrencies && visibleSpotCurrencies.length > 0 && (
				<>
					<div className="innerAssetsContainer">
						<h1 className="innerAssetsHeaderText">{t('wallet_spot')}</h1>
						<div
							className="innerAssetsTransferContainer"
							onClick={() =>
								handleWalletTransferRestsToMainModalOpen &&
								handleWalletTransferRestsToMainModalOpen(false)
							}
						>
							<TransferSpotIcon />
							<h1 className="innerAssetsTransferText">
								{t('wallet_transfer')}
							</h1>
						</div>
					</div>
					{visibleSpotCurrencies?.map((currency, idx: number) => (
						<WalletItem
							basePrecision={mainCurrencies?.basePrecision}
							isBalanceHidden={isBalanceHidden}
							baseAsset={globalAsset.code}
							key={idx}
							idx={idx}
							asset={currency}
						/>
					))}
				</>
			)}

			{visibleMarginCurrencies &&
				visibleMarginCurrencies.length > 0 &&
				!marginCurrencies?.isEmpty && (
					<>
						<div className="innerAssetsContainer">
							<h1 className="innerAssetsHeaderText">
								{t('wallet_leverage')}
							</h1>
							<LeverageIcon
								className=""
								onClick={() =>
									handleWalletTransferRestsToMainModalOpen &&
									handleWalletTransferRestsToMainModalOpen(true)
								}
							/>
						</div>
						{visibleMarginCurrencies?.map((currency, idx: number) => (
							<>
								{currency.available > 0 ? (
									<WalletItem
										basePrecision={mainCurrencies?.basePrecision}
										isBalanceHidden={isBalanceHidden}
										baseAsset={globalAsset.code}
										key={idx}
										idx={idx}
										asset={currency}
									/>
								) : (
									''
								)}
							</>
						))}
					</>
				)}

			{visibleCommissionCurrencies && visibleCommissionCurrencies.length > 0 && (
				<>
					<div className="innerAssetsContainer">
						<h1 className="innerAssetsHeaderText">
							{t('wallet_commission')}
						</h1>
						<div
							className="innerAssetsTransferContainer"
							onClick={() =>
								handleWalletTransferRestsToMainModalOpen &&
								handleWalletTransferRestsToMainModalOpen(false)
							}
						>
							<TransferSpotIcon />
							<h1 className="innerAssetsTransferText">
								{t('wallet_transfer')}
							</h1>
						</div>
					</div>
					{visibleCommissionCurrencies?.map((currency, idx: number) => (
						<WalletItem
							basePrecision={mainCurrencies?.basePrecision}
							isBalanceHidden={isBalanceHidden}
							baseAsset={globalAsset.code}
							key={idx}
							idx={idx}
							asset={currency}
						/>
					))}
				</>
			)}
		</div>
	);
};

interface MarketAssetsListProps {
	isLoading: boolean;
	tab: TMarketTab;
	timeFrame: string;
	isSorted: number;
}

const MarketAssetsList = ({
	isLoading,
	tab,
	timeFrame,
	isSorted,
}: MarketAssetsListProps) => {
	const { t } = useTranslation();
	const globalAsset = useAppSelector(selectGlobalAsset);
	const marketAssets = useAppSelector(selectMarketAssets);

	const [marketTabAssets, setMarketTabAssets] = useState<TMarketAsset[]>();

	const dispatch = useAppDispatch();

	const loadingCards = ['1', '2', '3', '4', '5', '6', '7'];

	const [percentChange, setPercentChange] =
		useState<keyof TMarketAsset>('percentChange24h');

	useEffect(() => {
		if (timeFrame === `1${t('filters_h')}`) {
			setPercentChange('percentChange1h');
		}
		if (timeFrame === `24${t('filters_h')}`) {
			setPercentChange('percentChange24h');
		}
		if (timeFrame === `7${t('filters_d')}`) {
			setPercentChange('percentChange7d');
		}
		if (timeFrame === `30${t('filters_d')}`) {
			setPercentChange('percentChange30d');
		}
	}, [timeFrame]);

	function filterAssetsByIsSorted(
		assets: TMarketAsset[] | undefined,
		isSorted: number
	) {
		if (assets) {
			if (isSorted === -1) {
				return assets;
			}
			if (isSorted === 1) {
				const localAssets = [...assets];
				return localAssets.sort((firstAsset, secondAsset) => {
					if (firstAsset[percentChange] > secondAsset[percentChange]) {
						return -1;
					} else if (firstAsset[percentChange] < secondAsset[percentChange]) {
						return 1;
					} else if (firstAsset[percentChange] === undefined) {
						return 1;
					} else if (secondAsset[percentChange] === undefined) {
						return -1;
					} else {
						return 0;
					}
				});
			}
			if (isSorted === 0) {
				const localAssets = [...assets];
				return localAssets.sort((firstAsset, secondAsset) => {
					if (firstAsset[percentChange] > secondAsset[percentChange]) {
						return 1;
					} else if (firstAsset[percentChange] < secondAsset[percentChange]) {
						return -1;
					} else if (firstAsset[percentChange] === undefined) {
						return -1;
					} else if (secondAsset[percentChange] === undefined) {
						return 1;
					} else {
						return 0;
					}
				});
			}
		}
	}

	useEffect(() => {
		setMarketTabAssets(filterAssetsByIsSorted(marketAssets[tab?.filter], isSorted));
	}, [marketAssets[tab?.filter], isSorted, timeFrame]);

	const handleFavouriteClick = (asset: TMarketAsset, isNeedToAdd: boolean) => {
		dispatch(setFavouriteAssets({ asset: asset, isNeedToAdd: isNeedToAdd }));
	};

	if (tab?.filter === 'crypto' && marketTabAssets?.length === 0) {
		return (
			<div
				style={{
					marginBottom: '64px',
				}}
				className="marketAssetsContainer"
			>
				<div className="marketAssetsEmptyFavouriteContainer">
					<h1 className="marketAssetsEmptyFavouriteHeader">
						{t('assets_favouriteAssets')}
					</h1>
					<h1 className="marketAssetsEmptyFavouriteBottom">
						{t('assets_favouriteAssetsDesc')}
					</h1>
					<FavouriteMarketAssetsEmptyIcon />
				</div>
			</div>
		);
	}

	return (
		<div
			style={{
				marginBottom: '64px',
			}}
			className="marketAssetsContainer"
		>
			{isLoading || marketTabAssets?.length === 0 || marketTabAssets === undefined
				? loadingCards.map((card) => (
						<MarketAssetItem isLoading key={card} idx={Number(card)} />
				  ))
				: marketTabAssets?.map(
						(asset: TMarketAsset, idx: number) =>
							Boolean(asset.isShowInPrices) && (
								<MarketAssetItem
									timeFrame={timeFrame}
									isFavourite={marketAssets['crypto']?.some(
										(localAsset) => asset.code === localAsset.code
									)}
									globalAssetCode={globalAsset?.code}
									key={asset.code}
									idx={idx}
									asset={asset}
									handleFavouriteClick={handleFavouriteClick}
								/>
							)
				  )}
		</div>
	);
};

const BankDepositInvoiceHistoryListEmpty = () => {
	const { t } = useTranslation();

	return (
		<div className="walletConfirmIdentityContainer">
			<WhaleIcon className="walletConfirmIdentityWhale" />
			<h1 className="walletConfirmIdentityHeader">{t('noInvoicesYet')}</h1>
		</div>
	);
};

interface BankDepositInvoiceHistoryListProps {
	isLoading: boolean;
	handleItemClick: (i: string) => void;
}

const BankDepositInvoiceHistoryList = ({
	isLoading,
	handleItemClick,
}: BankDepositInvoiceHistoryListProps) => {
	const loadingCards = ['1', '2', '3', '4', '5', '6', '7', '8'];
	const invoiceHistory = useAppSelector(selectInvoiceHistory);
	const invoiceHistoryIsEmpty = useAppSelector(selectInvoiceHistoryIsEmpty);
	const banksDictionary = useAppSelector(selectBanksDictionary);

	if (!isLoading && invoiceHistoryIsEmpty) {
		return <BankDepositInvoiceHistoryListEmpty />;
	}

	return (
		<>
			<div className="invoiceHistoryContainer">
				{isLoading && !banksDictionary?.dictionary ? (
					loadingCards.map((card) => (
						<div className="invoiceHistoryContainerLoading" key={card} />
					))
				) : (
					<div className="commonContainer">
						{invoiceHistory?.map(
							(invoice: TBankDepositInvoiceHistory, idx: number) => (
								<InvoiceBankDepositItem
									handleItemClick={handleItemClick}
									item={invoice}
									idx={idx}
								/>
							)
						)}
					</div>
				)}
			</div>
		</>
	);
};

interface UserDevicesListProps {
	handleModalOpen: (item?: TUserDevice) => void;
}

const UserDevicesList = ({ handleModalOpen }: UserDevicesListProps) => {
	const userDevices = useAppSelector(selectUserDevices);
	const currentDevice = useAppSelector(selectCurrentUserDevice);

	const { t } = useTranslation();

	return (
		<div
			style={{
				maxHeight: '100vh',
			}}
			className="invoiceHistoryContainer"
		>
			<h1 className="additionalTwoFaText">{t('devices_thisDevice')}</h1>

			{/*<h1
				className="additionalTwoFaText"
				onClick={() =>
					navigator.clipboard.writeText(
						JSON.stringify({
							device: new UAParser(window.navigator.userAgent)
								?.getDevice()
								.toString(),
							os: new UAParser(window.navigator.userAgent)
								?.getOS()
								.toString(),
							engine: new UAParser(window.navigator.userAgent)
								?.getEngine()
								.toString(),
							ua: new UAParser(window.navigator.userAgent)
								?.getUA()
								.toString(),
							f2x_user_agent: parseUserAgentString(window.navigator.userAgent),
							userAgentData: JSON.stringify(
								//eslint-disable-next-line
								(window.navigator as any)?.userAgentData
							),
						})
					)
				}
			>
				UA INFO
			</h1>*/}

			{currentDevice != undefined && (
				<div className="commonContainer">
					<UserDeviceItem
						onClick={handleModalOpen}
						item={currentDevice as TUserDevice}
						idx={0}
					/>
				</div>
			)}

			{userDevices?.length > 0 &&
				userDevices?.filter(
					(device) =>
						device?.deviceId != currentDevice?.deviceId &&
						device?.isSessionActive
				)?.length > 0 && (
					<h1 className="additionalTwoFaText">{t('devices_activeSessions')}</h1>
				)}

			{userDevices?.length > 0 && (
				<div className="commonContainer">
					{userDevices
						?.filter(
							(device) =>
								device?.deviceId != currentDevice?.deviceId &&
								device?.isSessionActive
						)
						?.map((device: TUserDevice, idx: number) => (
							<UserDeviceItem
								onClick={handleModalOpen}
								item={device}
								idx={idx}
							/>
						))}
				</div>
			)}
		</div>
	);
};

interface UserActivitiesListProps {
	isLoading: boolean;
}

const UserActivitiesList = ({ isLoading }: UserActivitiesListProps) => {
	const userActivities = useAppSelector(selectUserActivity);
	const accessToken = useAppSelector(selectAccessToken);
	const nextFrameId = useAppSelector(selectUserActivityNextFrameId);
	const isListEnd = useAppSelector(selectUserActivityIsListEnd);

	const navigate = useCustomNavigate();
	const dispatch = useAppDispatch();

	const { getVisitHistory } = useGetVisitHistory(navigate, dispatch);

	const loadingCards = ['1', '2', '3', '4'];

	const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
		if (
			!isListEnd &&
			Math.round(event.currentTarget.scrollTop) ===
				event.currentTarget.scrollHeight - event.currentTarget.offsetHeight
		) {
			getVisitHistory({
				accessToken: accessToken as string,
				dispatch: dispatch,
				nextFrameId: nextFrameId,
			});
		}
	};

	return (
		<div
			onScroll={handleScroll}
			style={{
				maxHeight: '100vh',
			}}
			className="invoiceHistoryContainer"
		>
			{isLoading ? (
				loadingCards.map((card) => (
					<div className="invoiceHistoryContainerLoading" key={card} />
				))
			) : (
				<>
					{userActivities?.map((activity: TUserActivity, idx: number) => (
						<UserActivityItem item={activity} idx={idx} />
					))}
				</>
			)}
		</div>
	);
};

interface SearchItemListProps {
	isLoading: boolean;
	searchValue: string;
}

const SearchItemList = ({ isLoading, searchValue }: SearchItemListProps) => {
	const loadingCards = ['1', '2', '3', '4'];

	const { t } = useTranslation();

	const mainRests = useAppSelector(selectMainAccountRests);
	const commssionRests = useAppSelector(selectCommissionAccountRests);
	const spotRests = useAppSelector(selectSpotAccountsRests);
	const marginRests = useAppSelector(selectMarginAccountsRests);
	const marketAssets = useAppSelector(selectMarketAssets);

	const globalAsset = useAppSelector(selectGlobalAsset);
	const basePrecision = useAppSelector(selectBasePrecision);

	const [isEmpty, setIsEmpty] = useState<boolean>(false);

	function filterRestsBySearchValue(
		assets: (TMarketAsset | TAsset)[] | undefined,
		searchValue: string
	) {
		if (assets) {
			if (searchValue?.trim().length === 0 || searchValue === '') {
				return assets;
			} else {
				return assets?.filter(
					(asset) =>
						asset?.name
							?.toLowerCase()
							?.includes(searchValue?.toLowerCase()) ||
						asset?.code?.toLowerCase()?.includes(searchValue?.toLowerCase())
				);
			}
		}
	}

	const mainCurrencies = useMemo(
		() => filterRestsBySearchValue(mainRests?.assets, searchValue),
		[mainRests, searchValue]
	);

	const spotCurrencies = useMemo(
		() => filterRestsBySearchValue(spotRests?.assets, searchValue),
		[spotRests, searchValue]
	);

	const marginCurrencies = useMemo(
		() => filterRestsBySearchValue(marginRests?.assets, searchValue),
		[marginRests, searchValue]
	);

	const commissionCurrencies = useMemo(
		() => filterRestsBySearchValue(commssionRests?.assets, searchValue),
		[commssionRests, searchValue]
	);

	const marketCurrencies = useMemo(
		() => filterRestsBySearchValue(marketAssets['all'], searchValue),
		[marketAssets, searchValue]
	);

	useEffect(() => {
		setIsEmpty(
			(mainCurrencies?.length ?? 0) +
				(spotCurrencies?.length ?? 0) +
				(marginCurrencies?.length ?? 0) +
				(commissionCurrencies?.length ?? 0) +
				(marketCurrencies?.length ?? 0) ===
				0
		);
	}, [
		mainCurrencies,
		spotCurrencies,
		marginCurrencies,
		commissionCurrencies,
		marketCurrencies,
	]);

	if (isEmpty && !isLoading && searchValue.length > 0) {
		return (
			<div
				style={{
					marginBottom: '64px',
				}}
				className="searchAssetsContainer"
			>
				<div className="walletConfirmIdentityContainer">
					<WhaleIcon className="walletConfirmIdentityWhale" />
					<h1 className="walletConfirmIdentityHeader">
						{t('general_noResultsFound')}
					</h1>
				</div>
			</div>
		);
	}

	return (
		<div
			style={{
				marginBottom: '64px',
			}}
			className="searchAssetsContainer"
		>
			{isLoading ? (
				<div className="searchAssetsBlock">
					{loadingCards.map((value) => (
						<SearchAssetItem isLoading={true} idx={Number(value)} />
					))}
				</div>
			) : (
				<div className="searchAssetOuterCommonContainer">
					{Number(mainCurrencies?.length) > 0 && (
						<div className="searchAssetOuterBlock">
							<h1 className="searchAssetBlockHeader">
								{t('wallet_title')}
							</h1>
							<div className="searchAssetsBlock">
								{mainCurrencies?.map((asset, idx) => (
									<SearchAssetItem
										basePrecision={basePrecision}
										idx={idx}
										asset={asset}
										globalAssetCode={globalAsset.code}
									/>
								))}
							</div>
						</div>
					)}
					{Number(spotCurrencies?.length) > 0 && (
						<div className="searchAssetOuterBlock">
							<h1 className="searchAssetBlockHeader">{t('wallet_spot')}</h1>
							<div className="searchAssetsBlock">
								{spotCurrencies?.map((asset, idx) => (
									<SearchAssetItem
										basePrecision={basePrecision}
										idx={idx}
										asset={asset}
										globalAssetCode={globalAsset.code}
									/>
								))}
							</div>
						</div>
					)}
					{Number(marginCurrencies?.length) > 0 &&
						marginCurrencies?.some(
							(asset) => (asset as TAsset)?.available > 0
						) &&
						!marginRests?.isEmpty && (
							<div className="searchAssetOuterBlock">
								<h1 className="searchAssetBlockHeader">
									{t('wallet_leverage')}
								</h1>
								<div className="searchAssetsBlock">
									{marginCurrencies?.map((asset, idx) => (
										<>
											{(asset as TAsset)?.available > 0 && (
												<SearchAssetItem
													basePrecision={basePrecision}
													idx={idx}
													asset={asset}
													globalAssetCode={globalAsset.code}
												/>
											)}
										</>
									))}
								</div>
							</div>
						)}
					{Number(commissionCurrencies?.length) > 0 && (
						<div className="searchAssetOuterBlock">
							<h1 className="searchAssetBlockHeader">
								{t('wallet_commission')}
							</h1>
							<div className="searchAssetsBlock">
								{commissionCurrencies?.map((asset, idx) => (
									<SearchAssetItem
										basePrecision={basePrecision}
										idx={idx}
										asset={asset}
										globalAssetCode={globalAsset.code}
									/>
								))}
							</div>
						</div>
					)}
					{Number(marketCurrencies?.length) > 0 && (
						<div className="searchAssetOuterBlock">
							<h1 className="searchAssetBlockHeader">
								{t('assets_title')}
							</h1>
							<div className="searchAssetsBlock">
								{marketCurrencies?.map((asset, idx) => (
									<>
										{(asset as TMarketAsset)?.isShowInPrices != 0 && (
											<SearchAssetItem
												basePrecision={basePrecision}
												idx={idx}
												asset={asset}
												globalAssetCode={globalAsset.code}
											/>
										)}
									</>
								))}
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export {
	WalletItemList,
	MarketAssetsList,
	SearchItemList,
	HistoryItemList,
	BankDepositInvoiceHistoryList,
	SavedAddressItemList,
	SavedAddressesEmptyList,
	SavedCardsItemList,
	SavedAddressesList,
	SavedBanksItemList,
	UserActivitiesList,
	UserDevicesList,
};

import { useState } from 'react';
import { useCustomNavigate } from '../../../../../hooks/useCustomNavigate';
import { SetupTwoFaFunctionalLayout } from '../../../../auth/setup-two-fa';
import { ROUTES } from '../../../../../types/routes';

const SecurityTwoFaSetupTwoFaLayout = () => {
	const navigate = useCustomNavigate();
	const [isPending, setIsPending] = useState<boolean>(false);

	const handleBackClickButton = () => {
		navigate(ROUTES.PROFILE.SECURITY.TWO_FA.INDEX);
	};

	const handleSubmit = () => {
		setIsPending(true);
		navigate(ROUTES.PROFILE.SECURITY.TWO_FA.SECRET_KEY);
	};

	return (
		<SetupTwoFaFunctionalLayout
			isPending={isPending}
			handleBackClickButton={handleBackClickButton}
			handleSubmit={handleSubmit}
		/>
	);
};

export default SecurityTwoFaSetupTwoFaLayout;

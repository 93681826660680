import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../../types/routes';
import { mapClassnameForMobile, TWOFA_UTILS } from '../../../../utils';
import { HeaderLayout } from '../../../common/header';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { useEffect, useState } from 'react';
import { CustomSwitch } from '../../../common/switchs';
import { ProfileIcons } from '../../../common/icons';
import {
	useGetTwoFaOptionsMutation,
	useSendSecurityOTP,
} from '../../../../data/mutations/profile';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { selectAccessToken } from '../../../../services/auth';
import { APIError } from '../../../common/error';
import {
	selectTwoFaOptionsAvailableIdentityTypes,
	setCurrentTwoFactorOption,
	setSecurityInit,
	setSecurityMethodType,
	setSecuritySelectedIdentityType,
	updateTwoFactorOption,
} from '../../../../services/security';
import { DisableTwoFaConfirmationModal } from '../../../common/modals';

const SecurityTwoFaSettingsLayout = () => {
	const availableIdentityTypes = useAppSelector(
		selectTwoFaOptionsAvailableIdentityTypes
	);
	const accessToken = useAppSelector(selectAccessToken);

	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();
	const { t } = useTranslation();

	const { getTwoFaOptionsMutation, isError, error, isPending } =
		useGetTwoFaOptionsMutation();

	useEffect(() => {
		getTwoFaOptionsMutation({
			accessToken: accessToken as string,
			dispatch: dispatch,
		});
	}, []);

	const handleBackClickButton = () => {
		dispatch(setSecurityInit());
		navigate(ROUTES.PROFILE.SECURITY.INDEX);
	};

	const [isDisableModalOpen, setIsDisableModalOpen] = useState<boolean>(false);

	const { sendSecurityOTP } = useSendSecurityOTP(navigate, true, true);

	const handleIsActiveClick = (type: string, value: boolean) => {
		dispatch(
			updateTwoFactorOption({
				type: type,
				value: value,
			})
		);
		dispatch(
			setCurrentTwoFactorOption({
				identityTypeName: type,
				isEnabled: value,
			})
		);
		if (value) {
			dispatch(setSecurityMethodType('enable'));
			dispatch(setSecuritySelectedIdentityType(type));
			if (type.toLowerCase() != TWOFA_UTILS.GOOGLE_AUTHENTICATOR?.toLowerCase()) {
				sendSecurityOTP({
					accessToken: accessToken as string,
					dispatch: dispatch,
					identityType: type,
					state: '',
				});
			} else {
				navigate(ROUTES.PROFILE.SECURITY.TWO_FA.SETUP_TWOFA);
			}
		} else {
			setIsDisableModalOpen(!isDisableModalOpen);
		}
	};

	const handleIsDisableModalOpen = () => {
		setIsDisableModalOpen(!isDisableModalOpen);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent aboutComponent'}>
				<HeaderLayout
					isFunctionalHeader
					isAboutHeader
					handleBackClick={handleBackClickButton}
				/>
				<DisableTwoFaConfirmationModal
					isModalOpen={isDisableModalOpen}
					handleIsModalOpen={handleIsDisableModalOpen}
				/>
				<div
					style={{ marginBottom: '16px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className="innerContainer">
						<h1 className="innerContainerMainText">{t('twoFA_title')}</h1>

						{isPending && availableIdentityTypes?.length === 0 && (
							<div className="savedAddressContainer">
								<div className="savedAddressContainerHeader">
									<div className="savedAddressContainerHeaderLoading" />
								</div>
								<div className="commonContainerLoading" />
							</div>
						)}

						{availableIdentityTypes?.find(
							(type) => type.identityTypeName === 'GoogleAuthenticator'
						) != undefined && (
							<div className="commonContainer">
								<div className="commonItem">
									<ProfileIcons.TwoFactorIcons
										type={'GoogleAuthenticator'}
									/>
									<h1 className="commonText">
										{t('twoFactorAuthSettings_enable2FA')}
									</h1>
									<div className="">
										<CustomSwitch
											isActive={
												availableIdentityTypes?.find(
													(identity) =>
														identity?.identityTypeName ===
														'GoogleAuthenticator'
												)?.isEnabled ?? false
											}
											onClick={() =>
												handleIsActiveClick(
													availableIdentityTypes?.find(
														(identity) =>
															identity?.identityTypeName ===
															'GoogleAuthenticator'
													)?.identityTypeName as string,
													!availableIdentityTypes?.find(
														(identity) =>
															identity?.identityTypeName ===
															'GoogleAuthenticator'
													)?.isEnabled as boolean
												)
											}
										/>
									</div>
								</div>

								<div className="commonItem">
									<ProfileIcons.EditPencilIcon />
									<h1 className="commonText">
										{t('twoFactorAuthCode_iLostAccessTo2FA')}
									</h1>
								</div>
							</div>
						)}

						{availableIdentityTypes?.length > 0 && (
							<>
								<h1 className="additionalTwoFaText">
									{t('twoFactorAuthSettings_additional2FA')}
								</h1>

								<div className="commonContainer">
									{availableIdentityTypes
										?.filter(
											(identity) =>
												identity?.identityTypeName !=
													'GoogleAuthenticator' &&
												identity?.identityTypeName !=
													'ExternalDevice'
										)
										?.map((identity) => (
											<div className="commonItem">
												<ProfileIcons.TwoFactorIcons
													type={identity?.identityTypeName}
												/>
												<h1 className="commonText">
													{t(
														`twoFactorAuthCode_${identity?.identityTypeName?.toLowerCase()}Title`
													)}
												</h1>
												<div className="">
													<CustomSwitch
														isActive={
															identity?.isEnabled ?? false
														}
														onClick={() => {
															handleIsActiveClick(
																identity?.identityTypeName,
																!identity?.isEnabled
															);
														}}
													/>
												</div>
											</div>
										))}
								</div>
							</>
						)}

						<APIError isError={isError} error={error} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SecurityTwoFaSettingsLayout;

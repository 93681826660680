import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../../../types/titles';
import SecurityChangePasswordSelectTwoFactorLayout from '../../../../../components/profile/security/change-password/select-two-factor';

const ProfileSecuritySelectTwoFactor = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.PROFILE.SECURITY.CHANGE_PASSWORD.SELECT_TWO_FA}</title>
			</Helmet>
			<SecurityChangePasswordSelectTwoFactorLayout />
		</>
	);
};

export default ProfileSecuritySelectTwoFactor;

import { customFetch } from '../../services';
import { setInputLength } from '../../services/auth';
import { setIsResendCodeActive, setMaskedContact, updateCounter } from '../../services/root';
import {
	setSecurityMethodType,
	setSecuritySelectedIdentityType,
	setSecurityState,
	setTwoFaOptionsIdentityTypes,
} from '../../services/security';
import { API_ROUTES } from '../../types/api.routes';
import { ROUTES } from '../../types/routes';
import {
	TDefaultBody,
	TDisableTwoFaBody,
	TEnableTwoFaBody,
	TIdentity,
	TPasswordRecoveryBody,
	TPasswordRecoveryChangeBody,
	TResetDeviceBody,
	TSecurityPasswordChangeBody,
	TSecuritySendOtpBody,
} from '../../types/types';
import { handleAuthorizationErrorState, mapResponseError } from '../../utils';

export async function fetchSecretKey() {
	const secretKeyData = await customFetch(API_ROUTES.SECURITY.NEWKEY.URL, {
		method: API_ROUTES.SECURITY.NEWKEY.METHOD,
		headers: {
			Authorization: `Bearer ${sessionStorage.getItem('temporaryAccessToken') != null
				? sessionStorage.getItem('temporaryAccessToken')
				: (JSON.parse(
					localStorage.getItem(
						localStorage.getItem('userId') as string
					) as string
				)?.accessToken as string)
				}`,
		},
	});

	const jsonSecretKeyData = await secretKeyData.json();
	if (secretKeyData.status != 200) {
		mapResponseError(secretKeyData, jsonSecretKeyData);
	}

	return jsonSecretKeyData;
}

export async function fetchPasswordRecoveryRequest({ email }: TPasswordRecoveryBody) {
	const passwordRecoveryRequestData = await customFetch(
		API_ROUTES.SECURITY.PASSWORD.RECOVERY.REQUEST.URL,
		{
			method: API_ROUTES.SECURITY.PASSWORD.RECOVERY.REQUEST.METHOD,
			body: JSON.stringify({
				email: email,
			}),
			headers: {},
		}
	);

	const jsonPasswordRecoveryRequestData = await passwordRecoveryRequestData.text();
	if (passwordRecoveryRequestData.status != 200) {
		mapResponseError(passwordRecoveryRequestData, jsonPasswordRecoveryRequestData);
	}

	return jsonPasswordRecoveryRequestData;
}

export async function fetchPasswordRecoveryChange({
	email,
	newPassword,
	code,
}: TPasswordRecoveryChangeBody) {
	const passwordRecoveryChangeData = await customFetch(
		API_ROUTES.SECURITY.PASSWORD.RECOVERY.CHANGE.URL,
		{
			method: API_ROUTES.SECURITY.PASSWORD.RECOVERY.CHANGE.METHOD,
			body: JSON.stringify({
				email: email,
				newPassword: newPassword,
				code: code,
			}),
			headers: {},
		}
	);

	const jsonPasswordRecoveryChangeData =
		passwordRecoveryChangeData.status != 200
			? await passwordRecoveryChangeData.json()
			: await passwordRecoveryChangeData.text();
	if (passwordRecoveryChangeData.status != 200) {
		mapResponseError(passwordRecoveryChangeData, jsonPasswordRecoveryChangeData);
	}

	return jsonPasswordRecoveryChangeData;
}

export async function fetchSecurityPasswordChange({
	newPassword,
	twoFA,
	dispatch,
	navigate,
	accessToken,
}: TSecurityPasswordChangeBody) {
	const securityPasswordChangeData = await customFetch(
		API_ROUTES.SECURITY.PASSWORD.CHANGE.URL,
		{
			method: API_ROUTES.SECURITY.PASSWORD.CHANGE.METHOD,
			body: JSON.stringify({
				newPassword: newPassword,
				twoFA: twoFA,
			}),
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		}
	);

	const jsonSecurityPasswordChangeData = await securityPasswordChangeData.json();

	if (securityPasswordChangeData.status != 200) {
		if (securityPasswordChangeData.status === 503) {
			navigate(ROUTES.ROOT.INDEX);
		}
		if (securityPasswordChangeData.status === 401) {
			handleAuthorizationErrorState(
				dispatch,
				navigate,
				accessToken,
				jsonSecurityPasswordChangeData
			);
		}
		throw jsonSecurityPasswordChangeData;
	}

	if (securityPasswordChangeData.status != 200) {
		mapResponseError(securityPasswordChangeData, jsonSecurityPasswordChangeData);
	}

	return jsonSecurityPasswordChangeData;
}

export async function fetchSecuritySendOtp({
	identityType,
	state,
	dispatch,
	accessToken,
}: TSecuritySendOtpBody) {
	const securitySendOtpData = await customFetch(
		API_ROUTES.SECURITY.PASSWORD.OTP.SEND.URL,
		{
			method: API_ROUTES.SECURITY.PASSWORD.OTP.SEND.METHOD,
			body: JSON.stringify({
				state: state,
				identityType: identityType.toLowerCase(),
			}),
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		}
	);

	const jsonSecuritySendOtpData = await securitySendOtpData.json();
	if (securitySendOtpData.status != 200) {
		mapResponseError(securitySendOtpData, jsonSecuritySendOtpData);
	}

	dispatch && dispatch(setInputLength(jsonSecuritySendOtpData?.length));

	dispatch && dispatch(setMaskedContact(jsonSecuritySendOtpData?.maskedContact));
	dispatch && dispatch(setIsResendCodeActive(false));
	dispatch &&
		dispatch(updateCounter(Math.floor(jsonSecuritySendOtpData?.resendIn / 1000)));
	dispatch && dispatch(setSecuritySelectedIdentityType(identityType));

	return jsonSecuritySendOtpData;
}

export async function fetchGetTwoFaOptions({ dispatch, accessToken }: TDefaultBody) {
	const twoFaOptionsData = await customFetch(API_ROUTES.SECURITY.TWOFA.OPTIONS.URL, {
		method: API_ROUTES.SECURITY.TWOFA.OPTIONS.METHOD,
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});

	const jsonTwoFaOptionsData = await twoFaOptionsData.json();
	if (twoFaOptionsData.status != 200) {
		mapResponseError(twoFaOptionsData, jsonTwoFaOptionsData, undefined, dispatch);
	}

	dispatch(setTwoFaOptionsIdentityTypes(jsonTwoFaOptionsData));

	return jsonTwoFaOptionsData;
}

export async function fetchEnableTwoFa({
	identityType,
	identity,
	code,
	key,
	dispatch,
	accessToken,
}: TEnableTwoFaBody) {
	const enableTwoFaData = await customFetch(
		API_ROUTES.SECURITY.TWOFA.IDENTITY_TYPE.ENABLE.URL.replace(
			'{identityType}',
			identityType
		),
		{
			method: API_ROUTES.SECURITY.TWOFA.IDENTITY_TYPE.ENABLE.METHOD,
			body: JSON.stringify({
				key: key,
				identity: identity,
				code: code,
			}),
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		}
	);

	const jsonEnableTwoFaData = await enableTwoFaData.json();
	if (enableTwoFaData.status != 200) {
		mapResponseError(enableTwoFaData, jsonEnableTwoFaData, undefined, dispatch);
	}

	return jsonEnableTwoFaData;
}

export async function fetchDisableTwoFa({
	twoFA,
	identityType,
	dispatch,
	accessToken,
	navigate,
}: TDisableTwoFaBody) {
	const disableTwoFaData = await customFetch(
		API_ROUTES.SECURITY.TWOFA.IDENTITY_TYPE.DISABLE.URL.replace(
			'{identityType}',
			identityType
		),
		{
			method: API_ROUTES.SECURITY.TWOFA.IDENTITY_TYPE.DISABLE.METHOD,
			body: JSON.stringify(
				twoFA
					? {
						twoFA: twoFA,
					}
					: {}
			),
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		}
	);

	const jsonDisableTwoFaData =
		disableTwoFaData?.status != 200
			? await disableTwoFaData.json()
			: await disableTwoFaData.text();

	if (disableTwoFaData.status != 200) {
		if (jsonDisableTwoFaData?.code === 'MULTI_FACTOR_REQUIRED') {
			dispatch(setSecurityState(jsonDisableTwoFaData?.data?.state));
			dispatch(setSecurityMethodType('disable'));

			if (
				jsonDisableTwoFaData?.data?.identityTypes.length === 1 &&
				jsonDisableTwoFaData?.data?.identityTypes.find(
					(identity: TIdentity) => identity.name === 'GoogleAuthenticator'
				)
			) {
				dispatch(setSecuritySelectedIdentityType('googleauthenticator'));
				navigate(ROUTES.PROFILE.SECURITY.TWO_FA.TWO_FA);
			}

			if (
				jsonDisableTwoFaData?.data?.identityTypes.length === 1 &&
				jsonDisableTwoFaData?.data?.identityTypes.find(
					(identity: TIdentity) => identity.name === 'Email'
				)
			) {
				dispatch(setSecuritySelectedIdentityType('email'));
				fetchSecuritySendOtp({
					identityType: 'email',
					state: jsonDisableTwoFaData?.data?.state,
					dispatch: dispatch,
					accessToken: accessToken,
				});
				navigate(ROUTES.PROFILE.SECURITY.TWO_FA.OTP);
			}

			if (
				jsonDisableTwoFaData?.data?.identityTypes.length === 1 &&
				jsonDisableTwoFaData?.data?.identityTypes.find(
					(identity: TIdentity) => identity.name === 'Sms'
				)
			) {
				dispatch(setSecuritySelectedIdentityType('sms'));
				fetchSecuritySendOtp({
					identityType: 'sms',
					state: jsonDisableTwoFaData?.data?.state,
					dispatch: dispatch,
					accessToken: accessToken,
				});
				navigate(ROUTES.PROFILE.SECURITY.TWO_FA.OTP);
			}
		}
		mapResponseError(disableTwoFaData, jsonDisableTwoFaData, undefined, dispatch);
	}

	return jsonDisableTwoFaData;
}

export async function fetchGetAllDevices({
	dispatch,
	accessToken,
}: TDefaultBody) {
	const allDevicesData = await customFetch(
		API_ROUTES.SECURITY.DEVICES.ALL.URL,
		{
			method: API_ROUTES.SECURITY.DEVICES.ALL.METHOD,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		}
	);

	const jsonAllDevicesData = await allDevicesData.json();
	if (allDevicesData.status != 200) {
		mapResponseError(allDevicesData, jsonAllDevicesData, undefined, dispatch);
	}

	return jsonAllDevicesData;
}

export async function fetchResetDevice({
	dispatch,
	accessToken,
	deviceId
}: TResetDeviceBody) {
	const resetDeviceData = await customFetch(
		API_ROUTES.SECURITY.DEVICES.RESET.URL,
		{
			method: API_ROUTES.SECURITY.DEVICES.RESET.METHOD,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify({
				deviceId: deviceId
			})
		}
	);

	const jsonResetDeviceData =
		resetDeviceData.status != 200
			? await resetDeviceData.json()
			: await resetDeviceData.text();

	if (resetDeviceData.status != 200) {
		mapResponseError(resetDeviceData, jsonResetDeviceData, undefined, dispatch);
	}

	return jsonResetDeviceData;
}
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { CustomButton } from '../../common/buttons';
import { APIError } from '../../common/error';
import {
	selectExchangeFromAmount,
	selectExchangeFromAsset,
	selectExchangeRateText,
	selectExchangeToAmount,
	selectExchangeToAsset,
} from '../../../services/exchange/exchange.slice';
import { selectAccessToken } from '../../../services/auth';
import { useEffect } from 'react';
import { ROUTES } from '../../../types/routes';
import { HeaderLayout } from '../../common/header';
import { formatNumber, mapClassnameForMobile } from '../../../utils';
import { useSendExchange } from '../../../data/mutations/exchange';

const ExchangeConfirmationLayout = () => {
	const selectedFromAsset = useAppSelector(selectExchangeFromAsset);
	const selectedToAsset = useAppSelector(selectExchangeToAsset);
	const selectedFromAmount = useAppSelector(selectExchangeFromAmount);
	const selectedToAmount = useAppSelector(selectExchangeToAmount);
	const exchangeRateText = useAppSelector(selectExchangeRateText);

	const accessToken = useAppSelector(selectAccessToken);

	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		if (selectedFromAsset === undefined) {
			navigate(ROUTES.EXCHANGE.INDEX);
		}
	}, []);

	const { sendExchange, isError, isPending, error } = useSendExchange(
		dispatch,
		navigate
	);

	const handleBackClickButton = () => {
		navigate(ROUTES.EXCHANGE.INDEX);
	};

	const handleSubmit = () => {
		sendExchange({
			assetFrom: selectedFromAsset?.code as string,
			assetTo: selectedToAsset?.code as string,
			amount: Number(selectedFromAmount),
			dispatch: dispatch,
			accessToken: accessToken as string,
		});
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent aboutComponent'}>
				<HeaderLayout
					isAboutHeader
					isFunctionalHeader
					handleBackClick={handleBackClickButton}
				/>
				<div
					style={{ marginBottom: '16px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className="innerContainer">
						<h1 className="innerContainerMainText">
							{t('confirmation_title')}
						</h1>
						<div className="commonContainer">
							<div
								style={{
									height: '40px',
								}}
								className="commonItem"
							>
								<h1 className="cryptoWithdrawCommonItemLeftText">
									{t('exchangeConfirmation_youPay')}
								</h1>
								<h1 className="cryptoWithdrawCommonItemRightText">
									{formatNumber(
										selectedFromAmount,
										selectedFromAsset?.precision
									)}{' '}
									{selectedFromAsset?.code}
								</h1>
							</div>
						</div>

						<div className="commonContainer">
							<div
								style={{
									height: '40px',
								}}
								className="commonItem"
							>
								<h1
									style={{
										width: 'max-content',
									}}
									className="cryptoWithdrawCommonItemLeftText"
								>
									{t('exchangeConfirmation_conversionRate')}
								</h1>
								<h1
									style={{
										width: 'max-content',
									}}
									className="cryptoWithdrawCommonItemRightText"
								>
									{exchangeRateText}
								</h1>
							</div>

							<div
								style={{
									height: '40px',
								}}
								className="commonItem"
							>
								<h1 className="cryptoWithdrawCommonItemLeftText">
									{t('exchangeConfirmation_youReceive')}
								</h1>
								<h1 className="cryptoWithdrawCommonItemRightText">
									≈{' '}
									{formatNumber(
										selectedToAmount,
										selectedToAsset?.precision
									)}
									{selectedToAsset?.code}
								</h1>
							</div>
						</div>
						<h1
							style={{
								width: '100%',
								textAlign: 'left',
							}}
							className="cryptoWithdrawBottomWarningText"
						>
							{t('exchangeConfirmation_finalExchangeRateMayChange')}
						</h1>
						<APIError isError={isError} error={error} />
					</div>
					<div className="depositButtonsBottomContainer">
						<CustomButton
							isDisabled={false}
							isLoading={isPending}
							text={t('withdrawCryptoConfrimation_confirm') as string}
							onClick={handleSubmit}
							className="confirmFullWidthButton"
						/>
						<CustomButton
							isDisabled={false}
							isGrey
							isLoading={false}
							text={t('general_cancel') as string}
							onClick={handleBackClickButton}
							className="confirmFullWidthButton"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExchangeConfirmationLayout;

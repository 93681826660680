import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../../types/titles';
import CardWithdrawSummaryLayout from '../../../../components/withdraw/card/summary';

const CardWithdrawSummary = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.WITHDRAW.CARD.SUMMARY}</title>
			</Helmet>
			<CardWithdrawSummaryLayout />
		</>
	);
};

export default CardWithdrawSummary;

import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../types/titles';
import RegistrationPersonalLayout from '../../components/registration/index';

const RegistrationPersonal = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.REGISTRATION.INDEX}</title>
			</Helmet>
			<RegistrationPersonalLayout />
		</>
	);
};

export default RegistrationPersonal;

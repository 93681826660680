import { customFetch } from '../../services';
import { setInputLength } from '../../services/auth';
import { setIsResendCodeActive, setMaskedContact, updateCounter } from '../../services/root';
import { API_ROUTES } from '../../types/api.routes';
import {
	TBankWithdrawBody,
	TBankWithdrawCommissionBody,
	TBankWithdrawCreateTemplateBody,
	TBankWithdrawDeleteTemplateBody,
	TBankWithdrawParamsBody,
	TBankWithdrawTemplateBody,
	TBankWithdrawUpdateTemplateBody,
	TCardWithdrawBody,
	TCardWithdrawCardValidationBody,
	TCardWithdrawCommissionBody,
	TCardWithdrawCreateTemplateBody,
	TCardWithdrawCreateTemplateFromSettingsBody,
	TCardWithdrawDeleteTemplateBody,
	TCardWithdrawUpdateTemplateBody,
	TCryptoWithdrawAddressValidationBody,
	TCryptoWithdrawBody,
	TCryptoWithdrawCommissionBody,
	TCryptoWithdrawCreateTemplateBody,
	TCryptoWithdrawDeleteTemplateBody,
	TCryptoWithdrawTemplateBody,
	TCryptoWithdrawUpdateTemplateBody,
	TDefaultBody,
	TSecuritySendOtpBody,
} from '../../types/types';
import { mapResponseError } from '../../utils';

export async function fetchCryptoWithdrawCommission({
	amount,
	networkName,
	asset,
	accessToken,
	dispatch,
}: TCryptoWithdrawCommissionBody) {
	const cryptoWithdrawCommissionData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.COMMISSION.URL.replace(
			'{networkName}',
			networkName
		) +
		`?${new URLSearchParams({
			amount: amount,
			asset: asset,
		})}`,
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.COMMISSION.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonCryptoWithdrawCommissionData = await cryptoWithdrawCommissionData.json();
	if (cryptoWithdrawCommissionData.status != 200) {
		mapResponseError(
			cryptoWithdrawCommissionData,
			jsonCryptoWithdrawCommissionData,
			undefined,
			dispatch
		);
	}

	return jsonCryptoWithdrawCommissionData;
}

export async function fetchCryptoWithdrawTemplate({
	networkName,
	dispatch,
	accessToken,
}: TCryptoWithdrawTemplateBody) {
	const cryptoWithdrawTemplateData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.GET_TEMPLATE.URL.replace(
			'{networkName}',
			networkName
		),
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.GET_TEMPLATE.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonCryptoWithdrawTemplateData = await cryptoWithdrawTemplateData.json();
	if (cryptoWithdrawTemplateData.status != 200) {
		mapResponseError(
			cryptoWithdrawTemplateData,
			jsonCryptoWithdrawTemplateData,
			undefined,
			dispatch
		);
	}

	return jsonCryptoWithdrawTemplateData;
}

export async function fetchCryptoWithdrawUpdateTemplate({
	networkName,
	dispatch,
	accessToken,
	templateId,
	templateName,
}: TCryptoWithdrawUpdateTemplateBody) {
	const cryptoWithdrawUpdateTemplateData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.UPDATE_TEMPLATE.URL.replace(
			'{networkName}',
			networkName
		).replace('{templateId}', templateId),
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.UPDATE_TEMPLATE.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
			body: JSON.stringify({
				templateName: templateName,
			}),
		}
	);

	const jsonCryptoWithdrawUpdateTemplateData =
		await cryptoWithdrawUpdateTemplateData.json();
	if (cryptoWithdrawUpdateTemplateData.status != 200) {
		mapResponseError(
			cryptoWithdrawUpdateTemplateData,
			jsonCryptoWithdrawUpdateTemplateData,
			undefined,
			dispatch
		);
	}

	return jsonCryptoWithdrawUpdateTemplateData;
}

export async function fetchCryptoWithdrawDeleteTemplate({
	networkName,
	dispatch,
	accessToken,
	templateId,
}: TCryptoWithdrawDeleteTemplateBody) {
	const cryptoWithdrawDeleteTemplateData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.DELETE_TEMPLATE.URL.replace(
			'{networkName}',
			networkName
		).replace('{templateId}', templateId),
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.DELETE_TEMPLATE.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonCryptoWithdrawDeleteTemplateData =
		await cryptoWithdrawDeleteTemplateData.json();
	if (cryptoWithdrawDeleteTemplateData.status != 200) {
		mapResponseError(
			cryptoWithdrawDeleteTemplateData,
			jsonCryptoWithdrawDeleteTemplateData,
			undefined,
			dispatch
		);
	}

	return jsonCryptoWithdrawDeleteTemplateData;
}

export async function fetchCryptoWithdrawCreateTemplate({
	networkName,
	dispatch,
	accessToken,
	templateName,
	cryptoAddress,
}: TCryptoWithdrawCreateTemplateBody) {
	const cryptoWithdrawCreateTemplateData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.CREATE_TEMPLATE.URL.replace(
			'{networkName}',
			networkName
		),
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.CREATE_TEMPLATE.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
			body: JSON.stringify({
				templateName: templateName,
				cryptoAddress: cryptoAddress,
			}),
		}
	);

	const jsonCryptoWithdrawCreateTemplateData =
		await cryptoWithdrawCreateTemplateData.json();
	if (cryptoWithdrawCreateTemplateData.status != 200) {
		mapResponseError(
			cryptoWithdrawCreateTemplateData,
			jsonCryptoWithdrawCreateTemplateData,
			undefined,
			dispatch
		);
	}

	return jsonCryptoWithdrawCreateTemplateData;
}

export async function fetchCryptoWithdrawAddressValidation({
	cryptoAddress,
	networkName,
	accessToken,
	dispatch,
}: TCryptoWithdrawAddressValidationBody) {
	const cryptoWithdrawAddressValidationData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.CHECK_ADDRESS.URL.replace(
			'{networkName}',
			networkName
		).replace('{cryptoAddress}', cryptoAddress),
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.CHECK_ADDRESS.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonCryptoWithdrawAddressValidationData =
		await cryptoWithdrawAddressValidationData.json();
	if (cryptoWithdrawAddressValidationData.status != 200) {
		mapResponseError(
			cryptoWithdrawAddressValidationData,
			jsonCryptoWithdrawAddressValidationData,
			undefined,
			dispatch
		);
	}

	return jsonCryptoWithdrawAddressValidationData;
}

export async function fetchCryptoWithdrawWithoutTwoFa({
	cryptoAddress,
	accountNumber,
	amount,
	assetCode,
	networkCode,
	networkName,
	accessToken,
	twoFa,
	dispatch,
}: TCryptoWithdrawBody) {
	const cryptoWithdrawData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.WITHDRAW.URL.replace(
			'{networkName}',
			networkName
		),
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.CRYPTO.WITHDRAW.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
			body: JSON.stringify({
				cryptoAddress: cryptoAddress,
				accountNumber: accountNumber,
				assetCode: assetCode,
				networkCode: networkCode,
				amount: amount,
				twoFa: twoFa
					? {
						state: twoFa?.state,
						code: twoFa?.code,
						identityType: twoFa?.identityType,
					}
					: undefined,
			}),
		}
	);

	const jsonCryptoWithdrawData = await cryptoWithdrawData.json();
	if (cryptoWithdrawData.status != 200) {
		mapResponseError(cryptoWithdrawData, jsonCryptoWithdrawData, undefined, dispatch);
	}

	return jsonCryptoWithdrawData;
}

export async function fetchCardWithdrawCommission({
	amount,
	asset,
	accessToken,
	dispatch,
}: TCardWithdrawCommissionBody) {
	const cardWithdrawCommissionData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.CARD.COMMISSION.URL +
		`?${new URLSearchParams({
			amount: amount,
			currencyCode: asset,
		})}`,
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.CARD.COMMISSION.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonCardWithdrawCommissionData = await cardWithdrawCommissionData.json();
	if (cardWithdrawCommissionData.status != 200) {
		mapResponseError(
			cardWithdrawCommissionData,
			jsonCardWithdrawCommissionData,
			undefined,
			dispatch
		);
	}

	return jsonCardWithdrawCommissionData;
}

export async function fetchCardWithdrawTemplate({ dispatch, accessToken }: TDefaultBody) {
	const cardWithdrawTemplateData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.CARD.GET_TEMPLATE.URL,
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.CARD.GET_TEMPLATE.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonCardWithdrawTemplateData = await cardWithdrawTemplateData.json();
	if (cardWithdrawTemplateData.status != 200) {
		mapResponseError(
			cardWithdrawTemplateData,
			jsonCardWithdrawTemplateData,
			undefined,
			dispatch
		);
	}

	return jsonCardWithdrawTemplateData;
}

export async function fetchCardWithdrawUpdateTemplate({
	dispatch,
	accessToken,
	templateId,
	templateName,
}: TCardWithdrawUpdateTemplateBody) {
	const cardWithdrawUpdateTemplateData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.CARD.UPDATE_TEMPLATE.URL.replace(
			'{templateId}',
			templateId
		),
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.CARD.UPDATE_TEMPLATE.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
			body: JSON.stringify({
				templateName: templateName,
			}),
		}
	);

	const jsonCardWithdrawUpdateTemplateData =
		await cardWithdrawUpdateTemplateData.json();
	if (cardWithdrawUpdateTemplateData.status != 200) {
		mapResponseError(
			cardWithdrawUpdateTemplateData,
			jsonCardWithdrawUpdateTemplateData,
			undefined,
			dispatch
		);
	}

	return jsonCardWithdrawUpdateTemplateData;
}

export async function fetchCardWithdrawDeleteTemplate({
	dispatch,
	accessToken,
	templateId,
}: TCardWithdrawDeleteTemplateBody) {
	const cardWithdrawDeleteTemplateData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.CARD.DELETE_TEMPLATE.URL.replace(
			'{templateId}',
			templateId
		),
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.CARD.DELETE_TEMPLATE.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonCardWithdrawDeleteTemplateData =
		await cardWithdrawDeleteTemplateData.json();
	if (cardWithdrawDeleteTemplateData.status != 200) {
		mapResponseError(
			cardWithdrawDeleteTemplateData,
			jsonCardWithdrawDeleteTemplateData,
			undefined,
			dispatch
		);
	}

	return jsonCardWithdrawDeleteTemplateData;
}

export async function fetchCardWithdrawCreateTemplate({
	cardNumber,
	cardholderName,
	userCardId,
	dispatch,
	accessToken,
	templateName,
}: TCardWithdrawCreateTemplateBody) {
	const cardWithdrawCreateTemplateData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.CARD.CREATE_TEMPLATE.URL,
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.CARD.CREATE_TEMPLATE.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
			body: JSON.stringify({
				templateName: templateName,
				cardNumber: cardNumber,
				cardholderName: cardholderName,
				userCardId: userCardId,
			}),
		}
	);

	const jsonCardWithdrawCreateTemplateData =
		await cardWithdrawCreateTemplateData.json();
	if (cardWithdrawCreateTemplateData.status != 200) {
		mapResponseError(
			cardWithdrawCreateTemplateData,
			jsonCardWithdrawCreateTemplateData,
			undefined,
			dispatch
		);
	}

	return jsonCardWithdrawCreateTemplateData;
}

export async function fetchCardWithdrawCreateTemplateFromSettings({
	cardNumber,
	cardholderName,
	isCardholderCard,
	dispatch,
	accessToken,
	templateName,
	currencyCode,
}: TCardWithdrawCreateTemplateFromSettingsBody) {
	const userCardId = await fetchCardWithdrawCardValidation({
		cardNumber: cardNumber,
		cardholderName: cardholderName,
		isCardholderCard: isCardholderCard,
		dispatch: dispatch,
		accessToken: accessToken,
		currencyCode: currencyCode,
	});

	const cardWithdrawCreateTemplateData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.CARD.CREATE_TEMPLATE.URL,
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.CARD.CREATE_TEMPLATE.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
			body: JSON.stringify({
				templateName: templateName,
				cardNumber: cardNumber,
				cardholderName: cardholderName,
				userCardId: userCardId?.userCardId,
			}),
		}
	);

	const jsonCardWithdrawCreateTemplateData =
		await cardWithdrawCreateTemplateData.json();
	if (cardWithdrawCreateTemplateData.status != 200) {
		mapResponseError(
			cardWithdrawCreateTemplateData,
			jsonCardWithdrawCreateTemplateData,
			undefined,
			dispatch
		);
	}

	return jsonCardWithdrawCreateTemplateData;
}

export async function fetchCardWithdrawCardValidation({
	cardNumber,
	cardholderName,
	isCardholderCard,
	currencyCode,
	accessToken,
	dispatch,
}: TCardWithdrawCardValidationBody) {
	const cardWithdrawCardValidationData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.CARD.CHECK_CARD.URL,
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.CARD.CHECK_CARD.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
			body: JSON.stringify({
				cardNumber: cardNumber,
				cardholderName: cardholderName,
				isCardholderCard: isCardholderCard,
				currencyCode: currencyCode,
			}),
		}
	);

	const jsonCardWithdrawCardValidationData =
		await cardWithdrawCardValidationData.json();
	if (cardWithdrawCardValidationData.status != 200) {
		mapResponseError(
			cardWithdrawCardValidationData,
			jsonCardWithdrawCardValidationData,
			undefined,
			dispatch
		);
	}

	return jsonCardWithdrawCardValidationData;
}

export async function fetchCardWithdraw({
	accountNumber,
	amount,
	currencyCode,
	userCardId,
	accessToken,
	twoFa,
	dispatch,
}: TCardWithdrawBody) {
	const cardWithdrawData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.CARD.WITHDRAW.URL,
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.CARD.WITHDRAW.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
			body: JSON.stringify({
				currencyCode: currencyCode,
				accountNumber: accountNumber,
				userCardId: userCardId,
				amount: amount,
				twoFa: twoFa
					? {
						state: twoFa?.state,
						code: twoFa?.code,
						identityType: twoFa?.identityType,
					}
					: undefined,
			}),
		}
	);

	const jsonCardWithdrawData = await cardWithdrawData.json();
	if (cardWithdrawData.status != 200) {
		mapResponseError(cardWithdrawData, jsonCardWithdrawData, undefined, dispatch);
	}

	return jsonCardWithdrawData;
}

export async function fetchBankWithdrawTemplate({
	paymentSystem,
	dispatch,
	accessToken,
}: TBankWithdrawTemplateBody) {
	const bankWithdrawTemplateData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.BANK.GET_TEMPLATE.URL +
		(paymentSystem
			? `?${new URLSearchParams({
				paymentSystem: paymentSystem,
			})}`
			: ''),
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.BANK.GET_TEMPLATE.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonBankWithdrawTemplateData = await bankWithdrawTemplateData.json();
	if (bankWithdrawTemplateData.status != 200) {
		mapResponseError(
			bankWithdrawTemplateData,
			jsonBankWithdrawTemplateData,
			undefined,
			dispatch
		);
	}

	return jsonBankWithdrawTemplateData;
}

export async function fetchBankWithdrawSendTemplate({
	templateName,
	params,
	dispatch,
	accessToken,
}: TBankWithdrawCreateTemplateBody) {
	const bankWithdrawCreateTemplateData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.BANK.CREATE_TEMPLATE.URL,
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.BANK.CREATE_TEMPLATE.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
			body: JSON.stringify({
				templateName: templateName,
				params: params,
			}),
		}
	);

	const jsonBankWithdrawCreateTemplateData =
		await bankWithdrawCreateTemplateData.json();
	if (bankWithdrawCreateTemplateData.status != 200) {
		mapResponseError(
			bankWithdrawCreateTemplateData,
			jsonBankWithdrawCreateTemplateData,
			undefined,
			dispatch
		);
	}

	return jsonBankWithdrawCreateTemplateData;
}

export async function fetchBankWithdrawUpdateTemplate({
	templateName,
	templateId,
	dispatch,
	accessToken,
}: TBankWithdrawUpdateTemplateBody) {
	const bankWithdrawUpdateTemplateData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.BANK.UPDATE_TEMPLATE.URL.replace(
			'{templateId}',
			templateId
		),
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.BANK.UPDATE_TEMPLATE.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
			body: JSON.stringify({
				templateName: templateName,
			}),
		}
	);

	const jsonBankWithdrawUpdateTemplateData =
		await bankWithdrawUpdateTemplateData.json();
	if (bankWithdrawUpdateTemplateData.status != 200) {
		mapResponseError(
			bankWithdrawUpdateTemplateData,
			jsonBankWithdrawUpdateTemplateData,
			undefined,
			dispatch
		);
	}

	return jsonBankWithdrawUpdateTemplateData;
}

export async function fetchBankWithdrawDeleteTemplate({
	templateId,
	dispatch,
	accessToken,
}: TBankWithdrawDeleteTemplateBody) {
	const bankWithdrawDeleteTemplateData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.BANK.DELETE_TEMPLATE.URL.replace(
			'{templateId}',
			templateId
		),
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.BANK.DELETE_TEMPLATE.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonBankWithdrawDeleteTemplateData =
		await bankWithdrawDeleteTemplateData.json();
	if (bankWithdrawDeleteTemplateData.status != 200) {
		mapResponseError(
			bankWithdrawDeleteTemplateData,
			jsonBankWithdrawDeleteTemplateData,
			undefined,
			dispatch
		);
	}

	return jsonBankWithdrawDeleteTemplateData;
}

export async function fetchBankWithdrawParams({ dispatch, accessToken }: TDefaultBody) {
	const bankWithdrawParamsData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.BANK.GET_PARAMS.URL,
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.BANK.GET_PARAMS.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonBankWithdrawParamsData = await bankWithdrawParamsData.json();
	if (bankWithdrawParamsData.status != 200) {
		mapResponseError(
			bankWithdrawParamsData,
			jsonBankWithdrawParamsData,
			undefined,
			dispatch
		);
	}

	return jsonBankWithdrawParamsData;
}

export async function fetchSendBankWithdrawParams({
	stepId,
	params,
	dispatch,
	accessToken,
}: TBankWithdrawParamsBody) {
	const bankWithdrawParamsData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.BANK.SEND_PARAMS.URL,
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.BANK.SEND_PARAMS.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
			body: JSON.stringify({
				stepId: stepId,
				params: params,
			}),
		}
	);

	const jsonBankWithdrawParamsData = await bankWithdrawParamsData.json();
	if (bankWithdrawParamsData.status != 200) {
		mapResponseError(
			bankWithdrawParamsData,
			jsonBankWithdrawParamsData,
			undefined,
			dispatch
		);
	}

	return jsonBankWithdrawParamsData;
}

export async function fetchSendBankWithdraw({
	accountNumber,
	amount,
	currencyCode,
	twoFA,
	params,
	dispatch,
	accessToken,
}: TBankWithdrawBody) {
	const bankWithdrawData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.BANK.WITHDRAW.URL,
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.BANK.WITHDRAW.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
			body: JSON.stringify({
				accountNumber: accountNumber,
				amount: amount,
				currencyCode: currencyCode,
				twoFA: twoFA,
				params: params,
			}),
		}
	);

	const jsonBankWithdrawData = await bankWithdrawData.json();
	if (bankWithdrawData.status != 200) {
		mapResponseError(bankWithdrawData, jsonBankWithdrawData, undefined, dispatch);
	}

	return jsonBankWithdrawData;
}

export async function fetchBankWithdrawCommission({
	amount,
	asset,
	bankCountryCode,
	accessToken,
	dispatch,
}: TBankWithdrawCommissionBody) {
	const bankWithdrawCommissionData = await customFetch(
		API_ROUTES.OPERATIONS.WITHDRAW.BANK.COMMISSION.URL +
		`?${new URLSearchParams({
			amount: amount,
			currencyCode: asset,
			bankCountryCode: bankCountryCode,
		})}`,
		{
			method: API_ROUTES.OPERATIONS.WITHDRAW.BANK.COMMISSION.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonBankWithdrawCommissionData = await bankWithdrawCommissionData.json();
	if (bankWithdrawCommissionData.status != 200) {
		mapResponseError(
			bankWithdrawCommissionData,
			jsonBankWithdrawCommissionData,
			undefined,
			dispatch
		);
	}

	return jsonBankWithdrawCommissionData;
}

export async function fetchOperationsSendOtp({
	identityType,
	state,
	dispatch,
	accessToken
}: TSecuritySendOtpBody) {
	const operationsSendOtpData = await customFetch(
		API_ROUTES.OPERATIONS.TWOFA.OTP.SEND.URL,
		{
			method: API_ROUTES.OPERATIONS.TWOFA.OTP.SEND.METHOD,
			body: JSON.stringify({
				state: state,
				identityType: identityType.toLowerCase(),
			}),
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
		}
	);

	const jsonOperationsSendOtpData = await operationsSendOtpData.json();
	if (operationsSendOtpData.status != 200) {
		mapResponseError(operationsSendOtpData, jsonOperationsSendOtpData);
	}

	dispatch && dispatch(setInputLength(jsonOperationsSendOtpData?.length));
	dispatch && dispatch(setIsResendCodeActive(false));
	dispatch && dispatch(setMaskedContact(jsonOperationsSendOtpData?.maskedContact));
	dispatch &&
		dispatch(updateCounter(Math.floor(jsonOperationsSendOtpData?.resendIn / 1000)));
		dispatch &&
		dispatch(updateCounter(Math.floor(jsonOperationsSendOtpData?.resendIn / 1000)));
	

	return jsonOperationsSendOtpData;
}
import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../types/titles';
import RegistrationSummaryLayout from '../../../components/registration/summary';

const RegistrationSummary = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.REGISTRATION.DATA}</title>
			</Helmet>
			<RegistrationSummaryLayout />
		</>
	);
};

export default RegistrationSummary;

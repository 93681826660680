import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../../types/titles';
import CardWithdrawConfirmationLayout from '../../../../components/withdraw/card/confirmation';

const CardWithdrawConfirmation = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.WITHDRAW.CARD.CONFIRMATION}</title>
			</Helmet>
			<CardWithdrawConfirmationLayout />
		</>
	);
};

export default CardWithdrawConfirmation;

import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { WhaleIcon } from '../../common/icons';
import { APIError } from '../../common/error';
import { CustomButton } from '../../common/buttons';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	selectConvertLowBalancesError,
	selectConvertLowBalancesIsError,
	setConvertLowBalancesInfoInit,
} from '../../../services/wallet';
import { useEffect } from 'react';

const WalletConvertSmallBalancesSummaryLayout = () => {
	const { t } = useTranslation();
	const navigate = useCustomNavigate();
	const dispatch = useAppDispatch();

	const isError = useAppSelector(selectConvertLowBalancesIsError);
	const error = useAppSelector(selectConvertLowBalancesError);

	const handleBackClickButton = () => {
		dispatch(setConvertLowBalancesInfoInit());
		navigate(ROUTES.WALLET.INDEX);
	};

	useEffect(() => {
		setTimeout(() => {
			navigate(ROUTES.WALLET.INDEX);
		}, 3000);
	}, []);

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<div
					style={{
						zIndex: '1',
						top: 'auto',
						marginBottom: '140px',
						gap: '0',
					}}
					className="walletLayout authMainContainer"
				>
					<WhaleIcon className="whaleConvertIcon" />
					<h1 className={'modalWalletConvertSummaryHeader'}>
						{t('operation_operationIsProcessed')}
					</h1>

					{isError ? (
						<APIError isError={isError} error={error} />
					) : (
						<h1 className={'modalWalletConvertSummaryBottom'}>
							{t('operation_conversionProcessedDetails')}
						</h1>
					)}
				</div>
			</div>
			<div
				style={{
					zIndex: '3',
				}}
				className="depositButtonsBottomContainer"
			>
				<CustomButton
					text={t('general_finishAndClose') as string}
					onClick={handleBackClickButton}
					className="confirmFullWidthButton"
				/>
			</div>
		</div>
	);
};

export default WalletConvertSmallBalancesSummaryLayout;

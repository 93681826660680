import React, { useEffect, useState } from 'react';
import { CustomCheckbox } from '../../common/checkboxes';
import {
	selectRegistrationBackCheckoxes,
	selectRegistrationCheckoxes,
	setRegistrationIsChecked
} from '../../../services/registration';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { TRegistrationGetEulaCheckbox } from '../../../types/types';

interface Props {
	checked: boolean[];
	isError: boolean;
	setFakeError: React.Dispatch<React.SetStateAction<boolean>>;
}
const RegistrationCheckboxes = ({ checked, isError, setFakeError }: Props) => {
	const checkboxes = useAppSelector(selectRegistrationCheckoxes);
	const backCheckBoxes = useAppSelector(selectRegistrationBackCheckoxes);

	const dispatch = useAppDispatch();

	const [mappedEula, setMappedEula] =
		useState<TRegistrationGetEulaCheckbox[]>();

	useEffect(() => {
		if (backCheckBoxes) {
			dispatch(
				setRegistrationIsChecked(
					[false, false, false].map((value, idx) => {
						if (idx < backCheckBoxes?.length) {
							return backCheckBoxes[idx]?.isSelected;
						}
						return false;
					})
				)
			);
		}
	}, [backCheckBoxes]);

	useEffect(() => {
		setMappedEula(checkboxes);
	}, [checkboxes]);

	const handleCheckBoxChange = (isChecked: boolean, idx: number) => {
		setFakeError(false);
		const localChecked = [...checked];
		localChecked[idx] = isChecked;
		dispatch(setRegistrationIsChecked(localChecked));
	};

	return (
		<div className={'registrationCheckboxes'}>
			{mappedEula?.map(
				//eslint-disable-next-line
				(checkBox: any, idx: number) => (
					<div className={'registrationCheckbox'}>
						<CustomCheckbox
							isError={isError}
							key={idx}
							checked={checked[idx]}
							onChange={(
								e: React.ChangeEvent<HTMLInputElement>
							) => handleCheckBoxChange(e.target.checked, idx)}
						/>
						<h1
							className={
								isError
									? 'registrationCheckboxTextError'
									: 'registrationCheckboxText'
							}
							dangerouslySetInnerHTML={{
								__html: checkBox?.questionValue
									.replaceAll('/n', '')
									.replaceAll(
										'href',
										"target='_blank' rel='noreferrer noopener' href"
									)
							}}
						></h1>
					</div>
				)
			)}
		</div>
	);
};

export default RegistrationCheckboxes;

import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { selectMainAccountRests, selectMainEWallet } from '../../../../services/wallet';
import {
	selectCryptoWithdrawAddress,
	selectCryptoWithdrawAmount,
	selectCryptoWithdrawAsset,
	selectCryptoWithdrawFee,
	selectCryptoWithdrawIsChecked,
	selectCryptoWithdrawNetwork,
} from '../../../../services/withdraw/crypto.withdraw.slice';
import {
	TAccountRest,
	TAsset,
	TInnerCryptoWithdraw,
	TInnerCryptoWithdrawAsset,
} from '../../../../types/types';
import { ROUTES } from '../../../../types/routes';
import { HeaderLayout } from '../../../common/header';
import { formatNumber, mapClassnameForMobile } from '../../../../utils';
import { APIError } from '../../../common/error';
import { CustomButton } from '../../../common/buttons';
import { selectAccessToken } from '../../../../services/auth';
import { useEffect, useState } from 'react';
import { useSendCryptoWithdraw } from '../../../../data/mutations/withdraw';

interface Props {
	address: string;
	fee: string;
	selectedAsset: TInnerCryptoWithdrawAsset | undefined;
	selectedNetwork: TInnerCryptoWithdraw | undefined;
	amount: string;
	accessToken: string;
	mainCurrencies: TAccountRest | undefined;
	isChecked: boolean;
	accountNumber: string;
}

const CryptoWithdrawConfirmationFunctionalLayout = ({
	address,
	accessToken,
	amount,
	fee,
	selectedAsset,
	selectedNetwork,
	mainCurrencies,
	isChecked,
	accountNumber,
}: Props) => {
	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();
	const { t } = useTranslation();

	const [mainAsset, setMainAsset] = useState<TAsset>();

	useEffect(() => {
		if (
			address === '' ||
			selectedAsset === undefined ||
			selectedNetwork === undefined
		) {
			navigate(ROUTES.WITHDRAW.CRYPTO.INDEX);
		}
		if (mainCurrencies?.assets) {
			setMainAsset(
				mainCurrencies?.assets?.find(
					(asset) => asset?.code === selectedAsset?.code
				)
			);
		}
	}, []);

	const { sendCryptoWithdraw, isError, error, isPending } = useSendCryptoWithdraw(
		dispatch,
		navigate,
		accessToken
	);

	const handleBackClickButton = () => {
		navigate(ROUTES.WITHDRAW.CRYPTO.INDEX);
	};

	const handleSubmit = () => {
		sendCryptoWithdraw({
			accountNumber: accountNumber,
			accessToken: accessToken,
			amount: amount,
			cryptoAddress: address,
			assetCode: selectedAsset?.code as string,
			networkCode: selectedNetwork?.networkCode as string,
			networkName: selectedNetwork?.networkName as string,
			dispatch: dispatch,
		});
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent aboutComponent'}>
				<HeaderLayout
					isAboutHeader
					isFunctionalHeader
					handleBackClick={handleBackClickButton}
				/>
				<div
					style={{ marginBottom: '16px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className="innerContainer">
						<h1 className="innerContainerMainText">
							{t('confirmation_title')}
						</h1>
						<div className="commonContainer">
							<div
								style={{
									height: '40px',
								}}
								className="commonItem"
							>
								<h1 className="cryptoWithdrawCommonItemLeftText">
									{t('withdrawCrypto_withdrawAsset')}
								</h1>
								<h1 className="cryptoWithdrawCommonItemRightText">
									{selectedAsset?.code}{' '}
									{`(${
										mainCurrencies?.assets?.find(
											(asset) => asset?.code === selectedAsset?.code
										)?.name
											? mainCurrencies?.assets?.find(
													(asset) =>
														asset?.code ===
														selectedAsset?.code
											  )?.name
											: selectedAsset?.code
									})`}
								</h1>
							</div>
							<div
								style={{
									height: '40px',
								}}
								className="commonItem"
							>
								<h1 className="cryptoWithdrawCommonItemLeftText">
									{t('general_network')}
								</h1>
								<h1 className="cryptoWithdrawCommonItemRightText">
									{selectedNetwork?.networkName}{' '}
									{selectedNetwork?.networkCode}
								</h1>
							</div>
							<div
								style={{
									height: '40px',
								}}
								className="commonItem"
							>
								<h1 className="cryptoWithdrawCommonItemLeftTextAddress">
									{t('withdrawCryptoConfirmation_address')}
								</h1>
								<h1
									style={{
										wordBreak: 'break-word',
										width: '200px !important',
									}}
									className="cryptoWithdrawCommonItemRightText"
								>
									{address}
								</h1>
							</div>
						</div>

						<div className="commonContainer">
							<div
								style={{
									height: '40px',
								}}
								className="commonItem"
							>
								<h1 className="cryptoWithdrawCommonItemLeftText">
									{t('withdraw_totalWithdraw')}
								</h1>
								<h1 className="cryptoWithdrawCommonItemRightText">
									{isChecked
										? formatNumber(
												Number(amount) + fee,
												mainAsset?.precision
										  )
										: formatNumber(
												Number(amount),
												mainAsset?.precision
										  )}{' '}
									{selectedAsset?.code}
								</h1>
							</div>
							<div
								style={{
									height: '40px',
								}}
								className="commonItem"
							>
								<h1 className="cryptoWithdrawCommonItemLeftText">
									{t('withdrawConfirmation_withdrawFee')}
								</h1>
								<h1 className="cryptoWithdrawCommonItemRightText">
									{formatNumber(fee, mainAsset?.precision)}{' '}
									{selectedAsset?.code}
								</h1>
							</div>
							<div
								style={{
									height: '40px',
								}}
								className="commonItem"
							>
								<h1 className="cryptoWithdrawCommonItemLeftText">
									{t('withdrawConfirmation_toBeReceived')}
								</h1>
								<h1 className="cryptoWithdrawCommonItemRightText">
									{isChecked
										? formatNumber(
												Number(amount),
												mainAsset?.precision
										  )
										: formatNumber(
												Number(amount) - Number(fee),
												mainAsset?.precision
										  )}{' '}
									{selectedAsset?.code}
								</h1>
							</div>
						</div>
						<APIError isError={isError} error={error} />
					</div>
					<div className="depositButtonsBottomContainer">
						<h1 className="cryptoWithdrawBottomWarningText">
							{t('withdrawCryptoConfirmation_withdrawWarn')}
						</h1>
						<CustomButton
							isDisabled={false}
							isLoading={isPending}
							text={t('withdrawCryptoConfrimation_confirm') as string}
							onClick={handleSubmit}
							className="confirmFullWidthButton"
						/>
						<CustomButton
							isDisabled={false}
							isGrey
							isLoading={false}
							text={t('general_cancel') as string}
							onClick={handleBackClickButton}
							className="confirmFullWidthButton"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const CryptoWithdrawConfirmationLayout = () => {
	const mainCurrencies = useAppSelector(selectMainAccountRests);
	const address = useAppSelector(selectCryptoWithdrawAddress);
	const amount = useAppSelector(selectCryptoWithdrawAmount);
	const selectedAsset = useAppSelector(selectCryptoWithdrawAsset);
	const selectedNetwork = useAppSelector(selectCryptoWithdrawNetwork);
	const fee = useAppSelector(selectCryptoWithdrawFee);
	const accessToken = useAppSelector(selectAccessToken);
	const isChecked = useAppSelector(selectCryptoWithdrawIsChecked);
	const accountNumber = useAppSelector(selectMainEWallet);

	return (
		<CryptoWithdrawConfirmationFunctionalLayout
			mainCurrencies={mainCurrencies}
			accountNumber={accountNumber}
			address={address}
			amount={amount}
			selectedAsset={selectedAsset}
			selectedNetwork={selectedNetwork}
			fee={fee}
			accessToken={accessToken as string}
			isChecked={isChecked}
		/>
	);
};

export default CryptoWithdrawConfirmationLayout;

import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '../../store';
import { TIdentity } from '../../types/types';

export const selectWithdrawType = (state: TRootState) => state.withdraw.withdrawType;

export const selectWithdrawState = (state: TRootState) => state.withdraw.state;

export const selectWithdrawSelectedIdentityTypes = (state: TRootState) => state.withdraw.selectedIdentityType;

export const selectWithdrawIdentityTypes = (state: TRootState) =>
    state.withdraw.identityTypes;

interface IRootInitialState {
    state: string;
    selectedIdentityType: string;
    identityTypes: TIdentity[];
    withdrawType: string;
}

const initialState: IRootInitialState = {
    state: '',
    selectedIdentityType: '',
    identityTypes: [],
    withdrawType: ''
};

export const withdrawSlice = createSlice({
    name: 'withdraw',
    initialState,
    reducers: {
        setWithdrawType(state, action) {
            state.withdrawType = action.payload;
        },
        setWithdrawState(state, action) {
            state.state = action.payload;
        },
        setWithdrawSelectedIdentityType(state, action) {
            state.selectedIdentityType = action.payload;
        },
        setWithdrawIdentityTypes(state, action) {
            state.identityTypes = action.payload;
        },
        setWithdrawInit(state) {
            state.state = '';
            state.selectedIdentityType = '';
            state.identityTypes = [];
            state.withdrawType = ''
        }
    },
});

export const {
    setWithdrawInit,
    setWithdrawIdentityTypes,
    setWithdrawSelectedIdentityType,
    setWithdrawState,
    setWithdrawType
} = withdrawSlice.actions;
